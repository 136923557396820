import * as yup from 'yup'

const ALPHANUMERIC_REGEXP = /^.[a-zA-Z0-9]+$/

const cycleName = yup
  .string()
  .ensure()
  .required('Cycle name cannot be empty')
  .min(2, 'should be at least 3 characters')

const crop = yup
  .string()
  .ensure()
  .required('Please select crop for the cycle')

export const newCycleSchema = yup.object().shape({
  name: cycleName,
  crop
})

const stageName = yup
  .string()
  .ensure()
  .required('growingStage name cannot be empty')
  .min(2, 'should be at least 3 characters')

export const newStageSchema = yup.object().shape({
  name: stageName
})

const durationInMinutes = yup
  .number()
  .integer()
  .moreThan(1, 'Minimum interval is 1 minutes')
  .lessThan(61, 'Maximum interval is 1 hour')

const precisionInPercents = yup
  .number()
  .integer()
  .moreThan(1, 'Should be positive')
  .lessThan(101, 'Maximum precision is 100%')

export const newAlarmSettings = yup.object().shape({
  durationInMinutes,
  precisionInPercents,
})
