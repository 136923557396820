import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { FiltersSection } from "src/components/forecastOfCriticalSituations/MapInfo";
import { Map } from "src/components";
import {
  MapContainer,
  OneFour,
  PageHeader,
  Root,
  ThreeFour,
  RowWrapper,
} from "src/ui";

const path = `/analytics/forecast-of-critical-situations/${123}`;

export const ForecastOfCriticalSituations = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <PageHeader title={t("forecast_of_critical_situations")} />
      <RowWrapper>
        <ThreeFour>
          <MapContainer>
            <Map />
            <Field
              top="49%"
              left="27.5%"
              width="80px"
              height="180px"
              radius="61%"
              rotate="48deg"
            >
              <FieldLink to={path} />
            </Field>
          </MapContainer>
        </ThreeFour>
        <OneFour>
          <FiltersSection />
        </OneFour>
      </RowWrapper>
    </Root>
  );
};

const Field = styled.span`
  position: absolute;
  display: inline-block;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  top: ${(p) => p.top};
  left: ${(p) => p.left};
  border-radius: ${(p) => p.radius};
  transform: rotate(${(p) => p.rotate});
  z-index: 1;
  cursor: pointer;
`;

const FieldLink = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 100%;
`;
