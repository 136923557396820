import React from 'react'
import styled from 'styled-components'
import Autocomplete from 'react-autocomplete'

const AutocompleteItem = styled.li`
  list-style-type: none;
  background-color: white;

  padding: 10px 15px;
  font-size: 16px;

  color: #4c4c4c;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;

  ${(p) =>
    p.isHighlighted &&
    `
    background-color: #f2f2f2;
  `}
`

const AutocompleteMenu = styled.ul`
  list-style-type: none;
  background-color: ${(p) => p.theme.white};
  position: absolute;
  top: calc(100% + 2px);
  width: 100%;
  z-index: 200;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
`

export const CustomAutocomplete = ({
  children,
  onChange,
  items,
  ...otherProps
}) => (
  <Autocomplete
    onSelect={onChange}
    items={items}
    getItemValue={(item) => item}
    wrapperStyle={{
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'relative'
    }}
    renderMenu={(items) => {
      if (items.length === 0) return <div />
      return <AutocompleteMenu>{items}</AutocompleteMenu>
    }}
    renderItem={(item, isHighlighted) => (
      <AutocompleteItem key={item} isHighlighted={isHighlighted}>
        {item}
      </AutocompleteItem>
    )}
    renderInput={children}
    {...otherProps}
  />
)
