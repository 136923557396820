import React from 'react';

const SelectArrowDown = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.277111"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.08859 4.65597L7.34448 1.18891C7.42114 1.10727 7.42114 0.976353 7.34448 0.89472C7.26782 0.813088 7.14488 0.813088 7.06822 0.89472L3.95118 4.21392L0.83414 0.89472C0.757479 0.813088 0.634534 0.813088 0.557874 0.89472C0.520267 0.934766 0.500017 0.988675 0.500017 1.04104C0.500017 1.09341 0.51882 1.14732 0.557874 1.18736L3.81377 4.65443C3.88898 4.73606 4.01337 4.73606 4.08859 4.65597Z"
      fill="black"
      stroke="black"
    />
  </svg>
);

export default SelectArrowDown;
