import React from 'react'
import PropTypes from 'prop-types'

const LightStageIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMin"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#C2C2C2"
      d="M10 16.25A6.254 6.254 0 0 1 3.75 10c0-3.438 2.813-6.25 6.25-6.25 3.438 0 6.25 2.813 6.25 6.25 0 3.438-2.79 6.25-6.25 6.25zm0-10A3.748 3.748 0 0 0 6.25 10 3.748 3.748 0 0 0 10 13.75 3.748 3.748 0 0 0 13.75 10 3.748 3.748 0 0 0 10 6.25zm7.023-1.597c-.231.231-.52.347-.838.347a1.186 1.186 0 0 1-.838-2.023l2.63-2.63a1.186 1.186 0 0 1 1.676 1.676l-2.63 2.63zm-16.676 15a1.186 1.186 0 0 1 0-1.676l2.63-2.63a1.186 1.186 0 0 1 1.676 1.676l-2.63 2.63c-.231.231-.549.347-.838.347-.318 0-.607-.116-.838-.347zM18.815 20c-.289 0-.607-.116-.838-.347l-2.63-2.63a1.186 1.186 0 0 1 1.676-1.676l2.63 2.63A1.186 1.186 0 0 1 18.815 20zM4.653 4.653c-.231.231-.52.347-.838.347-.318 0-.607-.116-.838-.347l-2.63-2.63A1.186 1.186 0 0 1 2.023.347l2.63 2.63a1.186 1.186 0 0 1 0 1.676z"
    />
  </svg>
)

LightStageIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

LightStageIcon.defaultProps = {
  width: 20,
  height: 20
}

export default LightStageIcon
