import React from 'react'
import PropTypes from 'prop-types'

const GridRowIcon = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      s
      <g
        id="Dashboard"
        transform="translate(-1215.000000, -118.000000)"
        fill={fill}
      >
        <path d="M1216,118 L1230,118 C1230.55228,118 1231,118.447715 1231,119 L1231,120 C1231,120.552285 1230.55228,121 1230,121 L1216,121 C1215.44772,121 1215,120.552285 1215,120 L1215,119 C1215,118.447715 1215.44772,118 1216,118 Z M1216,123 L1230,123 C1230.55228,123 1231,123.447715 1231,124 L1231,125 C1231,125.552285 1230.55228,126 1230,126 L1216,126 C1215.44772,126 1215,125.552285 1215,125 L1215,124 C1215,123.447715 1215.44772,123 1216,123 Z M1216,128 L1230,128 C1230.55228,128 1231,128.447715 1231,129 L1231,130 C1231,130.552285 1230.55228,131 1230,131 L1216,131 C1215.44772,131 1215,130.552285 1215,130 L1215,129 C1215,128.447715 1215.44772,128 1216,128 Z" />
      </g>
    </g>
  </svg>
)

GridRowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

GridRowIcon.defaultProps = {
  width: 16,
  height: 13
}

export default GridRowIcon
