import React from "react";
import { useTranslation } from "react-i18next";

import { CustomSelect } from "./";

import { SelectWrapper } from "src/ui";

const defaultStyles = {
  option: (provided) => ({
    ...provided,
    padding: 20,
  }),
};

export const FilterPrioritySelect = ({
  // options = defaultOptions,
  customStyles = defaultStyles,
  value,
  onChange,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const options = [
    { label: t("show_all"), value: null },
    { label: t("show_urgent_only"), value: t("urgent") },
    { label: t("show_high_only"), value: t("high") },
    { label: t("show_low_only"), value: t("low") },
  ];

  return (
    <SelectWrapper>
      <CustomSelect
        isDisabled={isDisabled}
        placeholder={t("priority")}
        styles={{ ...defaultStyles, ...customStyles }}
        options={options}
        value={value}
        onChange={onChange}
      />
    </SelectWrapper>
  );
};
