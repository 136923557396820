import React from 'react'
import PropTypes from 'prop-types'

const DashboardIcon = ({ width, height }) => (
  <svg width={width} height={height}>
    <defs>
      <path
        d="M12 1c6.065 0 11 4.935 11 11s-4.935 11-11 11S1 18.065 1 12 5.935 1 12 1zm0 20.855c5.434 0 9.855-4.421 9.855-9.855S17.434 2.145 12 2.145 2.145 6.566 2.145 12 6.566 21.855 12 21.855zm3.554-15.91A7.053 7.053 0 0 1 19.02 12c0 3.871-3.149 7.02-7.02 7.02A7.028 7.028 0 0 1 4.98 12a7.053 7.053 0 0 1 3.465-6.055.573.573 0 0 1 .581.987A5.903 5.903 0 0 0 6.125 12 5.882 5.882 0 0 0 12 17.875 5.882 5.882 0 0 0 17.875 12a5.903 5.903 0 0 0-2.901-5.068.573.573 0 1 1 .58-.987zM12 4.215c.316 0 .573.257.573.573V12a.573.573 0 1 1-1.146 0V4.788c0-.316.257-.573.573-.573z"
        id="dashboard__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="dashboard__b">
        <use xlinkHref="#dashboard__a" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#dashboard__a" />
      <g mask="url(#dashboard__b)" className="_color">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

DashboardIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

DashboardIcon.defaultProps = {
  width: 24,
  height: 24
}

export default DashboardIcon
