import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { useTranslation } from "react-i18next";

const Root = styled.menu`
  background-color: ${(p) => p.theme.white};
  box-shadow: 0 4px 11px 0 rgba(195, 195, 195, 0.5);
  color: ${(p) => p.theme.darkGray};

  ${(p) => p.theme.ProximaNova}
  font-size: 16px;
`;

const ItemsList = styled.ul`
  list-style-type: none;
`;

const Item = styled.li`
  width: 100%;
`;

const ActionButton = styled.button`
  border: none;
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;

  :hover {
    color: ${(p) => p.theme.green};
    background-color: ${(p) => transparentize(0.8, p.theme.green)};
  }
`;

export const ContextMenu = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Root className={className}>
      <ItemsList>
        <Item>
          <ActionButton>{t("assigns_to")}</ActionButton>
        </Item>

        <Item>
          <ActionButton>{t("edit_profile")}</ActionButton>
        </Item>

        <Item>
          <ActionButton>{t("vacation_setup")}</ActionButton>
        </Item>

        <Item>
          <ActionButton>{t("dismiss")}</ActionButton>
        </Item>
      </ItemsList>
    </Root>
  );
};
