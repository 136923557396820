import React from 'react'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryLine,
  VictoryLabel,
  VictoryAxis,
  VictoryPortal,
  createContainer
} from 'victory'

import { CompareTooltip } from './CompareTooltip'

import victoryTheme, { baseFont } from 'src/theme/chart'
import { SEC_IN_DAY } from 'src/utils/constants'

const Root = styled.div`
  width: 100%;
  height: 100%;
  * {
    overflow: visible;
  }
`

const ChartContainer = createContainer('voronoi', 'zoom')

export const ComparisonLineChart = ({
  containerWidth,
  containerHeight,
  dataSets,
  colors
}) => {

  const yValues = dataSets.reduce((acc, dataSet) => {
    const currentYVals = dataSet.data.slice(0,64).map((dataItem) => dataItem.y)
    return [...acc, ...currentYVals]
  }, [])

  const yMax = 1.2 * Math.max(...yValues)
  const yMin = 0.8 * Math.min(...yValues)

  return (
    <Root>
      <VictoryChart
        theme={victoryTheme}
        width={containerWidth}
        height={containerHeight}
        domainPadding={{ y: [0, containerHeight / 5] }}
        domain={{ y: [yMin, yMax] }}
        containerComponent={
          <ChartContainer
            zoomDomain={{ y: [yMin, yMax] }}
            downsample={400} // only render 150 evenly distributed points at the time
            minimumZoom={{ x: SEC_IN_DAY * 5, y: 0 }}
            zoomDimension="x"
            voronoiDimension="x"
            labels={(d) => {
              // Victory doesn't allow to pass objects here,
              // so we pass string with the following signature:
              // `title, y, units, color`
              // this array will be passed to our CustomTooltip component
              return [d.childName, d.y, d.style.data.stroke].join(',')
            }}
            labelComponent={
              <VictoryPortal
                // ! flyoutStyle and style props below are a hack.
                // For some reason, even if we use our custom tooltip,
                // width of a default one is still calculated, and it wont let
                // us render our custom tooltip at the left side of a chart
                // if it leads to default tooltip overflowing to the left
                // tl:dr - comment out flyoutStyle and style and hover
                // cursor on the left side of the chart
                flyoutStyle={{
                  display: 'none'
                }}
                style={{
                  fontSize: 0
                }}
              >
                <CompareTooltip />
              </VictoryPortal>
            }
          />
        }
      >
        <VictoryAxis
          style={{
            grid: {
              fill: 'none',
              stroke: '#D8D8D8',
              strokeWidth: 0.2,
              fillOpacity: 0.6
            }
          }}
          dependentAxis
        />

        <VictoryAxis
          fixLabelOverlap={true}
          tickFormat={(x) => `Day ${Math.floor(x / SEC_IN_DAY)}`} // here we get a number of day
        />

        <VictoryLabel
          verticalAnchor="start"
          style={{
            fontFamily: baseFont,
            fontSize: 16
          }}
          textAnchor="start"
          x={15}
          y={10}
        />

        {dataSets.map((dataSet) => {
          const groupColor = colors[dataSet.cycleId]
          const { data } = dataSet

          if (!data[0]) {
            return null
          }

          return (
            <VictoryLine
              key={data[0].x}
              name={dataSet.cycleName}
              color={groupColor}
              style={{
                data: {
                  stroke: groupColor,
                  strokeWidth: 2,
                }
              }}
              theme={victoryTheme}
              interpolation="catmullRom"
              data={data}
            />
          )
        })}
      </VictoryChart>
    </Root>
  )
}

ComparisonLineChart.defaultProps = {
  showLabels: true
}
