import gql from 'graphql-tag'

export const GROWING_CYCLES_QUERY = gql`
  query(
    $cropId: ID
    $status: String
    $startDateFrom: Int
    $startDateTo: Int
    $endDateFrom: Int
    $endDateTo: Int
  ) {
    growing_cycles(
      cropId: $cropId
      status: $status
      startDateFrom: $startDateFrom
      startDateTo: $startDateTo
      endDateFrom: $endDateFrom
      endDateTo: $endDateTo
    ) {
      id
      name

      duration_in_days
      status

      current_day_number
      start_at
      end_at

      crop {
        id
        name
      }
    }
  }
`
