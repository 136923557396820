import React from 'react';
import PropTypes from 'prop-types';

const WaterDrop = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.95 15.1461C17.95 18.3636 15.2861 20.9719 12 20.9719C8.71391 20.9719 6.05 18.3636 6.05 15.1461C6.05001 13.2448 7.03795 10.8283 8.35726 8.49497C9.62764 6.24812 11.1164 4.22956 12.0148 3.07863C12.9156 4.19636 14.3955 6.15123 15.6564 8.37063C16.9628 10.67 17.95 13.1098 17.95 15.1461ZM19 15.1461C19 18.9314 15.866 22 12 22C8.13401 22 5 18.9314 5 15.1461C5.00002 10.7872 9.42374 4.69579 11.2378 2.38107C11.6316 1.87866 12.3776 1.87175 12.7794 2.36799C14.6041 4.62148 19 10.5294 19 15.1461ZM16.3441 15.295C16.6662 15.3478 16.8877 15.6531 16.8005 15.9678C16.4968 17.0646 15.8585 18.0455 14.9676 18.7691C14.0766 19.4928 12.9858 19.9165 11.8499 19.9889C11.5241 20.0096 11.2707 19.7303 11.285 19.4042C11.2993 19.078 11.5763 18.8294 11.9014 18.7991C12.7473 18.7201 13.556 18.3927 14.2222 17.8515C14.8885 17.3103 15.3748 16.586 15.6255 15.7742C15.7218 15.4623 16.0219 15.2421 16.3441 15.295Z"
      fill="#85B5BA"
    />
  </svg>
);

WaterDrop.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

WaterDrop.defaultProps = {
  width: 24,
  height: 24,
};

export default WaterDrop;
