import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { EmployeesListItem } from "./EmployeesListItem";

const Root = styled.ul`
  width: 100%;
  display: flex;
  list-style-type: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 12px;
`;

export const EmployeesList = ({ className }) => {
  const { t } = useTranslation();

  const employees = [
    {
      id: 1,
      name: t("dave"),
      position: t("facility_manager"),
      src: "/images/Dave.png",
    },
    {
      id: 2,
      name: t("luisa"),
      position: t("rocket_scientist"),
      src: "/images/Luisa.png",
    },
    {
      id: 3,
      name: t("rita"),
      position: t("agronomist"),
      src: "/images/Rita.png",
    },
  ];

  return (
    <Root className={className}>
      {employees.map((employee) => (
        <EmployeesListItem key={employee.id} employee={employee} />
      ))}
    </Root>
  );
};
