import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import {
  AlarmIcon,
  ProfileIcon,
  CalendarIcon,
  AlarmActiveIcon,
} from "src/components/Icons";
import { Button } from "src/ui/Button";

const Root = styled.header`
  font-size: 18px;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.darkGreen};
  height: 90px;

  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.white};
`;
const CalendarIconWrapper = styled.div`
  margin-right: 20px;
`;
const Date = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100% - 600px);
  padding: 10px 35px;
  border-right: 1px solid ${(p) => p.theme.white};
`;

const LinkWrapper = styled.div`
  border-right: 1px solid ${(p) => p.theme.white};
  height: 100%;
`;
const AlarmWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 10px 32px;
  display: flex;
  align-items: center;
`;
const ProfileWrapper = styled.div`
  height: 100%;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  max-width: 187px;
  border-right: 1px solid ${(p) => p.theme.white};
  white-space: nowrap;
`;
const ProfileIconWrapper = styled.div`
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const ButtonWpapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 305px;
  width: 100%;
`;

const GetInTouchBtn = styled(Button)`
  min-width: 220px;
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  border-radius: 4px;
  padding: 16px 24px;
  transition: background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
`;

const GetInTouchBtnLink = styled.a`
  text-decoration: none;
`;

const HeaderNote = styled.span`
  display: block;
  width: calc(100% - 116px);
  padding: 0 0 0 36px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.darkGreen};
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  strong {
    ${(p) => p.theme.ProximaNovaBold};
  }
`;

export const Header = ({ alertsAmount }) => {
  const { t, i18n } = useTranslation();
  const isEnLng = i18n.language === "en";

  return (
    <Root>
      <Date>
        <CalendarIconWrapper>
          <CalendarIcon />
        </CalendarIconWrapper>
        {moment()
          .locale(i18n.language)
          .format(isEnLng ? "MMMM, D" : "D MMMM")}

        <HeaderNote title={t("default_template_warning")}>
          <Trans i18nKey="default_template_warning" />
        </HeaderNote>
      </Date>
      <LinkWrapper>
        <Link to="/alerts">
          <AlarmWrapper>
            {alertsAmount ? <AlarmActiveIcon /> : <AlarmIcon />}
          </AlarmWrapper>
        </Link>
      </LinkWrapper>
      <ProfileWrapper>
        <ProfileIconWrapper>
          <ProfileIcon />
        </ProfileIconWrapper>
        {t("user_name")}
      </ProfileWrapper>
      <ButtonWpapper>
        {isEnLng ? (
          <GetInTouchBtnLink
            href="https://jetruby.com/farming-management-system/#footer"
            rel="noopener noreferrer"
            target="_blank"
          >
            <GetInTouchBtn>{t("talk_to_an_expert")}</GetInTouchBtn>
          </GetInTouchBtnLink>
        ) : (
          <GetInTouchBtnLink>
            <GetInTouchBtn>{t("talk_to_an_expert")}</GetInTouchBtn>
          </GetInTouchBtnLink>
        )}
      </ButtonWpapper>
    </Root>
  );
};

Header.propTypes = {
  alertsAmount: PropTypes.number.isRequired,
};
