import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentBox } from "src/components/ContentBox";
import { Sunny } from "src/components/Icons";

const windowWidth = window.innerWidth;
const blockWidth = windowWidth < 1400 ? 50 : 25;

export function DailyLightIntegralSensor() {
  const { t } = useTranslation();
  const [sensor, setSensor] = useState({ signal: 16, defined: 0 });

  useEffect(() => {
    const interval = setInterval(() => {
      setSensor((prevSensor) => ({
        ...prevSensor,
        signal: Math.floor(Math.random() * 5 + 16),
      }));
    }, 700);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <ContentBox
      width={`${blockWidth}%`}
      padding={12}
      title={t("daily_light_intergal")}
      description={t("mol_day")}
      amount={sensor.signal}
      amountName={t("actual")}
      totalAmount={9}
      totalName={t("avg_week")}
      icon={<Sunny />}
      res="-23%"
      resType="bad"
    />
  );
}
