import React from "react";
import styled from "styled-components";

const ContainerWrapper = styled.div`
  width: 100%;
  min-width: 260px;
  margin: 0 0 32px;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #cf0707 0%, #58c08e 100%);
    border-radius: 0 0 4px 4px;
  }
`;

const ContainerContentWrapper = styled.div`
  height: 100%;
  padding: 24px 24px 16px;
  border-radius: 4px 4px 0 0;
  background: ${(p) => p.theme.white};
  position: relative;
  ${(p) =>
    p.shadowColor &&
    `
    box-shadow: inset 4px 0px 0px ${p.theme[p.shadowColor]};
  `}
`;

const Title = styled.span`
  display: block;
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 6px;
  padding: 0;
`;

const TitleIconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  float: left;
`;

const TitleDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${(p) => p.theme.green};
  padding: 0 0 0 40px;
  margin: 0 0 6px;
`;

const AmountWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  ${(p) =>
    p.alerts &&
    `
    justify-content: flex-start;
    padding: 10px 0 0 40px;
    align-items: baseline;
  `}
`;

const Amount = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  color: ${(p) => p.theme.yieldGreen};
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 42px;
  line-height: 52px;

  @media (max-width: 1700px) {
    font-size: 38px;
  }
`;

const AmounDescription = styled.div`
  display: inline-block;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.darkGray};
  font-size: 14px;
  line-height: 18px;
  margin: 7px 0 0;
`;

const Total = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  color: ${(p) => p.theme.green};
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 24px;
  line-height: 30px;
  padding: 0 0 0 16px;
  ${(p) =>
    p.isAlert &&
    `
    padding: 0 0 0 10px;
    font-size: 18px;
  `};

  @media (max-width: 1700px) {
    font-size: 22spx;
  }
`;

const TotalDescription = styled.div`
  display: inline-block;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.green};
  font-size: 14px;
  line-height: 18px;
  margin: 7px 0 0;
`;

const TotalAux = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  color: ${(p) => p.theme.green};
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 42px;
  line-height: 52px;
`;

export const SatisticBox = ({
  title,
  description,
  amount,
  totalAmount,
  amountName,
  totalName,
  icon,
  isAlert,
  shadowColor,
}) => {
  return (
    <ContainerWrapper>
      <ContainerContentWrapper shadowColor={shadowColor} isAlert={true}>
        <Title>
          <TitleIconWrapper>{icon}</TitleIconWrapper>
          {title}
        </Title>
        <TitleDescription>{description}</TitleDescription>
        <AmountWrapper alerts={isAlert}>
          {isAlert ? (
            <>
              <Amount>{amount}</Amount>
              {totalAmount && <Total isAlert>{" / " + totalAmount}</Total>}
            </>
          ) : (
            <>
              {amount && (
                <Amount>
                  {amount}
                  {totalAmount && " /"}
                  <AmounDescription>{amountName}</AmounDescription>
                </Amount>
              )}
              {totalAmount && !amount ? (
                <TotalAux>{totalAmount}</TotalAux>
              ) : (
                <Total>
                  {totalAmount}
                  <TotalDescription>{totalName}</TotalDescription>
                </Total>
              )}
            </>
          )}
        </AmountWrapper>
      </ContainerContentWrapper>
    </ContainerWrapper>
  );
};
