import React from 'react';

const AIWaterSuccessValueIcon = () => (
  <svg
    width="20"
    height="38"
    viewBox="0 0 20 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3333 28.3568L12.3333 0.333496L7.66668 0.333496L7.66667 28.3568L0.666674 28.3568L10 37.6668L19.3333 28.3568L12.3333 28.3568Z"
      fill="#58C08E"
    />
  </svg>
);

export default AIWaterSuccessValueIcon;
