import styled from 'styled-components';

export const PageDescription = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.textGray};
  margin: 6px 0 0;
`;
