import React from 'react'
import styled from 'styled-components'

import { ArrowIcon } from 'src/components/Icons'

const Input = styled.input`
  display: block;
  outline: none;
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: ${(p) => p.theme.fontGray};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  :focus {
    box-shadow: 0 0 1px 1px ${(p) => p.theme.orange};
  }
`

const ButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ChangeButton = styled.button`
  cursor: pointer;
  width: 25px;
  height: 50%;
  flex-grow: 1;

  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  svg {
    fill: ${(p) => p.theme.fontGray};
    width: 5px;

    ${(p) => {
      switch (p.direction) {
        case 'up':
          return `transform:  rotate(90deg)`
        default:
          return `transform:  rotate(-90deg)`
      }
    }}
  }

  :hover,
  :focus {
    svg {
      fill: ${(p) => p.theme.orange};
    }
  }
`

const Root = styled.label`
  display: block;
  position: relative;
  height: 50px;
  width: 120px;

  background-color: ${(p) => p.theme.white};

  :focus-within ${Input} {
    box-shadow: 0 0 1px 1px ${(p) => p.theme.orange};
  }
`

export const DaysNumberInput = ({ onChange, value, ...props }) => (
  <Root>
    <Input
      {...props}
      type="number"
      value={value}
      onChange={onChange}
      onBlur={(e) => e.target.value < 1 && onChange(1)}
    />
    <ButtonsWrapper>
      <ChangeButton onClick={() => onChange(value + 1)} direction="up">
        <ArrowIcon />
      </ChangeButton>
      <ChangeButton
        onClick={() => onChange(value > 1 ? value - 1 : 1)}
        direction="down"
      >
        <ArrowIcon />
      </ChangeButton>
    </ButtonsWrapper>
  </Root>
)
