import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const DayNumber = styled.div`
  color: ${(p) => transparentize(0.4, p.theme.black)};
  ${(p) => p.theme.ProximaNova};
  font-size: 24px;

  line-height: 18px;
`;

const DateWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;

  display: flex;
  justify-content: space-between;

  color: ${(p) => transparentize(0.75, p.theme.darkGray)};
  ${(p) => p.theme.ProximaNovaSemibold}
  font-size: 10px;

  text-transform: uppercase;
`;
// prettier-disable
const ScheduleLine = styled.li`
  width: 100%;
  height: 3px;
  margin: 1px;
  border-radius: 1.5px;

  background-color: ${(p) =>
    // I am not sure if this was a good idea to write this with ternaries, but I did it anyways :D

    /* eslint-disable */
    p.water
      ? '#8acff0'
      : p.light
      ? '#ffb236'
      : p.fan
      ? '#9ee4d8'
      : 'transparent'};
  /* eslint-enable */
`;

const Schedules = styled.ul`
  list-style-type: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const selectionStyles = css`
  background-color: ${(p) => p.theme.white};
  ${DayNumber} {
    color: #6ba0a5;
  }

  ${DateWrapper} {
    color: ${(p) => transparentize(0.3, p.theme.green)};
  }
`;

const Root = styled.div`
  position: relative;
  width: 98px;
  height: 98px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(p) => transparentize(0.4, p.theme.white)};

  ${(p) => p.withSelectionStyles && selectionStyles};
`;

const RootWrapper = styled.li`
  padding: 1px;
  cursor: pointer;
`;

export const GridCell = ({
  onClick,
  dayNumber,
  dayOfWeek,
  date,
  hasLightSchedule,
  hasWaterSchedule,
  hasFanSchedule,
  withSelectionStyles,
  onMouseOver,
  onMouseLeave,
}) => {
  return (
    <RootWrapper
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Root withSelectionStyles={withSelectionStyles}>
        <Schedules>
          <ScheduleLine water={hasWaterSchedule} />
          <ScheduleLine light={hasLightSchedule} />
          <ScheduleLine fan={hasFanSchedule} />
        </Schedules>
        <DayNumber>{dayNumber}</DayNumber>

        <DateWrapper>
          <span>{dayOfWeek}</span>

          <span>{date}</span>
        </DateWrapper>
      </Root>
    </RootWrapper>
  );
};

GridCell.propTypes = {
  dayNumber: PropTypes.number.isRequired,
  dayOfWeek: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  hasLightSchedule: PropTypes.bool.isRequired,
  hasWaterSchedule: PropTypes.bool.isRequired,
  hasFanSchedule: PropTypes.bool.isRequired,
  withSelectionStyles: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};
