import styled from 'styled-components';

export const PageInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 12px;
  padding: 0 0 0 12px;
`;
