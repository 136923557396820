export const SEC_IN_DAY = 86400

export const WATER_LOGS = [
  [
    [
      { id: '33', value: 18, created_at: 1502006400, __typename: 'SensorData' },
      { id: '34', value: 20, created_at: 1502010000, __typename: 'SensorData' },
      { id: '35', value: 28, created_at: 1502013600, __typename: 'SensorData' },
      { id: '36', value: 29, created_at: 1502017200, __typename: 'SensorData' },
      { id: '37', value: 28, created_at: 1502020800, __typename: 'SensorData' },
      { id: '38', value: 25, created_at: 1502024400, __typename: 'SensorData' },
      { id: '39', value: 24, created_at: 1502028000, __typename: 'SensorData' },
      { id: '40', value: 23, created_at: 1502031600, __typename: 'SensorData' },
      { id: '41', value: 20, created_at: 1502035200, __typename: 'SensorData' },
      { id: '42', value: 18, created_at: 1502038800, __typename: 'SensorData' },
      { id: '43', value: 17, created_at: 1502042400, __typename: 'SensorData' },
      { id: '44', value: 16, created_at: 1502046000, __typename: 'SensorData' },
      { id: '45', value: 18, created_at: 1502049600, __typename: 'SensorData' },
      { id: '46', value: 20, created_at: 1502053200, __typename: 'SensorData' },
      { id: '47', value: 21, created_at: 1502056800, __typename: 'SensorData' },
      { id: '48', value: 24, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 25, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 23, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 24, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 27, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 28, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 25, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 24, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 23, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 20, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 21, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 22, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 24, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 24, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 24, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 26, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 29, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 21, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 24, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 26, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 29, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 21, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 24, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 26, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 29, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 26, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 25, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 24, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 23, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 21, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 25, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 24, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 23, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 20, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 15, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 13, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 18, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 21, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 24, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 25, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 26, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 25, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 24, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 23, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 22, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 21, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 24, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 26, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 29, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
  [
    [
      { id: '33', value: 24, created_at: 1502006400, __typename: 'SensorData' },
      { id: '34', value: 21, created_at: 1502010000, __typename: 'SensorData' },
      { id: '35', value: 27, created_at: 1502013600, __typename: 'SensorData' },
      { id: '36', value: 26, created_at: 1502017200, __typename: 'SensorData' },
      { id: '37', value: 15, created_at: 1502020800, __typename: 'SensorData' },
      { id: '38', value: 28, created_at: 1502024400, __typename: 'SensorData' },
      { id: '39', value: 19, created_at: 1502028000, __typename: 'SensorData' },
      { id: '40', value: 20, created_at: 1502031600, __typename: 'SensorData' },
      { id: '41', value: 25, created_at: 1502035200, __typename: 'SensorData' },
      { id: '42', value: 18, created_at: 1502038800, __typename: 'SensorData' },
      { id: '43', value: 17, created_at: 1502042400, __typename: 'SensorData' },
      { id: '44', value: 23, created_at: 1502046000, __typename: 'SensorData' },
      { id: '45', value: 30, created_at: 1502049600, __typename: 'SensorData' },
      { id: '46', value: 16, created_at: 1502053200, __typename: 'SensorData' },
      { id: '47', value: 29, created_at: 1502056800, __typename: 'SensorData' },
      { id: '48', value: 22, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 27, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 25, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 18, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 19, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 16, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 15, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 24, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 28, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 27, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 25, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 18, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 19, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 16, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 15, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 24, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 28, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 22, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 23, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 29, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 26, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 20, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 30, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 17, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 21, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 26, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 25, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 24, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 23, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 21, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 25, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 29, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 15, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 17, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 18, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 27, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 25, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 24, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 20, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 21, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 23, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 16, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 28, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 19, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 30, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 26, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 22, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 26, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 29, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
  [
    [
      { id: '33', value: 15, created_at: 1502006400, __typename: 'SensorData' },
      { id: '34', value: 15, created_at: 1502010000, __typename: 'SensorData' },
      { id: '35', value: 16, created_at: 1502013600, __typename: 'SensorData' },
      { id: '36', value: 21, created_at: 1502017200, __typename: 'SensorData' },
      { id: '37', value: 25, created_at: 1502020800, __typename: 'SensorData' },
      { id: '38', value: 18, created_at: 1502024400, __typename: 'SensorData' },
      { id: '39', value: 15, created_at: 1502028000, __typename: 'SensorData' },
      { id: '40', value: 16, created_at: 1502031600, __typename: 'SensorData' },
      { id: '41', value: 17, created_at: 1502035200, __typename: 'SensorData' },
      { id: '42', value: 23, created_at: 1502038800, __typename: 'SensorData' },
      { id: '43', value: 22, created_at: 1502042400, __typename: 'SensorData' },
      { id: '44', value: 20, created_at: 1502046000, __typename: 'SensorData' },
      { id: '45', value: 20, created_at: 1502049600, __typename: 'SensorData' },
      { id: '46', value: 24, created_at: 1502053200, __typename: 'SensorData' },
      { id: '47', value: 23, created_at: 1502056800, __typename: 'SensorData' },
      { id: '48', value: 21, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 25, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 18, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 18, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 22, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 23, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 19, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 25, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 25, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 20, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 17, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 16, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 16, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 23, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 16, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 16, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 23, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 23, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 19, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 16, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 15, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 17, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 20, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 16, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 19, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 16, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 19, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 24, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 16, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 21, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 16, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 19, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 15, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 19, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 21, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 25, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 15, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 21, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 17, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 15, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 20, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 23, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 22, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 15, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 21, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 19, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 19, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 15, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 19, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
  [
    [
      { id: '33', value: 15, created_at: 1502006400, __typename: 'SensorData' },
      { id: '34', value: 15, created_at: 1502010000, __typename: 'SensorData' },
      { id: '35', value: 16, created_at: 1502013600, __typename: 'SensorData' },
      { id: '36', value: 21, created_at: 1502017200, __typename: 'SensorData' },
      { id: '37', value: 25, created_at: 1502020800, __typename: 'SensorData' },
      { id: '38', value: 15, created_at: 1502024400, __typename: 'SensorData' },
      { id: '39', value: 16, created_at: 1502028000, __typename: 'SensorData' },
      { id: '40', value: 23, created_at: 1502031600, __typename: 'SensorData' },
      { id: '41', value: 22, created_at: 1502035200, __typename: 'SensorData' },
      { id: '42', value: 17, created_at: 1502038800, __typename: 'SensorData' },
      { id: '43', value: 17, created_at: 1502042400, __typename: 'SensorData' },
      { id: '44', value: 25, created_at: 1502046000, __typename: 'SensorData' },
      { id: '45', value: 24, created_at: 1502049600, __typename: 'SensorData' },
      { id: '46', value: 15, created_at: 1502053200, __typename: 'SensorData' },
      { id: '47', value: 22, created_at: 1502056800, __typename: 'SensorData' },
      { id: '48', value: 21, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 15, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 16, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 23, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 22, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 17, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 17, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 25, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 24, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 15, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 22, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 25, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 17, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 22, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 17, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 19, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 25, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 15, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 22, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 17, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 19, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 24, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 21, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 16, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 22, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 18, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 20, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 19, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 18, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 22, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 22, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 17, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 25, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 19, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 23, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 23, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 21, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 15, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 22, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 20, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 16, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 18, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 21, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 20, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 16, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 23, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 18, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 21, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 23, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
  [
    [
      { id: '33', value: 23, created_at: 1502006400, __typename: 'SensorData' },
      { id: '34', value: 21, created_at: 1502010000, __typename: 'SensorData' },
      { id: '35', value: 23, created_at: 1502013600, __typename: 'SensorData' },
      { id: '36', value: 23, created_at: 1502017200, __typename: 'SensorData' },
      { id: '37', value: 23, created_at: 1502020800, __typename: 'SensorData' },
      { id: '38', value: 20, created_at: 1502024400, __typename: 'SensorData' },
      { id: '39', value: 22, created_at: 1502028000, __typename: 'SensorData' },
      { id: '40', value: 23, created_at: 1502031600, __typename: 'SensorData' },
      { id: '41', value: 21, created_at: 1502035200, __typename: 'SensorData' },
      { id: '42', value: 16, created_at: 1502038800, __typename: 'SensorData' },
      { id: '43', value: 22, created_at: 1502042400, __typename: 'SensorData' },
      { id: '44', value: 25, created_at: 1502046000, __typename: 'SensorData' },
      { id: '45', value: 16, created_at: 1502049600, __typename: 'SensorData' },
      { id: '46', value: 15, created_at: 1502053200, __typename: 'SensorData' },
      { id: '47', value: 22, created_at: 1502056800, __typename: 'SensorData' },
      { id: '48', value: 21, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 25, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 21, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 16, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 15, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 20, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 15, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 20, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 21, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 20, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 22, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 17, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 20, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 20, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 18, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 15, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 24, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 18, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 25, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 15, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 25, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 21, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 22, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 25, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 17, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 18, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 21, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 20, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 21, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 19, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 19, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 18, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 17, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 16, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 23, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 16, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 25, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 16, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 15, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 16, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 17, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 20, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 24, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 19, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 18, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 19, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 15, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 20, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 15, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
]

export  const ELECTRICITY_LOGS = [
  [
    [
      {id: '33', value: 2.3, created_at: 1502006400, __typename: 'SensorData'},
      {id: '34', value: 1.8, created_at: 1502010000, __typename: 'SensorData'},
      {id: '35', value: 2.6, created_at: 1502013600, __typename: 'SensorData'},
      {id: '36', value: 3.0, created_at: 1502017200, __typename: 'SensorData'},
      {id: '37', value: 1.9, created_at: 1502020800, __typename: 'SensorData'},
      {id: '38', value: 1.8, created_at: 1502024400, __typename: 'SensorData'},
      {id: '39', value: 2.0, created_at: 1502028000, __typename: 'SensorData'},
      {id: '40', value: 3.0, created_at: 1502031600, __typename: 'SensorData'},
      {id: '41', value: 2.0, created_at: 1502035200, __typename: 'SensorData'},
      {id: '42', value: 2.0, created_at: 1502038800, __typename: 'SensorData'},
      {id: '43', value: 2.4, created_at: 1502042400, __typename: 'SensorData'},
      {id: '44', value: 1.9, created_at: 1502046000, __typename: 'SensorData'},
      {id: '45', value: 2.0, created_at: 1502049600, __typename: 'SensorData'},
      {id: '46', value: 2.6, created_at: 1502053200, __typename: 'SensorData'},
      {id: '47', value: 2.0, created_at: 1502056800, __typename: 'SensorData'},
      {id: '48', value: 2.2, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 2.3, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 1.3, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 2.5, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 2.8, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 2.1, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 2.1, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 2.1, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 2.6, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 2.6, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 1.7, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 2.1, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 2.9, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 2.6, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 2.9, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 2.5, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 2.7, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 2.1, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 2.6, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 2.0, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 1.7, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 2.0, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 1.9, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 2.5, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 2.3, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 2.1, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 2.0, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 2.8, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 2.7, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 2.6, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 2.1, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 2.9, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 2.3, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 2.3, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 2.2, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 2.4, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 2.0, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 2.0, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 1.5, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 2.0, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 2.1, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 2.1, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 2.9, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 2.3, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 2.2, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 1.8, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 2.2, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 3.0, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 2.4, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
  [
    [
      {id: '33', value: 2.6, created_at: 1502006400, __typename: 'SensorData'},
      {id: '34', value: 2.1, created_at: 1502010000, __typename: 'SensorData'},
      {id: '35', value: 2.0, created_at: 1502013600, __typename: 'SensorData'},
      {id: '36', value: 2.5, created_at: 1502017200, __typename: 'SensorData'},
      {id: '37', value: 2.1, created_at: 1502020800, __typename: 'SensorData'},
      {id: '38', value: 2.3, created_at: 1502024400, __typename: 'SensorData'},
      {id: '39', value: 2.0, created_at: 1502028000, __typename: 'SensorData'},
      {id: '40', value: 1.6, created_at: 1502031600, __typename: 'SensorData'},
      {id: '41', value: 2.2, created_at: 1502035200, __typename: 'SensorData'},
      {id: '42', value: 2.3, created_at: 1502038800, __typename: 'SensorData'},
      {id: '43', value: 2.1, created_at: 1502042400, __typename: 'SensorData'},
      {id: '44', value: 2.2, created_at: 1502046000, __typename: 'SensorData'},
      {id: '45', value: 2.4, created_at: 1502049600, __typename: 'SensorData'},
      {id: '46', value: 2.3, created_at: 1502053200, __typename: 'SensorData'},
      {id: '47', value: 1.7, created_at: 1502056800, __typename: 'SensorData'},
      {id: '48', value: 1.9, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 1.8, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 1.6, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 2.0, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 2.1, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 2.1, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 2.4, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 2.4, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 2.3, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 2.7, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 2.0, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 2.0, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 2.8, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 2.6, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 1.6, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 2.1, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 1.6, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 2.4, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 2.4, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 2.4, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 2.1, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 1.9, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 2.3, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 2.2, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 1.8, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 2.3, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 2.1, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 1.9, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 2.1, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 2.7, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 1.7, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 2.0, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 2.4, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 2.0, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 2.3, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 1.8, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 2.4, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 2.4, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 2.2, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 2.6, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 2.6, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 2.7, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 2.2, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 2.2, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 1.8, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 2.1, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 2.3, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 2.4, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 2.3, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
  [
    [
      {id: '33', value: 2.3, created_at: 1502006400, __typename: 'SensorData'},
      {id: '34', value: 1.5, created_at: 1502010000, __typename: 'SensorData'},
      {id: '35', value: 2.1, created_at: 1502013600, __typename: 'SensorData'},
      {id: '36', value: 1.6, created_at: 1502017200, __typename: 'SensorData'},
      {id: '37', value: 2.4, created_at: 1502020800, __typename: 'SensorData'},
      {id: '38', value: 2.9, created_at: 1502024400, __typename: 'SensorData'},
      {id: '39', value: 2.0, created_at: 1502028000, __typename: 'SensorData'},
      {id: '40', value: 2.9, created_at: 1502031600, __typename: 'SensorData'},
      {id: '41', value: 1.6, created_at: 1502035200, __typename: 'SensorData'},
      {id: '42', value: 1.7, created_at: 1502038800, __typename: 'SensorData'},
      {id: '43', value: 2.0, created_at: 1502042400, __typename: 'SensorData'},
      {id: '44', value: 2.1, created_at: 1502046000, __typename: 'SensorData'},
      {id: '45', value: 2.2, created_at: 1502049600, __typename: 'SensorData'},
      {id: '46', value: 2.3, created_at: 1502053200, __typename: 'SensorData'},
      {id: '47', value: 1.9, created_at: 1502056800, __typename: 'SensorData'},
      {id: '48', value: 2.2, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 2.3, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 2.7, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 2.4, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 2.2, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 2.6, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 2.1, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 2.1, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 2.1, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 1.4, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 2.1, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 2.2, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 2.6, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 1.8, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 1.9, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 1.6, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 2.2, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 2.8, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 2.5, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 1.9, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 1.4, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 2.5, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 3.0, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 1.7, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 2.5, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 2.3, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 2.3, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 3.0, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 1.4, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 2.1, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 2.0, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 1.8, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 2.1, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 2.7, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 1.7, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 2.2, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 1.5, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 3.0, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 2.9, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 2.1, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 1.7, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 2.8, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 2.3, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 2.7, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 2.3, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 1.9, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 1.8, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 2.1, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 2.2, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
  [
    [
      {id: '33', value: 2.3, created_at: 1502006400, __typename: 'SensorData'},
      {id: '34', value: 1.7, created_at: 1502010000, __typename: 'SensorData'},
      {id: '35', value: 1.7, created_at: 1502013600, __typename: 'SensorData'},
      {id: '36', value: 2.7, created_at: 1502017200, __typename: 'SensorData'},
      {id: '37', value: 2.1, created_at: 1502020800, __typename: 'SensorData'},
      {id: '38', value: 1.7, created_at: 1502024400, __typename: 'SensorData'},
      {id: '39', value: 2.2, created_at: 1502028000, __typename: 'SensorData'},
      {id: '40', value: 2.6, created_at: 1502031600, __typename: 'SensorData'},
      {id: '41', value: 2.1, created_at: 1502035200, __typename: 'SensorData'},
      {id: '42', value: 2.3, created_at: 1502038800, __typename: 'SensorData'},
      {id: '43', value: 1.9, created_at: 1502042400, __typename: 'SensorData'},
      {id: '44', value: 2.3, created_at: 1502046000, __typename: 'SensorData'},
      {id: '45', value: 2.2, created_at: 1502049600, __typename: 'SensorData'},
      {id: '46', value: 2.3, created_at: 1502053200, __typename: 'SensorData'},
      {id: '47', value: 2.5, created_at: 1502056800, __typename: 'SensorData'},
      {id: '48', value: 2.2, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 2.1, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 2.3, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 2.2, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 2.4, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 2.4, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 1.7, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 2.3, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 2.2, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 1.7, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 2.1, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 2.7, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 2.7, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 2.4, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 2.0, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 2.2, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 2.2, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 2.0, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 2.1, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 2.0, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 1.5, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 2.2, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 1.8, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 2.1, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 2.1, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 1.9, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 2.3, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 2.4, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 2.0, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 2.3, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 1.7, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 2.3, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 2.1, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 2.0, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 2.8, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 2.1, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 2.1, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 1.8, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 2.1, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 1.6, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 1.9, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 2.1, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 2.6, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 1.5, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 2.6, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 2.3, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 2.2, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 2.0, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 2.6, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ],
  [
    [
      {id: '33', value: 2.0, created_at: 1502006400, __typename: 'SensorData'},
      {id: '34', value: 1.8, created_at: 1502010000, __typename: 'SensorData'},
      {id: '35', value: 2.1, created_at: 1502013600, __typename: 'SensorData'},
      {id: '36', value: 2.1, created_at: 1502017200, __typename: 'SensorData'},
      {id: '37', value: 2.3, created_at: 1502020800, __typename: 'SensorData'},
      {id: '38', value: 2.4, created_at: 1502024400, __typename: 'SensorData'},
      {id: '39', value: 1.5, created_at: 1502028000, __typename: 'SensorData'},
      {id: '40', value: 1.8, created_at: 1502031600, __typename: 'SensorData'},
      {id: '41', value: 1.9, created_at: 1502035200, __typename: 'SensorData'},
      {id: '42', value: 2.2, created_at: 1502038800, __typename: 'SensorData'},
      {id: '43', value: 2.4, created_at: 1502042400, __typename: 'SensorData'},
      {id: '44', value: 2.1, created_at: 1502046000, __typename: 'SensorData'},
      {id: '45', value: 2.3, created_at: 1502049600, __typename: 'SensorData'},
      {id: '46', value: 2.1, created_at: 1502053200, __typename: 'SensorData'},
      {id: '47', value: 2.2, created_at: 1502056800, __typename: 'SensorData'},
      {id: '48', value: 2.1, created_at: 1502060400, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 2.5, created_at: 1502064000, __typename: 'SensorData' },
      { id: '34', value: 1.9, created_at: 1502067600, __typename: 'SensorData' },
      { id: '35', value: 2.2, created_at: 1502071200, __typename: 'SensorData' },
      { id: '36', value: 2.3, created_at: 1502074800, __typename: 'SensorData' },
      { id: '37', value: 2.1, created_at: 1502078400, __typename: 'SensorData' },
      { id: '38', value: 2.4, created_at: 1502082000, __typename: 'SensorData' },
      { id: '39', value: 2.0, created_at: 1502085600, __typename: 'SensorData' },
      { id: '40', value: 2.2, created_at: 1502089200, __typename: 'SensorData' },
      { id: '41', value: 2.7, created_at: 1502092800, __typename: 'SensorData' },
      { id: '42', value: 2.6, created_at: 1502096400, __typename: 'SensorData' },
      { id: '43', value: 1.7, created_at: 1502100000, __typename: 'SensorData' },
      { id: '44', value: 2.0, created_at: 1502103600, __typename: 'SensorData' },
      { id: '45', value: 2.1, created_at: 1502107200, __typename: 'SensorData' },
      { id: '46', value: 2.1, created_at: 1502110800, __typename: 'SensorData' },
      { id: '47', value: 2.7, created_at: 1502114400, __typename: 'SensorData' },
      { id: '48', value: 1.6, created_at: 1502118000, __typename: 'SensorData' },
      { id: '49', value: 1.6, created_at: 1502121600, __typename: 'SensorData' },
      { id: '50', value: 2.7, created_at: 1502125200, __typename: 'SensorData' },
      { id: '51', value: 2.3, created_at: 1502128800, __typename: 'SensorData' },
      { id: '52', value: 2.2, created_at: 1502132400, __typename: 'SensorData' },
      { id: '53', value: 2.5, created_at: 1502136000, __typename: 'SensorData' },
      { id: '54', value: 2.6, created_at: 1502139600, __typename: 'SensorData' },
      { id: '55', value: 1.8, created_at: 1502143200, __typename: 'SensorData' },
      { id: '56', value: 2.1, created_at: 1502146800, __typename: 'SensorData' }
    ],
    [
      { id: '33', value: 2.1, created_at: 1502150400, __typename: 'SensorData' },
      { id: '34', value: 2.5, created_at: 1502154000, __typename: 'SensorData' },
      { id: '35', value: 1.9, created_at: 1502157600, __typename: 'SensorData' },
      { id: '36', value: 1.7, created_at: 1502161200, __typename: 'SensorData' },
      { id: '37', value: 2.7, created_at: 1502164800, __typename: 'SensorData' },
      { id: '38', value: 2.8, created_at: 1502168400, __typename: 'SensorData' },
      { id: '39', value: 2.1, created_at: 1502172000, __typename: 'SensorData' },
      { id: '40', value: 2.2, created_at: 1502175600, __typename: 'SensorData' },
      { id: '41', value: 2.1, created_at: 1502179200, __typename: 'SensorData' },
      { id: '42', value: 2.4, created_at: 1502182800, __typename: 'SensorData' },
      { id: '43', value: 1.9, created_at: 1502186400, __typename: 'SensorData' },
      { id: '44', value: 1.9, created_at: 1502190000, __typename: 'SensorData' },
      { id: '45', value: 1.8, created_at: 1502193600, __typename: 'SensorData' },
      { id: '46', value: 2.4, created_at: 1502197200, __typename: 'SensorData' },
      { id: '47', value: 1.8, created_at: 1502200800, __typename: 'SensorData' },
      { id: '48', value: 1.8, created_at: 1502204400, __typename: 'SensorData' },
      { id: '49', value: 2.2, created_at: 1502208000, __typename: 'SensorData' },
      { id: '50', value: 1.7, created_at: 1502211600, __typename: 'SensorData' },
      { id: '51', value: 2.3, created_at: 1502215200, __typename: 'SensorData' },
      { id: '52', value: 2.3, created_at: 1502218800, __typename: 'SensorData' },
      { id: '53', value: 1.7, created_at: 1502222400, __typename: 'SensorData' },
      { id: '54', value: 2.6, created_at: 1502226000, __typename: 'SensorData' },
      { id: '55', value: 1.9, created_at: 1502229600, __typename: 'SensorData' },
      { id: '56', value: 2.7, created_at: 1502233200, __typename: 'SensorData' }
    ]
  ]
]
