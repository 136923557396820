import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Query } from 'react-apollo';
import { camelizeKeys } from 'humps';


import {CompletedCyclesTable} from 'src/components/growthCycles/Completed/CompletedCyclesTable'
import {growingCyclesStub} from 'src/stubs/GrowthCycles'
import { GROWING_CYCLES_QUERY } from 'src/graphQL';
import { Spinner } from 'src/components';
import { ActionHeader } from 'src/components/growthCycles/Header';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const tablePosition = css`
  width: 100%;
  flex-grow: 1;
`;

const QUERY_VARS = {
  status: 'completed',
  cropId: null,
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
};

const isStubUsing = true

export const GrowthCyclesCompleted = () => {
  const [stubData, setStubData] = useState(growingCyclesStub)

  return (
    <Query query={GROWING_CYCLES_QUERY} variables={QUERY_VARS}>
      {({ loading, error, data }) => {

        const growingCycles = isStubUsing ? stubData : camelizeKeys(data.growing_cycles)

        return (
          <Root>
            <ActionHeader
              title='Completed'
              error={error} 
              setStubData={setStubData} 
              growingCyclesStub={growingCyclesStub} 
            />
            {loading && <Spinner fullwindow />}
            <CompletedCyclesTable
              css={tablePosition}
              data={loading ? [] : growingCycles}
            />
          </Root>
        );
      }}
    </Query>
  );
};
