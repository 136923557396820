import styled from 'styled-components'

export const SelectWrapper = styled.div`
  display: flex;
  width: 232px;
  margin: 0 0 0 24px;

  > * {
    width: 100%;
  }
`;