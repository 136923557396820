import gql from 'graphql-tag'

export const ALL_CYCLES_QUERY = gql`
  query {
    crops {
      id
      name
      growing_cycles_count
      expected_cycles_count
      completed_cycles_count
    }

    growing_cycles {
      id
      name

      duration_in_days
      status

      current_day_number
      start_at
      end_at
    }
  }
`
