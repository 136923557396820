import React from 'react';
import styled from 'styled-components';

import { ArrowDown } from 'src/components/Icons';

const ToggleWrapper = styled.div`
  &:hover,
  &:focus {
    background-color: ${(p) => p.theme.activeGray};
  }
`

const Toggle = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 32px;
  text-align: left;
  cursor: pointer;
  color: ${(p) => p.theme.grayBg};
  text-decoration: none;
  user-select: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;

  &:hover,
  &:active,
  &:focus {
    * {
      text-decoration: none;
      color: ${(p) => p.theme.lightestGreen};
      ${(p) => p.theme.ProximaNovaSemibold}
    }
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 14px;
    fill: ${(p) => p.theme.orange};
    transition: all 0.2s ease-out;
  }

  ${(p) =>
    !p.open &&
    `
    svg {
      fill: ${p.theme.green}
    }
  `}
`;

const Down = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    fill: ${(p) => p.theme.green};
    transition: all 0.2s ease-out;
  }

  ${(p) =>
    p.open &&
    `
    svg {
      fill: ${p.theme.orange}
      transform: rotate(-180deg);
    }
  `}
`;

export const DropdownLabel = ({ onClick, onKeyDown, open, label, icon }) => {
  return (
    <ToggleWrapper>
      <Toggle open={open} type="button" onClick={onClick} onKeyDown={onKeyDown}>
        <Left open={open}>
          {icon}
          {label}
        </Left>
        <Down open={open}>
          <ArrowDown />
        </Down>
      </Toggle>
    </ToggleWrapper>
  );
};
