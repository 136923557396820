import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';

import { SelectArrowDown } from 'src/components/Icons';

const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Control = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: ${(p) => p.theme.white};
  color: ${(p) => p.theme.darkGray};
  border: none;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  padding: 15px;

  > *:first-child {
    margin-right: 20px;
  }

  > *:nth-child(2) {
    font-size: 14px;
    line-height: 16px;
    color: ${(p) => p.theme.darkGray};
    padding: 0 16px 0 0;
  }

  :focus-within {
    box-shadow: 0 0 1px 1px ${(p) => p.theme.orange};
  }
`;

export const CustomSelect = ({
  value,
  onChange,
  options,
  className,
  icon,
  ...props
}) => {
  return (
    <Select
      getOptionLabel={(option) => option.label}
      isSearchable={false}
      value={value ? options.find((option) => option.value === value) : null}
      onChange={(selectedOption) => onChange(selectedOption.value)}
      options={options}
      styles={{
        placeholder: () => ({ color: (p) => p.theme.darkGray }),
        option: () => ({ width: '100%' }),
      }}
      components={{
        DropdownIndicator: ({ innerProps, innerRef }) => (
          <IconWrapper {...innerProps} ref={innerRef}>
            <SelectArrowDown />
          </IconWrapper>
        ),
        IndicatorSeparator: () => null,
        Control: ({ innerProps, innerRef, children }) => (
          <Control className={className} ref={innerRef} {...innerProps}>
            {icon}
            {children}
          </Control>
        ),

      }}
      {...props}
    />
  );
};

CustomSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  units: PropTypes.string,
};
