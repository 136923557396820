import React from 'react'
import {createSearchParams, useLocation, useSearchParams, Link} from 'react-router-dom'
import styled from 'styled-components'
import { compose } from 'react-apollo'

const StyledNavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: inherit;
  text-decoration: none;

  :active,
  :visited,
  :hover {
    text-decoration: none;
    color: inherit;
  }
`

const Wrapper = styled.span`
  text-align: center;
`

export const NavTabView = ({
  tab,
  tabKey,
  className,
  children,
}) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const tabParam = searchParams.get(tabKey || 'tab')

  return (
    <StyledNavLink
      replace
      to={{
        pathname: location.pathname,
        search: `?${createSearchParams({[tabKey]: tab})}`
      }}
      className={tabParam === tab ? className + ' active' : className}
    >
      <Wrapper>{children}</Wrapper>
    </StyledNavLink>
  )
}

export const NavTab = compose(NavTabView)
