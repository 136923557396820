import React, {useState} from 'react'
import styled from 'styled-components'
import {createSearchParams, Navigate, useLocation, useSearchParams} from 'react-router-dom'

import {TemperatureAlarms, HumidityAlarms, CO2Alarms, AlarmSettings} from './components'

import {SensorsTableIcon} from 'src/components/Icons'
import {Modal, NavTab} from 'src/components'
import {Button} from 'src/ui'
import {Toggler} from 'src/utils/toggler'


const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 1024px;
  padding: 16px;
  margin: 12px;
  background: #FFFFFF;
  border-radius: 4px;
`

const MenuItem = styled(NavTab)`
  width: 140px;
  justify-content: center;
  cursor: pointer;
  padding: 12px;
  color: ${(p) => p.theme.black};
  font-size: 18px;
  text-decoration: none;
  opacity: 0.81;
  &.active,
  :hover {
    font-weight: 600;
    opacity: 0.95;
  }
  &.active {
    box-shadow: inset 0px -4px 0px #FF6600;
  }
  span {
    display: flex;
    align-items: center;
    justify-items: left;
  }
`

const temperatureProps = {
  sensorType: 'temperature',
  formTitle: 'Temperature Sensor Alarm',
  label: 'temperature',
  units: 'C\u00b0',
  initialValues: { minValue: 18, maxValue: 30 },
}
const humidityProps = {
  sensorType: 'humidity',
  formTitle: 'Humidity Sensor Alarm',
  label: 'humidity',
  units: '%',
  initialValues: { minValue: 40, maxValue: 50 },
}
const co2Props = {
  sensorType: 'co2',
  formTitle: 'CO2 Sensor Alarm',
  label: 'co2',
  units: 'ppm',
  initialValues: { minValue: 1000, maxValue: 2000 },
}

const SensorsView = ({growingCycle}) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const sensorTab = searchParams.get('sensorTab')

  const [tableGrowingCycle, setTableGrowingCycle] = useState(growingCycle)
  const [sensorProps, setSensorProps] = useState(temperatureProps)

  const modifyTableGrowingCycle = (newItem) => {
    setTableGrowingCycle(prevTableGrowingCycle => ({
      ...prevTableGrowingCycle,
      [`${sensorProps.sensorType}AlarmConfigs`]:
        prevTableGrowingCycle[`${sensorProps.sensorType}AlarmConfigs`]
          .concat([newItem])
    }))
  }

  const getContent = () => {
    switch (sensorTab) {
      case 'temperature': {
        sensorProps.sensorType !== sensorTab &&
        setSensorProps(temperatureProps)

        return <TemperatureAlarms growingCycle={tableGrowingCycle}/>
      }
      case 'humidity': {
        sensorProps.sensorType !== sensorTab &&
        setSensorProps(humidityProps)

        return <HumidityAlarms growingCycle={tableGrowingCycle}/>
      }
      case 'co2': {
        sensorProps.sensorType !== sensorTab &&
        setSensorProps(co2Props)

        return <CO2Alarms growingCycle={tableGrowingCycle}/>
      }
      default:
        return (
          <Navigate
            replace
            to={{
              pathname: location.pathname,
              search: `?${createSearchParams({
                sensorTab: 'temperature'
              })}`
            }}
          />
        )
    }
  }

  return (
    <Root>
      <SensorTableHeader>
        <SensorsTableIconWrapper>
          <SensorsTableIcon
            width={30}
            height={30}
          />
        </SensorsTableIconWrapper>
        <span style={{fontWeight: '700', fontSize: '18px'}}>Sensors</span>
      </SensorTableHeader>
      <SensorTypeSwitcher>
        <span style={{display: 'inline-flex'}}>
          <MenuItem key="sensorTabTemperature" tabKey="sensorTab" tab="temperature">
            Temperature
          </MenuItem>
          <MenuItem key="sensorTabHumidity" tabKey="sensorTab" tab="humidity">
            Humidity
          </MenuItem>
          <MenuItem key="sensorTabCo2" tabKey="sensorTab" tab="co2">
            CO2
          </MenuItem>
        </span>

        <Toggler>
          {({ toggled, onToggle }) => (
            <>
              <Modal isOpen={toggled} onClose={() => onToggle(false)}>
                <AlarmSettings
                  growingCycle={tableGrowingCycle}
                  sensorType={sensorProps.sensorType}
                  formTitle={sensorProps.formTitle}
                  label={sensorProps.label}
                  units={sensorProps.units}
                  initialValues={sensorProps.initialValues}
                  isOpen={toggled}
                  close={() => onToggle(false)}
                  onSuccess={(newItem) => { modifyTableGrowingCycle(newItem) }}
                />
              </Modal>
              <Button inset type="button" onClick={() => onToggle(true)}>
                New Alarm
              </Button>
            </>
          )}
        </Toggler>
      </SensorTypeSwitcher>

      {getContent()}
    </Root>
  )
}

const SensorTableHeader = styled.div`
  background-color: #ffffff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`

const SensorsTableIconWrapper = styled.span`
  margin-right: 0;
  padding-right: 12px;
`

const SensorTypeSwitcher = styled.div`
  background-color: ${(p) => p.theme.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const Sensors = SensorsView
