import React from 'react'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryPortal,
  VictoryArea,
  VictoryLabel,
  VictoryAxis,
  VictoryTooltip,
  createContainer
} from 'victory'
import moment from 'moment'

import { Tooltip } from './Tooltip'

import victoryTheme, { baseFont, getRandomColorForChart } from 'src/theme/chart'
import { unitsMap } from 'src/utils/helpers'
import { SEC_IN_DAY } from 'src/utils/constants'

const Root = styled.div`
  width: 100%;
  height: 100%;
  * {
    overflow: visible;
  }
`

const ChartContainer = createContainer('voronoi', 'zoom')

export const LineChart = ({
  containerWidth,
  containerHeight,
  data,
  chartType
}) => {
  const units = unitsMap[chartType].units
  const yAxisLabel = `${unitsMap[chartType].name}, ${units}`

  const chartColor = getRandomColorForChart(0)

  const yValues = data.map((dataItem) => dataItem.y)

  const yMax = 1.2 * Math.max(...yValues)
  const yMin = 0.8 * Math.min(...yValues)

  return (
    <Root>
      <VictoryChart
        theme={victoryTheme}
        width={containerWidth}
        height={containerHeight}
        domain={{ y: [yMin, yMax] }}
        containerComponent={
          <ChartContainer
            zoomDomain={{ y: [yMin, yMax] }}
            downSample={150}
            minimumZoom={{ x: SEC_IN_DAY * 5, y: 0 }}
            voronoiDimension="x"
            zoomDimension="x"
            labels={() => ''}
            labelComponent={
              <VictoryTooltip
                flyoutComponent={
                  <VictoryPortal>
                    <Tooltip
                      formatY={(y) => `${y} ${units}`}
                      formatX={(x) => moment.unix(x).format('MMM DD HH:mm')}
                    />
                  </VictoryPortal>
                }
              />
            }
          />
        }
      >
        <VictoryAxis
          style={{
            grid: {
              fill: 'none',
              stroke: '#D8D8D8',
              strokeWidth: 0.2
            }
          }}
          dependentAxis
        />

        <VictoryAxis tickFormat={(x) => moment.unix(x).format('MMM DD')} />

        <VictoryArea
          name="cycle"
          style={{
            data: {
              stroke: chartColor,
              strokeWidth: 3,
              fill: chartColor,
              fillOpacity: 0.2
            }
          }}
          theme={victoryTheme}
          interpolation="catmullRom"
          data={data}
        />

        <VictoryLabel
          angle={-90}
          verticalAnchor="middle"
          text={yAxisLabel}
          style={{
            fontFamily: baseFont,
            fontSize: 16
          }}
          textAnchor="start"
          x={0}
          y={containerHeight - 50}
        />

        <VictoryLabel
          verticalAnchor="middle"
          text="Period"
          style={{
            fontFamily: baseFont,
            fontSize: 16
          }}
          textAnchor="start"
          x={50}
          y={containerHeight - 10}
        />
      </VictoryChart>
    </Root>
  )
}
