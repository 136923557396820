import React from 'react'
import PropTypes from 'prop-types'

const ScheduleTableIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 11C10.24 11 8 13.24 8 16C8 18.76 10.24 21 13 21C15.76 21 18 18.76 18 16C18 13.24 15.76 11 13 11ZM14.65 18.35L12.5 16.2V13H13.5V15.79L15.35 17.64L14.65 18.35ZM14 2H10.82C10.4 0.84 9.3 0 8 0C6.7 0 5.6 0.84 5.18 2H2C0.9 2 0 2.9 0 4V19C0 20.1 0.9 21 2 21H8.11C7.52 20.43 7.04 19.75 6.69 19H2V4H4V7H12V4H14V9.08C14.71 9.18 15.38 9.39 16 9.68V4C16 2.9 15.1 2 14 2ZM8 4C7.45 4 7 3.55 7 3C7 2.45 7.45 2 8 2C8.55 2 9 2.45 9 3C9 3.55 8.55 4 8 4Z"
      fill="#85B5BA"
    />
  </svg>
)

ScheduleTableIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

ScheduleTableIcon.defaultProps = {
  width: 24,
  height: 24
}

export default ScheduleTableIcon
