import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class ClickOutside extends Component {
  componentDidMount() {
    const { useCapture } = this.props

    document.addEventListener('touchend', this.handle, useCapture)
    document.addEventListener('click', this.handle, useCapture)
  }

  componentWillUnmount() {
    const { useCapture } = this.props

    document.removeEventListener('touchend', this.handle, useCapture)
    document.removeEventListener('click', this.handle, useCapture)
  }

  isTouch = false

  handle = (e) => {
    if (e.type === 'touchend') this.isTouch = true
    if (e.type === 'click' && this.isTouch) return
    const { onClickOutside } = this.props
    const el = this.container
    if (!el.contains(e.target)) onClickOutside(e)
  }

  getContainer = (ref) => {
    this.container = ref
  }

  render() {
    const {
      children,
      onClickOutside, // eslint-disable-line
      className,
      container: Root
    } = this.props

    return (
      <Root className={className} ref={this.getContainer}>
        {children}
      </Root>
    )
  }
}

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  useCapture: PropTypes.bool,
  className: PropTypes.string,
  container: PropTypes.any,
  children: PropTypes.node.isRequired
}

ClickOutside.defaultProps = {
  useCapture: true,
  container: 'div'
}
