import moment from "moment";

const GROWING_CYCLE = 'GrowingCycle'
const IN_PROGRESS = 'In progress'
const CROP = 'Crop'
const MAX_LIMIT_DAYS = 40

const randomDates = function() {
  const randomStartAt = moment().subtract(Math.floor((Math.random() * 29) + 1), 'days')
  const minLimitDays = moment().diff(randomStartAt, 'days')

  this.currentDayNumber = minLimitDays
  this.durationInDays = Math.floor(Math.random()*(MAX_LIMIT_DAYS - minLimitDays) + minLimitDays + 1)
  this.startAt = randomStartAt.unix()
  this.endAt = moment(randomStartAt).add(this.durationInDays, 'days').unix()

  delete this.init

  return this
}

export const growingCyclesStab = [
  {
    init: randomDates,
    crop: {
      id: '3',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "12",
    name: "Boston Lettuce GC #3",
    status: IN_PROGRESS,
    typename: GROWING_CYCLE,
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '4',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "13",
    name: "Boston Lettuce GC #4",
    status: IN_PROGRESS,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '5',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "14",
    name: "Boston Lettuce GC #5",
    status: IN_PROGRESS,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '6',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "15",
    name: "Boston Lettuce GC #6",
    status: IN_PROGRESS,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '7',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "16",
    name: "Boston Lettuce GC #7",
    status: IN_PROGRESS,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '8',
      name: 'Butterhead Lettuce',
      typename: CROP
    },
    id: "17",
    name: "Butterhead Lettuce GC #8",
    status: IN_PROGRESS,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '9',
      name: 'Butterhead Lettuce',
      typename: CROP
    },
    id: "18",
    name: "Butterhead Lettuce GC #9",
    status: IN_PROGRESS,
    typename: GROWING_CYCLE
  }.init(),
];

export const cropsStab = [
  {
    id: "1",
    name: "Butterhead Lettuce",
  },
  {
    id: "2",
    name: "Boston Lettuce",
  },
]