import React from 'react'
import styled, {css} from 'styled-components'
import {transparentize} from 'polished'

import {RowWrapper} from 'src/ui'
import {FanStageIcon, LightStageIcon, WaterStageIcon} from 'src/components/Icons'

const disabledButton = css`
  svg path {
    fill: #c7c7c7;
  }
`

export const DayCard = ({
  day,
  dayNumber,
  stages,
  onRedirect,
}) => {
  const stage = stages.find(stage => stage.id === day.stageId)

  return (
    <Root stage={stage}>
      <RowWrapper>
        <DayNumber>{dayNumber}</DayNumber>
        <div>
          <Date>{day.date.format('DD.MM.YYYY')}</Date>
          <WeekDay>{day.date.format('dddd')}</WeekDay>
        </div>
      </RowWrapper>
      <RowWrapper>
        <List>
          <StageIconWrapper
            isWater
            css={disabledButton}
            onClick={(e) => e.stopPropagation()}
            data-sensor='water'
            data-stage={day.stageId}
          >
            <WaterStageIcon />
          </StageIconWrapper>
          <StageIconWrapper
            isLight
            onClick={onRedirect}
            data-sensor='light'
            data-stage={day.stageId}
          >
            <LightStageIcon />
          </StageIconWrapper>
          <StageIconWrapper
            isFan
            css={disabledButton}
            onClick={(e) => e.stopPropagation()}
            data-sensor='fan'
            data-stage={day.stageId}
          >
            <FanStageIcon />
          </StageIconWrapper>
        </List>
      </RowWrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 214px;
  height: 151px;
  padding: 24px;
  gap: 24px;
  border-radius: 4px;
  background-color: ${p => {
    if(p.stage) {
      const {name} = p.stage
      
      if (name === 'Germination') return '#f9faf2'
      if (name === 'Grow') return '#f3faf3'
      if (name === 'Prepare for Harvesting') return '#faf6f2'
    }   
    
    return '#F2F2F2'
  }
  };
`
const DayNumber = styled.div`
  height: 44px;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #364652;
  margin: 4px 16px 4px 0;
`
const Date = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #364652;
  margin-bottom: 8px;
`
const WeekDay = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #85B5BA;
`
const List = styled.ul`
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`
const StageIconWrapper = styled.li`
  height: 30px;
  width: 20px;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :not(:first-child) {
    border-left: 1px solid ${transparentize(0.8, '#d8d8d8')};
  }

  svg path {
    fill: ${(p) => {
      if (p.isWater) return '#8acff0'
      if (p.isLight) return '#ffb236'
      if (p.isFan) return '#9ee4D8'
      
      return '#c2c2c2'
    }}
  }
`
