import React from 'react'
import PropTypes from 'prop-types'

const GridCardIcon = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard"
        transform="translate(-1178.000000, -117.000000)"
        fill={fill}
      >
        <path
          d="M1179,117 L1183,117 C1183.55228,117 1184,117.447715 1184,118 L1184,122 C1184,122.552285 1183.55228,123 1183,123 L1179,123 C1178.44772,123 1178,122.552285 1178,122 L1178,118 C1178,117.447715 1178.44772,117 1179,117 Z M1179,125 L1183,125 C1183.55228,125 1184,125.447715 1184,126 L1184,130 C1184,130.552285 1183.55228,131 1183,131 L1179,131 C1178.44772,131 1178,130.552285 1178,130 L1178,126 C1178,125.447715 1178.44772,125 1179,125 Z M1187,117 L1191,117 C1191.55228,117 1192,117.447715 1192,118 L1192,122 C1192,122.552285 1191.55228,123 1191,123 L1187,123 C1186.44772,123 1186,122.552285 1186,122 L1186,118 C1186,117.447715 1186.44772,117 1187,117 Z M1187,125 L1191,125 C1191.55228,125 1192,125.447715 1192,126 L1192,130 C1192,130.552285 1191.55228,131 1191,131 L1187,131 C1186.44772,131 1186,130.552285 1186,130 L1186,126 C1186,125.447715 1186.44772,125 1187,125 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
)

GridCardIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

GridCardIcon.defaultProps = {
  width: 14,
  height: 14
}

export default GridCardIcon
