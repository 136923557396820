import React from 'react'

const AlarmIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0421 19.0274L12 4.9726L3.95791 19.0274H20.0421ZM13.6931 3.9863C12.9406 2.67123 11.0594 2.67123 10.3069 3.9863L2.26484 18.0411C1.51236 19.3562 2.45295 21 3.95791 21H20.0421C21.547 21 22.4876 19.3562 21.7352 18.0411L13.6931 3.9863ZM11 8.99998H13V14H11V8.99998ZM13 17V15H11V17H13Z"
      fill="#364652"
    />
  </svg>
)

export default AlarmIcon
