import gql from 'graphql-tag'

export const UPDATE_STAGE_MUTATION = gql`
  mutation(
    $id: ID!
    $startGapInMinutes: Int!
    $hardwareDeviceId: ID!
    $items: [StageConfig!]!
  ) {
    configureGrowingStage(
      id: $id
      startGapInMinutes: $startGapInMinutes
      hardwareDeviceId: $hardwareDeviceId
      items: $items
    ) {
      success
      errors
    }
  }
`
