import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { compose, graphql } from 'react-apollo'
import { toast } from 'react-toastify'
import gql from 'graphql-tag'
import moment from 'moment'
import { camelizeKeys } from 'humps'

import { newCycleSchema } from 'src/utils/schema'
import { validateForm } from 'src/utils/form'
import { GROWING_CYCLES_QUERY, CREATE_CYCLE_MUTATION } from 'src/graphQL'
import {
  Spinner,
  NumberInput,
  CustomAutocomplete,
  DatePicker
} from 'src/components/index'
import { H3, Button } from 'src/ui'

const Header = styled.header`
  margin-top: 30px;
`

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const RowInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const FormRoot = styled.form`
  background-color: ${(p) => p.theme.white};
  width: 368px;
  padding: 24px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
`

const Input = styled.input.attrs({ autoComplete: 'off' })`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid ${(p) => p.theme.textDisable};

  :focus-within {
    border: 1px solid ${(p) => p.theme.orange};
  }

  ::placeholder {
    color: ${(p) => p.theme.textDisable};
  }
`

const Title = styled(H3)`
  padding: 0;
  font-size: 24px;
  line-height: 29px;
`

const CreateButton = styled(Button)`
  ${(p) => p.theme.ProximaNovaBold}
  font-size: 18px;
  width: 100%;
  padding: 16px;
`

const getAllCrops = gql`
  {
    crops(ids: []) {
      id
      name
    }

    farms {
      growing_spaces {
        id
        name
      }
    }
  }
`

const InputLabel = styled.label`
  position: relative;
  padding-bottom: 13px;
`

const StyledDurationInput = styled(NumberInput)`
  margin-right: 20px;
`

const DateInputLabel = styled.label`
  width: 148px;
  flex-grow: 1;
`

const ValidationError = styled.div`
  padding-left: 10px;
  min-height: 20px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.orange};
  width: 100%;
`

const WithValidationErrorWrapper = styled.div`
  width: 148px;
`

const StartAtWrapper = styled(WithValidationErrorWrapper)`
  width: 148px;
`

class NewCycleFormView extends Component {
  onSubmit = async (values) => {
    const {
      data: { farms, crops },
      createCycleMutation,
      afterRequest
    } = this.props

    const growingSpaceId = +farms[0].growing_spaces[0].id
    const selectedCropIndex = crops.findIndex(
      (crop) => crop.name === values.crop
    )

    const variables = {
      cropId: +crops[selectedCropIndex].id,
      growingSpaceId,
      name: values.name,
      startAt: moment(values.startAt).unix(),
      durationInDays: +values.durationInDays
    }

    try {
      const { data } = await createCycleMutation({
        variables,
        refetchQueries: [
          {
            query: GROWING_CYCLES_QUERY,
            variables: {
              status: 'planned',
              cropId: null,
              startDateFrom: null,
              startDateTo: null,
              endDateFrom: null,
              endDateTo: null
            }
          }
        ],
        awaitRefetchQueries: true
      })

      if (!data.createGrowingCycle.errors) return afterRequest()

      const parsedErrors = camelizeKeys(
        JSON.parse(data.createGrowingCycle.errors)
      )

      const mappedErrors = Object.keys(parsedErrors).reduce((acc, key) => {
        acc[key] = parsedErrors[key].slice(0, 1).join('. ')
        return acc
      }, {})

      return mappedErrors
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong while trying to create a new cycle')
      return afterRequest()
    }
  }

  render() {
    const { crops, loading, error } = this.props.data

    if (loading) {
      return <Spinner />
    }

    if (error) {
      this.props.close()
      toast.error('Error fetching data from the server')
    }

    return (
      <Form
        initialValues={{ durationInDays: 1, startAt: moment() }}
        validate={validateForm({ schema: newCycleSchema })}
        subscription={{}}
        onSubmit={this.onSubmit}
      >
        {({ handleSubmit }) => (
          <FormRoot onSubmit={handleSubmit}>
            <Header>
              <Title>Create new growing cycle</Title>
            </Header>
            <InputsWrapper>
              <Field name="name">
                {({ input, meta }) => (
                  <Fragment>
                    <InputLabel>
                      <Input type="text" {...input} placeholder="Cycle name" />
                      <ValidationError>
                        {meta.touched && meta.error}
                      </ValidationError>
                    </InputLabel>
                  </Fragment>
                )}
              </Field>
              <Field name="crop">
                {({ input, meta }) => (
                  <Fragment>
                    <InputLabel>
                      <CustomAutocomplete
                        onChange={input.onChange}
                        items={crops.map((crop) => crop.name)}
                        onSelect={input.onChange}
                      >
                        {(props) => (
                          <Input type="text" {...props} value={input.value}  placeholder="Select crop" />
                        )}
                      </CustomAutocomplete>
                      <ValidationError>
                        {meta.touched && meta.error}
                      </ValidationError>
                    </InputLabel>
                  </Fragment>
                )}
              </Field>

              <RowInputs>
                <Field name="startAt">
                  {({ input, meta }) => (
                    <StartAtWrapper>
                      <DateInputLabel>
                        <DatePicker
                          value={input.value}
                          onChange={input.onChange}
                        />
                      </DateInputLabel>
                      <ValidationError>
                        {meta.touched && (meta.error || meta.submitError)}
                      </ValidationError>
                    </StartAtWrapper>
                  )}
                </Field>
                <Field name="durationInDays">
                  {({ input, meta }) => (
                    <WithValidationErrorWrapper>
                      <InputLabel>
                        <StyledDurationInput
                          {...input}
                          min={1}
                          max={999}
                          appendix={{ singular: 'day', plural: 'days' }}
                        />
                      </InputLabel>
                      <ValidationError>
                        {meta.touched && meta.error}
                      </ValidationError>
                    </WithValidationErrorWrapper>
                  )}
                </Field>
              </RowInputs>
            </InputsWrapper>

            <CreateButton inset>Create</CreateButton>
          </FormRoot>
        )}
      </Form>
    )
  }
}

const enhance = compose(
  graphql(getAllCrops),
  graphql(CREATE_CYCLE_MUTATION, { name: 'createCycleMutation' })
)

export const NewCycleForm = enhance(NewCycleFormView)
