import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ParametersItem } from "./ParametersItem";

import { H3 } from "src/ui";

const Root = styled.menu`
  width: 100%;
  padding: 24px;
  background-color: ${(p) => p.theme.white};
`;

const Title = styled(H3)`
  font-size: 18px;
  line-height: 22px;
  padding: 0px;
`;

const OptionsList = styled.ul`
  list-style-type: none;
  ${(p) => p.theme.ProximaNova}
`;

export const Parameters = ({ parameters, onUpdateParameter }) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Title>{t("parameters")}</Title>
      <OptionsList>
        {Object.keys(parameters).map((key) => (
          <ParametersItem
            key={key}
            checked={parameters[key]}
            name={key}
            onChange={onUpdateParameter}
          />
        ))}
      </OptionsList>
    </Root>
  );
};
