import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select from 'react-select'

import { SelectArrowDown } from 'src/components/Icons'

const IconWrapper = styled.span`
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${(p) => p.theme.green};
    stroke: ${(p) => p.theme.green};
    opacity: 1;
  }
`

const Control = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  padding: 16px 8px;
  border: 1px solid ${(p) => p.theme.textDisable};

  :focus-within {
    border: 1px solid ${(p) => p.theme.orange};
  }
`

export const SelectField = ({
  value,
  onChange,
  options,
  className,
  ...props
}) => {
  return (
    <Select
      className={className}
      getOptionLabel={(option) => option.label}
      isSearchable={false}
      value={value ? options.find((option) => option.value === value) : null}
      onChange={(selectedOption) => onChange(selectedOption.value)}
      options={options}
      styles={{ placeholder: () => ({ color: '#bfc4cd' }) }}
      components={{
        DropdownIndicator: ({ innerProps, innerRef }) => (
          <IconWrapper {...innerProps} ref={innerRef}>
             <SelectArrowDown />
          </IconWrapper>
        ),
        IndicatorSeparator: () => null,
        Control: ({ innerProps, innerRef, children }) => (
          <Control ref={innerRef} {...innerProps}>
            {children}
          </Control>
        )
      }}
      {...props}
    />
  )
}

SelectField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  units: PropTypes.string
}
