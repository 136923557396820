import React from 'react'

import { SensorsSettingTable } from './SensorsSettingTable'

export const HumidityAlarms = ({ growingCycle }) => (
  <SensorsSettingTable
    growingCycle={growingCycle}
    initialValues={{ minValue: 40, maxValue: 50 }}
    sensorType={'humidity'}
    formTitle={'Humidity Sensor Alarm'}
    label={'humidity'}
    units={'%'}
    data={growingCycle.humidityAlarmConfigs}
  />
)
