import gql from 'graphql-tag'

export const ALL_CROPS_QUERY = gql`
  query {
    crops {
      id
      name
    }
  }
`
