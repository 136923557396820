import React from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

import { GrowingCyclesItem } from './GrowingCyclesItem';

import { H4 } from 'src/ui';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.darkGray};
  overflow-y: hidden;
  min-width: 270px;
  flex-shrink: 1;
  flex-grow: 1;
`;

const List = styled.ul`
  margin-right: 20px;
  list-style-type: none;
  flex-grow: 1;
`;

const Header = styled.header`
  margin-bottom: 25px;
`;

const Title = styled(H4)`
  font-size: 18px;
  padding: 0;
`;

const Count = styled.div`
  font-size: 14px;
  opacity: 0.65;
`;

const ScrollTrack = styled.div`
  height: 100%;
  width: 4px;
  background-color: ${(p) => p.theme.lightGray};
  position: absolute;
  right: 0;
`;

const ScrollThumb = styled.div`
  width: 100%;
  background-color: #ededed;
`;

export const GrowingCyclesList = ({
  growingCycles,
  selectedCycleId,
  onSelectGrowingCycle,
}) => {
  return (
    <Root>
      <Header>
        <Title>Growing Cycles</Title>
        <Count>Total {growingCycles.length}</Count>
      </Header>
      <Scrollbars
        renderTrackVertical={() => <ScrollTrack />}
        renderThumbVertical={() => <ScrollThumb />}
      >
        <List>
          {growingCycles.map(
            ({ name, id, current_day_number, duration_in_days }) => (
              <GrowingCyclesItem
                key={id}
                name={name}
                id={id}
                daysLeft={duration_in_days - current_day_number}
                selected={selectedCycleId === id}
                onSelect={() => onSelectGrowingCycle(id)}
              />
            ),
          )}
        </List>
      </Scrollbars>
    </Root>
  );
};
