import React from "react";
import styled from "styled-components";
import { Query, compose, graphql } from "react-apollo";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import humps from "humps";
import { useTranslation } from "react-i18next";

import { Schedules } from "src/components/plannedGrowthCyclesSettings/Schedules";
import { Sensors } from "src/components/plannedGrowthCyclesSettings/Sensors";
import { Spinner } from "src/components";
import { CYCLE_SETTINGS_QUERY, UPDATE_CYCLE_MUTATION } from "src/graphQL";
import { PageHeader } from "src/ui";

const SettingsView = () => {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <Query
      fetchPolicy="network-only"
      query={CYCLE_SETTINGS_QUERY}
      variables={{ id: params.cycleId }}
    >
      {({ data, loading, error }) => {
        if (error) {
          toast.error(t("something_went_wrong"));
          return null;
        }

        if (loading) return <Spinner fullwindow />;

        const { growingCycle } = humps.camelizeKeys(data);

        return (
          <Root>
            <PageHeader
              title="Planned Settings"
              subTitle={growingCycle.name}
              isBackButton
            />
            <Content>
              <Sensors growingCycle={growingCycle} />
              <Schedules growingCycle={growingCycle} />
            </Content>
          </Root>
        );
      }}
    </Query>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
`;

const enhance = compose(
  graphql(UPDATE_CYCLE_MUTATION, { name: "updateCycle" })
);

export const GrowthCyclesPlannedSettings = enhance(SettingsView);
