import React from 'react';

const FillLeafIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.05001 4.05C-0.679993 6.78 -0.679993 11.2 2.03001 13.93C3.50001 10.53 6.12001 7.69 9.39001 6C6.62001 8.34 4.68001 11.61 4.00001 15.32C6.60001 16.55 9.80001 16.1 11.95 13.95C15.43 10.47 16 0 16 0C16 0 5.53001 0.57 2.05001 4.05Z"
      fill="#85B5BA"
    />
  </svg>
)

export default FillLeafIcon;
