import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { compose, graphql, Query } from "react-apollo";
import { toast } from "react-toastify";
import { camelizeKeys } from "humps";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { LightingSchedule } from "src/components/growingStage/LightingSchedule";
import { GROWING_STAGE_QUERY, UPDATE_STAGE_MUTATION } from "src/graphQL";
import { SelectDurationInput, Spinner } from "src/components";
import { Button, PageHeader } from "src/ui";

const ApplyButton = styled(Button)`
  margin-left: 40px;
`;

const FormRoot = styled.form`
  display: flex;
  flex-direction: column;
`;

const ContentRoot = styled.div`
  display: flex;
`;

const RightColumn = styled.div`
  padding-left: 30px;
`;

const DaysInputWrapper = styled.div``;

const InputTitle = styled.div`
  margin-bottom: 24px;
  ${(p) => p.theme.ProximaNovaSemibold};
  opacity: 0.61;
  font-size: 16px;
  color: ${(p) => p.theme.black};
`;

const SendingSpinner = styled(Spinner)`
  margin-left: 20px;
  animation-duration: 0.2s;
  width: 20px;
  height: 20px;
`;

const LeftColumn = styled.div`
  flex-shrink: 0;
`;

const extractLightConfig = (config) =>
  config
    ? config.settings
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((setting) => {
          const cleanedSettingData = setting.data.replaceAll("=>", ": ");

          return {
            id: setting.id,
            duration: setting.durationInMinutes / 60,
            ...JSON.parse(cleanedSettingData),
          };
        })
    : [];

const StageView = ({ updateStage }) => {
  const { t } = useTranslation();
  const { growingStageId } = useParams();

  const [isSending, setIsSending] = useState(false);

  const onSubmit = async (values, hardwareDeviceId) => {
    const startGapInMinutes = values.startGapInHours * 60;
    const id = growingStageId;

    const items = values.lightSchedule.map((val, idx) => {
      const { duration, ...data } = val;

      return {
        orderNumber: idx + 1,
        durationInMinutes: duration * 60,
        data: JSON.stringify(data),
      };
    });

    const commonDurationInMinutes = items.reduce((acc, item) => {
      acc += item.durationInMinutes;
      return acc;
    }, startGapInMinutes);

    if (commonDurationInMinutes > 24 * 60) {
      toast.error(t("shedule_error"));
      return null;
    }

    const variables = {
      id,
      startGapInMinutes,
      hardwareDeviceId,
      items,
    };

    setIsSending(true);
    const response = await updateStage({ variables });
    // TODO: add error handling
    setIsSending(false);
  };

  return (
    <Query query={GROWING_STAGE_QUERY} variables={{ id: growingStageId }}>
      {({ loading, error, data }) => {
        if (loading) return <Spinner fullwindow />;

        if (error) {
          toast.error(t("something_went_wrong"));
          return null;
        }

        const { growingCycleStage } = camelizeKeys(data);

        const {
          startGapInMinutes,
          hardwareConfigs,
          growingCycle,
        } = growingCycleStage;

        const config = hardwareConfigs[0] || null;

        const lightSchedule = extractLightConfig(config);

        const deviceId = growingCycle.growingSpace.devices[0].id;

        return (
          <Form
            keepDirtyOnReinitialize={true}
            initialValues={{
              startGapInHours: startGapInMinutes / 60,
              lightSchedule,
            }}
            onSubmit={(values) => onSubmit(values, deviceId)}
          >
            {({ handleSubmit }) => (
              <FormRoot onSubmit={handleSubmit}>
                <PageHeader title={growingCycleStage.name} isBackButton>
                  <ApplyButton type="submit">Apply</ApplyButton>
                  {isSending && <SendingSpinner />}
                </PageHeader>

                <ContentRoot>
                  <LeftColumn>
                    <Field name="startGapInHours">
                      {({ input }) => (
                        <DaysInputWrapper>
                          <InputTitle>Gap since 00:00:</InputTitle>
                          <SelectDurationInput
                            units="h"
                            listLength={23}
                            value={input.value}
                            onChange={input.onChange}
                          />
                        </DaysInputWrapper>
                      )}
                    </Field>
                  </LeftColumn>

                  <RightColumn>
                    <Field name="lightSchedule">
                      {({ input: { onChange, value: schedule } }) => (
                        // move change handlers to separate functions when
                        // new schedules will be implemented
                        <LightingSchedule
                          onRemove={(removedItemId) => {
                            const updatedSchedule = schedule.filter(
                              (item) => item.id !== removedItemId
                            );

                            return onChange(updatedSchedule);
                          }}
                          onUpdate={(updatedItem) => {
                            const updatedSchedule = schedule.map((item) =>
                              item.id === updatedItem.id ? updatedItem : item
                            );

                            return onChange(updatedSchedule);
                          }}
                          onCreate={(newItem) =>
                            onChange([
                              ...schedule,
                              { ...newItem, id: moment.unix() },
                            ])
                          }
                          schedule={schedule}
                        />
                      )}
                    </Field>
                  </RightColumn>
                </ContentRoot>
              </FormRoot>
            )}
          </Form>
        );
      }}
    </Query>
  );
};

const enhance = compose(
  graphql(UPDATE_STAGE_MUTATION, { name: "updateStage" })
);

export const GrowingStage = enhance(StageView);
