import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import { DropdownLabel } from './DropdownLabel';

import { AsideNavItem } from 'src/ui';
import { CircleIcon } from 'src/components/Icons';

const Root = styled.div`
  width: 100%;

  transition: all 0.2s ease-out;
  max-height: 54px;
  overflow: hidden;
  flex-shrink: 0;
  ${(p) =>
    p.open &&
    `
    height: auto;
    max-height: none;
    background-color: ${p.theme.dropdownActiveGray};
  `}
`;

const DropDownNavItem = styled(AsideNavItem)`
  font-size: 16px;
  padding-left: 56px;

  svg {
    fill: none;
  }

  &.disable {
    pointer-events: none;
  }

  &.active {
    svg {
      stroke: ${(p) => p.theme.orange};
    }
  }
`;

export const NavDropdownView = (props) => {
  const { 
    labelIcon, 
    labelText, 
    items, 
    pathRegexp, 
    activeItem, 
    setActiveItem, 
    toggleHandler, 
    location 
  } = props

  const [open, setOpen] = useState(!!location.pathname.match(pathRegexp))

  const hiddenProp = { tabIndex: open ? '0' : '-1', 'aria-hidden': !open };

  useEffect(() => {
    if (!!location.pathname.match(pathRegexp))
      setActiveItem(labelText)
    else setOpen(false)
  }, [location])

  useEffect(() => {
    if (activeItem === null) return
    activeItem === labelText ? setOpen(true) : setOpen(false)
  }, [activeItem])

  return (
    <Root open={open}>
      <DropdownLabel
        open={open}
        onClick={() => toggleHandler(labelText)}
        // onKeyDown={toggleHandlerWithEnter}
        icon={labelIcon}
        label={labelText}
      />

      <div>
        {items.map((item) => (
          <DropDownNavItem
            key={item.to}
            {...hiddenProp}
            to={item.to}
          >
            <CircleIcon />
            {item.label}
          </DropDownNavItem>
        ))}
      </div>
    </Root>
  )
}

// const enhance = compose();

export const NavDropdown = NavDropdownView
