import React from 'react';
import styled from 'styled-components';

import { FieldItem } from './FieldItem';

const Root = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

export const FieldsList = ({ fields }) => {
  return (
    <Root>
      {fields.map((field) => (
        <FieldItem key={field.id} field={field} />
      ))}
    </Root>
  );
};
