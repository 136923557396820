import gql from 'graphql-tag'

export const COMPLETED_CYCLE_QUERY = gql`
  query getCycle($id: ID!) {
    growing_cycle(id: $id) {
      id
      name
      start_at
      end_at
      duration_in_days

      stages {
        id
        duration_in_days
        name
      }
      crop {
        id
        name
      }

      days {
        id
        par_sensor_logs {
          id
          additional_data
          created_at
        }
        co2_sensor_logs {
          id
          value
          created_at
        }
        temperature_sensor_logs {
          id
          value
          created_at
        }
        humidity_sensor_logs {
          id
          value
          created_at
        }
      }
    }
  }
`
