import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const PrivateRoute = () => {
  const isAuthenticated = Boolean(localStorage.getItem('token'))

  if (isAuthenticated) {
    return <Outlet />
  }

  return <Navigate to={'dashboard'} replace />
}
