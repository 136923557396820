import React from 'react'

import {AlertsContext} from './store'

export const useAlertsContext = () => {
  const [
    alerts,
    setAlerts,
    analytics,
    setAnalytics,
    isOpenModal,
    setIsOpenModal,
  ] = React.useContext(AlertsContext)

  const handleSetAlerts = (newAlerts) => {
    setAlerts(newAlerts)
  }

  const handleResolveAlert = (id) => {
    setAlerts(prevAlerts => {
      const alertIndex = prevAlerts.findIndex(alert => alert.id === id)

      if (analytics.alertId === id) setAnalytics({})
      prevAlerts[alertIndex] = {...prevAlerts[alertIndex], resolved: true}

      return [...prevAlerts]
    })
  }

  const getAlert = alertId => alerts.find(item => item.id === alertId )

  const setAnalyticsByAlertId = (alertId) => {
    const alert = alerts.find(item => item.id = alertId)

    setAnalytics({alertId, data: alert.analytics})
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  return {
    alerts,
    analytics,
    isOpenModal,
    setAlerts: handleSetAlerts,
    resolveAlert: handleResolveAlert,
    getAlert,
    setAnalytics,
    setAnalyticsByAlertId,
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
  }
};