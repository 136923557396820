import React from 'react'
import PropTypes from 'prop-types'

const WaterStageIcon = ({ height, width }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 13C0 10.542.591 10.13 6.329.5c.184-.31.525-.5.893-.5.369 0 .71.19.894.5 5.935 9.961 6.328 10.103 6.328 12.5 0 3.86-3.24 7-7.222 7S0 16.86 0 13zm12.381 0c0-1.648-.17-1.67-5.159-10.004C2.296 11.217 2.063 11.31 2.063 13c0 2.757 2.315 5 5.16 5 2.844 0 5.158-2.243 5.158-5zm-6.623 1.801c-.27-.336-.27-.88 0-1.216.27-.336.706-.336.976 0 .27.335.707.336.976 0 .27-.336.707-.336.977 0s.27.88 0 1.216c-.404.503-.934.755-1.465.755-.53 0-1.06-.252-1.464-.755z"
      fill="#8ACFF0"
    />
  </svg>
)

WaterStageIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

WaterStageIcon.defaultProps = {
  width: 15,
  height: 20
}

export default WaterStageIcon
