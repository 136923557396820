import React from 'react';
import PropTypes from 'prop-types';

const Sunny = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.76 5.33999L4.96 3.54999L3.55 4.95999L5.34 6.74999L6.76 5.33999ZM1 11H4V13H1V11ZM11 1.04999H13V3.99999H11V1.04999ZM19.04 3.54499L20.448 4.95199L18.658 6.74199L17.251 5.33399L19.04 3.54499ZM17.24 18.66L19.03 20.46L20.44 19.05L18.64 17.26L17.24 18.66ZM20 11H23V13H20V11ZM12 5.99999C8.69 5.99999 6 8.68999 6 12C6 15.31 8.69 18 12 18C15.31 18 18 15.31 18 12C18 8.68999 15.31 5.99999 12 5.99999ZM12 16C9.79 16 8 14.21 8 12C8 9.78999 9.79 7.99999 12 7.99999C14.21 7.99999 16 9.78999 16 12C16 14.21 14.21 16 12 16ZM11 20H13V22.95H11V20ZM3.55 19.04L4.96 20.45L6.75 18.65L5.34 17.24L3.55 19.04Z"
      fill="#85B5BA"
    />
  </svg>
);

Sunny.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Sunny.defaultProps = {
  width: 24,
  height: 24,
};

export default Sunny;
