import styled, { css } from "styled-components";

export const DataName = styled.span`
  display: inline-block;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.green};
  font-size: 18px;
  line-height: 22px;
  margin-right: 5px;

  @media (max-width: 1700px) {
    font-size: 16px;
  }
`;

export const DataValue = styled.span`
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  font-size: 18px;
  line-height: 22px;

  @media (max-width: 1700px) {
    font-size: 16px;
  }
`;

export const RoundColor = css`
  ${(p) => {
    switch (p.color) {
      case "red":
        return `
            background-color: ${p.theme.darkRed};
            border: 1px solid ${p.theme.vinousBorder};
          `;
      case "yellow":
        return `
            background-color: ${p.theme.roundYellow};
            border: 1px solid ${p.theme.yellowBorder};
          `;
      default:
        return `
            background-color: ${p.theme.roundGreen};
            border: 1px solid ${p.theme.greenBorder};
          `;
    }
  }}
`;
