import React, { Fragment } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { yieldForecast } from "src/stubs";
import { MaturityChart } from "src/components/yieldForecast/MaturityChart";
import { H3, ThreeFour, OneFour, PageHeader } from "src/ui";
import { AlarmActiveIcon } from "src/components/Icons";
import { DataName, DataValue, RoundColor } from "src/ui/Analytics";

const Root = styled.div`
  height: 100%;
`;

const Content = styled.div`
  display: flex;
`;

const List = styled.ul`
  list-style-type: none;
`;

const UpperArea = styled(List)`
  display: flex;
  width: 100%;
  padding: 24px 0;
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: ${(p) => p.theme.white};
`;

const DataItem = styled.li`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${(p) =>
    !p.isParams &&
    `
    width: 33.3%;
    padding-left: 24px;
    margin: 0;
  `}

  @media (max-width: 1700px) {
    flex-wrap: wrap;
  }
`;

const Name = styled(DataName)`
  ${(p) => p.theme.ProximaNovaBold};

  ${(p) =>
    p.isParams &&
    `
    ${p.theme.ProximaNova};
    width: 165px;
    margin-right: 28px;
  `}

  @media (max-width: 1700px) {
    width: 100%;
  }
`;

const ChartBlock = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};
  margin-bottom: 22px;
`;

const ChartTitle = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.darkGray};
`;

const ChartName = styled.p`
  ${(p) => p.theme.ProximaNovaBold};
  margin: 0;
  font-size: 24px;
  line-height: 30px;
`;

const Percent = styled.span`
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  line-height: 22px;
`;

const ChartWrapper = styled.div`
  padding: 24px 24px 50px 40px;
  display: flex;
  flex-wrap: wrap;
`;

const Legend = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

const LegendItem = styled.li`
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  line-height: 22px;
  color: ${(p) => p.theme.darkGray};
  display: flex;
  align-items: center;
  padding: 16px 5px;
`;

const Icon = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(p) => p.theme[p.color]};
`;

const Recommendation = styled.div`
  ${(p) => p.theme.ProximaNova};
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.lightBlue};
`;

const RecommendationTitle = styled(H3)`
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin-bottom: 16px;
  color: ${(p) => p.theme.black};
`;

const Params = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};
  margin-bottom: 24px;
`;

const ParamsTitle = styled(H3)`
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin-bottom: 24px;
`;

const Rise = styled.span`
  display: inline-block;
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.yieldGreen};
  margin-left: 8px;
`;

const Ranges = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

const RangeWrapper = styled.div`
  ${(p) => p.theme.ProximaNova};
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 32px;
`;

const Range = styled.div`
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 8px;
  background: linear-gradient(90deg, #cf0707 0%, #e6ce7a 50.31%, #58c08e 100%);
  border-radius: 4px;
`;

const Round = styled.span`
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: ${(p) => p.left};
  top: -4px;
  border-radius: 50%;
  ${RoundColor};
`;

const AlarmBlock = styled.div`
  width: 100%;
  padding: 32px 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};

  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const AlarmTitle = styled.p`
  ${(p) => p.theme.ProximaNovaBold};
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 8px;

  svg {
    margin-right: 18px;
  }
`;

const AlarmText = styled.p`
  margin: 0;
  padding-left: 40px;
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.green};
  font-size: 14px;
  line-height: 18px;
`;

const ListItem = ({ name, value, isParams }) => (
  <DataItem isParams={isParams}>
    <Name isParams={isParams}>{name}:</Name>
    <DataValue>{value}</DataValue>
  </DataItem>
);

export const YieldForecastStatistics = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { yieldId } = params;
  const currentYield = yieldForecast.defaultFields.find(
    (field) => field.id === +yieldId
  );

  const maturityChartValues = [
    {
      week: 1,
      act: 0,
      opt: 0,
      pes: 0,
    },
    {
      week: 2,
      act: 9,
      opt: 18,
      pes: 3,
    },
    {
      week: 3,
      act: 20,
      opt: 28,
      pes: 9,
    },
    {
      week: 4,
      act: 22,
      opt: 40,
      pes: 16,
    },
    {
      week: 5,
      act: 36,
      opt: 48,
      pes: 20,
    },
    {
      week: 6,
      act: 40,
      opt: 58,
      pes: 25,
    },
    {
      week: 7,
      act: 49,
      opt: 65,
      pes: 31,
    },
    {
      week: 8,
      opt: 73,
      pes: 38,
    },
    {
      week: 9,
      opt: 80,
      pes: 41,
    },
    {
      week: 10,
      opt: 89,
      pes: 45,
    },
    {
      week: 11,
      opt: 96,
      pes: 50,
    },
    {
      week: 12,
      opt: 101,
      pes: 55,
    },
    {
      week: t("week"),
      opt: "109",
      pes: 59,
    },
  ];

  const chartLegend = [
    {
      color: "blue",
      text: t("actual_legend"),
    },
    {
      color: "green",
      text: t("optimistic_legend"),
    },
    {
      color: "darkRed",
      text: t("pessimistic_legend"),
    },
  ];

  const paramsValue = ({ value, rise }) => (
    <Fragment>
      {value}
      <Rise>{rise}</Rise>
    </Fragment>
  );

  return (
    <Root>
      <PageHeader
        title={t("yield_forecast")}
        subTitle={`${t("id_field")}: <strong>${currentYield.id}</strong>`}
        isBackButton
      />
      <Content>
        <ThreeFour>
          <UpperArea>
            <ListItem
              name={t("field_area")}
              value={`${currentYield.area} ${t("hectares")}`}
            />
            <ListItem name={t("crop")} value={currentYield.crop} />
            <ListItem name={t("growth_phase")} value={currentYield.phase} />
          </UpperArea>
          <ChartBlock>
            <ChartTitle>
              <ChartName>{t("maturity")}</ChartName>
              <Percent>%</Percent>
            </ChartTitle>
            <ChartWrapper>
              <MaturityChart data={maturityChartValues} />
              <Legend>
                {chartLegend.map((item) => (
                  <LegendItem key={item.text}>
                    <Icon color={item.color} />
                    {item.text}
                  </LegendItem>
                ))}
              </Legend>
            </ChartWrapper>
          </ChartBlock>
          <Recommendation>
            <RecommendationTitle>
              {t("expert_recommendations")}
            </RecommendationTitle>
            {t("expert_recommendations_text")}
          </Recommendation>
        </ThreeFour>
        <OneFour>
          <Params>
            <ParamsTitle>{t("parameters")}</ParamsTitle>
            <List>
              <ListItem
                isParams
                name={t("maturation_rate")}
                value={paramsValue(currentYield.maturation)}
              />
              <ListItem
                isParams
                name={t("yield_forecast")}
                value={paramsValue(currentYield.yieldParam)}
              />
              <ListItem
                isParams
                name={t("forecast_cost")}
                value={paramsValue(currentYield.cost)}
              />
            </List>
          </Params>
          <Ranges>
            <RangeWrapper>
              {t("weather_conditions_risks")}
              <Range>
                <Round left={"13%"} color="red" />
              </Range>
            </RangeWrapper>
            <RangeWrapper>
              {t("plant_diseases_risks")}
              <Range>
                <Round left={"40%"} color="yellow" />
              </Range>
            </RangeWrapper>
            <RangeWrapper>
              {t("technogenic_risks")}
              <Range>
                <Round left={"70%"} />
              </Range>
            </RangeWrapper>
            <RangeWrapper>
              {t("economic_risks")}
              <Range>
                <Round left={"85%"} />
              </Range>
            </RangeWrapper>
          </Ranges>
          <AlarmBlock>
            <AlarmTitle>
              <AlarmActiveIcon />
              {t("low_soil_temperature")}
            </AlarmTitle>
            <AlarmText>{t("low_soil_temperature_value")}</AlarmText>
          </AlarmBlock>
          <AlarmBlock>
            <AlarmTitle>
              <AlarmActiveIcon />
              {t("soil_drying_up")}
            </AlarmTitle>
            <AlarmText>{t("soil_drying_up_value")}</AlarmText>
          </AlarmBlock>
        </OneFour>
      </Content>
    </Root>
  );
};
