import React from 'react'
import PropTypes from 'prop-types'

const GrowingCyclesIcon = ({ width, height }) => (
  <svg width={width} height={height}>
    <defs>
      <path
        d="M8.581 3A1.42 1.42 0 0 1 10 4.419V8.58A1.42 1.42 0 0 1 8.581 10H4.42A1.42 1.42 0 0 1 3 8.581V4.42A1.42 1.42 0 0 1 4.419 3H8.58zm.103 5.162V4.833a.474.474 0 0 0-.473-.473H4.852a.474.474 0 0 0-.473.473v3.329c0 .26.212.473.473.473h3.359c.26 0 .473-.212.473-.473zM19.58 14A1.42 1.42 0 0 1 21 15.419v4.162A1.42 1.42 0 0 1 19.581 21H15.42A1.42 1.42 0 0 1 14 19.581V15.42A1.42 1.42 0 0 1 15.419 14h4.162zm.103 5.162v-3.329a.474.474 0 0 0-.473-.473h-3.359a.474.474 0 0 0-.473.473v3.329c0 .26.212.473.473.473h3.359c.26 0 .473-.212.473-.473zM5.002 13.894c.103-.632.204-1.174.434-1.406.09-.091.198-.236.46-.172.261.064.292.163.349.322.089.249-.093.636-.202 1.356-.238 1.565.114 2.548.99 3.438a3.693 3.693 0 0 0 2.305 1.089c.285.028.57.025.852-.007l-.417-.288c-.139-.035-.139-.175-.042-.303l.347-.492c.072-.101.208-.13.303-.065l1.627 1.125c.121.037.122.19.042.303l-1.232 1.742c-.072.102-.207.13-.302.065-.323-.242-.507-.396-.552-.462-.031-.046.044-.148.05-.158l.331-.467a5.038 5.038 0 0 1-1.177.026 4.604 4.604 0 0 1-2.872-1.357 4.756 4.756 0 0 1-1.143-1.916c-.236-.76-.294-1.492-.151-2.373zm14.419-3.04c-.103.631-.204 1.174-.434 1.405-.09.091-.198.236-.46.172-.261-.064-.292-.162-.349-.321-.089-.25.093-.637.202-1.357.238-1.565-.114-2.548-.99-3.438a3.693 3.693 0 0 0-2.305-1.089 4.039 4.039 0 0 0-.852.007l.417.289c.139.034.139.175.042.303l-.347.491c-.072.102-.208.131-.303.065l-1.628-1.124c-.12-.038-.12-.19-.041-.304l1.232-1.742c.072-.101.207-.13.302-.065.323.243.507.397.552.462.031.047-.044.148-.051.158l-.33.467a5.038 5.038 0 0 1 1.177-.025 4.604 4.604 0 0 1 2.872 1.357c.522.53.917 1.192 1.143 1.916.236.759.294 1.491.151 2.372z"
        id="GrowingCyclesIcon__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="GrowingCyclesIcon__b">
        <use xlinkHref="#GrowingCyclesIcon__a" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#GrowingCyclesIcon__a" />
      <g mask="url(#GrowingCyclesIcon__b)" className="_color">
        <path d="M-.19-1.2h26v26h-26z" />
      </g>
    </g>
  </svg>
)

GrowingCyclesIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

GrowingCyclesIcon.defaultProps = {
  width: 24,
  height: 24
}

export default GrowingCyclesIcon
