import styled from 'styled-components';

export const CheckBox = styled.span`
  display: block;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 2px solid #85b5ba;
  border-radius: 2px;
  cursor: pointer;
  margin: 3px 0 0;

  ${(p) =>
    p.checked &&
    `
    background: #fff;
    background-image: url('/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
  `}
`;
