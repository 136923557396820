import React from 'react'
import styled from 'styled-components'

const Legend = styled.div`
  width: 300px;
  flex-shrink: 0;
`

const Label = styled.div`
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin: 0;

  :not(:last-child) {
    margin-bottom: 30px;
  }
`

const ColorDot = styled.span`
  margin-right: 14px;
  background-color: ${(p) => p.dotColor};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`

const Content = styled.p`
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.green};
  margin: 0;
`
const Value = styled.span`
  display: inline-block;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.darkGray};
  margin-left: 5px;
`

const cycleInfo = {
  water: {
    content: 'Total water consumption',
    value: '120 Gal'
  },
  temperature: {
    content: 'Temperature',
    value: '~16 °C'
  },
  co2: {
    content: 'Carbon dioxide',
    value: '~500 ppm'
  },
  electricity: {
    content: 'Electricity consumption',
    value: '13,2 kW'
  },
}

export const ChartLegend = ({ chartType, colors, cyclesToShow }) => {
  return (
    <Legend>
      {cyclesToShow.map(cycle =>
        <Label key={cycle.cycleId}>
          <ColorDot dotColor={colors[cycle.cycleId]} />
          <div>
            {cycle.cycleName}
            <Content>
              {cycleInfo[chartType].content}:
              <Value>
                {cycleInfo[chartType].value}
              </Value>
            </Content>
          </div>
        </Label>
      )}
    </Legend>
  )
}