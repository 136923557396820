import styled from 'styled-components';

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  //height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${p => p.justifyContent || 'flex-start'};
  align-items: ${p => p.alignItems || 'flex-start'};
  border: none;
  //flex-grow: 1;
  margin: ${({margin}) => margin || '0'};
`;
