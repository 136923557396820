import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { WarningIcon } from "src/components/Icons";
import { PageHeader, Root } from "src/ui";

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 15px;
  text-decoration: none;
  width: max-content;
`;

const BlockRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  width: auto;
`;

const Item = styled.div`
  max-width: ${({ width }) => width || "auto"};
  font-style: normal;
  width: 100%;
  font-weight: ${({ bold }) => bold || "400"};
  font-size: 18px;
  line-height: 22px;
  color: ${({ color }) => color || "#000"};
  min-height: 22px;
  text-align: ${({ align }) => align || "left"};
  margin: ${({ margin }) => margin || "0 5px 16px"};
  white-space: ${({ nowrap }) => (nowrap ? "nowrap" : "initial")};
  flex-shrink: 0;
`;

const RiskId = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  text-align: left;
  max-width: 140px;
  margin-bottom: 10px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ color }) => color || "#000"};
  margin-right: 12px;
  border-radius: 50%;
`;

const Classification = styled.div`
  width: 238px;
  height: 266px;
  background: ${({ color }) => color};
  border-radius: 4px;
  text-align: center;
  padding-top: 63px;

  & .icon {
    width: min-content;
    margin: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 51px;
    text-align: center;
    color: #364652;
  }

  & svg {
    fill: ${({ iconColor }) => iconColor};
    margin-right: 12px;
  }

  & .status {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #364652;
    margin-top: 48px;
  }

  & .date {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #364652;
    margin-top: 5px;
  }
`;

const BlockTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #364652;
  margin-bottom: 11px;
  min-height: 22px;
  padding: 0 12px;
  width: ${({ width }) => width || "max-content"};
  margin-right: ${({ marginRight }) => marginRight || "0"};
`;

const Block = styled.div`
  padding: 8px 16px;
  overflow-x: auto;
  width: ${({ width }) => width || "auto"};
  background: ${({ bg }) => bg || "transparent"};
`;

const WrapRow = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const WrapTableHead = styled.div`
  display: flex;
`;

// TODO need refactor
export const ForecastOfCriticalSituationInfo = () => {
  const { t, i18n } = useTranslation();
  const isEnLng = i18n.language === "en";

  const today = moment();
  const yesterday = moment(today).subtract(1, "days");

  return (
    <Root>
      <PageHeader
        title={t("risk_assessment")}
        subTitle={`${t("id_field")}: <strong>54787</strong>`}
        isBackButton
      />
      {isEnLng ? (
        <WrapTableHead>
          <BlockTitle width="270px" marginRight="5px">
            {t("classification_by_priority")}
          </BlockTitle>
          <BlockTitle>{t("classification_by_source_and_urgency")}</BlockTitle>
        </WrapTableHead>
      ) : (
        <BlockTitle>
          {t("classification_by_priority_source_and_urgency")}
        </BlockTitle>
      )}

      <WrapRow>
        <Row>
          <Block>
            <div>
              <Classification
                color="#FFE5E5"
                iconColor={(p) => p.theme.darkRed}
              >
                <div className="icon">
                  <BlockRow>
                    <WarningIcon />
                    <h2>3</h2>
                  </BlockRow>
                </div>
                <p className="status">{t("high_priority_risks")}</p>
                <p className="time">{t("over_five_days")}</p>
              </Classification>
            </div>
          </Block>
          <Block width="485px">
            <BlockRow>
              <Item width={"190px"} />
              <Item bold="600" align="center" width={"80px"}>
                {t("high_risk")}
              </Item>
              <Item bold="600" align="center" width={"80px"}>
                {t("medium_risk")}
              </Item>
              <Item bold="600" align="center" width={"80px"}>
                {t("low_risk")}
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("technology_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("water_risk")}</Item>
              <Item
                color={(p) => p.theme.darkRed}
                align="center"
                width={"80px"}
              >
                1
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("climatic_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("epidemiological_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item
                color={(p) => p.theme.darkRed}
                align="center"
                width={"80px"}
              >
                1
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("operational_risk")}</Item>
              <Item
                color={(p) => p.theme.darkRed}
                align="center"
                width={"80px"}
              >
                1
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("economic_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
          </Block>
          <div style={{ margin: "35px 0 15px" }}>
            <Block width="762px" bg="#fff">
              <BlockRow>
                <Item bold="600" width={"132px"}>
                  {t("risk_id")}
                </Item>
                <Item bold="600" width={"260px"}>
                  {t("risk_description")}
                </Item>
                <Item bold="600" width={"140px"} align="center">
                  {t("risk_owner")}
                </Item>
                <Item bold="600" width={"140px"} align="center">
                  {t("open_data")}
                </Item>
              </BlockRow>
              <BlockRow>
                <RiskId>
                  <Dot color={(p) => p.theme.darkRed} />
                  <p>{t("risk")} 2518</p>
                </RiskId>
                <Item width={"260px"}>{t("first_high_risk")}</Item>
                <Item align="center" width={"140px"}>
                  3
                </Item>
                <Item align="center" width={"140px"}>
                  {moment(today).format(isEnLng ? "YYYY/MM/DD" : "DD/MM/YYYY")}
                </Item>
              </BlockRow>
              <BlockRow>
                <RiskId>
                  <Dot color={(p) => p.theme.darkRed} />
                  <p>{t("risk")} 2517</p>
                </RiskId>
                <Item width={"260px"}>{t("second_high_risk")}</Item>
                <Item align="center" width={"140px"}>
                  2
                </Item>
                <Item align="center" width={"140px"}>
                  {moment(today).format(isEnLng ? "YYYY/MM/DD" : "DD/MM/YYYY")}
                </Item>
              </BlockRow>
              {/* <BlockRow>
                <RiskId>
                  <Dot color={(p) => p.theme.darkRed} />
                  <p>{t("risk")} 2516</p>
                </RiskId>
                <Item width={"260px"}>{t("third_high_risk")}</Item>
                <Item align="center" width={"140px"}>
                  0
                </Item>
                <Item align="center" width={"140px"}>
                  {moment(yesterday).format(
                    isEnLng ? "YYYY/MM/DD" : "DD/MM/YYYY"
                  )}
                </Item>
              </BlockRow> */}
            </Block>
          </div>
        </Row>
      </WrapRow>
      <WrapRow>
        <Row>
          <Block>
            <div>
              <Classification
                color="#FDECC0"
                iconColor={(p) => p.theme.darkRed}
              >
                <div className="icon">
                  <BlockRow>
                    <WarningIcon />
                    <h2>1</h2>
                  </BlockRow>
                </div>
                <p className="status">{t("medium_priority_risks")}</p>
                <p className="time">{t("over_one_week")}</p>
              </Classification>
            </div>
          </Block>
          <Block width="485px">
            <BlockRow>
              <Item width={"190px"} />
              <Item bold="600" align="center" width={"80px"}>
                {t("high_risk")}
              </Item>
              <Item bold="600" align="center" width={"80px"}>
                {t("medium_risk")}
              </Item>
              <Item bold="600" align="center" width={"80px"}>
                {t("low_risk")}
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("technology_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("water_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("climatic_risk")}</Item>
              <Item align="center" width={"80px"} color={(p) => p.theme.orange}>
                1
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("epidemiological_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("operational_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("economic_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
          </Block>
          <div>
            <Block width="762px" bg="#fff">
              <BlockRow>
                <Item bold="600" width={"132px"}>
                  {t("risk_id")}
                </Item>
                <Item bold="600" width={"260px"}>
                  {t("risk_description")}
                </Item>
                <Item bold="600" align="center" width={"140px"}>
                  {t("risk_owner")}
                </Item>
                <Item bold="600" align="center" width={"140px"}>
                  {t("open_data")}
                </Item>
              </BlockRow>
              <BlockRow>
                <RiskId>
                  <Dot color={(p) => p.theme.orange} />
                  <p>{t("risk")} 2405</p>
                </RiskId>
                <Item width={"260px"}>{t("first_medium_risk")}</Item>
                <Item align="center" width={"140px"}>
                  1
                </Item>
                <Item align="center" width={"140px"}>
                  {moment(yesterday).format(
                    isEnLng ? "YYYY/MM/DD" : "DD/MM/YYYY"
                  )}
                </Item>
              </BlockRow>
            </Block>
          </div>
        </Row>
      </WrapRow>
      <WrapRow>
        <Row>
          <Block>
            <div>
              <Classification
                color="#D9FBEA"
                iconColor={(p) => p.theme.yieldGreen}
              >
                <div className="icon">
                  <BlockRow>
                    <WarningIcon />
                    <h2>3</h2>
                  </BlockRow>
                </div>
                <p className="status">{t("low_priority_risks")}</p>
                <p className="time">{t("over_three_week")}</p>
              </Classification>
            </div>
          </Block>
          <Block width="485px">
            <BlockRow>
              <Item width={"190px"} />
              <Item bold="600" align="center" width={"80px"}>
                {t("high_risk")}
              </Item>
              <Item bold="600" align="center" width={"80px"}>
                {t("medium_risk")}
              </Item>
              <Item bold="600" align="center" width={"80px"}>
                {t("low_risk")}
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("technology_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item
                align="center"
                width={"80px"}
                color={(p) => p.theme.yieldGreen}
              >
                2
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("water_risk")}</Item>
              <Item
                align="center"
                width={"80px"}
                color={(p) => p.theme.yieldGreen}
              >
                1
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("climatic_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("epidemiological_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("operational_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
            <BlockRow>
              <Item width={"190px"}>{t("economic_risk")}</Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
              <Item align="center" width={"80px"}>
                0
              </Item>
            </BlockRow>
          </Block>
          <div>
            <Block width="762px" bg="#fff">
              <BlockRow>
                <Item bold="600" width={"132px"}>
                  {t("risk_id")}
                </Item>
                <Item bold="600" width={"260px"}>
                  {t("risk_description")}
                </Item>
                <Item bold="600" align="center" width={"140px"}>
                  {t("risk_owner")}
                </Item>
                <Item bold="600" align="center" width={"140px"}>
                  {t("open_data")}
                </Item>
              </BlockRow>
              <BlockRow>
                <RiskId dotColor={(p) => p.theme.yieldGreen}>
                  <Dot color={(p) => p.theme.yieldGreen} />
                  <p>{t("risk")} 2604</p>
                </RiskId>
                <Item width={"260px"}>{t("first_low_risk")}</Item>
                <Item align="center" width={"140px"}>
                  3
                </Item>
                <Item align="center" width={"140px"}>
                  {moment(today).format(isEnLng ? "YYYY/MM/DD" : "DD/MM/YYYY")}
                </Item>
              </BlockRow>
              <BlockRow>
                <RiskId>
                  <Dot color={(p) => p.theme.yieldGreen} />
                  <p>{t("risk")} 2603</p>
                </RiskId>
                <Item width={"260px"}>{t("second_low_risk")}</Item>
                <Item align="center" width={"140px"}>
                  0
                </Item>
                <Item align="center" width={"140px"}>
                  {moment(yesterday).format(
                    isEnLng ? "YYYY/MM/DD" : "DD/MM/YYYY"
                  )}
                </Item>
              </BlockRow>
              {/* <BlockRow>
                <RiskId>
                  <Dot color={(p) => p.theme.yieldGreen} />
                  <p>{t("risk")} 2601</p>
                </RiskId>
                <Item width={"260px"}>{t("third_low_risk")}</Item>
                <Item align="center" width={"140px"}>
                  0
                </Item>
                <Item align="center" width={"140px"}>
                  {moment(yesterday).format(
                    isEnLng ? "YYYY/MM/DD" : "DD/MM/YYYY"
                  )}
                </Item>
              </BlockRow> */}
            </Block>
          </div>
        </Row>
      </WrapRow>
    </Root>
  );
};
