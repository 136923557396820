import React from "react";
import { Trans } from "react-i18next";

export const yieldForecast = {
  defaultFields: [
    {
      id: 2599877,
      area: 150,
      crop: (
        <span>
          25 <Trans i18nKey="boston_lettuce" />
        </span>
      ),
      phase: <Trans i18nKey="vegetatio" />,
      yield: (
        <span>
          205 <Trans i18nKey="t_ga" />
        </span>
      ),
      maturation: {
        value: "42%",
        rise: "+17%",
      },
      yieldParam: {
        value: (
          <span>
            18 <Trans i18nKey="t_ga" />
          </span>
        ),
        rise: (
          <span>
            +3 <Trans i18nKey="t_ga" />
          </span>
        ),
      },
      cost: {
        value: <Trans i18nKey="first_price_value" />,
        rise: <Trans i18nKey="first_rise_value" />,
      },
      rating: {
        type: "plus",
        value: "+30",
      },
    },
    {
      id: 2599889,
      area: 350,
      crop: (
        <span>
          154 <Trans i18nKey="lettuce" />
        </span>
      ),
      phase: <Trans i18nKey="maturation" />,
      yield: (
        <span>
          423 <Trans i18nKey="t_ga" />
        </span>
      ),
      maturation: {
        value: "62%",
        rise: "+20%",
      },
      yieldParam: {
        value: (
          <span>
            38 <Trans i18nKey="t_ga" />
          </span>
        ),
        rise: (
          <span>
            +10 <Trans i18nKey="t_ga" />
          </span>
        ),
      },
      cost: {
        value: <Trans i18nKey="second_price_value" />,
        rise: <Trans i18nKey="second_rise_value" />,
      },
      rating: {
        type: "minus",
        value: "-70",
      },
    },
    {
      id: 2592487,
      area: 4150,
      crop: (
        <span>
          25 <Trans i18nKey="boston_lettuce" />
        </span>
      ),
      phase: <Trans i18nKey="vegetatio" />,
      yield: (
        <span>
          4087 <Trans i18nKey="t_ga" />
        </span>
      ),
      maturation: {
        value: "32%",
        rise: "+15%",
      },
      yieldParam: {
        value: (
          <span>
            34
            <Trans i18nKey="t_ga" />
          </span>
        ),
        rise: (
          <span>
            +10 <Trans i18nKey="t_ga" />
          </span>
        ),
      },
      cost: {
        value: <Trans i18nKey="third_price_value" />,
        rise: <Trans i18nKey="third_rise_value" />,
      },
      rating: {
        type: "plus",
        value: "+50",
      },
    },
  ],
};
