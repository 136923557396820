import gql from 'graphql-tag'

export const CYCLE_COMPARE_PAGE_QUERY = gql`
  query($cropId: ID!) {
    growing_cycles(cropId: $cropId) {
      id
      name
      duration_in_days
      status

      current_day_number
      start_at
      end_at
    }
  }
`
