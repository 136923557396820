import React from 'react'
import PropTypes from 'prop-types'

const FarmStatusIcon = ({ width, height }) => (
  <svg width={width} height={height}>
    <defs>
      <path
        d="M22.292 8.027c.7-3.498.708-6.266.708-6.382A.644.644 0 0 0 22.355 1c-.116 0-2.884.008-6.382.708-1.246.249-2.426.556-3.531.915l-.023.008c-.715.233-1.399.49-2.047.767a19.63 19.63 0 0 0-2.28 1.153c-1.103.654-2.064 1.389-2.874 2.198a8.453 8.453 0 0 0-2.492 6.017c0 2.056.725 4.001 2.053 5.543L1.19 21.9a.644.644 0 1 0 .911.912l3.59-3.59a8.445 8.445 0 0 0 5.544 2.053c2.273 0 4.41-.885 6.017-2.492.81-.81 1.544-1.772 2.198-2.874a19.628 19.628 0 0 0 1.153-2.28 24.59 24.59 0 0 0 .774-2.068c.36-1.106.667-2.287.916-3.533zm-6.09-5.05a36.783 36.783 0 0 1 5.49-.67 36.778 36.778 0 0 1-.669 5.492c-.18.9-.421 1.899-.74 2.932H14.18l3.75-3.75a.645.645 0 0 0-.912-.91L13.27 9.82V3.716a30.15 30.15 0 0 1 2.932-.74zM18.538 14.9H10.01l2.881-2.88 6.958-.001c-.358.973-.79 1.951-1.31 2.881zM11.98 4.151v6.957L9.099 13.99V5.46c.93-.52 1.908-.952 2.881-1.31zm-7.965 8.615c0-1.929.751-3.742 2.115-5.105.513-.514 1.08-.978 1.68-1.396v9.014l-2.116 2.115a7.164 7.164 0 0 1-1.679-4.628zm12.324 5.105a7.173 7.173 0 0 1-5.105 2.114 7.165 7.165 0 0 1-4.628-1.68L8.72 16.19h9.014a12.38 12.38 0 0 1-1.396 1.68z"
        id="FarmStatusIcon__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="FarmStatusIcon__b">
        <use xlinkHref="#FarmStatusIcon__a" />
      </mask>
      <use
        className="_color"
        fillRule="nonzero"
        xlinkHref="#FarmStatusIcon__a"
      />
      <g mask="url(#FarmStatusIcon__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

FarmStatusIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

FarmStatusIcon.defaultProps = {
  width: 24,
  height: 24
}

export default FarmStatusIcon
