import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';

export const SoilChart = ({ data, ticks }) => {
  return (
    <LineChart
      width={760}
      height={363}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid />
      <XAxis dataKey="name" stroke="#BFC4CD" />
      <YAxis
        ticks={ticks}
        dataKey="opt"
        tickCount={4}
        domain={[0, 28]}
        padding={{ top: 40 }}
        stroke="#BFC4CD"
      />
      <Line
        type="monotone"
        dataKey="gray1"
        strokeDasharray="5 5"
        stroke="#BFC4CD"
        strokeWidth={2}
        dot={{ fill: '#BFC4CD' }}
        activeDot={false}
      />
      <Line
        type="monotone"
        dataKey="blue"
        stroke="#3253AD"
        strokeWidth={2}
        dot={{ fill: '#3253AD' }}
        activeDot={false}
      />
      <Line
        type="monotone"
        dataKey="gray2"
        strokeDasharray="5 5"
        stroke="#BFC4CD"
        strokeWidth={2}
        dot={{ fill: '#BFC4CD' }}
        activeDot={false}
      />
      <Line
        type="monotone"
        dataKey="green"
        stroke="#85B5BA"
        strokeWidth={2}
        dot={{ fill: '#85B5BA' }}
        activeDot={false}
      />
      <Line
        type="monotone"
        dataKey="gray3"
        strokeDasharray="5 5"
        stroke="#BFC4CD"
        strokeWidth={2}
        dot={{ fill: '#BFC4CD' }}
        activeDot={false}
      />
      <Line
        type="monotone"
        dataKey="red"
        stroke="#CF0707"
        strokeWidth={2}
        dot={{ fill: '#CF0707' }}
        activeDot={false}
      />
    </LineChart>
  );
};
