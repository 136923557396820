import React from 'react'
import PropTypes from 'prop-types'

const PlusIcon = ({ width, height, fill, ...props }) => (
  <svg width={width} height={height} {...props}>
    <path
      d="M11.516 11.461L6.963 6.91l-5.04 5.04-1.046-1.045 5.04-5.04-4.575-4.577.975-.975 4.576 4.576 4.901-4.901 1.046 1.045-4.902 4.901 4.553 4.553z"
      fill={fill}
      fillRule="evenodd"
      opacity={0.329}
    />
  </svg>
)

PlusIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

PlusIcon.defaultProps = {
  width: 13,
  height: 12
}

export default PlusIcon
