import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

import { CloseIcon } from "src/components/Icons";

ReactModal.setAppElement("#root");

const StyledModal = styled(ReactModal).attrs({
  tabIndex: -1,
})`
  position: relative;
  z-index: 1000;
  background: white;
  box-shadow: 0 0 40px rgb(0 0 0 / 25%);
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  svg {
    fill: #c2c2c2;
  }
`;

const modalInlineStyles = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "328px",
  },
};

export const Modal = ({ className, isOpen, onClose, children, ...props }) => (
  <StyledModal
    className={className}
    isOpen={isOpen}
    onRequestClose={onClose}
    style={modalInlineStyles}
    {...props}
  >
    {children}
    <CloseButton onClick={onClose}>
      <CloseIcon />
    </CloseButton>
  </StyledModal>
);

Modal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element,
};
