import React from 'react';
import styled from 'styled-components';

const List = styled.ul`
  ${(p) => p.theme.ProximaNova};
  font-size: 12px;
  display: flex;
  list-style-type: none;
  color: ${(p) => p.theme.darkGray};
`;
const Item = styled.li`
  border-right: 1px solid ${(p) => p.theme.lightGray};
  margin-left: 15px;
`;

const Label = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

const RadioDot = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.gray};

  ${(p) =>
    p.checked &&
    `
    :after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${p.theme.darkGray};
    }
  `};
`;

const Value = styled.span`
  padding-left: 10px;
  padding-right: 15px;
  text-transform: capitalize;
`;

const HiddenInput = styled.input`
  display: none;
`;

const possibleTypes = [
  {
    value: 'temperature',
  },
  {
    value: 'humidity',
  },
  {
    value: 'CO2',
  },
];

export const SensorTypeSelect = ({ currentType, onSelect }) => {
  return (
    <List>
      {possibleTypes.map(({ value }) => {
        const checked = value.toLowerCase() === currentType.toLowerCase();
        return (
          <Item key={value}>
            <Label>
              <RadioDot checked={checked} />
              <Value>{value}</Value>
              <HiddenInput
                onChange={() => onSelect(value.toLowerCase())}
                type="radio"
                name="type"
                checked={checked}
              />
            </Label>
          </Item>
        );
      })}
    </List>
  );
};
