import React from "react";
import "moment/locale/ru";
import { createRoot } from "react-dom/client";

import "./translations/i18n";
import { App } from "./app";

if (process.env.NODE_ENV !== "development") {
  window.Raven.config(
    "https://a70501d7846a4abd84fa191c7815ecc3@sentry.jetru.by/82"
  ).install();
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<App />);
