import React from "react";
import styled from "styled-components";

const ContainerWrapper = styled.div`
  padding: ${({ padding }) => (padding ? `${padding}px` : "12px")};
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "100%")};
  min-width: 260px;
`;

const ContainerContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 24px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  ${(p) => {
    if (p.alertType === "error")
      return `
    box-shadow: 0px 0px 10px ${p.theme.contentBoxErrorBorder}
  `;
    if (p.alertType === "ok")
      return `
    box-shadow: 0px 0px 10px ${p.theme.contentBoxOkBorder}
  `;
  }}
  ${(p) =>
    p.shadowColor &&
    `
    box-shadow: inset 4px 0px 0px ${p.theme[p.shadowColor]};
`}
`;

const Title = styled.span`
  display: block;
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 6px;
  padding: 0;

  @media (max-width: 1700px) {
    font-size: 16px;
  }
`;

const TitleIconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  float: left;
`;

const TitleDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${(p) => p.theme.green};
  padding: 0 0 0 40px;
  margin: 0 0 6px;
`;

const AmountWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-left: 36px;
  flex-direction: row;
  align-items: flex-end;
  ${({ isLeftAmountPosition }) =>
    isLeftAmountPosition
      ? `justify-content: flex-start;`
      : `justify-content:flex-end;`}
  }
`;

const Amount = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  color: ${(p) => p.theme.darkGray};
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 42px;
  line-height: 52px;

  @media (max-width: 1700px) {
    font-size: 40px;
  }
`;

const AmounDescription = styled.div`
  display: inline-block;
  ${(p) => p.theme.ProximaNova};
  color: currentColor;
  font-size: 14px;
  line-height: 18px;
  margin: 7px 0 0;
`;

const Total = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  color: ${(p) => {
    if (p.alertType === "error") return p.theme.darkRed;
    if (p.alertType === "ok") return p.theme.mainGreen;
  }};
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 24px;
  line-height: 30px;
  padding: 0 0 0 16px;

  @media (max-width: 1700px) {
    font-size: 20px;
  }
`;

const TotalAux = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  color: ${(p) => p.theme.green};
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 42px;
  line-height: 52px;
`;

const CurrentResult = ({ resType, children }) => {
  const CurrentResultWrapper = styled.div`
    display: inline-block;
    ${(p) => p.theme.ProximaNovaBold};
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 16px;
  `;

  const CurrentResultNormal = styled.div`
    color: ${(p) => p.theme.mainGreen};
  `;

  const CurrentResultBad = styled.div`
    color: ${(p) => p.theme.red};
  `;

  return (
    <CurrentResultWrapper>
      {resType === "bad" ? (
        <CurrentResultBad>{children}</CurrentResultBad>
      ) : (
        <CurrentResultNormal>{children}</CurrentResultNormal>
      )}
    </CurrentResultWrapper>
  );
};

export const ContentBox = ({
  width,
  height,
  padding,
  title,
  description,
  amount,
  totalAmount,
  amountName,
  totalName,
  icon,
  alertType,
  isLeftAmountPosition,
  shadowColor,
  res,
  resType,
  content,
}) => {
  return (
    <ContainerWrapper width={width} height={height} padding={padding}>
      <ContainerContentWrapper shadowColor={shadowColor} alertType={alertType}>
        <div>
          {" "}
          <Title>
            <TitleIconWrapper>{icon}</TitleIconWrapper>
            {title}
          </Title>
          <TitleDescription>{description}</TitleDescription>
        </div>

        {content ? (
          content
        ) : (
          <>
            <AmountWrapper
              alertType={alertType}
              isLeftAmountPosition={isLeftAmountPosition}
            >
              {alertType ? (
                <>
                  <Amount>{amount}</Amount>
                  {totalAmount && (
                    <Total alertType={alertType}>{totalAmount}</Total>
                  )}
                </>
              ) : (
                <>
                  {amount && (
                    <Amount>
                      {amount}
                      {totalAmount && " /"}
                      <AmounDescription>{amountName}</AmounDescription>
                    </Amount>
                  )}
                  {totalAmount && !amount ? (
                    <TotalAux>{totalAmount}</TotalAux>
                  ) : (
                    <Total>
                      {totalAmount}
                      <AmounDescription>{totalName}</AmounDescription>
                    </Total>
                  )}
                </>
              )}
            </AmountWrapper>
            <CurrentResult resType={resType}>{res}</CurrentResult>
          </>
        )}
      </ContainerContentWrapper>
    </ContainerWrapper>
  );
};

ContentBox.defaultProps = {
  width: "100%",
  padding: "12px",
};
