import React from "react";

const LogoIconRu = ({ width, height, ...props }) => (
  <svg
    width={width} // 241
    height={height} //68
    viewBox="0 0 241 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M57.3441 28.0299H59.6503V34.3804L64.9317 28.0299H67.7567L62.2448 34.2651L68.1027 41.4109H65.1161L59.6503 34.4957V41.4109H57.3441V28.0299Z"
      fill="white"
    />
    <path
      d="M70.1897 41.4109V28.0299H73.995C77.9271 28.0299 79.1725 30.1275 79.1725 32.3289C79.1725 34.5302 77.4659 36.674 74.2718 36.674C73.8797 36.674 73.1186 36.674 72.5421 36.674V41.5031L70.1897 41.4109ZM72.4959 30.2312V34.3343C73.0473 34.3972 73.6017 34.4318 74.1566 34.438C75.5519 34.438 76.6357 33.7695 76.6357 32.2712C76.6357 30.7729 75.7822 30.1967 74.1794 30.1967L72.4959 30.2312Z"
      fill="white"
    />
    <path
      d="M84.2347 32.617C84.7284 32.5579 85.2248 32.5271 85.722 32.5247C89.0314 32.5247 90.6227 34.2305 90.6227 36.8467C90.6227 39.6705 88.6279 41.4569 85.2031 41.4569C83.9463 41.4569 82.6664 41.4569 81.8823 41.4569V28.0759H84.1885L84.2347 32.617ZM84.2347 34.8184V39.198C84.6498 39.198 85.0417 39.198 85.4914 39.198C87.2211 39.198 88.1321 38.4142 88.1321 36.8929C88.1321 35.3715 87.175 34.7146 85.5376 34.7146C85.1017 34.7259 84.6669 34.7606 84.2347 34.8184V34.8184ZM92.3065 28.0299H94.6127V41.4109H92.3065V28.0299Z"
      fill="white"
    />
    <path
      d="M98.3947 28.0299H100.828L105.44 34.2766L109.799 28.0299H112.013V41.4109H109.707V31.7295L105.486 37.6767H105.14L100.874 31.7872V41.3994H98.5678L98.3947 28.0299Z"
      fill="white"
    />
    <path
      d="M118.044 39.2095H119.474C120.027 38.5295 121.307 35.7519 121.538 30.7037L121.676 28.0299H129.967V39.2095H131.789V43.8197H129.586V41.434H120.235V43.8197H118.044V39.2095ZM122.057 39.2095H127.638V30.2312H123.948L123.74 32.5362C123.676 34.8573 123.101 37.1358 122.057 39.2095Z"
      fill="white"
    />
    <path
      d="M134.107 41.4109V28.0299H136.413V37.5499L143.435 28.0299H145.499V41.4109H143.193V31.914L136.182 41.4109H134.107Z"
      fill="white"
    />
    <path
      d="M147.621 39.2095H149.051C149.616 38.5295 150.896 35.7519 151.127 30.7037L151.253 28.0299H159.556V39.2095H161.378V43.8197H159.175V41.434H149.823V43.8197H147.621V39.2095ZM151.634 39.2095H157.226V30.2312H153.536L153.317 32.5362C153.266 34.8589 152.69 37.14 151.634 39.2095V39.2095Z"
      fill="white"
    />
    <path
      d="M162.15 28.0299H164.848L169.703 34.4149V28.0299H172.009V34.4149L176.852 28.0299H179.562L174.534 34.4149L179.781 41.4109H177.06L172.009 34.4957V41.4109H169.703V34.4957L164.629 41.4109H161.943L167.189 34.438L162.15 28.0299Z"
      fill="white"
    />
    <path
      d="M181.834 41.4109V28.0299H184.14V37.5499L191.163 28.0299H193.226V41.4109H190.92V31.914L183.898 41.4109H181.834Z"
      fill="white"
    />
    <path
      d="M195.302 28.0299H206.337V30.2312H202.002V41.4109H199.696V30.2312H195.348L195.302 28.0299Z"
      fill="white"
    />
    <path
      d="M211.134 27.9953H213.198L218.86 41.4109H216.358L214.963 37.9533H209.197L207.802 41.4109H205.426L211.134 27.9953ZM210.143 35.798H214.063L212.103 31.038L210.143 35.798Z"
      fill="white"
    />
    <path
      d="M228.639 30.2312H224.741L224.476 32.7553C223.692 40.535 221.951 41.7913 219.31 41.4109L219.368 39.1058C220.394 39.2326 221.754 38.7946 222.354 30.7268L222.539 28.053H230.98V41.434H228.673L228.639 30.2312Z"
      fill="white"
    />
    <path
      d="M23.4539 44.6726C22.4617 44.6703 21.4766 44.5069 20.5368 44.1886C19.0313 43.6886 17.6856 42.7975 16.638 41.6067C15.5905 40.4158 14.8785 38.9681 14.575 37.4117L11.1157 20.2157C10.7914 18.66 10.8843 17.0462 11.3851 15.538C11.886 14.0298 12.7769 12.6807 13.9675 11.6276C15.1582 10.5745 16.6063 9.8546 18.165 9.54116C19.7237 9.22772 21.3376 9.33184 22.843 9.8428L39.4823 15.4672C40.984 15.9728 42.3258 16.865 43.3727 18.054C44.4196 19.2429 45.1348 20.6866 45.4458 22.2397C45.7568 23.7927 45.6527 25.4002 45.1443 26.9003C44.6359 28.4004 43.7408 29.7402 42.5495 30.7845L29.3695 42.31C27.7568 43.7999 25.6498 44.6413 23.4539 44.6726V44.6726ZM19.9372 9.9811C18.6895 9.98914 17.4589 10.2728 16.3337 10.8117C15.2085 11.3507 14.2166 12.1317 13.4287 13.0987C12.6408 14.0657 12.0766 15.1949 11.7764 16.4053C11.4763 17.6158 11.4475 18.8776 11.6923 20.1005L15.1516 37.2963C15.4422 38.747 16.1094 40.0959 17.0862 41.2075C18.0631 42.3192 19.3152 43.1546 20.717 43.6298C22.1188 44.105 23.6211 44.2034 25.073 43.9151C26.5248 43.6268 27.8753 42.9619 28.9889 41.9872L42.1689 30.3926C43.284 29.415 44.1217 28.161 44.5976 26.7568C45.0734 25.3527 45.1708 23.848 44.8797 22.3943C44.5885 20.9406 43.9194 19.5892 42.9394 18.4763C41.9595 17.3634 40.7033 16.5283 39.2977 16.055L22.6584 10.4421C21.7831 10.1386 20.8636 9.98279 19.9372 9.9811V9.9811Z"
      fill="white"
    />
    <path
      d="M23.708 47.9344C22.5143 47.9283 21.3334 47.6869 20.2332 47.224C19.1329 46.7612 18.1348 46.0859 17.2961 45.2369C16.4573 44.3879 15.7945 43.3818 15.3454 42.2763C14.8963 41.1709 14.6699 39.9877 14.679 38.7946L14.8865 21.2415C14.9061 19.6584 15.3421 18.1082 16.1505 16.7468C16.959 15.3853 18.1118 14.2604 19.4928 13.485C20.8738 12.7096 22.4343 12.3109 24.0182 12.3291C25.6021 12.3473 27.1535 12.7817 28.5164 13.5886L43.6104 22.5438C44.9705 23.3528 46.094 24.505 46.8681 25.8848C47.6423 27.2646 48.0402 28.8235 48.0215 30.4054C48.0028 31.9872 47.5683 33.5365 46.7617 34.8976C45.9552 36.2588 44.8048 37.384 43.426 38.1607L28.1819 46.7126C26.8237 47.5053 25.2808 47.9267 23.708 47.9344ZM23.9155 12.8971C21.6906 12.8999 19.5564 13.7782 17.9745 15.342C16.3927 16.9058 15.4904 19.0294 15.4631 21.253L15.2556 38.7946C15.236 40.2782 15.608 41.7407 16.3344 43.0347C17.0608 44.3286 18.116 45.4082 19.3931 46.1645C20.6702 46.9208 22.1242 47.327 23.6086 47.3423C25.093 47.3576 26.555 46.9814 27.8475 46.2516L43.1493 37.6652C44.4415 36.9385 45.5198 35.8849 46.2758 34.6101C47.0319 33.3352 47.439 31.884 47.4565 30.4021C47.474 28.9203 47.1014 27.4598 46.3758 26.1675C45.6501 24.8751 44.5968 23.7963 43.3221 23.0393L28.2165 14.0841C26.916 13.3083 25.43 12.8983 23.9155 12.8971V12.8971Z"
      fill="white"
    />
    <path
      d="M23.2696 51.1614C21.9196 51.1521 20.589 50.8412 19.3748 50.2514C18.1606 49.6615 17.0937 48.8077 16.2524 47.7524C15.4111 46.6971 14.8165 45.4672 14.5123 44.1525C14.2082 42.8379 14.2019 41.472 14.4943 40.1547L18.3458 23.0393C18.6972 21.4968 19.4486 20.074 20.5245 18.9137C21.6004 17.7534 22.9629 16.8966 24.4751 16.4293C25.9873 15.9621 27.5957 15.9009 29.1391 16.2519C30.6824 16.603 32.106 17.3539 33.267 18.4291L46.1821 30.3234C47.347 31.3979 48.2078 32.7603 48.678 34.2734C49.1482 35.7864 49.2112 37.3964 48.8604 38.9415C48.5097 40.4865 47.7573 41.9119 46.6798 43.0739C45.6022 44.2358 44.2375 45.0934 42.7227 45.56L25.968 50.7926C25.0916 51.0475 24.1823 51.1717 23.2696 51.1614V51.1614ZM27.1901 16.5851C26.3219 16.5855 25.4588 16.7176 24.6302 16.9769C23.2024 17.4046 21.9149 18.2062 20.9012 19.2985C19.8875 20.3907 19.1843 21.7339 18.8647 23.1891L15.0135 40.3044C14.686 41.7508 14.7456 43.2579 15.1866 44.6738C15.6276 46.0897 16.4343 47.3645 17.5252 48.3696C18.6161 49.3747 19.9528 50.0746 21.4006 50.3988C22.8484 50.723 24.3561 50.6601 25.7718 50.2163L42.5265 44.9953C43.9441 44.5546 45.2205 43.7486 46.2274 42.6583C47.2343 41.5679 47.9361 40.2317 48.2622 38.7842C48.5883 37.3366 48.5273 35.8287 48.0851 34.4122C47.643 32.9957 46.8355 31.7207 45.7437 30.7153L32.8289 18.8326C31.3029 17.3925 29.2888 16.5814 27.1901 16.562V16.5851Z"
      fill="white"
    />
    <path
      d="M22.2088 54.2387C20.6988 54.2331 19.2143 53.849 17.8912 53.1216C16.568 52.3943 15.4485 51.3469 14.6351 50.0752C13.8217 48.8036 13.3405 47.3485 13.2354 45.8428C13.1302 44.3372 13.4045 42.8292 14.0332 41.4569L21.3671 25.5173C22.0261 24.0778 23.0525 22.8371 24.3436 21.9201C25.6347 21.0032 27.1447 20.4423 28.7216 20.2939C30.2984 20.1455 31.8868 20.4148 33.3264 21.0748C34.766 21.7349 36.0064 22.7622 36.9225 24.0536L47.0812 38.3682C48.0002 39.6596 48.5625 41.1703 48.711 42.748C48.8594 44.3258 48.5891 45.9147 47.9272 47.3547C47.2653 48.7947 46.2352 50.0349 44.9408 50.9501C43.6465 51.8653 42.1336 52.4231 40.5547 52.5675L23.0737 54.2041L22.2088 54.2387ZM29.5886 20.815C29.3199 20.7978 29.0503 20.7978 28.7815 20.815C27.2985 20.9386 25.8755 21.4556 24.6592 22.3126C23.4429 23.1697 22.4776 24.3357 21.8629 25.6902L14.5405 41.6414C13.9235 42.9884 13.6717 44.4741 13.8105 45.9491C13.9493 47.4241 14.4739 48.8365 15.3313 50.045C16.1887 51.2535 17.349 52.2153 18.6957 52.8341C20.0425 53.4528 21.5285 53.7066 23.0044 53.5702L40.4739 51.9336C41.9516 51.7986 43.3676 51.2765 44.5791 50.4201C45.7906 49.5637 46.7548 48.4033 47.3746 47.0557C47.9943 45.7082 48.2476 44.221 48.1091 42.7444C47.9705 41.2677 47.4451 39.8537 46.5855 38.6448L36.4265 24.3302C35.6501 23.2422 34.624 22.3563 33.4342 21.7466C32.2444 21.137 30.9257 20.8214 29.5886 20.8265V20.815Z"
      fill="white"
    />
    <path
      d="M38.0296 59.0794C37.6672 59.0797 37.3052 59.0567 36.9457 59.0103L19.5106 56.9818C17.9371 56.7973 16.4398 56.2021 15.1692 55.2562C13.8987 54.3104 12.8996 53.0471 12.2721 51.5931C11.6446 50.1391 11.4111 48.5456 11.5948 46.9728C11.7784 45.4 12.3729 43.9031 13.3185 42.6326L23.8001 28.5601C24.7447 27.289 26.0077 26.2891 27.4618 25.661C28.916 25.0329 30.5101 24.7987 32.0836 24.9822C33.6571 25.1656 35.1547 25.7601 36.4252 26.7059C37.6957 27.6517 38.6943 28.9152 39.3209 30.3695L46.2396 46.5051C46.8302 47.8744 47.0722 49.3687 46.9439 50.8543C46.8156 52.3399 46.3212 53.7706 45.5045 55.0185C44.6878 56.2663 43.5744 57.2924 42.2639 58.0051C40.9535 58.7178 39.4868 59.0948 37.995 59.1025L38.0296 59.0794ZM31.007 25.4943C29.6891 25.4958 28.3905 25.8101 27.2178 26.4112C26.045 27.0123 25.0318 27.8831 24.2615 28.9519L13.7796 43.0246C12.8929 44.2108 12.3344 45.6093 12.1598 47.0797C11.9852 48.5501 12.2007 50.0406 12.785 51.4013C13.3693 52.762 14.3016 53.9451 15.4882 54.8316C16.6748 55.7181 18.0741 56.2768 19.5452 56.4516L36.9801 58.4916C38.4531 58.663 39.9455 58.4441 41.307 57.8568C42.6686 57.2695 43.8515 56.3344 44.7371 55.1455C45.6227 53.9567 46.1798 52.5559 46.3525 51.0838C46.5251 49.6116 46.3073 48.1201 45.7207 46.7588L38.8021 30.6232C38.2208 29.2567 37.2866 28.0692 36.0952 27.1822C34.9039 26.2951 33.4982 25.7405 32.0219 25.575C31.6853 25.5305 31.3465 25.5035 31.007 25.4943V25.4943Z"
      fill="white"
    />
  </svg>
);
export default LogoIconRu;
