import React from 'react'
import styled from 'styled-components'

import {DropdownArrow} from 'src/components/Icons'

export const ButtonBack = () => {
  return (
    <IconWrapper onClick={history.back}>
      <DropdownArrow width={16} height={16} />
    </IconWrapper>
  )
}

const IconWrapper = styled.span`
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  svg {
    transform: rotate(90deg);
  }
`
