import gql from 'graphql-tag'

export const GROWING_STAGE_QUERY = gql`
  query($id: ID!) {
    growing_cycle_stage(id: $id) {
      id
      name
      start_gap_in_minutes
      hardware_configs {
        id
        name
        settings {
          id
          duration_in_minutes
          order_number
          data
        }
      }

      growing_cycle {
        id
        name
        growing_space {
          id
          name
          devices {
            id
            name
            configs {
              id
              name
              settings {
                id
                duration_in_minutes
                order_number
                data
              }
            }
          }
        }
      }
    }
  }
`
