import React from 'react'

const CloseIcon = () => (
  <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.108 15.584L10.524 9l6.584-6.584A1.078 1.078 0 0 0 15.584.892L9 7.476 2.416.892A1.078 1.078 0 1 0 .892 2.416L7.476 9 .892 15.584a1.078 1.078 0 1 0 1.524 1.524L9 10.524l6.584 6.584a1.074 1.074 0 0 0 1.524 0c.42-.421.42-1.103 0-1.524z"
      fill="#C2C2C2"
    />
  </svg>
)

export default CloseIcon
