import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ArrowIcon, ClocksIcon } from 'src/components/Icons'

const Input = styled.input`
  display: block;
  outline: none;
  height: 56px;
  width: 100%;
  padding: 18px;
  padding-left: 48px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.textDisable};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  outline: none;
  :focus {
    border: 1px ${(p) => p.theme.orange};
  }
`

const ButtonsWrapper = styled.div`
  position: absolute;
  right: 18px;
  bottom: 0;
  padding: 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ChangeButton = styled.button`
  display: block;
  cursor: pointer;
  width: 25px;
  height: 50%;
  flex-grow: 1;

  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  svg {
    width: 14px;
    height: 10px;
  }

  svg path {
    fill: ${(p) => p.disabled ? p.theme.textDisable : p.theme.fontGray};
    width: 5px;
    ${(p) => {
      switch (p.direction) {
        case 'up':
          return `transform:  rotate(-90deg)`
        default:
          return `transform:  rotate(90deg)`
      }
    }}
  }

  :hover,
  :focus {
    svg path {
      fill: ${(p) => !p.disabled && p.theme.orange};
    }
  }
`

const Root = styled.div`
  display: block;
  position: relative;
  width: 100%;

  :focus-within ${Input} {
    border: 1px solid ${(p) => p.theme.orange};
  }
`

const Appendix = styled.div`
  color: ${(p) => p.theme.textDisable};
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 70px;
  right: 25px;
`

const ClockWrapper = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 18px;
`

const handleChange = (e, onChange, max, min) => {
  const { value } = e.target
  if (value < min) return onChange(min)
  if (value > max) return onChange(max)
  return onChange(value)
}

export const NumberInput = ({
  onChange,
  value,
  className,
  max,
  min,
  appendix,
  ...props
}) => (
  <Root className={className}>
    <Input
      {...props}
      type="number"
      value={value}
      onChange={(e) => handleChange(e, onChange, max, min)}
      autoComplete="off"
    />
    <ClockWrapper>
      <ClocksIcon />
    </ClockWrapper>
    {appendix && (
      <Appendix>{value === 1 ? appendix.singular : appendix.plural}</Appendix>
    )}
    <ButtonsWrapper>
      <ChangeButton
        type="button"
        onClick={() => onChange(+value < max ? +value + 1 : max)}
        direction="up"
        disabled={value === 999}
      >
        <ArrowIcon />
      </ChangeButton>
      <ChangeButton
        type="button"
        onClick={() => onChange(value > min ? +value - 1 : min)}
        direction="down"
        disabled={value === 1}
      >
        <ArrowIcon />
      </ChangeButton>
    </ButtonsWrapper>
  </Root>
)

NumberInput.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  appendix: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired
  })
}

NumberInput.defaultProps = {
  value: 0
}
