import React, { Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { VideoPopup } from "./";

import { H4 } from "src/ui";
import { Toggler } from "src/utils/toggler";
import { Modal } from "src/components";

const Root = styled.section`
  height: 100%;
  width: 100%;
  padding: 24px;
  background-color: ${(p) => p.theme.white};
`;

const Title = styled(H4)`
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  padding: 0;
  margin: 0;
  margin-bottom: 25px;
  font-size: 22px;
`;

const WebcamsList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Card = styled.li`
  width: 148px;
  height: 140px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;
  }

  @media (max-width: 1830px) {
    width: 100%;
    margin: auto auto 16px auto;
  }
`;

const PreviewWrapper = styled.div`
  width: 100%;
  height: 107px;
`;

const Preview = styled.div`
  width: 100%;
  height: 107px;
  border-radius: 4px 4px 0 0;
  background-image: url("${(p) => p.path}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Time = styled.div`
  width: 100%;
  height: 33px;
  padding: 5px 16px;
  ${(p) => p.theme.ProximaNova};
  font-size: 18px;
  line-height: 22px;
  color: ${(p) => p.theme.green};
  background-color: ${(p) => p.theme.grayBg};
  border-radius: 0 0 4px 4px;
`;

const videos = [
  {
    id: 1,
    title: "ID_44783.#3424",
    preview: "/images/01.jpg",
    content: "/videos/01.mp4",
  },
  {
    id: 2,
    title: "ID_43283.#3124",
    preview: "/images/02.jpg",
    content: "/videos/02.mp4",
  },
  // {
  //   id: 3,
  //   preview: "/images/03.jpg",
  //   content: "/videos/03.mp4",
  // },

  // {
  //   id: 4,
  //   preview: "/images/06.jpg",
  //   content: "/videos/06.mp4",
  // },
  {
    id: 5,
    title: "ID_32383.#3244",
    preview: "/images/07.jpg",
    content: "/videos/07.mp4",
  },
  {
    id: 6,
    title: "ID_98683.#4876",
    preview: "/images/08.jpg",
    content: "/videos/08.mp4",
  },
  // {
  //   id: 7,
  //   preview: "/images/09.jpg",
  //   content: "/videos/09.mp4",
  // },
  {
    id: 8,
    title: "ID_43245.#7643",
    preview: "/images/10.jpg",
    content: "/videos/10.mp4",
  },
  // {
  //   id: 9,
  //   preview: "/images/11.jpg",
  //   content: "/videos/11.mp4",
  // },
  {
    id: 10,
    title: "ID_43986.#9634",
    preview: "/images/12.jpg",
    content: "/videos/12.mp4",
  },
  {
    id: 11,
    title: "ID_43281.#4376",
    preview: "/images/04.jpg",
    content: "/videos/04.mp4",
  },
  {
    id: 12,
    title: "ID_27653.#6335",
    preview: "/images/05.jpg",
    content: "/videos/05.mp4",
  },
];

export const Webcam = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Title>{t("webcam")}</Title>
      <WebcamsList>
        {videos.map((video) => (
          <Toggler key={video.id}>
            {({ toggled, onToggle }) => (
              <Fragment>
                <Card key={video.id} onClick={() => onToggle(true)}>
                  <PreviewWrapper>
                    <Preview path={video.preview} />
                  </PreviewWrapper>
                  <Time>{moment().format("LT")}</Time>
                </Card>
                <Modal isOpen={toggled} onClose={() => onToggle(false)}>
                  <VideoPopup video={video} />
                </Modal>
              </Fragment>
            )}
          </Toggler>
        ))}
      </WebcamsList>
    </Root>
  );
};
