import styled from 'styled-components'

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 18px;
`;