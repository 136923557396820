import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Calendar } from 'react-date-range'
import moment from 'moment'

import { CalendarIcon } from 'src/components/Icons'
import { ClickOutside } from 'src/utils/click-outside'

const Root = styled.div`
  position: relative;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const InputRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  height: 56px;
  border-radius: 4px;
  cursor: pointer;
  padding: 17px;
  border: 1px solid ${(p) => p.theme.textDisable};

  :focus-within {
    border: 1px solid ${(p) => p.theme.orange};
  }

  svg path {
    fill: ${(p) => p.theme.green};
  }

  ${(p) =>
    p.focused &&
    `
    border: 1px solid ${p.theme.orange};
  `}
`

const Text = styled.div`
  white-space: nowrap;
  ${(p) => p.theme.RobotoBold};
  width: 100%;

  transition: all 0.5s;
  :focus {
    box-shadow: none;
  }

  ${(p) =>
    p.noValue &&
    `
    color: #ccc;
  `}
`

const PickerRoot = styled(ClickOutside)`
  .rdr-Day.is-selected {
    background-color: ${(p) => p.theme.orange} !important;
  }

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 200;

  ${(p) =>
    p.pickerPosition === 'top' &&
    `
    bottom: 105%;
  `}

  ${(p) =>
    p.pickerPosition === 'bottom' &&
    `
    top:  105%;
  `}
`

export class DatePicker extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    pickerPosition: PropTypes.oneOf(['top', 'bottom']),
    className: PropTypes.string,
    displayFormat: PropTypes.string,
    icon: PropTypes.element,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    pickerPosition: 'top',
    displayFormat: 'YY/MM/DD',
    icon: <CalendarIcon />
  }

  state = {
    pickerIsShown: false
  }

  hidePicker = () => this.setState({ pickerIsShown: false })
  showPicker = () => this.setState({ pickerIsShown: true })

  handleSelect = (value) => {
    if (!moment(value).isSame(this.props.value)) {
      this.props.onChange(value)
    }

    return this.hidePicker()
  }

  render() {
    const {
      value,
      pickerPosition,
      className,
      displayFormat,
      icon,
      placeholder
    } = this.props
    const { pickerIsShown } = this.state

    return (
      <Root>
        <InputRoot
          focused={pickerIsShown}
          tabIndex="0"
          onClick={this.showPicker}
          className={className}
        >
          <IconWrapper>{icon}</IconWrapper>
          <Text noValue={!Boolean(value)}>
            {value ? moment(value).format(displayFormat) : placeholder}
          </Text>
        </InputRoot>

        {pickerIsShown && (
          <PickerRoot
            pickerPosition={pickerPosition}
            onClickOutside={this.hidePicker}
          >
            <Calendar
              date={value}
              onSelect={this.handleSelect}
              onChange={this.handleSelect}
            />
          </PickerRoot>
        )}
      </Root>
    )
  }
}
