import React from "react";
import PropTypes from "prop-types";
import { DateRange } from "react-date-range";
import moment from "moment";
import * as rdrLocales from "react-date-range/dist/locale";
import { useTranslation } from "react-i18next";

export const CustomDateRange = ({ startDate, endDate, onChange }) => {
  const { i18n } = useTranslation();

  return (
    <DateRange
      showDateDisplay={false}
      showPreview={false}
      locale={i18n.language === "en" ? rdrLocales.en : rdrLocales.ru}
      showMonthAndYearPickers={false}
      ranges={[
        {
          key: "selection",
          startDate,
          endDate,
        },
      ]}
      onChange={(newValue) => {
        onChange({
          startDate: newValue.selection.startDate,
          endDate: newValue.selection.endDate,
        });
      }}
    />
  );
};

CustomDateRange.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

CustomDateRange.defaultProps = {
  startDate: moment().toDate(),
  endDate: moment().toDate(),
};
