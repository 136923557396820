import styled from "styled-components";

export const MapContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  //width: calc(100% - 370px);
  //min-width: calc(100% - 370px);
  height: 716px;
  overflow: hidden;
  //padding: 12px 0 12px 12px;
  //margin: 0 12px 16px 0;
  //overflow-x: auto;

  img {
    width: 1150px;
    min-width: 1150px;
    height: 692px;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }
`;