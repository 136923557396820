const errorMap = {
  'Network Error': 'Cannot establish connection to the server'
}

export const mapErrorMessage = (errorMessage) => {
  for (let key in errorMap) {
    if (errorMessage.toLowerCase().includes(key.toLowerCase())) {
      return errorMap[key]
    }
  }

  return 'Something went wrong'
}
