import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Line } from 'rc-progress'
import { transparentize } from 'polished'

import { PhytophySettings } from './PhytophySettings'

import { Modal, BaseTable } from 'src/components'
import { Toggler } from 'src/utils/toggler'
import { ScheduleSettingIcon, ScheduleCloseIcon } from 'src/components/Icons'

// TODO: refactor this table. extract styles to separate styled components.
const StyledTable = styled(BaseTable)`
  &.ReactTable {
    border: none;

    /* removes default message for empry array passed as data to table. Leave it as is.*/
    .rt-noData {
      display: none;
    }

    .rt-thead.-headerGroups {
      background: #fbfbfb;
      padding: 10px 0;
    }

    .rt-th {
      padding: 5px 5px 5px 5px !important;
      text-align: left;
    }
    .rt-tr {
      margin-bottom: 5px;
    }

    .rt-tbody {
      &:hover {
        .rt-td {
          background: #fff;
        }
      }
    }

    .rt-td {
      background: ${(p) => p.theme.white};
    }
  }
`

const ActionsBlock = styled.div`
  padding-left: 20px;
  width: 100%;
  display: flex;
  align-items: center;
`

const Cell = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  padding: 10px 0;
  ${(p) => p.theme.ProximaNova};
  font-size: 14px;
`

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
`

const Header = styled.div`
  padding: 0 20px;
  ${(p) => p.theme.ProximaNovaSemibold};
  outline: none;
  font-size: 16px;
  opacity: 0.43;
`

const CellDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Digital = styled.div`
  align-self: flex-start;
`

const ColorName = styled.div`
  text-transform: uppercase;
  align-self: flex-start;
`

const VerticalLine = styled.div`
  margin: 0 17px;
  background: ${transparentize(0.6, '#d8d8d8')};
  width: 1px;
  height: 36px;
  opacity: 0.4;
`

const LineInCell = styled.div`
  margin: 4px 0;

  width: 100%;
  height: 1px;
  background-color: ${transparentize(0.8, '#979797')};
`

const Degree = styled(Line)`
  transform: rotate(-90deg);
  width: 35px;
  height: 4px;
`

const Data = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const ChannelCell = ({ name, color, value }) => (
  <Cell>
    <Data>
      <Degree
        percent={value % 100}
        strokeWidth="10"
        trailWidth="10"
        strokeColor={color}
      />
      <CellDescription>
        <Digital>{value}</Digital>
        <LineInCell />
        <ColorName>{name}</ColorName>
      </CellDescription>
    </Data>
  </Cell>
)

export const LightingScheduleTable = ({ data, onRemove, onUpdate }) => (
  <StyledTable
    data={data}
    columns={[
      {
        Header: <Header>Duration</Header>,
        columns: [
          {
            accessor: 'duration',
            width: 90,
            Cell: (row) => (
              <Cell>
                <CellDescription>{row.value}h</CellDescription>
              </Cell>
            )
          }
        ]
      },
      {
        Header: <Header>Value (%)</Header>,
        columns: [
          {
            accessor: 'Blue',
            width: 90,
            Cell: (row) => (
              <ChannelCell name="Blue" color="#0D71FC" value={row.value} />
            )
          },
          {
            accessor: 'Green',
            width: 90,
            Cell: (row) => (
              <ChannelCell name="Green" color="#A8DA40" value={row.value} />
            )
          },
          {
            accessor: 'UV',
            width: 90,
            Cell: (row) => (
              <ChannelCell name="UV" color="#461783" value={row.value} />
            )
          },
          {
            accessor: 'HyperRed',
            width: 90,
            Cell: (row) => (
              <ChannelCell name="h.red" color="#FF003C" value={row.value} />
            )
          },
          {
            accessor: 'FarRed',
            width: 90,
            Cell: (row) => (
              <ChannelCell name="Far red" color="#8F0122" value={row.value} />
            )
          },
          {
            accessor: 'White',
            width: 90,
            Cell: (row) => (
              <ChannelCell name="White" color="#ffffff" value={row.value} />
            )
          },
          {
            minWidth: 100,
            accessor: 'Total',
            Cell: (row) => (
              <ChannelCell name="Total" color="#A8DA40" value={row.value} />
            )
          }
        ]
      },

      {
        Header: <Header>Actions</Header>,
        columns: [
          {
            minWidth: 150,
            Cell: ({ original }) => (
              <Cell>
                <ActionsBlock>
                  <Toggler>
                    {({ toggled, onToggle }) => (
                      <Fragment>
                        <Modal isOpen={toggled} onClose={() => onToggle(false)}>
                          <PhytophySettings
                            initialValues={original}
                            onApply={(values) => {
                              onToggle(false)
                              onUpdate(values)
                            }}
                          />
                        </Modal>
                        <IconWrapper onClick={() => onToggle(true)}>
                          <ScheduleSettingIcon />
                        </IconWrapper>
                      </Fragment>
                    )}
                  </Toggler>
                  <VerticalLine />
                  <IconWrapper onClick={() => onRemove(original.id)}>
                    <ScheduleCloseIcon />
                  </IconWrapper>
                </ActionsBlock>
              </Cell>
            )
          }
        ]
      }
    ]}
    pageSize={data.length}
  />
)
