import * as React from "react";

const Yield = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={12}
      cy={12}
      r={11}
      fill="white"
      stroke="#58C08E"
      strokeWidth={2}
    />
    <path
      d="M12 17.6467H4.94116V16.2349H8.99999C8.48469 14.2514 6.92469 12.6984 4.94116 12.1831C5.39293 12.0702 5.86587 11.9996 6.35293 11.9996C9.47293 11.9996 12 14.5267 12 17.6467ZM19.0588 12.1831C18.607 12.0702 18.1341 11.9996 17.647 11.9996C15.5788 11.9996 13.7788 13.1149 12.7906 14.7737C12.9953 15.2396 13.1647 15.7267 13.2635 16.2349C13.3553 16.6937 13.4047 17.1667 13.4047 17.6467H19.0518V16.2349H14.9929C15.5153 14.2514 17.0753 12.6984 19.0588 12.1831ZM14.5694 11.3078C15.12 9.83254 16.1435 8.59724 17.4565 7.77842C14.4282 7.87724 12 10.3549 12 13.4114C12 13.4184 12 13.4255 12 13.4255C12.6706 12.529 13.5529 11.802 14.5694 11.3078ZM11.5906 9.77607C10.9976 8.23019 9.79763 6.98077 8.25881 6.35254C9.27528 7.66548 9.88234 9.30313 9.88234 11.089C9.88234 11.2372 9.86116 11.3784 9.8541 11.5196C10.1576 11.689 10.44 11.8867 10.7153 12.0984C10.8635 11.2655 11.1741 10.482 11.5906 9.77607Z"
      fill="#58C08E"
    />
  </svg>
);

export default Yield;