import React, { Component } from 'react'
import styled from 'styled-components'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import ContainerDimensions from 'react-container-dimensions'
import { toast } from 'react-toastify'

import { GrowingCyclesList } from './GrowingCyclesList'

import { mapErrorMessage } from 'src/utils/mapErrorMessage'
import { extractSensorData } from 'src/utils/helpers'
import { LineChart, Spinner, SensorTypeSelect } from 'src/components/'

const queries = {
  co2: gql`
    query getGrowCycle($id: ID!) {
      growing_cycle(id: $id) {
        id
        name
        days {
          co2_sensor_logs {
            id
            value
            created_at
          }
        }
      }
    }
  `,
  humidity: gql`
    query getGrowCycle($id: ID!) {
      growing_cycle(id: $id) {
        id
        name
        days {
          humidity_sensor_logs {
            id
            value
            created_at
          }
        }
      }
    }
  `,
  temperature: gql`
    query getGrowCycle($id: ID!) {
      growing_cycle(id: $id) {
        id
        name
        days {
          temperature_sensor_logs {
            id
            value
            created_at
          }
        }
      }
    }
  `
}

const Root = styled.div`
  height: 100%;
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: space-between;
`

const ChartWrapper = styled.div`
  width: 100%;
  flex-grow: 5;
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export class ChartController extends Component {
  state = {
    chartType: 'temperature',
    selectedCycleId: ''
  }

  componentDidMount = () => {
    if (this.props.growingCycles[0]) {
      this.setState({ selectedCycleId: this.props.growingCycles[0].id })
    }
  }

  onSelectGrowingCycle = (id) => this.setState({ selectedCycleId: id })
  onSelectType = (type) => this.setState({ chartType: type })

  render() {
    const { growingCycles } = this.props
    const { selectedCycleId, chartType } = this.state

    if (growingCycles.length === 0) {
      return null
    }

    return (
      <Root>
        <ChartWrapper>
          <SensorTypeSelect
            currentType={chartType}
            onSelect={this.onSelectType}
          />
          <Query query={queries[chartType]} variables={{ id: selectedCycleId }}>
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                )

              if (error) {
                toast.error(mapErrorMessage(error.message))
                return null
              }

              const chartData = extractSensorData(
                chartType,
                data.growing_cycle.days
              )

              return (
                <ContainerDimensions>
                  {({ height, width }) => (
                    <LineChart
                      containerWidth={width}
                      containerHeight={height}
                      chartType={chartType}
                      data={chartData}
                    />
                  )}
                </ContainerDimensions>
              )
            }}
          </Query>
        </ChartWrapper>

        <GrowingCyclesList
          growingCycles={growingCycles}
          selectedCycleId={selectedCycleId}
          onSelectGrowingCycle={this.onSelectGrowingCycle}
        />
      </Root>
    )
  }
}
