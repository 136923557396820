import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Query } from 'react-apollo'

import { ALL_CROPS_QUERY } from 'src/graphQL'
import { FillLeafIcon } from 'src/components/Icons'
import { CustomSelect, FilterDateRangeSelect } from 'src/components/index'
import {FiltersWrapper} from "src/ui";

const CropsSelect = styled(CustomSelect)`
  min-width: 240px;
  height: 50px;
`

const FieldWrapper = styled.label`
  :first-child {
    margin-right: 16px;
`

const defaultStyles = {
  option: provided => ({
    ...provided,
    padding: 20,
  }),
}

export const FiltersForm = ({customStyles = defaultStyles, stubData, setStubData }) => {
  const [filterCropsValue, setFilterCropsValue] = useState(null)
  const [filterPeriodValue, setFilterPeriodValue] = useState('')

  const onChangeCropsSelect = (value => {
    setFilterCropsValue(value)
  })

  const onChangePeriodSelect = (value => {
    const endOfDay = new Date(value.endDate)
    endOfDay.setHours(23,59,59)
  
    setFilterPeriodValue({startDate: value.startDate, endDate: endOfDay})
  })

  useEffect(() => {
    const {startDate, endDate} = filterPeriodValue

    if (!filterCropsValue && !startDate) {
      setStubData(stubData)
    } else if (filterCropsValue && !startDate) {
      setStubData(stubData.filter(row => 
        row.crop.id === filterCropsValue
      ))
    } else if (!filterCropsValue && startDate && endDate) {
      setStubData(stubData.filter(row => 
        row.startAt >= moment(startDate).unix() &&
        row.startAt <= moment(endDate).unix()
      ))
    } else if (filterCropsValue && startDate && endDate) {
      setStubData(stubData.filter(row => 
        row.crop.id === filterCropsValue &&
        row.startAt >= moment(startDate).unix() &&
        row.startAt <= moment(endDate).unix()
      ))}



  }, [filterCropsValue, filterPeriodValue])

  return (
    <FiltersWrapper>
      <Query query={ALL_CROPS_QUERY}>
        {({ data, loading, error }) => {
          let options = []
          if (!loading && !error) {
            options = data.crops.map((crop) => ({
              label: crop.name,
              value: crop.id
            }))
            options.unshift({label: 'All', value: null})
          }

          return (
            <FieldWrapper>
              <CropsSelect
                icon = {<FillLeafIcon />}
                placeholder='Crop'
                options={options}
                styles={{...defaultStyles, ...customStyles}}
                value={filterCropsValue}
                onChange={onChangeCropsSelect}
              />
            </FieldWrapper>
          )
        }}
      </Query>
      <FilterDateRangeSelect
        value={filterPeriodValue}
        onChange={onChangePeriodSelect}
      />
    </FiltersWrapper>
  )
}
