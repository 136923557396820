import React from "react";
import styled from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";
import Dropdown from "rc-dropdown";
import Menu from "rc-menu";
import "rc-dropdown/assets/index.css";
import { useTranslation } from "react-i18next";

import { defaultFields } from "src/stubs/AiWaterManagement";
import { SatisticBox } from "src/components/aiWaterManagement/SatisticBox";
import { Map } from "src/components";
import { H3, ThreeFour, OneFour, MapContainer, PageHeader } from "src/ui";
import {
  AlarmActiveIcon,
  ChartIcon,
  WaterDrop,
  SensorIcon,
} from "src/components/Icons";
import { DataName, DataValue } from "src/ui/Analytics";

const Root = styled.div`
  height: 100%;
`;

const Content = styled.div`
  display: flex;
`;

const List = styled.ul`
  list-style-type: none;
`;

const UpperAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;

const UpperArea = styled(List)`
  display: flex;
  width: calc(100% - 262px);
  padding: 24px 0;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-right: 24px;
  background-color: ${(p) => p.theme.white};
`;

const SensorsButton = styled.span`
  display: flex;
  width: 238px;
  background-color: ${(p) => p.theme.white};
  border-radius: 4px;
  overflow: hidden;
`;

const DataItem = styled.li`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  ${(p) =>
    !p.isParams &&
    `
    width: 33.3%;
    padding-left: 24px;
    margin: 0;
  `}

  @media (max-width: 1650px) {
    flex-wrap: wrap;
  }
`;

const Name = styled(DataName)`
  ${(p) => p.theme.ProximaNovaBold};

  ${(p) =>
    p.isParams &&
    `
    ${p.theme.ProximaNova};
    width: 165px;
    margin-right: 28px;
  `}

  @media (max-width: 1650px) {
    width: 100%;
  }
`;

const Recommendation = styled.div`
  ${(p) => p.theme.ProximaNova};
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.lightBlue};
`;

const RecommendationTitle = styled(H3)`
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin-bottom: 16px;
  color: ${(p) => p.theme.black};
`;

const Params = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};
  margin-bottom: 24px;
`;

const ParamsTitle = styled(H3)`
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin-bottom: 24px;
`;

const AlarmBlock = styled.div`
  width: 100%;
  padding: 32px 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};

  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const AlarmTitle = styled.p`
  ${(p) => p.theme.ProximaNovaBold};
  display: flex;
  align-content: center;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 8px;

  svg {
    margin-right: 18px;
  }
`;

const AlarmText = styled.p`
  margin: 0;
  padding-left: 40px;
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.green};
  font-size: 14px;
  line-height: 18px;
`;

const ListItem = ({ name, value, isParams }) => (
  <DataItem isParams={isParams}>
    <Name isParams={isParams}>{name}:</Name>
    <DataValue>{value}</DataValue>
  </DataItem>
);

const SensorItem = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  flex-direction: column;
  position: absolute;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
`;

const DropdownMenu = ({ id, condition, moisture, temperature, level }) => {
  const DropdownMenuWrapper = styled(Menu)`
    &.rc-dropdown-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      min-width: 280px;
      background-color: ${(p) => p.theme.white};
      border: 1px solid ${(p) => p.theme.orange};
      border-radius: 8px;
      box-shadow: none;
      padding: 20px 24px 16px;
    }
  `;

  const DropdownMenuTitle = styled.div`
    display: inline-flex;
    align-items: flex-end;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    ${(p) => p.theme.ProximaNovaBold};
    color: ${(p) => p.theme.darkGray};
    span {
      font-size: 18px;
      line-height: 30px;
      text-transform: none;
      ${(p) => p.theme.ProximaNova};
    }
  `;

  const DropdownMenuItem = styled.div`
    display: inline-flex;
    font-size: 18px;
    line-height: 24px;
    ${(p) => p.theme.ProximaNovaBold};
    color: ${(p) => p.theme.darkGray};
    margin: 4px 0;
  `;

  const DropdownMenuDescription = styled.div`
    display: inline-flex;
    width: 180px;
    font-size: 18px;
    line-height: 24px;
    color: ${(p) => p.theme.green};
    ${(p) => p.theme.ProximaNova};
  `;

  const DropdownMenuCondition = styled.div`
    display: inline-flex;
    font-size: 18px;
    line-height: 24px;
    ${(p) => p.theme.ProximaNovaBold};
  `;

  const DropdownMenuConditionOk = styled(DropdownMenuCondition)`
    color: ${(p) => p.theme.yieldGreen};
  `;

  const DropdownMenuConditionNo = styled(DropdownMenuCondition)`
    color: ${(p) => p.theme.red};
  `;

  const { t } = useTranslation();

  return (
    <DropdownMenuWrapper onSelect="">
      <DropdownMenuTitle>
        ID {id}&nbsp;<span>{t("sensor").toLowerCase()}</span>
      </DropdownMenuTitle>
      <DropdownMenuItem>
        <DropdownMenuDescription>{t("condition")}:</DropdownMenuDescription>
        {condition ? (
          <DropdownMenuConditionOk>OK</DropdownMenuConditionOk>
        ) : (
          <DropdownMenuConditionNo>NO</DropdownMenuConditionNo>
        )}
      </DropdownMenuItem>
      <DropdownMenuItem>
        <DropdownMenuDescription>{t("soil_moisture")}:</DropdownMenuDescription>
        {moisture}%
      </DropdownMenuItem>
      <DropdownMenuItem>
        <DropdownMenuDescription>
          {t("soil_temperature")}:
        </DropdownMenuDescription>
        {temperature} °C
      </DropdownMenuItem>
      <DropdownMenuItem>
        <DropdownMenuDescription>{t("charge_level")}:</DropdownMenuDescription>
        {level}%
      </DropdownMenuItem>
    </DropdownMenuWrapper>
  );
};

const DropdownButton = styled.button`
  display: flex;
  flex-direction: column;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
`;

export const AIWaterManagementSensors = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { fieldId } = useParams();

  const currentField = defaultFields.find(
    (field) => field.id === Number(fieldId)
  );
  const {
    id,
    area,
    crop,
    phase,
    warnings,
    temperature,
    humidity,
    windSpeed,
    recommendation,
    atmospherePressure,
    water,
    avrHumidity,
    avrSoilTemperature,
    sensors,
  } = currentField;

  return (
    <Root>
      <PageHeader
        title={t("ai_water_management_title")}
        subTitle={`${t("id_field")}: <strong>${id}</strong>`}
        isBackButton
      />
      <Content>
        <ThreeFour>
          <UpperAreaWrapper>
            <UpperArea>
              <ListItem
                name={t("field_area")}
                value={`${area} ${t("hectares")}`}
              />
              <ListItem name={t("crop")} value={crop} />
              <ListItem name={t("growth_phase")} value={phase} />
            </UpperArea>

            <Link
              to={`${location.pathname.substring(
                0,
                location.pathname.lastIndexOf("/")
              )}`}
            >
              <SensorsButton>
                <ChartIcon />
              </SensorsButton>
            </Link>
          </UpperAreaWrapper>
          <MapContainer>
            <Map />
            {sensors.map((sensor) => (
              <SensorItem key={sensor.id} left={sensor.left} top={sensor.top}>
                <Dropdown trigger={["click"]} overlay={DropdownMenu(sensor)}>
                  <DropdownButton>
                    <SensorIcon />
                  </DropdownButton>
                </Dropdown>
              </SensorItem>
            ))}
          </MapContainer>
          <Recommendation>
            <RecommendationTitle>{recommendation.title}</RecommendationTitle>
            {recommendation.description}
          </Recommendation>
        </ThreeFour>
        <OneFour>
          <Params>
            <ParamsTitle>{t("parameters")}</ParamsTitle>
            <List>
              <ListItem isParams name={t("air_temp")} value={temperature} />
              <ListItem isParams name={t("humidity")} value={humidity} />
              <ListItem isParams name={t("wind_speed")} value={windSpeed} />
              <ListItem
                isParams
                name={t("atmosphere_pressure")}
                value={atmospherePressure}
              />
              <ListItem isParams name={t("water_consuption")} value={water} />
              <ListItem isParams name={t("avg_humidity")} value={avrHumidity} />
              <ListItem
                isParams
                name={t("avg_soil_temperature")}
                value={avrSoilTemperature}
              />
            </List>
          </Params>
          <SatisticBox
            width={100}
            padding={0}
            title={t("water_consuption")}
            description={t("reserves_gallons")}
            amount={1700}
            amountName={t("today")}
            totalAmount={2900}
            totalName={t("net_two_days")}
            icon={<WaterDrop />}
          />
          {warnings.map((warning) => (
            <AlarmBlock key={warning.id}>
              <AlarmTitle>
                <AlarmActiveIcon />
                {warning.title}
              </AlarmTitle>
              <AlarmText>{warning.description}</AlarmText>
            </AlarmBlock>
          ))}
        </OneFour>
      </Content>
    </Root>
  );
};
