import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ComparisonChartItem } from './ComparisonChartItem'

const Root = styled.div`
  padding-left: 12px;
  display: flex;
  flex-direction: column;
`

const ItemWrapper = styled.div`
  padding-right: 14px;
  padding-bottom: 14px;
  height: 537px;
  min-width: 300px;
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.white};
`

export const ComparisonChartList = ({ dataSets, activeParameters, colors, idsOfCyclesToShow, idsFromQuery, onUpdateIds }) => {
  return (
    <Root>
      {activeParameters.map((parameter) => (
        <ItemWrapper key={parameter}>
          <Inner>
            <ComparisonChartItem
              colors={colors}
              key={parameter}
              dataSets={dataSets[parameter]}
              chartType={parameter}
              idsFromQuery={idsFromQuery}
              onUpdateIds={onUpdateIds}
              idsOfCyclesToShow={idsOfCyclesToShow}
            />
          </Inner>
        </ItemWrapper>
      ))}
    </Root>
  )
}

ComparisonChartList.propTypes = {
  dataSets: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        cycleId: PropTypes.string.isRequired,
        cycleName: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired
          }).isRequired
        ).isRequired
      }).isRequired
    ).isRequired
  ).isRequired,
  activeParameters: PropTypes.arrayOf(PropTypes.string).isRequired
}
