import React from 'react'

const WarningIcon = () => {
    return (
        <svg width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M36.0842 32.5548L20 4.44521L3.91581 32.5548H36.0842ZM23.3861 2.4726C21.8812 -0.157534 18.1188 -0.157535 16.6139 2.4726L0.529672 30.5822C-0.975281 33.2123 0.905909 36.5 3.91581 36.5H36.0842C39.0941 36.5 40.9753 33.2123 39.4703 30.5822L23.3861 2.4726ZM18 12.5H22V22.5H18V12.5ZM22 24.5H18V28.5H22V24.5Z"/>
        </svg>

    )
}

export default WarningIcon
