import React from "react";
import { useTranslation } from "react-i18next";

import { Statistic, Webcam } from "src/components/dashboard";
import { PageHeader, OneFour, Root, ThreeFour, RowWrapper } from "src/ui";
import { AlertsTable } from "src/components";

export const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <PageHeader
        title={t("dashboard")}
        subTitle={`${t("id_field")}: <strong>257879</strong>`}
      />
      <Statistic />
      <RowWrapper>
        <ThreeFour>
          <AlertsTable />
        </ThreeFour>
        <OneFour>
          <Webcam />
        </OneFour>
      </RowWrapper>
    </Root>
  );
};
