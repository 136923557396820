import gql from 'graphql-tag'

export const setPhotofyrlMutation = gql`
  mutation(
    $id: ID!
    $UV: Int!
    $Blue: Int!
    $Green: Int!
    $HyperRed: Int!
    $FarRed: Int!
    $White: Int!
  ) {
    setPhytofyrl(
      id: $id
      UV: $UV
      Blue: $Blue
      Green: $Green
      HyperRed: $HyperRed
      FarRed: $FarRed
      White: $White
    ) {
      success
      errors
    }
  }
`
