import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { transparentize } from "polished";

import { ContextMenu } from "./ContextMenu";

import { SettingsIcon } from "src/components/Icons";
import { Toggler } from "src/utils/toggler";
import { ClickOutside } from "src/utils/click-outside";

const Root = styled.div`
  max-width: 369px;
  width: 100%;
  border-radius: 2px;
  background-color: ${(p) => p.theme.white};
  padding: 24px;
  margin-bottom: 24px;
  margin-right: 24px;

  @media (max-width: 1400px) {
    max-width: 700px;
    margin: 20px auto;
  }
`;

const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 184px;
  background-color: ${transparentize(0.9, "#d8d8d8")};
  margin-bottom: 16px;

  @media (max-width: 1400px) {
    height: 250px;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Name = styled.div`
  color: ${(p) => p.theme.darkGray};
  ${(p) => p.theme.ProximaNovaBold}
  font-size: 24px;
  line-height: 30px;
`;

const Position = styled.div`
  color: ${(p) => p.theme.green};
  ${(p) => p.theme.ProximaNova}
  font-size: 18px;
  line-height: 22px;
`;

const PersonalInfo = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const MenuButton = styled.button`
  border: none;
  background: none;
  height: 20px;
  width: 20px;

  cursor: pointer;

  svg {
    fill: ${(p) => p.theme.textGrayAux};
    transition: 0.2s;
  }

  :hover svg {
    fill: ${(p) => p.theme.green};
  }
`;

const contextMenuPosition = css`
  position: absolute;
  top: 100%;
  right: 0;
`;

const ImagePlaceholder = styled.img.attrs({
  src: "/icons/profile-pic.svg",
  alt: "Profile picture placeholder",
})`
  width: 42px;
  height: 42px;
`;

export const EmployeesListItem = ({ employee }) => {
  const { name, src, position } = employee;

  return (
    <Root>
      <PhotoWrapper>
        {src ? <Photo src={src} alt="Profile picture" /> : <ImagePlaceholder />}
      </PhotoWrapper>
      <PersonalInfo>
        <Name>{name}</Name>
        <Toggler>
          {({ onToggle, toggled }) => (
            <div>
              <MenuButton onClick={() => onToggle(true)}>
                <SettingsIcon />
              </MenuButton>
              {toggled && (
                <ClickOutside onClickOutside={() => onToggle(false)}>
                  <ContextMenu css={contextMenuPosition} />
                </ClickOutside>
              )}
            </div>
          )}
        </Toggler>
      </PersonalInfo>
      <Position>{position}</Position>
    </Root>
  );
};

EmployeesListItem.propTypes = {
  employee: PropTypes.shape({
    src: PropTypes.string,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
  }),
};
