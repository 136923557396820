import React, { Fragment, useState } from "react";
import { Link, createSearchParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { Query } from "react-apollo";
import { toast } from "react-toastify";
import { camelizeKeys } from "humps";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";

import { CYCLE_COMPARE_PAGE_QUERY, ALL_CROPS_QUERY } from "src/graphQL";
import { cropsStab, growingCyclesStab } from "src/stubs/GrowingCycles";
import { CloseIconAux } from "src/components/Icons";
import { Spinner, CustomTable, CustomSelect } from "src/components/";
import {
  CheckBox,
  Root,
  RowWrapper,
  ThreeFour,
  OneFour,
  PageHeader,
} from "src/ui";

const SelectWrapper = styled.div`
  width: 200px;
`;

const StyledTable = styled(CustomTable)`
  width: 100%;

  &.ReactTable {
    .-cursor-pointer:first-child {
      pointer-events: none;
    }

    .rt-th {
      font-size: 18px;
      line-height: 24px;
      ${(p) => p.theme.ProximaNovaBold};
      color: ${(p) => p.theme.darkGray};
    }

    .rt-th {
      padding: 12px 16px 3px;
      text-align: left;
      outline: none;
      ${(p) => p.theme.ProximaNovaSemibold};
      font-size: 18px;
      line-height: 24px;

      &:first-child {
        padding-left: 0;
      }
    }

    .rt-tr-group {
      width: calc(100% - 8px);
    }

    .rt-thead {
      font-size: 18px;
      line-height: 24px;
      ${(p) => p.theme.ProximaNovaBold};
      color: ${(p) => p.theme.darkGray};
      padding: 8px 0 24px;

      .rt-tr {
        > div:first-of-type {
          flex: 0 !important;
          width: 0 !important;
        }
      }
    }

    .rt-tbody .rt-tr {
      font-size: 18px;
      line-height: 24px;
      ${(p) => p.theme.ProximaNova};
      color: ${(p) => p.theme.darkGray};
      background: #fff;
      box-shadow: inset 4px 0 0 #85b5ba;
      /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1), inset 4px 0px 0px #85B5BA; */
      border-radius: 4px;
      cursor: pointer;
      margin: 0 0 8px;
      padding: 31px 19px;

      :hover {
        background-color: ${(p) => p.theme.white};
      }

      .checked {
        ${(p) => p.theme.ProximaNovaBold};
      }
    }
  }
`;

const STATUSES = ["Planned", "In progress", "Completed"];

export const adjustCropsData = (growingCycles) => {
  return growingCycles.map((cycle) => {
    let { currentDayNumber } = cycle;

    let completion = Math.floor(
      (currentDayNumber / cycle.durationInDays) * 100
    );

    if (cycle.status === "Completed") {
      completion = 100;
    }

    const daysLeft = cycle.durationInDays - currentDayNumber;

    return {
      ...cycle,
      currentDayNumber,
      daysLeft,
      completion,
    };
  });
};

const NotFound = styled.div`
  text-align: center;
`;

// CompareWrapper
const CompareWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${(p) => p.theme.ProximaNova};
  background-color: ${(p) => p.theme.white};
  color: ${(p) => p.theme.textDisable};
  font-size: 18px;
  line-height: 24px;
  border-radius: 4px;
  padding: 24px;
`;

const CompareTitle = styled.h3`
  display: block;
  width: 100%;
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 22px;
`;

const SelectedList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 24px;
`;

const SelectedItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: inset 0px -1px 0px #d8d8d8;
  padding: 16px 24px 16px 0;
  position: relative;
`;

const SelectedItemTitle = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 100%;
  font-size: 18px;
  line-height: 24px;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.darkGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedItemIcon = styled.div`
  display: inline-flex;
  position: absolute;
  cursor: pointer;
  right: 0;

  svg {
    fill: ${(p) => p.theme.textSecond};
    transition: fill 0.2s ease-in-out 0s;
  }

  &:hover {
    svg {
      fill: ${(p) => p.theme.darkGray};
    }
  }
`;

const BtnLink = styled(Link)`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 54px;
  ${(p) => p.theme.ProximaNovaBold};
  border-radius: 4px;
  border: 2px solid ${(p) => p.theme.orange};
  background-color: transparent;
  color: ${(p) => p.theme.orange};
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
  text-decoration: none;

  &:hover {
    background-color: ${(p) => p.theme.orange};
    color: ${(p) => p.theme.white};
  }
`;

const usedCrops = (crops = [], isStabUsing = true) =>
  crops && crops.length && !isStabUsing ? crops : cropsStab;

const usedGrowingCycles = (growingCycles = [], isStabUsing = true) =>
  adjustCropsData(
    growingCycles && growingCycles.length && !isStabUsing
      ? growingCycles
      : growingCyclesStab
  );

export const GrowingCycles = () => {
  const { t } = useTranslation();
  const [selectedCycles, setSelectedCycles] = useState([]);

  const getCellCheckedClassName = (row) =>
    selectedCycles.some(
      (selectedCycle) => Number(selectedCycle.id) === Number(row.original.id)
    )
      ? "checked"
      : undefined;

  const selectCycle = (id, name) => {
    const isSelected = selectedCycles.some((x) => +x.id === +id);
    const cycle = {
      id,
      name,
    };

    if (isSelected) {
      setSelectedCycles((prevSelectedStyles) =>
        prevSelectedStyles.filter((cycle) => +cycle.id !== +id)
      );
    } else if (selectedCycles.length <= 4) {
      setSelectedCycles([...selectedCycles, cycle]);
    } else {
      toast.info("Cannot select more than 5 cycles");
    }
  };

  const generateSearchString = () => {
    return selectedCycles.map((cycle) => cycle.id);
  };

  return (
    <Root>
      <RowWrapper>
        <ThreeFour>
          <Query query={ALL_CROPS_QUERY}>
            {({ loading: cropsLoading, error, data }) => {
              const crops = data && data.crops ? usedCrops(data.crops) : [];

              if (error) {
                toast.error(t("loading_crops_error"));
                return null;
              }

              if (cropsLoading) {
                return <Spinner fullwindow />;
              }

              const options = crops.map((crop) => ({
                label: crop.name,
                value: crop.id,
              }));

              return (
                <Form
                  onSubmit={() => {}}
                  subscriptions={{ values: true }}
                  initialValues={{
                    cropId: options.length ? options[0].value : "",
                  }}
                >
                  {({ handleSubmit, values }) => (
                    <>
                      <PageHeader title="Growing Cycles">
                        <ActionsWrapper>
                          <form onSubmit={handleSubmit}>
                            <Field name="cropId">
                              {({ input }) => (
                                <SelectWrapper>
                                  <CustomSelect
                                    placeholder="Select crop"
                                    options={options}
                                    value={input.value}
                                    onChange={input.onChange}
                                  />
                                </SelectWrapper>
                              )}
                            </Field>
                          </form>
                        </ActionsWrapper>
                      </PageHeader>

                      <Query
                        query={CYCLE_COMPARE_PAGE_QUERY}
                        variables={{ cropId: values.cropId }}
                      >
                        {({ loading, data }) => {
                          if (error) {
                            toast.error(t("loading_cycles_error"));
                            return null;
                          }

                          const { growingCycles } = camelizeKeys(data);

                          const tableData = loading
                            ? []
                            : usedGrowingCycles(
                                growingCycles.filter(
                                  (cycle) =>
                                    cycle.status.toLowerCase() !== "planned"
                                )
                              );

                          return (
                            <Fragment>
                              <StyledTable
                                getTrGroupProps={(_, { original }) => ({
                                  onClick: () =>
                                    selectCycle(original.id, original.name),
                                })}
                                data={tableData}
                                columns={[
                                  {
                                    Header: "",
                                    accessor: "id",
                                    width: 36,
                                    Cell: (row) => (
                                      <CheckBox
                                        checked={selectedCycles.some(
                                          (x) => +x.id === +row.original.id
                                        )}
                                        onClick={() =>
                                          selectCycle(
                                            row.value,
                                            row.original.name
                                          )
                                        }
                                      />
                                    ),
                                  },
                                  {
                                    Header: "Name",
                                    accessor: "name",
                                    sortMethod: (a, b) => {
                                      return +a > +b ? 1 : -1;
                                    },
                                    minWidth: 168,
                                    Cell: (row) => (
                                      <span
                                        className={getCellCheckedClassName(row)}
                                      >
                                        {row.original.name}
                                      </span>
                                    ),
                                  },
                                  {
                                    Header: "Days left",
                                    accessor: "daysLeft",
                                    minWidth: 80,
                                    sortMethod: (a, b) => {
                                      return +a - +b;
                                    },
                                    Cell: (row) => (
                                      <span
                                        className={getCellCheckedClassName(row)}
                                      >
                                        {row.value}
                                      </span>
                                    ),
                                  },
                                  {
                                    Header: "Start date",
                                    accessor: "startAt",
                                    minWidth: 80,
                                    Cell: (row) => (
                                      <span
                                        className={getCellCheckedClassName(row)}
                                      >
                                        {moment
                                          .unix(row.value)
                                          .format("YY/MM/DD")}
                                      </span>
                                    ),
                                  },
                                  {
                                    Header: "End date",
                                    accessor: "endAt",
                                    minWidth: 80,
                                    Cell: (row) => (
                                      <span
                                        className={getCellCheckedClassName(row)}
                                      >
                                        {moment
                                          .unix(row.value)
                                          .format("YY/MM/DD")}
                                      </span>
                                    ),
                                  },
                                  {
                                    Header: "Status",
                                    accessor: "status",
                                    minWidth: 80,
                                    sortMethod: (a, b) => {
                                      return (
                                        STATUSES.indexOf(a) -
                                        STATUSES.indexOf(b)
                                      );
                                    },
                                    Cell: (row) => {
                                      return (
                                        <span
                                          className={getCellCheckedClassName(
                                            row
                                          )}
                                        >
                                          {row.value}
                                        </span>
                                      );
                                    },
                                  },
                                  {
                                    Header: "Completion",
                                    accessor: "completion",
                                    minWidth: 72,
                                    sortMethod: (a, b) => {
                                      return +a - +b;
                                    },
                                    Cell: (row) => (
                                      <span
                                        className={getCellCheckedClassName(row)}
                                      >
                                        {`${row.original.completion}%`}
                                      </span>
                                    ),
                                  },
                                ]}
                                pageSize={tableData.length}
                              />
                              {loading && <Spinner fullwindow />}
                              {tableData.length === 0 && (
                                <NotFound>No cycles found</NotFound>
                              )}
                            </Fragment>
                          );
                        }}
                      </Query>
                    </>
                  )}
                </Form>
              );
            }}
          </Query>
        </ThreeFour>
        <OneFour>
          <CompareWrapper>
            <CompareTitle>Growing Cycles to Compare</CompareTitle>
            {!!selectedCycles.length ? (
              <>
                <SelectedList>
                  {selectedCycles.map((cycle) => {
                    const { id, name } = cycle;

                    return (
                      <SelectedItem key={id}>
                        <SelectedItemTitle>{name}</SelectedItemTitle>
                        <SelectedItemIcon>
                          <CloseIconAux onClick={() => selectCycle(id, name)} />
                        </SelectedItemIcon>
                      </SelectedItem>
                    );
                  })}
                </SelectedList>

                <BtnLink
                  to={{
                    pathname: "/analytics/growing-cycles/compare",
                    search: `?${createSearchParams({
                      cyclesIds: generateSearchString(),
                    })}`,
                  }}
                >
                  Compare cycles
                </BtnLink>
              </>
            ) : (
              <span>You have no items to compare</span>
            )}
          </CompareWrapper>
        </OneFour>
      </RowWrapper>
    </Root>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;
