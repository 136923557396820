import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translationEN.json";
import translationRU from "./locales/ru/translationRU.json";

i18n.use(initReactI18next).init({
  lng: process.env.REACT_APP_LOCALE,
  resources: {
    en: {
      translation: translationEN,
    },
    ru: {
      translation: translationRU,
    },
  },
  fallbackLng: "en",
  supportedLngs: ["en", "ru"],
  react: { useSuspense: false },

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
