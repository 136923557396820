import React, { Fragment } from 'react';
import { compose, graphql } from 'react-apollo';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { camelizeKeys } from 'humps';

import { newAlarmSettings } from 'src/utils/schema';
import { validateForm } from 'src/utils/form';
import { NumericInput, CustomAutocomplete } from 'src/components';
import { Button, H3 } from 'src/ui';
import { CREATE_ALARM, UPDATE_ALARM } from 'src/graphQL';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ApplyButton = styled(Button)`
  ${(p) => p.theme.ProximaNovaBold};
  transition: background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
  border: 2px solid ${(p) => p.theme.orange};
  border-radious: 4px;
  width: 100%;
  margin-bottom: 24px;

  &:hover {
    background-color: ${(p) => p.theme.orange};
    color: ${(p) => p.theme.white};
  }
`;

const Root = styled.div`
  background: ${(p) => p.theme.white};
  padding: 24px;
  width: 368px;
  border-radius: 4px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
`;

const Header = styled.header`
  width: 100%;
  margin-top: 30px;
`;

const Title = styled(H3)`
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 24px;
  line-height: 29px;
  padding: 0;
`;

const Text = styled.div`
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.green};
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 24px;
`;

const Label = styled(Text)`
  margin-bottom: 8px;
`;

const Description = styled.div`
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.green};
  font-size: 18px;
  line-height: 22px;
  padding: 16px;
  border: 2px solid ${(p) => p.theme.green};
  border-radius: 4px;
  width: 100%;
`;

const InputPeriod = styled(NumericInput)`
  width: 100%;
  margin-bottom: 24px;
`;

const InputTemperature = styled(NumericInput)`
  width: 46.5%;
`;

const Input = styled.input.attrs({ autoComplete: 'off' })`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid ${(p) => p.theme.textDisable};

  :focus-within {
    border: 1px solid ${(p) => p.theme.orange};
  }

  ::placeholder {
    color: ${(p) => p.theme.textDisable};
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const stageNamesForSelect = (stages) => {
  let result = stages.map((i) => i.name);
  result.unshift('');

  return result;
};

const onSubmit = async (values, props) => {
  const {
    id,
    sensorType,
    createAlarm,
    updateAlarm,
    close,
    onSuccess,
    growingCycle: { id: growingCycleId, stages },
  } = props;

  let variables = values;

  const selectedStageIndex = stages.findIndex(
    (stage) => stage.name === values.stage,
  );

  if (selectedStageIndex > -1) {
    variables.growingCycleStageId = +stages[selectedStageIndex].id;
  }

  if (id) {
    variables.id = id;
  } else {
    variables.sensorType = sensorType;
    variables.growingCycleId = growingCycleId;
  }

  const response = await (id ? updateAlarm : createAlarm)({ variables });
  const result = response.data.createAlarm || response.data.updateAlarm;

  if (result.alarm) {
    onSuccess(camelizeKeys(result.alarm));
    close();
  }
};

const AlarmSettingsForm = (props) => (
  <Root>
    <Header>
      <Title>{props.formTitle}</Title>
      <Text>Set alert if:</Text>
    </Header>
    <FormWrapper>
      <Form
        onSubmit={(values) => onSubmit(values, props)}
        validate={validateForm({ schema: newAlarmSettings })}
        initialValues={{
          minValue: props.initialValues.minValue,
          maxValue: props.initialValues.maxValue,
          durationInMinutes: props.initialValues.durationInMinutes || 15,
          precisionInPercents: props.initialValues.precisionInPercents || 75,
        }}
        render={({ handleSubmit, form, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Field name="stage">
              {({ input }) => (
                <Fragment>
                  <CustomAutocomplete
                    onChange={input.onChange}
                    items={stageNamesForSelect(props.growingCycle.stages)}
                    onSelect={input.onChange}
                  >
                    {(props) => (
                      <Input
                        type="text"
                        {...props}
                        value={input.value}
                        placeholder="Select stage"
                      />
                    )}
                  </CustomAutocomplete>
                </Fragment>
              )}
            </Field>
            <Label>
              If {props.label} is ({props.units})
            </Label>
            <Row>
              <Field name="minValue">
                {({ input }) => (
                  <InputTemperature
                    {...input}
                    placeholder="More then"
                    direction="up"
                  />
                )}
              </Field>
              <Field name="maxValue">
                {({ input }) => (
                  <InputTemperature
                    {...input}
                    placeholder="Less then"
                    direction="down"
                  />
                )}
              </Field>
            </Row>
            <Label>Period of time (min)</Label>
            <Field name="durationInMinutes">
              {({ input }) => <InputPeriod {...input} />}
            </Field>
            <Label>Precision (%)</Label>
            <Field name="precisionInPercents">
              {({ input }) => <InputPeriod {...input} />}
            </Field>
            <ApplyButton inset size="18" type="submit" disabled={submitting}>
              Create
            </ApplyButton>
            <Description>
              Alarm if {values.precisionInPercents}% of incoming logs per last
              &nbsp;{values.durationInMinutes} minutes {props.label} is more
              then {values.minValue} {props.units} or if&nbsp;
              {props.label} is less then {values.maxValue} {props.units}
            </Description>
          </form>
        )}
      />
    </FormWrapper>
  </Root>
);

const enhance = compose(
  graphql(CREATE_ALARM, { name: 'createAlarm' }),
  graphql(UPDATE_ALARM, { name: 'updateAlarm' }),
);

export const AlarmSettings = enhance(AlarmSettingsForm);
