import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

export const MaturityChart = ({ data }) => {
  return (
    <LineChart
      width={760}
      height={363}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid />
      <XAxis
        dataKey="week"
      />
      <YAxis
        ticks={[0, 20, 40, 60, 80, 100]}
        dataKey="opt"
        tickCount={6}
        domain={[0, 100]}
        padding={{ top: 40 }}
        label={{
          offset: 0,
          value: '%',
          position: 'insideTop'
        }}
      />
      <Line
        type="monotone"
        dataKey="act"
        stroke="#3253ad"
        dot={{ fill: "#3253ad"}}
      />
      <Line type="monotone" dataKey="opt" stroke="#85b5ba" dot={false} />
      <Line type="monotone" dataKey="pes" stroke="#cf0707" dot={false} />
    </LineChart>
  )
}