import React from 'react';

const ChartIcon = () => (
  <svg
    width="238"
    height="70"
    viewBox="0 0 238 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_913_8297)">
      <rect width="238" height="70" rx="4" fill="white" />
      <line
        x1="-0.517822"
        y1="67.2891"
        x2="242"
        y2="67.2891"
        stroke="#BFC4CD"
      />
      <line
        x1="-0.517822"
        y1="50.2568"
        x2="242"
        y2="50.2568"
        stroke="#BFC4CD"
      />
      <line
        x1="-0.517822"
        y1="33.2251"
        x2="242"
        y2="33.2251"
        stroke="#BFC4CD"
      />
      <line
        x1="-0.517822"
        y1="16.5635"
        x2="242"
        y2="16.5635"
        stroke="#BFC4CD"
      />
      <line
        x1="-0.517822"
        y1="-0.468262"
        x2="242"
        y2="-0.468262"
        stroke="#BFC4CD"
      />
      <line
        x1="17.3848"
        y1="-16.2593"
        x2="17.3848"
        y2="85.5614"
        stroke="#BFC4CD"
      />
      <line
        x1="34.7859"
        y1="-16.2593"
        x2="34.7859"
        y2="91.1152"
        stroke="#BFC4CD"
      />
      <line
        x1="52.1885"
        y1="-16.2593"
        x2="52.1885"
        y2="85.5614"
        stroke="#BFC4CD"
      />
      <line
        x1="69.5901"
        y1="-16.2593"
        x2="69.5901"
        y2="91.1152"
        stroke="#BFC4CD"
      />
      <line
        x1="86.9924"
        y1="-16.2593"
        x2="86.9924"
        y2="85.5614"
        stroke="#BFC4CD"
      />
      <line
        x1="104.394"
        y1="-16.2593"
        x2="104.394"
        y2="91.1152"
        stroke="#BFC4CD"
      />
      <line
        x1="121.796"
        y1="-16.2593"
        x2="121.796"
        y2="85.5614"
        stroke="#BFC4CD"
      />
      <line
        x1="139.199"
        y1="-16.2593"
        x2="139.199"
        y2="91.1152"
        stroke="#BFC4CD"
      />
      <line
        x1="156.601"
        y1="-16.2593"
        x2="156.601"
        y2="85.5614"
        stroke="#BFC4CD"
      />
      <line
        x1="174.003"
        y1="-16.2593"
        x2="174.003"
        y2="91.1152"
        stroke="#BFC4CD"
      />
      <line
        x1="191.405"
        y1="-16.2593"
        x2="191.405"
        y2="85.5614"
        stroke="#BFC4CD"
      />
      <line
        x1="208.807"
        y1="-16.2593"
        x2="208.807"
        y2="91.1152"
        stroke="#BFC4CD"
      />
      <line
        x1="226.208"
        y1="-16.2593"
        x2="226.208"
        y2="85.5614"
        stroke="#BFC4CD"
      />
      <line
        x1="-0.0180664"
        y1="-16.2593"
        x2="-0.0180711"
        y2="91.1152"
        stroke="#BFC4CD"
      />
      <path
        d="M104.262 20.9509C107.225 20.9509 114.75 24.3304 120.924 29.8371C127.774 35.9463 146.998 47.054 156.284 47.054C163.504 47.054 172.555 33.2396 173.686 31.3181C175.537 28.1709 183.127 16.693 190.903 16.5078C198.678 16.3227 203.676 20.2104 208.305 24.0981C212.933 27.9858 223.855 41.3151 225.892 43.7217C227.928 46.1284 237.925 56.1253 241.813 57.7915"
        stroke="#BFC4CD"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <circle cx="104.263" cy="20.7655" r="2.22154" fill="#3253AD" />
      <circle cx="104.263" cy="20.7658" r="3.94308" stroke="#3253AD" />
      <circle
        cx="86.6767"
        cy="29.8371"
        r="1.16616"
        fill="#3253AD"
        stroke="#3253AD"
      />
      <circle
        cx="121.481"
        cy="29.8371"
        r="1.16616"
        fill="#BFC4CD"
        stroke="#BFC4CD"
      />
      <circle
        cx="138.883"
        cy="40.945"
        r="1.16616"
        fill="#BFC4CD"
        stroke="#BFC4CD"
      />
      <circle
        cx="156.285"
        cy="46.8693"
        r="1.16616"
        fill="#BFC4CD"
        stroke="#BFC4CD"
      />
      <circle
        cx="173.687"
        cy="31.3185"
        r="1.16616"
        fill="#BFC4CD"
        stroke="#BFC4CD"
      />
      <circle
        cx="191.089"
        cy="16.508"
        r="1.16616"
        fill="#BFC4CD"
        stroke="#BFC4CD"
      />
      <circle
        cx="208.491"
        cy="24.2838"
        r="1.16616"
        fill="#BFC4CD"
        stroke="#BFC4CD"
      />
      <circle
        cx="225.893"
        cy="43.1666"
        r="1.16616"
        fill="#BFC4CD"
        stroke="#BFC4CD"
      />
      <circle
        cx="69.2741"
        cy="35.3913"
        r="1.16616"
        fill="#3253AD"
        stroke="#3253AD"
      />
      <circle
        cx="51.8727"
        cy="47.6095"
        r="1.16616"
        fill="#3253AD"
        stroke="#3253AD"
      />
      <circle
        cx="34.4701"
        cy="37.9831"
        r="1.16616"
        fill="#3253AD"
        stroke="#3253AD"
      />
      <circle
        cx="17.2541"
        cy="25.5793"
        r="1.35128"
        fill="#3253AD"
        stroke="#3253AD"
      />
      <circle
        cx="-0.333844"
        cy="21.6915"
        r="1.16616"
        fill="#3253AD"
        stroke="#3253AD"
      />
      <path
        d="M-0.334229 21.8768C2.07244 21.6917 11.1437 22.6174 17.0678 25.5794C22.992 28.5415 29.8417 33.5399 34.4699 37.983C36.4553 39.8889 44.4669 47.6097 51.6869 47.6097C61.6992 47.6097 66.1691 38.2574 69.2741 35.3912C74.0874 30.9481 82.5179 30.6738 86.491 29.8373C90.0085 29.0968 96.8582 21.1363 104.078 20.9512"
        stroke="#3253AD"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default ChartIcon;
