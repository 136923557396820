import styled from 'styled-components';

export const PageTitle = styled.span`
  display: inline-block;
  font-size: 24px;
  line-height: 32px;
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  margin: 0;
`;
