import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { transparentize } from 'polished'

export const RouteTab = styled(NavLink).attrs({ replace: true })`
  background-color: ${(p) => p.theme.white};
  border-radius: 2px;
  min-width: 140px;
  height: 46px;

  border-top-style: solid;
  border-bottom-style: solid;
  border-color: transparent;
  border-top-width: 2px;
  border-bottom-width: 2px;
  text-decoration: none;
  text-transform: uppercase;
  ${(p) => p.theme.ProximaNovaBold};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-out;

  font-size: 12px;
  color: ${(p) => transparentize(0.4, p.theme.black)};

  :hover {
    color: ${(p) => p.theme.black};
  }

  &.active {
    color: ${(p) => p.theme.black};
    border-bottom-color: ${(p) => p.theme.orange};
  }
`
