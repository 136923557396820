import React from 'react'
import PropTypes from 'prop-types'

const MessageIcon = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard"
        transform="translate(-1324.000000, -43.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <path
          d="M1325.28658,43.6004025 C1324.82739,43.2704938 1324.9316,43 1325.51618,43 L1332.00038,43 L1338.48458,43 C1339.06858,43 1339.17279,43.2704938 1338.71245,43.6004025 L1332.96742,47.7427625 C1332.70691,47.9309094 1332.34792,48.0137775 1331.9998,47.998142 C1331.65112,48.0137775 1331.29212,47.9303883 1331.03161,47.7427625 L1325.28658,43.6004025 Z M1339.14994,45.1688511 C1339.61747,44.8335598 1340,45.0134324 1340,45.5682661 L1340,52.9908656 C1340,53.5456993 1339.51983,54 1338.93341,54 L1331.99971,54 L1325.06659,54 C1324.48017,54 1324,53.5456993 1324,52.9908656 L1324,45.5682661 C1324,45.0134324 1324.38253,44.8335598 1324.85006,45.1688511 L1331.14908,49.6928382 C1331.38457,49.8596687 1331.693,49.9417254 1332.00086,49.9379215 C1332.30815,49.9417254 1332.61658,49.8602121 1332.85207,49.6928382 L1339.14994,45.1688511 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
)

MessageIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

MessageIcon.defaultProps = {
  width: 16,
  height: 11
}

export default MessageIcon
