import React from 'react'
import PropTypes from 'prop-types'

const FanStageIcon = ({ height, width }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.474 11.002a6.701 6.701 0 0 1-8.195 1.006 3.87 3.87 0 0 0 .724 4.46 1.418 1.418 0 0 1-2.005 2.006c-2.22-2.219-2.553-5.62-1.004-8.194a3.871 3.871 0 0 0-4.463.722 1.417 1.417 0 1 1-2.005-2.004c2.22-2.22 5.62-2.553 8.196-1.003.717-1.428.499-3.24-.724-4.464a1.418 1.418 0 1 1 2.005-2.005 6.7 6.7 0 0 1 1.004 8.196 3.863 3.863 0 0 0 4.462-.724 1.417 1.417 0 1 1 2.005 2.004z"
      fill="#9EE4D8"
    />
  </svg>
)

FanStageIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

FanStageIcon.defaultProps = {
  width: 18,
  height: 18
}

export default FanStageIcon
