import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Table, TitleFilters } from "./";

import { PageHeader } from "src/ui";
import { AlertsStore } from "src/store";

export const AlertsTable = () => {
  const { t } = useTranslation();

  return (
    <AlertsStore>
      <PageHeader title={t("alerts")}>
        <ActionsWrapper>
          <TitleFilters />
        </ActionsWrapper>
      </PageHeader>
      <Table />
    </AlertsStore>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;
