import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

const Status = styled.p`
  margin: 0;
  color: ${(p) => p.theme.darkGray};
  font-family: ${(p) => p.theme.ProximaNovaBold};
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 5px;
`

const Subitle = styled.p`
  margin: 0;
  color: ${transparentize(0.55, '#000000')};
  font-family: ${(p) => p.theme.ProximaNova};
  font-size: 14px;
  line-height: 17px;
`

export const Title = ({ status, subtitle }) => (
  <div>
    <Status>{status}</Status>
    <Subitle>{subtitle}</Subitle>
  </div>
)