import React from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { toast } from 'react-toastify'

import { ChartController } from './ChartController'

import { mapErrorMessage } from 'src/utils/mapErrorMessage'
import { Spinner } from 'src/components/'

const GET_GROWING_CYCLES = gql`
  {
    farms {
      growing_spaces {
        growing_cycles {
          id
          name
          duration_in_days
          current_day_number
          crop {
            id
            name
            growing_cycles_count
          }
        }
      }
    }
  }
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Root = styled.div`
  height: 500px;
  width: 100%;
  background-color: ${(p) => p.theme.white};
`

export const EnvData = () => (
  <Root>
    <Query query={GET_GROWING_CYCLES}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )

        if (error) {
          toast.error(mapErrorMessage(error.message))
          return null
        }

        const growingCycles = data.farms[0].growing_spaces[0].growing_cycles
        return <ChartController growingCycles={growingCycles} />
      }}
    </Query>
  </Root>
)
