 import React from 'react';
import PropTypes from 'prop-types';

const CloseIconAux = ({ width, height, fill, ...props }) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
  </svg>
);

CloseIconAux.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

CloseIconAux.defaultProps = {
  width: 24,
  height: 24,
};

export default CloseIconAux;
