import React from 'react'

import { SensorsSettingTable } from './SensorsSettingTable'

export const TemperatureAlarms = ({ growingCycle }) => (
  <SensorsSettingTable
    growingCycle={growingCycle}
    initialValues={{ minValue: 18, maxValue: 30 }}
    sensorType={'temperature'}
    formTitle={'Temperature Sensor Alarm'}
    label={'temperature'}
    units={'C\u00b0'}
    data={growingCycle.temperatureAlarmConfigs}
  />
)
