import React, { Component } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

const Input = styled.input`
  display: block;
  outline: none;
  border: none;
  height: 100%;
  padding: 10px;
  font-size: 16px;
  color: ${(p) => transparentize(0.5, p.theme.fontGray)};
  background-color: ${(p) => p.theme.lightGray};
  width: 60px;

  margin: 0 5px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  :focus {
    color: ${(p) => p.theme.fontGray};
  }
`
const PeriodBlockStart = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const PeriodBlockEnd = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const Root = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
`
const Label = styled.span`
  font-size: 16px;
  color: ${(p) => p.theme.black};
  ${(p) => p.theme.ProximaNovaSemibold};
  opacity: 0.6;
  min-width: 60px;
`

const InputWrapper = styled.div`
  position: relative;
  display: flex;
`

export class DurationInput extends Component {
  render() {
    return (
      <Root>
        <PeriodBlockStart>
          <Label>Starts at</Label>
          <InputWrapper>
            <Input type="number" />
          </InputWrapper>
          <InputWrapper>
            <Input type="number" />
          </InputWrapper>
        </PeriodBlockStart>
        <PeriodBlockEnd>
          <Label>Due to</Label>
          <InputWrapper>
            <Input type="number" />
          </InputWrapper>
          <InputWrapper>
            <Input type="number" />
          </InputWrapper>
        </PeriodBlockEnd>
      </Root>
    )
  }
}
