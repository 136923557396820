import React from 'react'
import PropTypes from 'prop-types'

const LocationsIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard"
        transform="translate(-26.000000, -322.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="pin-(1)" transform="translate(26.000000, 322.000000)">
          <path
            d="M8.25429091,23.4549903 C8.46507576,23.7941359 8.83321515,24 9.22922121,24 L9.77077879,24 C10.1667848,24 10.5349818,23.7941942 10.7457091,23.4549903 C11.7209848,21.8852039 12.9486727,20.5116699 14.136,19.1833981 C16.5253364,16.5102524 18.9960848,13.7461165 19,9.61031068 C18.9970636,7.03491262 18.0075091,4.61866019 16.2136788,2.80654369 C14.4220364,0.996699029 12.0377667,0 9.5,0 C6.96223333,0 4.57796364,0.996699029 2.78632121,2.80660194 C0.992433333,4.61871845 0.00293636364,7.03497087 0,9.61281553 C0.00391515152,13.7461165 2.47466364,16.5102524 4.864,19.1833981 C6.05138485,20.5116699 7.27907273,21.8852039 8.25429091,23.4549903 Z M9.5,2.33009709 C13.4639182,2.33009709 16.6924212,5.59718447 16.6969697,9.61048544 C16.6938606,12.8478058 14.7169394,15.059534 12.4278424,17.6204854 C11.4362727,18.7298447 10.4178727,19.8691456 9.5,21.1589709 C8.5820697,19.8691456 7.56372727,18.7298447 6.57215758,17.6204854 C4.28306061,15.059534 2.30613939,12.8478058 2.3030303,9.61299029 C2.30757879,5.59718447 5.53608182,2.33009709 9.5,2.33009709 Z"
            id="Shape"
          />
          <path
            d="M10.5465116,12.9534884 C10.5465116,12.3755 10.0779884,11.9069767 9.5,11.9069767 C8.17281395,11.9069767 7.09302326,10.827186 7.09302326,9.5 C7.09302326,8.17281395 8.17281395,7.09302326 9.5,7.09302326 C10.827186,7.09302326 11.9069767,8.17281395 11.9069767,9.5 C11.9069767,10.0779884 12.3755,10.5465116 12.9534884,10.5465116 C13.5314767,10.5465116 14,10.0779884 14,9.5 C14,7.01872093 11.9813314,5 9.5,5 C7.0186686,5 5,7.01872093 5,9.5 C5,11.9812791 7.0186686,14 9.5,14 C10.0779884,14 10.5465116,13.5314767 10.5465116,12.9534884 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
)

LocationsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

LocationsIcon.defaultProps = {
  width: 19,
  height: 24
}

export default LocationsIcon
