import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { StatisticCards } from "src/components/alerts";
import { OneFour, PageHeader, Root, ThreeFour, RowWrapper } from "src/ui";
import { Table, TitleFilters } from "src/components/alerts/AlertsTable";
import { AlertsStore } from "src/store";

export const Alerts = () => {
  const { t } = useTranslation();

  return (
    <AlertsStore>
      <Root>
        <PageHeader title={t("alerts")} isThreeFourWidth>
          <ActionsWrapper>
            <TitleFilters />
          </ActionsWrapper>
        </PageHeader>
        <RowWrapper>
          <ThreeFour>
            <Table />
          </ThreeFour>
          <OneFour>
            <StatisticCards />
          </OneFour>
        </RowWrapper>
      </Root>
    </AlertsStore>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;
