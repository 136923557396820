import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentBox } from "src/components/ContentBox";
import { WaterDrop } from "src/components/Icons";

const windowWidth = window.innerWidth;
const blockWidth = windowWidth < 1400 ? 50 : 25;

export function WaterReservesSensor() {
  const { t } = useTranslation();
  const [sensor, setSensor] = useState({ signal: 5500, defined: 0 });

  useEffect(() => {
    const interval = setInterval(() => {
      setSensor((prevSensor) =>
        prevSensor.signal === 0
          ? { ...prevSensor, signal: 5500 }
          : { ...prevSensor, signal: prevSensor.signal - 1 }
      );
    }, 1000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <ContentBox
      width={`${blockWidth}%`}
      padding={12}
      title={t("water")}
      description={t("reserves_gallons_1000")}
      amount={sensor.signal}
      amountName={t("today")}
      totalAmount={7460}
      totalName={t("tomorrow")}
      icon={<WaterDrop />}
      res={t("norm")}
      resType="norm"
    />
  );
}
