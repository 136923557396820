import gql from 'graphql-tag'

export const getCyclesByIds = gql`
  query getCycles($ids: [ID!]) {
    growing_cycles(ids: $ids) {
      id
      name
      start_at
      days {
        co2_sensor_logs {
          id
          value
          created_at
        }
        humidity_sensor_logs {
          id
          value
          created_at
        }
        temperature_sensor_logs {
          id
          value
          created_at
        }
      }
    }
  }
`
