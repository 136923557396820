import React from 'react';

const AIWaterBadValueIcon = () => (
  <svg
    width="20"
    height="38"
    viewBox="0 0 20 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66666 9.64317L7.66666 37.6665L12.3333 37.6665L12.3333 9.64317L19.3333 9.64317L9.99999 0.333176L0.666657 9.64317L7.66666 9.64317Z"
      fill="#CF0707"
    />
  </svg>
);

export default AIWaterBadValueIcon;
