import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FieldsList } from "src/components/yieldForecast";
import { Map } from "src/components";
import { yieldForecast } from "src/stubs";
import {
  MapContainer,
  OneFour,
  PageHeader,
  ThreeFour,
  RowWrapper,
} from "src/ui";

const Root = styled.div`
  height: 100%;
`;

const Field = styled.span`
  position: absolute;
  display: inline-block;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  top: ${(p) => p.top};
  left: ${(p) => p.left};
  border-radius: ${(p) => p.radius};
  transform: rotate(${(p) => p.rotate});
  z-index: 1;
  cursor: pointer;
`;

const FieldLink = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 100%;
`;

export const YieldForecast = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const fieldIds = yieldForecast.defaultFields.map((field) => field.id);

  return (
    <Root>
      <PageHeader title={t("yield_forecast")} />
      <RowWrapper>
        <ThreeFour>
          <MapContainer>
            <Map />
            <Field top="37%" left="50.5%" width="40px" height="40px">
              <FieldLink to={`${location.pathname}/${fieldIds[0]}`} />
            </Field>
            <Field
              top="71%"
              left="40.5%"
              width="75px"
              height="60px"
              radius="40%"
            >
              <FieldLink to={`${location.pathname}/${fieldIds[1]}`} />
            </Field>
            <Field
              top="49%"
              left="27.5%"
              width="80px"
              height="180px"
              radius="61%"
              rotate="48deg"
            >
              <FieldLink to={`${location.pathname}/${fieldIds[2]}`} />
            </Field>
          </MapContainer>
        </ThreeFour>
        <OneFour>
          <FieldsList fields={yieldForecast.defaultFields} />
        </OneFour>
      </RowWrapper>
    </Root>
  );
};
