import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Yield } from "src/components/Icons";

const path = `/analytics/forecast-of-critical-situations/${123}`;

export const FiltersSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Filter href={path}>
        <div className="slide-wrap">
          <Slide percent={50} />
        </div>
        <div>
          <div className="info">
            <Yield />
            <span className="text">ID 2599877</span>
            <span className="text-field">{t("field")}</span>
          </div>
        </div>
      </Filter>
      <Filter href={path}>
        <div className="slide-wrap">
          <Slide percent={20} />
        </div>
        <div>
          <div className="info">
            <Yield />
            <span className="text">ID 2599889</span>
            <span className="text-field">{t("field")}</span>
          </div>
        </div>
      </Filter>
      <Filter href={path}>
        <div className="slide-wrap">
          <Slide percent={70} />
        </div>
        <div>
          <div className="info">
            <Yield />
            <span className="text">ID 2592487</span>
            <span className="text-field">{t("field")}</span>
          </div>
        </div>
      </Filter>
    </>
  );
};

const Filter = styled.a`
  padding: 28px 24px;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  //width: 370px;
  cursor: pointer;
  display: block;
  text-decoration: none;

  @media (max-width: 1400px) {
    width: 33%;
    margin: 0 !important;
  }

  & + * {
    margin-top: 16px;
  }

  & .slide-wrap {
    width: 100%;
    height: 8px;
    background: linear-gradient(
      90deg,
      #cf0707 0%,
      #e6ce7a 50.31%,
      #58c08e 100%
    );
    border-radius: 4px;
    position: relative;
  }

  & .info {
    display: flex;
    align-items: center;
    margin-top: 28px;

    & svg {
      margin-right: 16px;
    }

    & .text {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #000;
    }

    & .text-field {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #364652;
      margin-left: 10px;
      font-style: italic;
    }
  }
`;

const Slide = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ percent }) =>
    percent > 65 ? "#58C08E" : percent < 35 ? "#C64627" : "#E6CE7A"};
  border: 1px solid #c64627;
  box-sizing: border-box;
  position: absolute;
  top: -4px;
  left: ${(props) => props.percent}%;
`;
