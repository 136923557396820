import React from 'react'
import PropTypes from 'prop-types'

const SensorsIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard"
        transform="translate(-24.000000, -194.000000)"
        fill="#FFFFFF"
      >
        <g id="Group-3" transform="translate(24.000000, 194.000000)">
          <path
            d="M4.5,17 C2.01471863,17 0,14.9852814 0,12.5 C0,10.0147186 2.01471863,8 4.5,8 C6.98528137,8 9,10.0147186 9,12.5 C9,14.9852814 6.98528137,17 4.5,17 Z M4.5,15 C5.88071187,15 7,13.8807119 7,12.5 C7,11.1192881 5.88071187,10 4.5,10 C3.11928813,10 2,11.1192881 2,12.5 C2,13.8807119 3.11928813,15 4.5,15 Z"
            id="Combined-Shape"
          />
          <path
            d="M16.5,9 C14.0147186,9 12,6.98528137 12,4.5 C12,2.01471863 14.0147186,0 16.5,0 C18.9852814,0 21,2.01471863 21,4.5 C21,6.98528137 18.9852814,9 16.5,9 Z M16.5,7 C17.8807119,7 19,5.88071187 19,4.5 C19,3.11928813 17.8807119,2 16.5,2 C15.1192881,2 14,3.11928813 14,4.5 C14,5.88071187 15.1192881,7 16.5,7 Z"
            id="Combined-Shape-Copy"
          />
          <path
            d="M16.5,25 C14.0147186,25 12,22.9852814 12,20.5 C12,18.0147186 14.0147186,16 16.5,16 C18.9852814,16 21,18.0147186 21,20.5 C21,22.9852814 18.9852814,25 16.5,25 Z M16.5,23 C17.8807119,23 19,21.8807119 19,20.5 C19,19.1192881 17.8807119,18 16.5,18 C15.1192881,18 14,19.1192881 14,20.5 C14,21.8807119 15.1192881,23 16.5,23 Z"
            id="Combined-Shape-Copy-2"
          />
        </g>
      </g>
    </g>
  </svg>
)

SensorsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

SensorsIcon.defaultProps = {
  width: 21,
  height: 25
}

export default SensorsIcon
