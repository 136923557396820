import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { getDaysArray } from './helpers'
import {DaysGrid, CycleFieldsForm, CycleFieldsStatic, DaysSchedule} from './components'

import {ScheduleTableIcon} from 'src/components/Icons'

const fieldsPosition = css`
  margin-right: 100px;
  margin-bottom: 16px;
`

const gridPosition = css`
  flex-basis: 400px;
  flex-grow: 1;
  flex-shrink: 0;
`

export const Schedules = ({ growingCycle }) => {
  const allDays = getDaysArray(growingCycle)

  let fieldsComponent
  if (growingCycle.status.toLowerCase() === 'planned') {
    fieldsComponent = (
      <CycleFieldsForm
        css={fieldsPosition}
        durationInDays={growingCycle.durationInDays}
        startAt={growingCycle.startAt}
      />
    )
  }

  if (growingCycle.status.toLowerCase() === 'in progress') {
    fieldsComponent = (
      <CycleFieldsStatic
        css={fieldsPosition}
        durationInDays={growingCycle.durationInDays}
        startAt={growingCycle.startAt}
      />
    )
  }

  return (
    <Root>
      <ScheduleHeader>
        <ScheduleHeaderIconWrapper>
          <ScheduleTableIcon />
        </ScheduleHeaderIconWrapper>
        <span style={{fontWeight: '700', fontSize: '18px'}}>Schedule</span>
      </ScheduleHeader>
      {/*{fieldsComponent}*/}
      <CycleFieldsForm
        css={fieldsPosition}
        durationInDays={growingCycle.durationInDays}
        startAt={growingCycle.startAt}
      />

      <DaysSchedule growingCycle={growingCycle} />

      {/*<DaysGrid*/}
      {/*  css={gridPosition}*/}
      {/*  days={allDays}*/}
      {/*  numberOfStages={growingCycle.stages.length}*/}
      {/*/>*/}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 476px;
  padding: 16px;
  margin: 12px;
  background: #FFFFFF;
  border-radius: 4px;
`

const ScheduleHeader = styled.div`
  background-color: #ffffff; 
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`

const ScheduleHeaderIconWrapper = styled.span`
  margin-right: 0;
  padding-right: 12px;
`

Schedules.propTypes = {
  growingCycle: PropTypes.object.isRequired
}
