import React from 'react'
import styled from 'styled-components'
import { Query, compose } from 'react-apollo'
import { toast } from 'react-toastify'
import {useSearchParams} from 'react-router-dom'

import { CompareCheckBoxesItem } from './CompareCheckBoxesItem'

import { mapErrorMessage } from 'src/utils/mapErrorMessage'
import { Spinner } from 'src/components/'
import { H3 } from 'src/ui'
import { getCyclesByIds } from 'src/graphQL/queries/getCyclesByIds'

const Root = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.white};
  padding: 30px;
  margin-top: 30px;
`

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const CompareCheckBoxesView = ({
  idsOfCyclesToShow,
  onUpdateIds,
  colors
}) => {
  const [searchParams] = useSearchParams()
  const cyclesIds = searchParams.getAll('cyclesIds')

  return (
    <Root>
      <H3>Growing Cycles</H3>
      <Query query={getCyclesByIds} variables={{ ids: cyclesIds }}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )

          if (error) {
            toast.error(mapErrorMessage(error.message))
            return null
          }

          const { growing_cycles } = data

          const cyclesToShow = growing_cycles.filter(({ id }) =>
            cyclesIds.includes(id)
          )

          return (
            <LabelsWrapper>
              {cyclesToShow.map((cycle) => (
                <CompareCheckBoxesItem
                  color={colors[cycle.id]}
                  key={cycle.id}
                  name={cycle.name}
                  checked={idsOfCyclesToShow.includes(cycle.id)}
                  onChange={() => onUpdateIds(cycle.id)}
                />
              ))}
            </LabelsWrapper>
          )
        }}
      </Query>
    </Root>
  )
}

// const enhance = compose(withRouter)

export const CompareCheckBoxes = compose(CompareCheckBoxesView)
