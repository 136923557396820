import React from 'react'
import PropTypes from 'prop-types'

const DefaultAvatarIcon = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard"
        transform="translate(-1378.000000, -36.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="user-(8)" transform="translate(1378.000000, 36.000000)">
          <path
            d="M8.70963799,20.8766417 L3.78961755,23.5603458 C3.50090002,23.7177715 0.241427587,28.789062 0,29 C6,29 9.57205716,29 13.617836,29 C17.6337474,29 24,29 28,29 C27.7361719,28.7772395 23.6227694,23.7538612 23.305429,23.5958132 L18.0369564,20.961888 C17.3562301,20.6215249 16.926265,19.925865 16.926265,19.1648703 L16.926265,17.0978022 C17.0743572,16.9291762 17.2436054,16.7126381 17.4246761,16.456899 C18.1427365,15.4426543 18.6859486,14.326985 19.0624014,13.156559 C19.7381497,12.94811 20.2359386,12.3240072 20.2359386,11.5823018 L20.2359386,9.3758528 C20.2359386,8.89050868 20.0200227,8.45681015 19.6846374,8.15315896 L19.6846374,4.96357697 C19.6846374,4.96357697 20.339852,0 13.617836,0 C6.89581991,0 7.55103447,4.96357697 7.55103447,4.96357697 L7.55103447,8.15315896 C7.215027,8.45681015 6.99973333,8.89050868 6.99973333,9.3758528 L6.99973333,11.5823018 C6.99973333,12.1634703 7.30525123,12.6749483 7.76259472,12.9705105 C8.31389586,15.3704749 9.7574835,17.0978022 9.7574835,17.0978022 L9.7574835,19.113847 C9.75686126,19.8480855 9.35489678,20.5244561 8.70963799,20.8766417 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
)

DefaultAvatarIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

DefaultAvatarIcon.defaultProps = {
  width: 28,
  height: 29
}

export default DefaultAvatarIcon
