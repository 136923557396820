import React, {useState} from 'react'
import styled from 'styled-components'
import {Navigate, useSearchParams} from 'react-router-dom'
import {Query} from 'react-apollo'
import {toast} from 'react-toastify'

import {
  ComparisonChartList,
  Parameters,
  CompareCheckBoxes
} from 'src/components/compareGrowingCycles'
import { WATER_LOGS, ELECTRICITY_LOGS } from 'src/utils/constants'
import { mapErrorMessage } from 'src/utils/mapErrorMessage'
import { extractDataForCompare } from 'src/utils/helpers'
import { getCyclesByIds } from 'src/graphQL/queries/getCyclesByIds'
import {Spinner} from 'src/components'
import { getRandomColorForChart } from 'src/theme/chart'
import {PageHeader} from 'src/ui'

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const ChartsWrapper = styled.section`
  flex-basis: 72%;
`

const AsideWrapper = styled.div`
  flex-basis: 28%;
  padding-left: 16px;
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 30vh;
`

const CompareCycles = () => {
  const [searchParams] = useSearchParams()
  const cyclesIds = searchParams.getAll('cyclesIds')

  const [idsOfCyclesToShow, setIdsOfCyclesToShow] = useState(cyclesIds)
  const colors = cyclesIds.reduce((acc, cycleId, index) => (
      {...acc, [cycleId]: getRandomColorForChart(index)}
    ), {}
  )
  const [parameters, setParameters] = useState({
    water: true,
    temperature: true,
    co2: true,
    electricity: true,
  })


  const onUpdateParameter = (e) => {
    const {name} = e.target

    setParameters(prevParameters => ({...prevParameters, [name]: !prevParameters[name]}))
  }

  const onUpdateIds = (idToUpdate) => {
    setIdsOfCyclesToShow(prevIdsOfCyclesToShow =>
      prevIdsOfCyclesToShow.includes(idToUpdate)
        ? prevIdsOfCyclesToShow.filter(id => id !== idToUpdate)
        : [...prevIdsOfCyclesToShow, idToUpdate]
    )
  }

  return (
    (!idsOfCyclesToShow?.length > 0)
    ? <Navigate to="/analytics/growing-cycles" />
    : (
      <>
        <PageHeader
          title='Comparison Graphics'
          isBackButton
        />
        <Root>
          <Query query={getCyclesByIds} variables={{ ids: idsOfCyclesToShow }}>
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                )

              if (error) {
                toast.error(mapErrorMessage(error.message))
                return null
              }

              const modifiedData = {
                ...data,
                growing_cycles:
                  data.growing_cycles.map((item, i) => {
                    return ({
                      ...item,
                      days: data.growing_cycles[i].days.map((item, j) => ({
                        ...item,
                        water_sensor_logs: WATER_LOGS[i][j] || [],
                        electricity_sensor_logs: ELECTRICITY_LOGS[i][j] || []
                      }))
                    })
                  })
              }

              const cyclesToShow = modifiedData.growing_cycles.filter(({ id }) =>
                idsOfCyclesToShow.includes(id)
              )

              const dataSets = extractDataForCompare(cyclesToShow)
              const activeParameters = Object.keys(parameters)
                .filter((paramName) => parameters[paramName])
                .map((param) => param.toLowerCase())

              return (
                <ChartsWrapper>
                  <ComparisonChartList
                    colors={colors}
                    dataSets={dataSets}
                    activeParameters={activeParameters}
                    idsOfCyclesToShow={idsOfCyclesToShow}
                    idsFromQuery={cyclesIds}
                    onUpdateIds={onUpdateIds}
                  />
                </ChartsWrapper>
              )
            }}
          </Query>

          <AsideWrapper>
            <Parameters
              parameters={parameters}
              onUpdateParameter={onUpdateParameter}
            />

            {idsOfCyclesToShow.length > 0 && (
              <CompareCheckBoxes
                colors={colors}
                idsOfCyclesToShow={idsOfCyclesToShow}
                onUpdateIds={onUpdateIds}
              />
            )}
          </AsideWrapper>
        </Root>
      </>
    )
  )
}

// const enhance = compose(withRouter)

export const GrowingCyclesCompare = CompareCycles
