import React from 'react'
import PropTypes from 'prop-types'

const AlertIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} {...props}>
    <defs>
      <path
        d="M14.022 1.393l7.716 11.983A3 3 0 0 1 19.215 18H3.785a3 3 0 0 1-2.523-4.624L8.978 1.393a3 3 0 0 1 5.044 0zm-2.528-.32c-.488 0-.895.223-1.22.668L2.435 13.844c-.389.765-.457 1.426-.205 1.983.251.557.697.877 1.336.962l15.988-.004c.6-.099 1.059-.418 1.375-.958.315-.54.195-1.201-.362-1.983L12.774 1.74c-.365-.445-.792-.668-1.28-.668zm.68 11.192H10.84l-.278-6.902h1.874l-.264 6.902zm-.667 2.957c-.512 0-.944-.454-.944-.993 0-.525.432-.978.944-.978s.93.453.93.978c0 .54-.418.993-.93.993z"
        id="alertIcon__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="alertIcon__b">
        <use xlinkHref="#alertIcon__a" />
      </mask>
      <use xlinkHref="#alertIcon__a" />
      <g mask="url(#alertIcon__b)">
        <path d="M0-3h23v23H0z" />
      </g>
    </g>
  </svg>
)

AlertIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

AlertIcon.defaultProps = {
  width: 24,
  height: 24
}

export default AlertIcon
