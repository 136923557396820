import React from 'react'
import PropTypes from 'prop-types'

const StagePopupCloseIcon = ({ width, height }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.405 10.39L7.016 6l4.39-4.39A.718.718 0 0 0 10.388.596L6 4.984 1.612.594A.718.718 0 1 0 .595 1.612L4.984 6l-4.39 4.39a.718.718 0 1 0 1.017 1.015L6 7.016l4.39 4.39a.716.716 0 0 0 1.015 0 .718.718 0 0 0 0-1.017z"
      fill="#F60"
    />
  </svg>
)

StagePopupCloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

StagePopupCloseIcon.defaultProps = {
  width: 12,
  height: 12
}

export default StagePopupCloseIcon
