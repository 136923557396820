import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { compose, graphql } from 'react-apollo'

import { AlarmSettings } from './AlarmSettings'

import { ScheduleSettingIcon, ScheduleCloseIcon } from 'src/components/Icons'
import { Modal, BaseTable } from 'src/components'
import { DELETE_ALARM } from 'src/graphQL'
import { Toggler } from 'src/utils/toggler'

const VericalLine = styled.div`
  margin: 0 17px;
  background: ${transparentize(0.6, '#d8d8d8')};
  width: 1px;
  height: 36px;
  opacity: 0.4;
`

const ActionsBlock = styled.div`
  padding-left: 20px;
  width: 100%;
  display: flex;
  align-items: center;
`

const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  opacity: 0.84;
  color: #364652;
`
const Cell = styled.div`
  opacity: 0.61;
`
const SensorsSettingTableWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(p) => p.theme.white};
  .ReactTable {
    border: none;

    .rt-resizable-header-content {
      position: relative;
      display: inline;

      &::before {
        content: '';
        display: none;
        position: absolute;
        left: calc(100% + 15px);
        top: 50%;
        width: 2px;
        height: 7px;
        background: #ff6600;
        border-radius: 2px;
        transform: rotate(-45deg) translateY(-50%);
      }

      &::after {
        content: '';
        display: none;
        position: absolute;
        left: calc(100% + 14px);
        top: 50%;
        width: 2px;
        height: 7px;
        background: #ff6600;
        border-radius: 2px;
        transform: rotate(45deg) translateY(-50%);
      }
    }

    .-sort-asc,
    .-sort-desc {
      color: #ff6600;
      box-shadow: none !important;

      .rt-resizable-header-content {
        position: relative;
        display: inline;

        &::before,
        &::after {
          display: block;
        }
      }
    }

    .-sort-desc {
      .rt-resizable-header-content {
        &::before {
          left: calc(100% + 19px);
        }

        &::after {
          left: calc(100% + 10px);
        }
      }
    }

    .rt-thead.-header {
      box-shadow: none;
    }

    .rt-th {
      padding: 10px 10px 32px 10px !important;
      border: none !important;
      display: flex;
      align-items: center;
      text-align: left;
      outline: none;
      ${(p) => p.theme.ProximaNova};
      font-size: 18px;

      &:first-child {
        pointer-events: none;

        .rt-resizable-header-content {
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
    .rt-tbody .rt-tr-group {
      border: none !important;
    }
    .rt-tr {
      &:hover {
        .rt-td {
          background: #fff;
          border: none !important;
        }
      }
    }

    .rt-td {
      display: flex;
      align-items: center;
      padding: 10px !important;
      ${(p) => p.theme.ProximaNova};
      font-size: 16px;
      border: none !important;
    }

    .pagination-bottom,
    .rt-resizer {
      display: none;
    }
  }
`

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
`
class SensorsSettingTableView extends Component {
  state = { data: this.props.data }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({data: this.props.data})
    }
  }

  deleteAlarm = async (id, onDelete) => {
    if (!confirm('You Sure?')) {
      return
    }

    const response = await this.props.deleteAlarm({ variables: { id } })

    onDelete()
  }

  render() {
    const {
      growingCycle,
      sensorType,
      formTitle,
      label,
      units,
    } = this.props

    const { data } = this.state

    return (
      <SensorsSettingTableWrapper>
        <BaseTable
          data={data}
          columns={[
            {
              Header: <Header>Min ({units})</Header>,
              accessor: 'minValue',
              minWidth: 100,
              Cell: (row) => <Cell>{row.value}</Cell>
            },
            {
              Header: <Header>Max ({units})</Header>,
              accessor: 'maxValue',
              minWidth: 100,
              Cell: (row) => <Cell>{row.value}</Cell>
            },
            {
              Header: <Header>Interval (min.)</Header>,
              accessor: 'durationInMinutes',
              minWidth: 120,
              Cell: (row) => <Cell>{row.value}</Cell>
            },
            {
              Header: <Header>Precision (%)</Header>,
              accessor: 'precisionInPercents',
              minWidth: 120,
              Cell: (row) => <Cell>{row.value}</Cell>
            },
            {
              Header: <Header>Stage </Header>,
              accessor: 'stage',
              minWidth: 160,
              Cell: (row) => <Cell>{row.value && row.value.name}</Cell>
            },
            {
              accessor: 'status',
              minWidth: 130,
              Cell: ({ original }) => (
                <Cell>
                  <ActionsBlock>
                    <Toggler>
                      {({ toggled, onToggle }) => (
                        <Fragment>
                          <Modal
                            isOpen={toggled}
                            onClose={() => onToggle(false)}
                          >
                            <AlarmSettings
                              id={original.id}
                              initialValues={original}
                              growingCycle={growingCycle}
                              sensorType={sensorType}
                              formTitle={formTitle}
                              label={label}
                              units={units}
                              isOpen={toggled}
                              close={() => onToggle(false)}
                              onSuccess={(uItem) => {
                                const data = this.state.data.map((item) =>
                                  item.id === uItem.id ? uItem : item
                                )

                                this.setState({data})
                              }}
                            />
                          </Modal>
                          <IconWrapper onClick={() => onToggle(true)}>
                            <ScheduleSettingIcon />
                          </IconWrapper>
                        </Fragment>
                      )}
                    </Toggler>
                    <VericalLine />
                    <IconWrapper
                      onClick={() => {
                        this.deleteAlarm(original.id, () => {
                          const withoutRow = this.state.data.filter(
                            (item) => item.id !== original.id
                          )

                          this.setState({ data: withoutRow })
                        })
                      }}
                    >
                      <ScheduleCloseIcon />
                    </IconWrapper>
                  </ActionsBlock>
                </Cell>
              )
            }
          ]}
          pageSize={data.length}
          sortable={false}
        />
      </SensorsSettingTableWrapper>
    )
  }
}

const enhance = compose(graphql(DELETE_ALARM, { name: 'deleteAlarm' }))

export const SensorsSettingTable = enhance(SensorsSettingTableView)
