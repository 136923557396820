import React from 'react'
import styled from 'styled-components'

const MapImage = styled.img`
  min-width: 1030px;
  object-fit: cover;
`;

export const Map = () => {
  return (
    <MapImage src='/images/map.jpg' alt='Map image' />
  )
}