import styled from 'styled-components'
import { lighten, transparentize } from 'polished'

export const Button = styled.button`
  border: none;
  background: none;

  display: block;
  cursor: pointer;
  text-transform: uppercase;
  background: ${(p) => p.theme.orange};
  box-shadow: 0 2px 4px 0 rgba(179, 177, 177, 0.5);
  ${(p) => p.theme.ProximaNovaSemibold};
  font-size: 18px;
  font-weight: 700;
  color: ${(p) => p.theme.white};
  letter-spacing: 0;
  padding: 12px 30px;
  border-radius: 2px;

  :hover {
    background-color: ${(p) => lighten(0.1, p.theme.orange)};
  }

  ${(p) =>
    p.inset && `
      border: 2px solid ${p.theme.orange};
      color: ${transparentize(0.16, p.theme.orange)};
      box-shadow: none;
      background: transparent;
      border-radius: 4px;
      transition: background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;

      :hover {
        background-color: ${p.theme.orange};
        color: ${p.theme.white};
      }
    `
  }

  ${(p) =>
    p.green &&
    `
    background-color: ${p.theme.green};

    :hover {
      background: ${lighten(0.1, p.theme.green)};
    }
  `}
`
