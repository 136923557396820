import React from 'react'

const AlarmActiveIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7679 4.02857C12.9822 2.65714 11.0178 2.65714 10.2321 4.02857L2.27654 17.9143C1.49081 19.2857 2.47297 21 4.04444 21H19.9556C21.527 21 22.5092 19.2857 21.7235 17.9143L13.7679 4.02857Z"
      fill="#FF6600"
    />
    <rect x="11" y="8" width="2" height="6" fill="white" />
    <rect x="11" y="16" width="2" height="2" fill="white" />
  </svg>
)

export default AlarmActiveIcon
