import gql from 'graphql-tag'

export const CREATE_ALARM = gql`
  mutation(
    $growingCycleId: ID!
    $growingCycleStageId: ID
    $durationInMinutes: Int!
    $precisionInPercents: Int!
    $minValue: Int
    $maxValue: Int
    $sensorType: String!
  ) {
    createAlarm(
      growingCycleId: $growingCycleId
      growingCycleStageId: $growingCycleStageId
      durationInMinutes: $durationInMinutes
      precisionInPercents: $precisionInPercents
      minValue: $minValue
      maxValue: $maxValue
      sensorType: $sensorType
    ) {
      alarm {
        id
        duration_in_minutes
        precision_in_percents
        min_value
        max_value

        stage {
          id
          name
        }
      }
      errors
    }
  }
`

export const UPDATE_ALARM = gql`
  mutation(
    $id: ID!
    $growingCycleStageId: ID
    $durationInMinutes: Int!
    $precisionInPercents: Int!
    $minValue: Int
    $maxValue: Int
  ) {
    updateAlarm(
      id: $id
      growingCycleStageId: $growingCycleStageId
      durationInMinutes: $durationInMinutes
      precisionInPercents: $precisionInPercents
      minValue: $minValue
      maxValue: $maxValue
    ) {
      alarm {
        id
        duration_in_minutes
        precision_in_percents
        min_value
        max_value

        stage {
          id
          name
        }
      }
      errors
    }
  }
`

export const DELETE_ALARM = gql`
  mutation($id: ID!) {
    deleteAlarm(id: $id) {
      success
    }
  }
`

export const RESOLVE_ALERT = gql`
  mutation($id: ID!) {
    resolveAlert(id: $id) {
      alert {
        id
        message
        created_at
        resolved
      }
    }
  }
`
