import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { SEC_IN_DAY } from 'src/utils/constants';
import { darkGray } from 'src/theme';

const Root = styled.div`
  display: inline-block;
  background-color: ${(p) => p.theme.darkGray};
  color: ${(p) => p.theme.white};
  font-size: 12px;
  padding: 15px;
  padding-bottom: 5px;
  opacity: 0.85;

  :before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50px;
    right: calc(100% - 2px);
    transform: translate(0, -50%);
    border-width: 12px;
    border-style: solid;
    border-color: transparent;
    border-right-color: ${(p) => p.theme.darkGray};
  }
`;

const Header = styled.header`
  margin-bottom: 15px;
  font-weight: 700;
`;

const DataList = styled.ul`
  list-style-type: none;
`;

const DataItem = styled.li`
  margin-bottom: 10px;

  display: flex;
  align-items: center;
`;

const ColorDot = styled.div`
  margin-right: 5px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
`;

export const CompareTooltip = (props) => {
  const { x, datum, text } = props;

  return (
    <g>
      <path
        d={`M${x},200 L${x},60`}
        style={{ strokeWidth: 0.6, stroke: darkGray }}
      />

      <foreignObject x={x + 12} y={28} width="100%" height="100%">
        <Root>
          <Header>
            Day {Math.floor(datum.x / SEC_IN_DAY)},{' '}
            {moment.unix(datum.x).format('HH:mm')}
          </Header>
          <DataList>
            {text.map((item) => {
              const [cycleName, value, units, color] = item.split(',');

              return (
                <DataItem key={cycleName + value}>
                  <ColorDot color={color} />
                  <div>
                    {`${cycleName}:`} <br /> {`${value} ${units}`}
                  </div>
                </DataItem>
              );
            })}
          </DataList>
        </Root>
      </foreignObject>
    </g>
  );
};
