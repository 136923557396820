import React from "react";
import { useTranslation } from "react-i18next";

import { ContentBox } from "src/components";
import { Sunny, TreeLeaf, GrowthDynamicsIcon } from "src/components/Icons";
import { FlexContainer } from "src/ui";
import { Weather } from "src/components/dashboard/Weather";
import {
  LeafWetnessSensor,
  SoilNPKSensor,
  WaterConsumptionSensor,
  WaterReservesSensor,
  SoilMoistureSensor,
} from "src/components/dashboard/Sensors";
import { DailyLightIntegralSensor } from "src/components/dashboard/Sensors/DailyLightIntegralSensor";

const windowWidth = window.innerWidth;
const blockWidth = windowWidth < 1400 ? 50 : 25;

export const Statistic = () => {
  const { t } = useTranslation();

  return (
    <>
      <FlexContainer>
        {/* <ContentBox
          width={`${blockWidth}%`}
          padding={12}
          title={t("energy_consumed")}
          description={t("kilowatt_hour")}
          amount={468}
          amountName={t("today")}
          totalAmount={2734}
          totalName={t("week")}
          icon={<TreeLeaf />}
          res="+11%"
          resType="bad"
        /> */}
        <SoilMoistureSensor />
        <WaterConsumptionSensor />
        <WaterReservesSensor />
        <DailyLightIntegralSensor />
      </FlexContainer>
      <FlexContainer>
        <LeafWetnessSensor />
        <ContentBox
          width={`${blockWidth}%`}
          height={"156px"}
          padding={12}
          title={t("growth_dynamics")}
          amount="+3"
          totalAmount="OK"
          icon={<GrowthDynamicsIcon />}
          isLeftAmountPosition
          alertType="ok"
        />
        <SoilNPKSensor />
        <ContentBox
          width={`${blockWidth}%`}
          height={"156px"}
          padding={12}
          icon={<Sunny />}
          title={t("weather")}
          content={<Weather />}
        />
      </FlexContainer>
    </>
  );
};
