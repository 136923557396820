import React from 'react'
import styled from 'styled-components'

import { unitsMap } from 'src/utils/helpers'
import { CheckBox } from 'src/ui'

const Root = styled.li`
  display: block;
  padding: 16px 3px;
  :not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.lightGrayAux};
  }
`

const ParameterName = styled.span`
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.darkGray};
  font-size: 18px;
  line-height: 22px;
  display: inline-block;
  margin-left: 19px;
`

const Label = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  :focus ${CheckBox} {
    outline: none;
    box-shadow: 0 0 2px 2px ${(p) => p.theme.orange};
  }
  :hover {
    ${ParameterName} {
      font-weight: 700;
      color: ${(p) => p.theme.orange};
    }
  }
`

const Units = styled.span`
  margin-left: auto;
  color: ${(p) => p.theme.darkGray};
`

const HiddenInput = styled.input`
  display: none;
`

export const ParametersItem = ({ checked, name, onChange }) => {
  return (
    <Root>
      <Label tabIndex="0">
        <HiddenInput type="checkbox" onChange={onChange} name={name} />
        <CheckBox checked={checked} />
        <ParameterName>{unitsMap[name.toLowerCase()].name}</ParameterName>
        <Units>{unitsMap[name.toLowerCase()].units}</Units>
      </Label>
    </Root>
  )
}
