import React from 'react'

import {AlertAIAnalytics} from './'

import {Modal} from 'src/components/Modal'
import {useAlertsContext} from 'src/store'

export function AlertAiAnalyticsModal() {
  const {
    isOpenModal,
    closeModal,
    setAnalytics,
  } = useAlertsContext()

  const handleCloseModal = () => {
    setAnalytics({})
    closeModal()
  }

  return (isOpenModal &&
    (
      <Modal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
      >
        <AlertAIAnalytics/>
      </Modal>
    )
  )
}
