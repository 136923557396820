import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`;

const Rotor = styled.div`
  width: 50px;
  height: 50px;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  border-color: ${(p) =>
    `${p.theme.lightGray} ${p.theme.darkGray} ${p.theme.lightGray} ${
      p.theme.darkGray
    }`};

  animation-name: ${rotate};
  animation-duration: 0.7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  box-shadow: 0 0 4px ${(p) => p.theme.darkGray},
    inset 0 0 4px ${(p) => p.theme.darkGray};

  ${(p) =>
    p.fullwindow &&
    `
    position: absolute;
    z-index: 25000;
    left: 50%;
    top: 50%;
  `}
`;
// TODO: add ability to render spinner after timeout
// use state (show: true|false) and componentDidMount
// to change state after timeout
// Pass timeout in props
export const Spinner = ({ className, fullwindow }) => {
  if (fullwindow) {
    return ReactDOM.createPortal(
      <Rotor fullwindow className={className} />,
      document.getElementById('root'),
    );
  }

  return <Rotor className={className} />;
};

Spinner.propTypes = {
  className: PropTypes.string,
  fullwindow: PropTypes.bool,
};
