import React, {useState} from 'react'
import styled from 'styled-components'

import {LogoIcon} from 'src/components/Icons'

const Root = styled.main`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #fff;
  background-color: ${(p) => p.theme.darkGray};
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
  text-align: center;
`;

const Heading = styled.h2`
  margin: 0;
  margin-bottom: 40px;
  font-size: 20px;
`;

const Form = styled.form`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  border-bottom: 2px solid #fff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  background-color: transparent;

  ::placeholder {
    color: #fff;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    border-bottom: 2px solid #fff;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #ff6600 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  ::-webkit-input-placeholder {
    /* Chrome */
    color: #fff;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
    opacity: 1;
  }
  :-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #fff;
    opacity: 1;
  }
`;

const SubmitButton = styled.button`
  margin-top: 32px;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  color: #f34e00;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  padding: 10px 54px;
  border: none;
`;

export const FieldDescription = styled.span`
  font-size: 14px;
  line-height: 24px;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.white};
  margin: -20px 0 16px 0;
  padding: 0;
`;

const Error = styled.div`
  color: red;
  height: 18px;
`;

const FormDescription = styled.span`
  font-size: 14px;
  line-height: 24px;
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.white};
  margin: 24px 0 16px 0;
  padding: 0;
`

export const LoginPage = () => {
  const [email, setEmail] = useState('test@test.com')
  const [password, setPassword] = useState('11111111')
  const [error, setError] = useState('')

  const handleChangeEmail = event => {
    setEmail(event.target.value.trim())
  }

  const handleChangePassword = event => {
    setPassword(event.target.value.trim())
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth_tokens`,
      {
        method: 'POST',
        body: `email=${email}&password=${password}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ).then((res) => res.json());

    if (!response.success) {
      return setError('Wrong login/password')
    }
    localStorage.setItem('token', response.auth_token);
    history.replace('/dashboard')
  }

  return (
    <Root>
      <LogoWrapper>
        <a
          href="https://jetruby.com/farming-management-system"
          rel="noopener noreferrer"
          target="_blank"
        >
          <LogoIcon />
        </a>
      </LogoWrapper>
      <header>
        <Heading>Enter your account</Heading>
      </header>
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <Input
            type="text"
            autoFocus
            value={'Client'}
            placeholder="Login"
            onChange={handleChangeEmail}
          />
        </InputWrapper>
        <FieldDescription>
          Use <strong>Client</strong> as Login
        </FieldDescription>
        <InputWrapper>
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleChangePassword}
          />
        </InputWrapper>
        <FieldDescription>
          Use <strong>11111111</strong> as Password
        </FieldDescription>

        {!!error && <Error>{error}</Error>}

        <SubmitButton>LOG IN</SubmitButton>

        <FormDescription>
          Just click LOG IN. Fields already filled.
        </FormDescription>
      </Form>
    </Root>
  )
}
