import React from 'react'
import styled from 'styled-components'

const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`
const Label = styled.label`
  outline: 0;
  display: block;
  width: 44px;
  height: 26px;
  position: relative;
  cursor: pointer;
  user-select: none;
  background: #e2e2e2;
  border: 2px solid #ededed;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  &:after,
  &:before {
    position: relative;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
  }

  &:after {
    left: 0;
    border-radius: 50%;
    transition: all 0.2s ease;
    background: ${(p) => p.theme.orange};
    ${(p) =>
      p.checked &&
      `
      left: 50% 
    `}
  }

  &:before {
    display: none;
  }
`
export const CheckboxButton = (p) => (
  <Label checked={p.checked}>
    <Input onChange={p.onChange} checked={p.checked} />
  </Label>
)
