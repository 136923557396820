import React, { Component } from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import styled from 'styled-components'
import { compose, graphql } from 'react-apollo'
// import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import { GridCell } from './GridCell'
import { StageTypeSelectorPopup } from './StageTypeSelectorPopup'

import { CREATE_STAGE_MUTATION, CYCLE_SETTINGS_QUERY } from 'src/graphQL'
import { ClickOutside } from 'src/utils/click-outside'

const Root = styled.section`
  display: flex;
  flex-direction: column;
`

const GridRoot = styled.ul`
  position: relative;
  list-style-type: none;

  display: flex;
  flex-wrap: wrap;
  /* 1000px is a total width of 10 grid cells */
  max-width: 1000px;
`
const PopupWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const PositionedPopup = styled(StageTypeSelectorPopup)`
  position: absolute;
  top: ${(p) => `${p.top}px`};
  left: ${(p) => `${p.left}px`};
  transform: translate(-50%, -120%);

  z-index: 20;
`

export class DaysGridView extends Component {
  static propTypes = {
    days: PropTypes.arrayOf(
      PropTypes.shape({
        isFirstUnscheduled: PropTypes.bool.isRequired,
        stageId: PropTypes.string,
        hasWaterSchedule: PropTypes.bool.isRequired,
        hasLightSchedule: PropTypes.bool.isRequired,
        hasFanSchedule: PropTypes.bool.isRequired,
        date: momentPropTypes.momentObj
      }).isRequired
    ).isRequired,
    numberOfStages: PropTypes.number.isRequired
  }

  state = {
    mouseClickPosition: { x: null, y: null },
    selected: false,
    hoveredStageId: null,
    endDayNumber: null,
    firstUnscheduledDayNumber:
      this.props.days.findIndex((day) => day.isFirstUnscheduled) + 1
  }

  updatedEndDayNumber = (endDayNumber) =>
    this.setState((prev) => {
      if (prev.selected) return
      return { endDayNumber, hoveredStageId: null }
    })

  updateHoveredStageId = (hoveredStageId) =>
    this.setState((prev) => {
      if (prev.selected) return
      return { hoveredStageId, endDayNumber: null }
    })

  handleMouseEnter = (stageId, dayNumber) => {
    if (stageId) {
      return this.updateHoveredStageId(stageId)
    }

    return this.updatedEndDayNumber(dayNumber)
  }

  handleMouseLeave = (stageId) => {
    if (stageId) {
      return this.updateHoveredStageId(null)
    }

    return this.updatedEndDayNumber(null)
  }

  setSelection = () => this.setState({ selected: true })

  dropSelection = () => {
    this.setState({ selected: false, hoveredStageId: null, endDayNumber: null })
  }

  getClickPosition = (e) => {
    if (this.state.selected) return

    const bounds = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - bounds.left
    const y = e.clientY - bounds.top
    return this.setState({ mouseClickPosition: { x, y } })
  }

  handleRedirectToStage = async (tab) => {
    console.log(this.props)

    const {
      hoveredStageId,
      endDayNumber,
      firstUnscheduledDayNumber
    } = this.state
    const { history, match, createStage, numberOfStages } = this.props

    if (hoveredStageId) {
      return history.push(
        `${match.url}/growing-stage/${hoveredStageId}?scheduleType=${tab}`
      )
    }

    if (endDayNumber) {
      const durationInDays = endDayNumber - firstUnscheduledDayNumber + 1
      const orderNumber = numberOfStages + 1
      const name = `Day ${firstUnscheduledDayNumber} - Day ${endDayNumber}`
      const growingCycleId = match.params.cycleId

      try {
        const { data } = await createStage({
          variables: {
            durationInDays,
            orderNumber,
            name,
            growingCycleId
          },
          refetchQueries: [
            {
              query: CYCLE_SETTINGS_QUERY,
              variables: {
                id: match.params.cycleId
              }
            }
          ],
          awaitRefetchQueries: true
        })

        if (data.createGrowingStage.errors) {
          return toast.error('Error occured while creating new subschedule')
        }

        const stageId = data.createGrowingStage.growingStage.id
        return history.push(`${match.url}/subschedules/${stageId}`)
      } catch (error) {
        return toast.error('Something went wrong')
      }
    }
  }

  render() {
    const { days, className } = this.props
    const {
      hoveredStageId,
      endDayNumber,
      selected,
      firstUnscheduledDayNumber,
      mouseClickPosition
    } = this.state

    return (
      <Root className={className}>
        <ClickOutside onClickOutside={this.dropSelection}>
          <GridRoot onClick={this.getClickPosition}>
            {days.map((day, idx) => {
              const dayNumber = idx + 1

              const isInStage =
                hoveredStageId !== null && day.stageId === hoveredStageId

              const isWithtinNewSelection =
                dayNumber >= firstUnscheduledDayNumber &&
                dayNumber <= endDayNumber

              const withSelectionStyles = isInStage || isWithtinNewSelection

              return (
                <GridCell
                  key={idx}
                  dayNumber={dayNumber}
                  date={day.date.format('DD.MM.YYYY')}
                  dayOfWeek={day.date.format('ddd')}
                  withSelectionStyles={withSelectionStyles}
                  hasWaterSchedule={day.hasWaterSchedule}
                  hasLightSchedule={day.hasLightSchedule}
                  hasFanSchedule={day.hasFanSchedule}
                  onMouseOver={() =>
                    this.handleMouseEnter(day.stageId, dayNumber)
                  }
                  onMouseLeave={() => this.handleMouseLeave(day.stageId)}
                  onClick={this.setSelection}
                />
              )
            })}

            {selected && (
              <PopupWrapper onClick={this.dropSelection}>
                <PositionedPopup
                  left={mouseClickPosition.x}
                  top={mouseClickPosition.y}
                  onRedirect={this.handleRedirectToStage}
                  onClose={(e) => {
                    e.stopPropagation()
                    this.dropSelection()
                  }}
                  hasWaterSchedule={true}
                  hasLightSchedule={true}
                  hasFanSchedule={true}
                />
              </PopupWrapper>
            )}
          </GridRoot>
        </ClickOutside>
      </Root>
    )
  }
}

const enhance = compose(
  // withRouter,
  graphql(CREATE_STAGE_MUTATION, { name: 'createStage' })
)

export const DaysGrid = enhance(DaysGridView)
