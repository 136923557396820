import React from 'react'
import PropTypes from 'prop-types'

const AnalyticsIcon = ({ width, height }) => (
  <svg width={width} height={height}>
    <defs>
      <path
        d="M16.59 7.59l2.035-2.13a2.792 2.792 0 1 1 .99.708l-2.132 2.233a2.792 2.792 0 1 1-4.493-.087l-2.573-2.45a2.779 2.779 0 0 1-1.63.525c-.539 0-1.043-.153-1.47-.418L5.357 8.139a2.792 2.792 0 1 1-.868-.646l-.048-.039 2.052-2.267a2.792 2.792 0 1 1 4.73-.224l2.701 2.57a2.78 2.78 0 0 1 1.281-.31c.505 0 .978.133 1.386.367zm4.114-2.318a1.675 1.675 0 1 0 0-3.35 1.675 1.675 0 0 0 0 3.35zm-5.5 6.417a1.675 1.675 0 1 0 0-3.35 1.675 1.675 0 0 0 0 3.35zM8.787 5.272a1.675 1.675 0 1 0 0-3.35 1.675 1.675 0 0 0 0 3.35zm-5.5 6.417a1.675 1.675 0 1 0 0-3.35 1.675 1.675 0 0 0 0 3.35z"
        id="analytics__a"
      />
    </defs>
    <g transform="translate(0 6)" fillRule="evenodd">
      <mask id="analytics__b">
        <use xlinkHref="#analytics__a" />
      </mask>
      <use strokeWidth={0.2} xlinkHref="#analytics__a" />
      <g mask="url(#analytics__b)" className="_color">
        <path d="M-1-12h25v26H-1z" />
      </g>
    </g>
  </svg>
)

AnalyticsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

AnalyticsIcon.defaultProps = {
  width: 24,
  height: 24
}

export default AnalyticsIcon
