import gql from 'graphql-tag'

export const CYCLE_SETTINGS_QUERY = gql`
  query getCycle($id: ID!) {
    growing_cycle(id: $id) {
      id
      name
      duration_in_days
      start_at
      status

      temperature_alarm_configs {
        id
        duration_in_minutes
        precision_in_percents
        min_value
        max_value

        stage {
          id
          name
        }
      }

      humidity_alarm_configs {
        id
        duration_in_minutes
        precision_in_percents
        min_value
        max_value

        stage {
          id
          name
        }
      }

      co2_alarm_configs {
        id
        duration_in_minutes
        precision_in_percents
        min_value
        max_value

        stage {
          id
          name
        }
      }

      stages {
        id
        duration_in_days
        name

        hardware_configs {
          id
          name
          settings {
            id
          }
        }
      }
    }
  }
`
