import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LeafWetnessIcon from "src/components/Icons/LeafWetnessIcon";
import { ContentBox } from "src/components/ContentBox";

const MIN = -10;
const MAX = 3;
const ALERT_HYSTERESIS = 3;

const windowWidth = window.innerWidth;
const blockWidth = windowWidth < 1400 ? 50 : 25;

let current = 0;
let isUpDirection = true;

export function LeafWetnessSensor() {
  const { t } = useTranslation();
  const [sensor, setSensor] = useState({ signal: 55, defined: 63 });
  const [diff, setDiff] = useState(-8);
  const [alertType, setAlertType] = useState("error");

  useEffect(() => {
    setDiff(() => {
      const newDiff = sensor.defined - sensor.signal;

      if (Math.abs(newDiff) <= ALERT_HYSTERESIS) {
        return "OK";
      }

      return `${newDiff}%`;
    });
    setAlertType(() => {
      if (
        sensor.signal > sensor.defined + ALERT_HYSTERESIS ||
        sensor.signal < sensor.defined - ALERT_HYSTERESIS
      ) {
        return "error";
      }
      return "ok";
    });
  }, [sensor]);

  useEffect(() => {
    current = sensor.defined;

    const interval = setInterval(() => {
      if (isUpDirection && current < sensor.defined + MAX) {
        current = ++current;
        isUpDirection = true;
      }
      if (isUpDirection && current >= sensor.defined + MAX) {
        current = --current;
        isUpDirection = false;
      }
      if (!isUpDirection && current > sensor.defined - Math.abs(MIN)) {
        current = --current;
        isUpDirection = false;
      }
      if (!isUpDirection && current <= sensor.defined - Math.abs(MIN)) {
        current = ++current;
        isUpDirection = true;
      }

      setSensor((prevSensor) => ({ ...prevSensor, signal: current }));
    }, 6000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <ContentBox
      width={`${blockWidth}%`}
      height={"156px"}
      padding={12}
      title={t("leaf_wetness")}
      amount={`${sensor.signal}%`}
      totalAmount={diff}
      icon={<LeafWetnessIcon />}
      isLeftAmountPosition
      alertType={alertType}
    />
  );
}
