import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'

import { GlobalStyles } from 'src/theme/GlobalStyles'
import { client } from 'src/graphQL'
import * as theme from 'src/theme'
import { AppRoutes } from 'src/pages/appRoutes'
import {Layout} from 'src/components'

localStorage.setItem('token', '1M8fu2Gogc3anDJ-zEdH') //autologin added by AG-54

export const App = () => (
  <ApolloProvider client={client}>
    <ToastContainer/>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles/>

        <BrowserRouter>
          <Layout>
            <AppRoutes/>
          </Layout>
        </BrowserRouter>
      </>
    </ThemeProvider>
  </ApolloProvider>
)
