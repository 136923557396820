import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Header, AsideNav } from 'src/components/';

const asideNavPosition = css`
  flex-basis: 328px;
  flex-shrink: 0;
`;

const LayoutWrapper = styled.div`
  display: flex;
`;

const MainWrapper = styled.div`
  flex: 1 1;
  min-height: 100vh;
  max-width: calc(100% - 328px);
  display: flex;
  flex-direction: column;
`;

const Content = styled.main`
  flex-grow: 1;
  padding: 20px 8px 8px;
`;

export const Layout = ({ children }) => (
  <LayoutWrapper>
    <AsideNav css={asideNavPosition} />
    <MainWrapper>
      <Header alertsAmount={1} />
      <Content>{children}</Content>
    </MainWrapper>
  </LayoutWrapper>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
