import { css } from 'styled-components';

/* LIBRARY STYLESHEETS */
import 'rc-slider/assets/index.css'; // slider
import 'react-toastify/dist/ReactToastify.css'; // toastify
import 'react-html5video/dist/styles.css'; // videoplayer

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const ProximaNovaLight = css`
  font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
  font-weight: 300;
`;
export const ProximaNova = css`
  font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
  font-weight: 400;
`;
export const ProximaNovaBold = css`
  font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
  font-weight: 700;
`;

export const grayBg = '#F2F2F2';
export const mainGray = '#f8f8f8';
export const gray = '#eaeaea';
export const lightGrayAux = '#c2c2c2';
export const lightGray = '#f9f9f9';
export const textGray = '#6d6d6d';
export const textGrayAux = '#6b7280';
export const textDisable = '#BFC4CD';
export const textSecond = '#6B7280';
export const fontGray = '#4a4a4a';
export const darkGray = '#364652';
export const dropdownActiveGray = '#293C4A';
export const activeGray = '#233440';
export const white = '#ffffff';
export const orange = '#ff6600';
export const green = '#85b5ba';
export const lighterGreen = '#465761';
export const lightestGreen = '#bce3e7';
export const brightGreen = '#60aeb6';
export const mainGreen = '#198754';
export const black = '#000000';
export const red = '#d72f2f';
export const darkRed = '#cf0707';
export const lightGrayAlert = '#bfc4cd';
export const yieldGreen = '#58c08e';
export const lightBlue = '#def3f5';
export const vinousBorder = '#4f0404';
export const roundYellow = '#e5c374';
export const yellowBorder = '#b08c2f';
export const roundGreen = '#87c588';
export const greenBorder = '#398960';
export const blue = '#3253ad';
export const contentBoxErrorBorder = 'rgba(207, 7, 7, 0.2)'
export const contentBoxOkBorder = 'rgba(97, 203, 152, 0.2)'
