import React from 'react';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';

import { BaseTable } from 'src/components/index';

export const HeaderCell = styled.div`
  padding: 0 16px;
  span {
    ${(p) => p.theme.ProximaNova};
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Cell = styled.div``;

const TheadComponent = styled.div`
  width: 100%;
  min-width: 100% !important;
  min-height: 56px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 8px;

  .rt-tr {
    background: transparent;
  }
`;

const TbodyRoot = styled.div`
  width: 100%;
  min-width: 100% !important;
  flex-grow: 1;
  overflow-y: hidden;

  .rt-tr {
    background: #fff;
    border-radius: 4px;
  }
`;

const StyledScrollContainer = styled(Scrollbars)`
  display: flex;
`;

const ScrollTrack = styled.div`
  background-color: #efefef;
  width: 4px;
  border-radius: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  box-sizing: content-box;
`;

const ScrollThumb = styled.div`
  background: ${(p) => p.theme.orange};
  width: 4px;
  border-radius: 2px;
  padding: 2px 0;
`;

const TbodyComponent = ({ children, ...tableProps }) => (
  <TbodyRoot {...tableProps}>
    <StyledScrollContainer
      hideTracksWhenNotNeeded
      renderTrackVertical={() => <ScrollTrack />}
      renderThumbVertical={() => <ScrollThumb />}
    >
      {children}
    </StyledScrollContainer>
  </TbodyRoot>
);

const TrGroupComponent = styled.div`
  border-radius: 2px;
  margin-top: 2px;
  position: relative;
  padding: 0 30px;
  min-height: 70px;
  background-color: #fcfcfc;
  display: flex;
  align-items: center;

  :hover {
    background-color: ${(p) => p.theme.white};
  }
`;

const StyledBaseTable = styled(BaseTable)`
  &.ReactTable {
    padding: 0 12px;
    border: none;
    flex-grow: 1;
    ${(p) => p.theme.ProximaNovaSemibold};
    font-size: 14px;

    .rt-table {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .rt-body {
      height: 100%;
    }

    .rt-th {
      font-size: 18px;
      line-height: 24px;
      ${(p) => p.theme.ProximaNovaBold};
      color: ${(p) => p.theme.darkGray};
    }

    .rt-tr {
      width: 100%;

      &:hover {
        .rt-td {
          background: ${(p) => p.theme.white};
        }
      }
    }

    .rt-td {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 83px;
      padding: 0 16px;
      ${(p) => p.theme.ProximaNova};
      font-size: 18px;
      line-height: 22px;
      color: ${(p) => p.theme.darkGray};
      background: ${(p) => p.theme.white};

      :first-child,
      :last-child {
        border-radius: 4px;
      }
    }
  }
`;

export const CyclesBaseTable = (props) => (
  <StyledBaseTable
    TrGroupComponent={(props) => <TrGroupComponent {...props} />}
    TbodyComponent={(props) => <TbodyComponent {...props} />}
    TheadComponent={(props) => <TheadComponent {...props} />}
    pageSize={props.data.length}
    {...props}
  />
);
