import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Dot } from './Dot';

const Root = styled.div`
  position: relative;
  left: 5px;
  background-color: ${(p) => p.theme.darkGray};
  color: ${(p) => p.theme.white};
  font-size: 12px;
  max-width: 120px;
  padding: 10px;
  opacity: 0.85;
  transform: translateY(-50%);

  :before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 50%;
    right: calc(100% - 2px);
    transform: translate(0, -50%);
    border-width: 15px;
    border-style: solid;
    border-color: transparent;
    border-right-color: ${(p) => p.theme.darkGray};
  }
`;
const Text = styled.div`
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Tooltip = ({ x, y, datum, formatX, formatY }) => {
  return (
    <g>
      <Dot x={x} y={y} />
      <g transform={`translate(${x}, ${y})`}>
        <foreignObject x="20" width="100%" height="100%">
          <Root>
            <Text>{formatX(datum.x)}</Text>
            <Text>{formatY(datum.y)}</Text>
          </Root>
        </foreignObject>
      </g>
    </g>
  );
};

Tooltip.propTypes = {
  formatX: PropTypes.func.isRequired,
  formatY: PropTypes.func.isRequired,
};
