import React from "react";
import { useTranslation } from "react-i18next";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

export const WateringChart = ({ data }) => {
  const { t } = useTranslation();

  return (
    <LineChart
      width={760}
      height={363}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid />
      <XAxis dataKey="name" stroke="#BFC4CD" />
      <YAxis
        ticks={[0, 4, 8, 12, 16, 20, 24, 28]}
        dataKey="opt"
        tickCount={4}
        domain={[0, 28]}
        padding={{ top: 40 }}
        stroke="#BFC4CD"
      />
      <Tooltip />
      <Line
        type="monotone"
        dataKey={t("ai")}
        strokeDasharray="5 5"
        stroke="#BFC4CD"
        strokeWidth={2}
        dot={{ fill: "#BFC4CD" }}
        activeDot={false}
      />
      <Line
        type="monotone"
        dataKey={t("used_water_amount")}
        stroke="#3253AD"
        strokeWidth={2}
        dot={{ fill: "#3253AD" }}
        activeDot={false}
      />
    </LineChart>
  );
};
