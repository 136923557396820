import gql from 'graphql-tag'

export const CREATE_STAGE_MUTATION = gql`
  mutation(
    $name: String!
    $growingCycleId: ID!
    $durationInDays: Int!
    $orderNumber: Int!
  ) {
    createGrowingStage(
      name: $name
      growingCycleId: $growingCycleId
      durationInDays: $durationInDays
      orderNumber: $orderNumber
    ) {
      growingStage {
        id
        name
      }
      errors
    }
  }
`
