import React from 'react'
import PropTypes from 'prop-types'

const LaborIcon = ({ width, height }) => (
  <svg width={width} height={height}>
    <defs>
      <path
        d="M15.497 3.055a4.575 4.575 0 0 1 .011 6.468 4.57 4.57 0 0 1-6.465.011 4.575 4.575 0 0 1 3.225-7.813c1.21 0 2.372.48 3.229 1.334zm-3.214 5.766a2.48 2.48 0 1 0 0-4.961 2.48 2.48 0 0 0 0 4.961zm5.45 6.644c.866.863 1.452 2.121 1.758 3.775a1 1 0 0 1-.986 1.182l-12.852-.037a1 1 0 0 1-.982-1.178c.29-1.602.872-2.843 1.748-3.722a8 8 0 0 1 11.314-.02zM6.69 18.721c-.01.092 10.794.101 10.794.002-.003-1.541-2.144-3.758-5.336-3.792-3.192-.034-5.258 2.042-5.458 3.79z"
        id="labor__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="labor__b">
        <use xlinkHref="#labor__a" />
      </mask>
      <use className="_color" fillRule="nonzero" xlinkHref="#labor__a" />
      <g mask="url(#labor__b)" className="_color">
        <path d="M0 0h24v32H0z" />
      </g>
    </g>
  </svg>
)

LaborIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

LaborIcon.defaultProps = {
  width: 24,
  height: 24
}

export default LaborIcon
