import React from "react";
import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars";
import {transparentize} from 'polished'

import {columns, NoAlertsNotification} from './'

import {BaseTable} from "src/components/BaseTable";
import {useAlertsContext} from 'src/store'

const TbodyComponent = ({ children, ...tableProps }) => (
  <TbodyRoot {...tableProps}>
    <StyledScrollContainer
      hideTracksWhenNotNeeded
      renderTrackVertical={() => <ScrollTrack />}
      renderThumbVertical={() => <ScrollThumb />}
    >
      {children}
    </StyledScrollContainer>
  </TbodyRoot>
);

export const Table = () => {
  const {alerts} = useAlertsContext()

  return (alerts && alerts.length > 0
    ? <StyledTable
      TbodyComponent={TbodyComponent}
      pageSize={alerts.length}
      data={alerts}
      columns={columns}
    />
    : <NoAlertsNotification/>
  )
}

const StyledTable = styled(BaseTable)`
  &.ReactTable {
    border: none;
    flex-grow: 1;

    .rt-th {
      font-size: 18px;
      line-height: 24px;
      ${(p) => p.theme.ProximaNovaBold};
      color: ${(p) => p.theme.darkGray};
    }

    .rt-table {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .rt-resizable-header-content {
      position: relative;
      display: inline;

      &::before {
        content: '';
        display: none;
        position: absolute;
        left: calc(100% + 15px);
        top: 50%;
        width: 2px;
        height: 7px;
        background: ${(p) => p.theme.orange};
        border-radius: 2px;
        transform: rotate(-45deg) translateY(-50%);
      }

      &::after {
        content: '';
        display: none;
        position: absolute;
        left: calc(100% + 14px);
        top: 50%;
        width: 2px;
        height: 7px;
        background: ${(p) => p.theme.orange};
        border-radius: 2px;
        transform: rotate(45deg) translateY(-50%);
      }
    }

    .-sort-asc,
    .-sort-desc {
      color: ${(p) => p.theme.orange};
      box-shadow: none !important;

      .rt-resizable-header-content {
        position: relative;
        display: inline;

        &::before,
        &::after {
          display: block;
        }
      }
    }

    .rt-thead.-header {
      box-shadow: none;
    }

    .rt-th {
      padding: 12px 16px 3px;
      text-align: left;
      outline: none;
      ${(p) => p.theme.ProximaNovaSemibold};
      font-size: 18px;
      line-height: 24px;

      &:first-child {
        padding-left: 0;
      }
    }

    .rt-tr-group {
      width: calc(100% - 8px);
    }

    .rt-tr {
      width: 100%;
      margin-bottom: 8px;

      &:hover {
        .rt-td {
          background: ${(p) => p.theme.white};
        }
      }
    }

    .rt-td {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 86px;
      ${(p) => p.theme.ProximaNova};
      font-size: 18px;
      line-height: 22px;
      color: ${(p) => p.theme.darkGray};
      background: ${(p) => p.theme.white};
      border-radius: 4px;
    }

    .pagination-bottom,
    .rt-resizer {
      display: none;
    }

    *:not(button) {
      border: none !important;
    }
  }
`;

const TbodyRoot = styled.div`
  height: 100%;
  overflow-y: hidden;
  flex-grow: 1;
`;

const StyledScrollContainer = styled(Scrollbars)`
  margin-top: 12px;
  display: flex;
`;

const ScrollTrack = styled.div`
  background-color: ${(p) => p.theme.gray};
  width: 4px;
  border-radius: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  box-sizing: content-box;
  border-left: 15px solid ${(p) => p.theme.mainGray};
`;

const ScrollThumb = styled.div`
  background: ${(p) => transparentize(0.3, p.theme.orange)};
  width: 4px;
  border-radius: 2px;
  padding: 2px 0;
`;