import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { transparentize } from 'polished'
// import { withRouter } from 'react-router-dom'

import { CalendarIcon } from 'src/components/Icons'

const Root = styled.div`
  display: flex;
`

const DateInputWrapper = styled.div`
  margin-left: 20px;
`

const LabelText = styled.div`
  ${(p) => p.theme.ProximaNovaSemibold}
  font-size: 16px;
  color: ${(p) => transparentize(0.4, p.theme.black)};
  margin-bottom: 30px;
`

const DurationText = styled.div`
  background-color: ${(p) => p.theme.white};
  height: 50px;
  width: 110px;
  border: none;
  border-radius: 2px;
  padding: 15px;

  display: flex;
  justify-content: space-between;
`

const StartAtText = styled.div`
  height: 50px;
  width: 230px;
  background-color: ${(p) => p.theme.white};
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
`

const IconWrapper = styled.div`
  svg path {
    fill: ${(p) => p.theme.green};
  }
`

const CycleFieldsStaticView = ({ className, durationInDays, startAt }) => {
  return (
    <Root className={className}>
      <div>
        <LabelText>Days</LabelText>
        <DurationText>{durationInDays}</DurationText>
      </div>

      <DateInputWrapper>
        <div>
          <LabelText>Start date</LabelText>
          <StartAtText>
            {moment.unix(startAt).format('DD MMM., YYYY')}
            <IconWrapper>
              <CalendarIcon />
            </IconWrapper>
          </StartAtText>
        </div>
      </DateInputWrapper>
    </Root>
  )
}

CycleFieldsStaticView.propTypes = {
  className: PropTypes.string,
  durationInDays: PropTypes.number.isRequired,
  startAt: PropTypes.number.isRequired
}

export const CycleFieldsStatic = CycleFieldsStaticView
