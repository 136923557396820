import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

const Root = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 12px;
  background-color: ${(p) => p.theme.lightGray};
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    background-color: ${(p) => darken(0.05, p.theme.lightGray)};
  }

  ${(p) =>
    p.selected &&
    `
    background-color: ${darken(0.05, p.theme.lightGray)};
  `};
`

const Name = styled.div`
  font-size: 14px;
`

const Identifier = styled.div`
  font-size: 9px;
`

const DaysLeftNum = styled.div`
  font-size: 22px;
`

const DaysLeft = styled.div`
  font-size: 9px;
`

const Column = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const GrowingCyclesItem = ({
  name,
  id,
  daysLeft,
  onSelect,
  selected
}) => {
  return (
    <Root selected={selected} onClick={onSelect}>
      <Column>
        <Name>{name}</Name>
        <Identifier>ID {id}</Identifier>
      </Column>

      <Column>
        <DaysLeftNum>{daysLeft}</DaysLeftNum>
        <DaysLeft>Days left</DaysLeft>
      </Column>
    </Root>
  )
}
