import React, { Fragment, useState } from "react";
import styled, { css } from "styled-components";
import { Query } from "react-apollo";
import { camelizeKeys } from "humps";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { FiltersForm, NewCycleForm } from "src/components/growthCycles";
import { PlannedCyclesTable } from "src/components/growthCycles/Planned/PlannedCyclesTable";
import { growingCyclesStub } from "src/components/growthCycles/Planned/DataStubs";
import { Toggler } from "src/utils/toggler";
import { Button, PageHeader } from "src/ui";
import { GROWING_CYCLES_QUERY } from "src/graphQL";
import { Spinner, Modal } from "src/components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const tablePosition = css`
  width: 100%;
  flex-grow: 1;
`;

const NewCycleBtn = styled(Button)`
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  padding: 12px 24px;
`;

const QUERY_VARS = {
  status: "planned",
  cropId: null,
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
};

const isStubUsing = true;

export const GrowthCyclesPlanned = () => {
  const { t } = useTranslation();
  const [stubData, setStubData] = useState(growingCyclesStub);

  return (
    <Query query={GROWING_CYCLES_QUERY} variables={QUERY_VARS}>
      {({ loading, error, data }) => {
        if (error) {
          toast.error(t("loading_cycles_error"));
          return null;
        }

        const growingCycles = isStubUsing
          ? stubData
          : camelizeKeys(data.growing_cycles);

        return (
          <Root>
            <PageHeader title="Planned" subTitle="Growth cycles">
              <ActionsWrapper>
                <Toggler>
                  {({ toggled, onToggle }) => (
                    <Fragment>
                      <NewCycleBtn
                        inset
                        type="button"
                        onClick={() => onToggle(true)}
                      >
                        NEW GROWING CYCLE
                      </NewCycleBtn>

                      <Modal isOpen={toggled} onClose={() => onToggle(false)}>
                        <NewCycleForm afterRequest={() => onToggle(false)} />
                      </Modal>
                    </Fragment>
                  )}
                </Toggler>
                <FiltersForm
                  stubData={growingCyclesStub}
                  setStubData={setStubData}
                />
              </ActionsWrapper>
            </PageHeader>

            {loading && <Spinner fullwindow />}
            <PlannedCyclesTable
              css={tablePosition}
              data={loading ? [] : growingCycles}
            />
          </Root>
        );
      }}
    </Query>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
