import React from 'react'
import PropTypes from 'prop-types'

const FarmMapIcon = ({ width, height }) => (
  <svg width={width} height={height}>
    <defs>
      <path
        d="M6.844 13.975H5.186v.433c0 .237-.285.574-.73.574-.444 0-.703-.337-.703-.574v-.974a.86.86 0 0 1 .86-.86H19.392a.86.86 0 0 1 .86.86v1.218c0 .237-.321.53-.734.53s-.745-.293-.745-.53v-.659l-6.342-.005H6.93a.297.297 0 0 1-.085-.013zM16.42 2.007c.71 0 1.288.578 1.288 1.288v6.526a1.29 1.29 0 0 1-1.288 1.289H7.575A1.29 1.29 0 0 1 6.287 9.82V3.295c0-.71.578-1.288 1.288-1.288h8.845zm-.003 7.364V3.724a.43.43 0 0 0-.43-.43H8.125a.43.43 0 0 0-.43.43v5.647c0 .237.193.43.43.43h7.862a.43.43 0 0 0 .43-.43zm5.295 6.545c.71 0 1.288.578 1.288 1.288v3.778a1.29 1.29 0 0 1-1.288 1.288h-3.779a1.29 1.29 0 0 1-1.288-1.288v-3.778c0-.71.578-1.288 1.288-1.288h3.779zm.093 4.686V17.58a.43.43 0 0 0-.43-.43h-3.048a.43.43 0 0 0-.43.43v3.022c0 .237.193.43.43.43h3.049a.43.43 0 0 0 .43-.43zm-7.877-4.686c.71 0 1.288.578 1.288 1.288v3.778a1.29 1.29 0 0 1-1.288 1.288H10.15a1.29 1.29 0 0 1-1.288-1.288v-3.778c0-.71.578-1.288 1.288-1.288h3.778zm.094 4.686V17.58a.43.43 0 0 0-.43-.43h-3.049a.43.43 0 0 0-.43.43v3.022c0 .237.193.43.43.43h3.05a.43.43 0 0 0 .429-.43zm-7.89-4.686c.71 0 1.289.578 1.289 1.288v3.778a1.29 1.29 0 0 1-1.289 1.288H2.354a1.29 1.29 0 0 1-1.288-1.288v-3.778c0-.71.578-1.288 1.288-1.288h3.778zm.094 4.686V17.58a.43.43 0 0 0-.43-.43H2.747a.43.43 0 0 0-.43.43v3.022c0 .237.194.43.43.43h3.05a.43.43 0 0 0 .429-.43z"
        id="FarmMapIcon__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="FarmMapIcon__b">
        <use xlinkHref="#FarmMapIcon__a" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#FarmMapIcon__a" />
      <g mask="url(#FarmMapIcon__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

FarmMapIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

FarmMapIcon.defaultProps = {
  width: 24,
  height: 24
}

export default FarmMapIcon
