import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import Slider from 'rc-slider'

const Root = styled.div`
  display: flex;
  align-items: center;

  user-select: none;

  .rc-slider-handle {
    border-radius: 2px;
    width: 30px;
    height: 30px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(179, 177, 177, 0.5);

    margin-left: -15px;
    margin-top: 0;
    :focus,
    :active {
      box-shadow: 0 2px 4px 0 rgba(179, 177, 177, 0.5);
      background: #70a3a8;
    }
  }

  .rc-slider-track {
    height: 1px;
    background-color: ${() => transparentize(0.8, '#979797')};
  }

  .rc-slider-rail {
    height: 1px;
    background-color: ${() => transparentize(0.8, '#979797')};
  }
`

const StyledSlider = styled(Slider)`
  display: flex;
  align-items: center;
`

const SliderTitle = styled.div`
  text-transform: capitalize;
  width: 100px;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  flex-shrink: 0;

  ${(p) => p.theme.ProximaNova};
`

const SliderInput = styled.input`
  width: 30px;
  color: inherit;
  margin-left: 20px;
  border: none;
  outline: none;
`

export const SliderField = ({
  className,
  onChange,
  name,
  value,
  units,
  min,
  max
}) => (
  <Root className={className}>
    <SliderTitle>{`${name} ${units}`}</SliderTitle>
    <StyledSlider
      min={min}
      max={max}
      value={value}
      onChange={(newValue) => onChange(newValue, name)}
    />
    <SliderInput
      maxLength={3}
      name={name}
      onChange={(e) => onChange(+e.target.value, e.target.name)}
      value={value}
    />
  </Root>
)

SliderField.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  units: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number
}

SliderField.defaultProps = {
  min: 0,
  max: 100,
  units: ''
}
