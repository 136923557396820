import React from 'react'
import styled from 'styled-components'
import { Form, Field, FormSpy } from 'react-final-form'
import moment from 'moment'
import { Mutation } from 'react-apollo'
import diff from 'object-diff'
import humps from 'humps'
import { toast } from 'react-toastify'
import {useParams} from 'react-router-dom'

import { UPDATE_CYCLE_MUTATION } from 'src/graphQL'
import { DatePicker, SelectDurationInput } from 'src/components'
import {FillLeafIcon} from 'src/components/Icons'

const StartAtDatePicker = styled(DatePicker)`
  height: 50px;
  width: 212px;
  background-color: ${(p) => p.theme.white};
  border-radius: 2px;

  svg path {
    fill: ${(p) => p.theme.green};
  }
`

const Root = styled.form`
  display: flex;
  background-color: ${p => p.theme.white};
`

const DateInputWrapper = styled.div`
  margin-right: 20px;
`

// returns a function that checks if new values are different from previous,
// and if so, sends mutation  to server, updating the cycle
const createChangeSpy = (updateGrowingCycle, cycleId) => {
  let prevValues = null

  return async ({ values }) => {
    if (prevValues !== null) {
      if (values.startAt) {
        // transform startAt momentObj to unix timestamp
        values.startAt = moment(values.startAt).unix()
      }

      const difference = diff(prevValues, values)

      if (Object.keys(difference) === 0) return

      const { data } = await updateGrowingCycle({
        variables: { ...difference, id: cycleId },
        optimisticResponse: {
          updateGrowingCycle: {
            __typename: 'UpdatePayload',
            errors: null,
            growingCycle: {
              __typename: 'GrowingCycle',
              id: cycleId,
              ...humps.decamelizeKeys(values)
            }
          }
        }
      })

      if (data.updateGrowingCycle.errors) {
        const errors = JSON.parse(data.updateGrowingCycle.errors)

        return Object.values(errors).forEach((errors) =>
          errors.forEach((error) => toast.error(error))
        )
      }

      return toast.success('Growing cycle update successfully')
    }

    prevValues = values
  }
}

const CycleFieldsFormView = ({ className, durationInDays, startAt }) => {
  const {cycleId} = useParams()

  return (
    <Mutation mutation={UPDATE_CYCLE_MUTATION}>
      {(updateGrowingCycle) => (
        <Form
          initialValues={{
            durationInDays,
            startAt: moment.unix(startAt).toDate()
          }}
          subscription={{}}
          onSubmit={() => {}}
        >
          {({ handleSubmit }) => (
            <Root onSubmit={handleSubmit} className={className}>
              <FormSpy
                subscription={{ values: true }}
                onChange={createChangeSpy(
                  updateGrowingCycle,
                  cycleId
                )}
              />
              <Field name="startAt">
                {({ input }) => (
                  <DateInputWrapper>
                    <StartAtDatePicker
                      displayFormat="DD MMM., YYYY"
                      pickerPosition="bottom"
                      value={input.value}
                      onChange={input.onChange}
                    />
                  </DateInputWrapper>
                )}
              </Field>
              <Field name="durationInDays">
                {({ input }) => (
                  <label>
                    <SelectDurationInput
                      units="day"
                      icon={<FillLeafIcon />}
                      listLength={120}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  </label>
                )}
              </Field>
            </Root>
          )}
        </Form>
      )}
    </Mutation>
  )
}

export const CycleFieldsForm = CycleFieldsFormView
