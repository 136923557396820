import React from 'react'
import PropTypes from 'prop-types'

const SearchIcon = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard"
        transform="translate(-1184.000000, -42.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="musica-searcher" transform="translate(1184.000000, 42.000000)">
          <path
            d="M13.6573716,12.0028445 L10.6159433,8.96147208 C10.5997795,8.94530826 10.5808191,8.93401036 10.5639282,8.91896513 C11.1623813,8.01116207 11.5116654,6.92449423 11.5116654,5.75588865 C11.5116654,2.57698392 8.93468152,0 5.75583272,0 C2.57698392,0 0,2.57698392 0,5.75583272 C0,8.93462559 2.57692799,11.5116654 5.75577679,11.5116654 C6.9244383,11.5116654 8.01105021,11.1623813 8.91885327,10.5639282 C8.93389849,10.5807632 8.94514046,10.5997235 8.96130429,10.6158874 L12.0027885,13.6573716 C12.4596823,14.1142095 13.2004219,14.1142095 13.6573716,13.6573716 C14.1142095,13.2004778 14.1142095,12.4597382 13.6573716,12.0028445 Z M5.75583272,9.51629966 C3.67892071,9.51629966 1.99530985,7.8326888 1.99530985,5.75583272 C1.99530985,3.67892071 3.67897664,1.99530985 5.75583272,1.99530985 C7.83263287,1.99530985 9.51629966,3.67897664 9.51629966,5.75583272 C9.51629966,7.8326888 7.83263287,9.51629966 5.75583272,9.51629966 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
)

SearchIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

SearchIcon.defaultProps = {
  width: 14,
  height: 14
}

export default SearchIcon
