import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { CyclesBaseTable, HeaderCell, Cell } from '../index';

import { SettingsIcon } from 'src/components/Icons';

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;

  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const IconLink = styled(Link)`
  display: block;
  width: 20px;

  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    fill: ${(p) => p.theme.textGrayAux};
    transition: 0.2s;
  }

  :hover svg {
    fill: ${(p) => p.theme.orange};
    transition: 0.2s;
  }
`;

const RowRoot = styled.div`
  width: calc(100% - 8px);
  position: relative;

  border-radius: 4px;
  min-height: 83px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const Row = ({ children, ...props }) => (
  <RowRoot {...props}>{children}</RowRoot>
);

const SettingIcon = (value) => (
  <IconWrapper>
    <IconLink to={`${location.pathname}/${value.original.id}/settings/`}>
      <SettingsIcon />
    </IconLink>
  </IconWrapper>
);

const PlannedCyclesTableView = ({ data, className, match }) => (
  <CyclesBaseTable
    TrGroupComponent={Row}
    // getTrGroupProps={(_, { original }) => ({ original })}
    className={className}
    data={data}
    defaultSorted={[{
      id: 'endAt',
      desc: true
    }]}
    columns={[
      {
        accessor: 'name',
        Header: <HeaderCell>Name</HeaderCell>,
        width: 465,
        Cell: ({ value }) => <Cell>{value}</Cell>,
      },
      {
        accessor: 'crop',
        Header: <HeaderCell>Crop</HeaderCell>,
        width: 445,
        Cell: ({ value }) => <Cell>{value.name}</Cell>,
      },
      {
        accessor: 'durationInDays',
        Header: (
          <HeaderCell>
            Duration <span>days</span>
          </HeaderCell>
        ),
        width: 160,
        Cell: ({ value }) => <Cell>{value}</Cell>,
      },
      {
        accessor: 'startAt',
        Header: <HeaderCell>Start date</HeaderCell>,
        width: 153,
        Cell: ({ value }) => (
          <Cell>{moment.unix(value).format('DD.MM.YY')}</Cell>
        ),
      },

      {
        Header: <HeaderCell>End date</HeaderCell>,
        accessor: 'endAt',
        width: 130,
        Cell: ({ value }) => (
          <Cell>{moment.unix(value).format('DD.MM.YY')}</Cell>
        ),
      },
      {
        Header: '',
        accessor: 'settings',
        width: 140,
        Cell: (value) => SettingIcon(value, match),
      },
    ]}
  />
);

export const PlannedCyclesTable = PlannedCyclesTableView
