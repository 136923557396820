import React from 'react';

const NewIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM6.5 11H5.3L2.75 7.5V11H1.5V5H2.75L5.25 8.5V5H6.5V11ZM11.5 6.26H9V7.38H11.5V8.64H9V9.75H11.5V11H7.5V5H11.5V6.26ZM18.5 10C18.5 10.55 18.05 11 17.5 11H13.5C12.95 11 12.5 10.55 12.5 10V5H13.75V9.51H14.88V5.99H16.13V9.5H17.25V5H18.5V10Z" fill="#FF6600"/>
  </svg>
)

export default NewIcon

