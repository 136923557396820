import moment from "moment";

const GROWING_CYCLE = 'GrowingCycle'
const COMPLETED = 'Completed'
const CROP = 'Crop'
const MAX_LIMIT_DAYS = 40

const randomDates = function() {
  const randomStartAt = moment().subtract(Math.floor((Math.random() * 365 - MAX_LIMIT_DAYS) + MAX_LIMIT_DAYS + 1), 'days')

  this.currentDayNumber = null
  this.durationInDays = Math.floor(Math.random() * (MAX_LIMIT_DAYS - 8) + 8)
  this.startAt = randomStartAt.unix()
  this.endAt = moment(randomStartAt).add(this.durationInDays, 'days').unix()

  delete this.init

  return this
}

export const growingCyclesStub = [
  {
    init: randomDates,
    crop: {
      id: '2',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "12",
    name: "Boston Lettuce GC #3",
    status: COMPLETED,
    typename: GROWING_CYCLE,
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '2',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "13",
    name: "Boston Lettuce GC #4",
    status: COMPLETED,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '2',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "14",
    name: "Boston Lettuce GC #5",
    status: COMPLETED,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '2',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "15",
    name: "Boston Lettuce GC #6",
    status: COMPLETED,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '2',
      name: 'Boston Lettuce',
      typename: CROP
    },
    id: "16",
    name: "Boston Lettuce GC #7",
    status: COMPLETED,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '1',
      name: 'Butterhead Lettuce',
      typename: CROP
    },
    id: "17",
    name: "Butterhead Lettuce GC #8",
    status: COMPLETED,
    typename: GROWING_CYCLE
  }.init(),
  {
    init: randomDates,
    crop: {
      id: '1',
      name: 'Butterhead Lettuce',
      typename: CROP
    },
    id: "18",
    name: "Butterhead Lettuce GC #9",
    status: COMPLETED,
    typename: GROWING_CYCLE
  }.init(),
];