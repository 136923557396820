import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

import { AlertAIAnalyticsChart } from "./";

import { Button } from "src/ui";
import { useAlertsContext } from "src/store";

export const AlertAIAnalytics = () => {
  const {
    alerts,
    analytics: { alertId, data: analyticsData },
    resolveAlert,
    closeModal,
  } = useAlertsContext();
  const { t } = useTranslation();

  const alert = alerts.find((alert) => alert.id === alertId);

  const onClick = () => {
    resolveAlert(alertId);
    closeModal();
  };

  return alert &&
    !alert.resolved &&
    analyticsData &&
    analyticsData.length > 0 ? (
    <Root>
      {analyticsData.map((article) =>
        article.id === "chart" ? (
          <AlertAIAnalyticsChart key={article.id} data={article.data} />
        ) : (
          <Article key={article.id}>
            <Data>
              <Trans
                i18nKey={article.transId}
                components={{
                  strong: <strong />,
                  ol: <ol />,
                  li: <li />,
                }}
              />
            </Data>
          </Article>
        )
      )}
      <ResolveBtn inset onClick={onClick}>
        {t("resolve")}
      </ResolveBtn>
    </Root>
  ) : (
    <></>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 1006px;
  height: min-content;
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 59px;
`;
const Article = styled.div`
  display: flex;
`;
const Data = styled.div`
  color: #364652;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;
  ol {
    padding: 0 0 0 16px;
  }
`;
const ResolveBtn = styled(Button)`
  ${(p) => p.theme.ProximaNova};
  font-size: 18px;
  line-height: 24px;
  padding: 13px 24px;
  align-self: flex-end;
`;
