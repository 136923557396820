import React from 'react'

const ScheduleSettingIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMin"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.937 8.89c-.031-.282-.359-.493-.642-.493-.917 0-1.73-.538-2.071-1.37a2.227 2.227 0 0 1 .56-2.473.555.555 0 0 0 .06-.754 9.895 9.895 0 0 0-1.584-1.6.557.557 0 0 0-.76.062c-.596.66-1.667.905-2.495.56A2.221 2.221 0 0 1 11.655.65a.555.555 0 0 0-.491-.584A9.984 9.984 0 0 0 8.914.06a.556.556 0 0 0-.495.572 2.225 2.225 0 0 1-1.37 2.133c-.817.334-1.88.091-2.475-.563a.558.558 0 0 0-.755-.064 9.933 9.933 0 0 0-1.617 1.6.556.556 0 0 0 .06.76c.695.63.92 1.633.558 2.495-.345.822-1.198 1.352-2.175 1.352a.544.544 0 0 0-.578.49 10.017 10.017 0 0 0-.004 2.275c.031.282.369.491.655.491.871-.022 1.707.517 2.058 1.37.35.853.125 1.847-.56 2.474a.556.556 0 0 0-.06.753c.465.592.998 1.13 1.582 1.6.23.185.563.16.761-.06.598-.661 1.67-.906 2.493-.56a2.218 2.218 0 0 1 1.353 2.17.555.555 0 0 0 .49.584 9.94 9.94 0 0 0 2.25.006.557.557 0 0 0 .496-.572 2.223 2.223 0 0 1 1.368-2.133c.823-.336 1.882-.09 2.477.563a.559.559 0 0 0 .754.064 9.956 9.956 0 0 0 1.618-1.6.555.555 0 0 0-.06-.76 2.216 2.216 0 0 1-.56-2.495 2.239 2.239 0 0 1 2.046-1.355l.124.003a.557.557 0 0 0 .585-.49c.088-.752.09-1.517.004-2.274zM10 15.556A5.562 5.562 0 0 1 4.444 10 5.562 5.562 0 0 1 10 4.444 5.562 5.562 0 0 1 15.556 10 5.562 5.562 0 0 1 10 15.556z"
      fill="#C2C2C2"
    />
  </svg>
)

export default ScheduleSettingIcon
