import gql from 'graphql-tag'

export const CREATE_CYCLE_MUTATION = gql`
  mutation(
    $cropId: ID!
    $growingSpaceId: ID!
    $durationInDays: Int!
    $name: String!
    $startAt: Int!
  ) {
    createGrowingCycle(
      cropId: $cropId
      growingSpaceId: $growingSpaceId
      name: $name
      startAt: $startAt
      durationInDays: $durationInDays
    ) {
      growingCycle {
        id
        name
      }
      errors
    }
  }
`
