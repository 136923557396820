import React from 'react'
import styled from 'styled-components'

import { CheckBox } from 'src/ui'

const Label = styled.label`
  display: flex;
  cursor: pointer;
  flex-basis: 50%;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 30px;
  }
`

const HiddenInput = styled.input`
  display: none;
`

const ColorDot = styled.span`
  margin-right: 8px;
  margin-left: 20px;
  background-color: ${(p) => p.dotColor};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`

export const CompareCheckBoxesItem = ({ name, checked, onChange, color }) => {
  return (
    <Label>
      <HiddenInput type="checkbox" onChange={onChange} />
      <CheckBox checked={checked} />
      <ColorDot dotColor={color} />
      {name}
    </Label>
  )
}
