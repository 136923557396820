import gql from 'graphql-tag'

export const UPDATE_CYCLE_MUTATION = gql`
  mutation($id: ID!, $name: String, $startAt: Int, $durationInDays: Int) {
    updateGrowingCycle(
      id: $id
      name: $name
      startAt: $startAt
      durationInDays: $durationInDays
    ) {
      errors
      growingCycle {
        id
        duration_in_days
        start_at
      }
    }
  }
`
