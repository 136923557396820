import React from 'react'

export const Dot = ({ x, y }) => {
  return (
    <g>
      <circle
        cx={x}
        cy={y}
        r={8}
        style={{ height: 8, width: 8, fillOpacity: 0.3, fill: '#ff5200' }}
      />
      <circle
        cx={x}
        cy={y}
        r={4}
        style={{ height: 8, width: 8, fill: '#ff5200' }}
      />
    </g>
  )
}
