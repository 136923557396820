import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql, compose } from 'react-apollo'
import {sum} from 'lodash'

import { SliderField } from './SliderField'

import { SelectDurationInput } from 'src/components'
import { Button } from 'src/ui'
import { setPhotofyrlMutation } from 'src/graphQL/mutations/setPhotofyrlMutation'

const MAX_TOTAL = 300;

const StyledSlider = styled(SliderField)`
  margin-bottom: 20px;
`;

const TotalSlider = styled(SliderField)`
  margin-top: 40px;
  color: ${(p) => p.theme.orange};
`;

const ApplyButton = styled(Button)`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  width: 100%;
  max-width: 600px;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  min-width: 560px;
  padding: 70px 70px 50px;
`;

const Header = styled.header`
  margin-bottom: 45px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Element = styled.div`
  ${(p) => p.theme.ProximaNova}
  font-size: 14px;
`;

const LeftBlock = styled.div`
  width: 50%;
`;

const RightBlock = styled.div`
  width: 50%;
  text-align: right;
  ${(p) => p.theme.ProximaNova}
  font-size: 14px;
`;

const Heading = styled.h4`
  margin-top: 10px;
  ${(p) => p.theme.ProximaNovaBold}
  font-size: 22px;
  color: ${(p) => p.theme.darkGray};
`;

const Time = styled.div`
  color: #7fbac0;
  ${(p) => p.theme.ProximaNovaSemibold}
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const ListWrapper = styled.div`
  margin-top: 50px;
`;

const FormRoot = styled.form`
  width: 100%;
`;

const Line = styled.div`
  margin: 35px 0;
  width: 100%;
  height: 1px;
  opacity: 0.06;
  background: #979797;
`;

const StyledSelect = styled(SelectDurationInput)`
  background-color: ${(p) => p.theme.lightGray};
  margin-left: 10px;
`;

const extractChannelKeys = ({ UV, Blue, Green, HyperRed, FarRed, White }) =>
  Object.keys({
    UV,
    Blue,
    Green,
    HyperRed,
    FarRed,
    White,
  });

export class PhytophySettingsView extends Component {
  static propTypes = {
    onApply: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.any.isRequired,
      UV: PropTypes.number.isRequired,
      Blue: PropTypes.number.isRequired,
      Green: PropTypes.number.isRequired,
      HyperRed: PropTypes.number.isRequired,
      FarRed: PropTypes.number.isRequired,
      White: PropTypes.number.isRequired,
      Total: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired,
    }),
  };

  static defaultProps = {
    initialValues: {
      id: Date.now(),
      UV: 0,
      Blue: 0,
      Green: 0,
      HyperRed: 0,
      FarRed: 0,
      White: 0,
      Total: 0,
      duration: 1,
    },
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line no-unused-vars
    const { id, duration, Total, ...channels } = props.initialValues;

    this.state = {
      duration,
      channels,
      Total,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { id } = this.props.initialValues;
    const { channels, duration, Total } = this.state;

    this.props.onApply({ ...channels, id, duration, Total });
  };

  onSliderChange = (value, name) => {
    const { channels } = this.state;

    // prevent UV slider from changes
    if (name === 'UV') return;

    let newTotal = sum(Object.values({ ...channels, [name]: value }));
    let newValue = value;

    if (newTotal > MAX_TOTAL) {
      const diff = newTotal - MAX_TOTAL;

      newValue -= diff;
      newTotal -= diff;
    }

    return this.setState((prev) => ({
      ...prev,
      Total: newTotal,
      channels: {
        ...prev.channels,
        [name]: newValue,
      },
    }));
  };

  onDurationChange = (value) => this.setState({ duration: value });

  render() {
    const { channels, duration, Total } = this.state;

    return (
      <Root>
        <FormRoot onSubmit={this.onSubmit}>
          <Header>
            <LeftBlock>
              <Element>Phytophy #768900</Element>
              <Heading>Settings</Heading>
            </LeftBlock>
            <RightBlock>
              Choose the duration the given perioid will last
            </RightBlock>
          </Header>

          <Time>
            Duration:
            <StyledSelect
              units="h"
              listLength={23}
              value={duration}
              onChange={this.onDurationChange}
            />
          </Time>

          <Line />

          <ListWrapper>
            {extractChannelKeys(channels).map((name) => (
              <StyledSlider
                name={name}
                units="(nm.)"
                key={name}
                value={channels[name]}
                onChange={this.onSliderChange}
              />
            ))}
          </ListWrapper>

          <TotalSlider
            name="total"
            max={300}
            value={Total}
            onChange={() => {}}
          />
          <ApplyButton>APPLY</ApplyButton>
        </FormRoot>
      </Root>
    );
  }
}

const enhance = compose(graphql(setPhotofyrlMutation));

export const PhytophySettings = enhance(PhytophySettingsView);
