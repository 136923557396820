import React from "react";
import styled from "styled-components";
import { DefaultPlayer as Video } from "react-html5video";
import { useTranslation } from "react-i18next";

import { H4 } from "src/ui";

const Root = styled.section`
  padding: 30px;
  background-color: ${(p) => p.theme.white};
`;

const Title = styled(H4)`
  font-size: 22px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Location = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.fontGray};
`;

const WebcamsList = styled.ul`
  list-style-type: none;
`;

export const CropWebcam = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Header>
        <Title>{t("webcam")}</Title>
        <Location>Rack #167, Cam3</Location>
      </Header>
      <WebcamsList>
        <Video
          autoPlay
          loop
          muted
          controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
        >
          <source src="/video.mp4" type="video/webm" />
        </Video>
      </WebcamsList>
    </Root>
  );
};
