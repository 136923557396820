import React from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {DropdownArrow} from 'src/components/Icons'

export const PageHeader = ({
  title,
  subTitle,
  isBackButton,
  isThreeFourWidth,
  children,
}) => (
  <Root width={isThreeFourWidth ? '75%' : '100%'}>
    {isBackButton && <BackButton />}
    {subTitle
      ? (
        <TitleSubtitleWrapper>
          <Title dangerouslySetInnerHTML={{__html: title}} />
          <SubTitle dangerouslySetInnerHTML={{__html: subTitle}} />
        </TitleSubtitleWrapper>
      )
      : <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
    }
    {children}
  </Root>
)

const BackButton = () => {
  const navigate = useNavigate()
  
  return (
    <BackButtonWrapper onClick={() => navigate(-1)}>
      <DropdownArrow width={16} height={16}/>
    </BackButtonWrapper>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  width: ${p => p.width};
  margin: 0 0 12px;
  padding: 12px;
`;
const Title = styled.div`
  width: max-content;
  font-size: 24px;
  font-weight: 700;
  color: #364652;
`
const SubTitle = styled.div`
  width: max-content;
  font-size: 14px;
  font-weight: 400;
  color: #6d6d6d;
  margin-top: 6px;
`
const TitleWrapper = styled.div`
  width: max-content;
  margin-right: 24px;
`
const TitleSubtitleWrapper = styled.div`
  width: max-content;
  margin-right: 24px;
`
const BackButtonWrapper = styled.span`
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  svg {
    transform: rotate(90deg);
  }
`
