import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { CyclesBaseTable, HeaderCell, Cell } from '../index';

const RowRoot = styled.div`
  width: calc(100% - 8px);
  position: relative;

  border-radius: 4px;
  min-height: 83px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const InProgressCyclesTableView = ({ data, className }) => {
  return (
    <CyclesBaseTable
      TrGroupComponent={({ ...props }) => <RowRoot {...props} />}
      // getTrGroupProps={(_, { original }) => ({ original })}
      className={className}
      data={data}
      defaultSorted={[{
        id: 'endAt',
        desc: true
      }]}
      columns={[
        {
          accessor: 'name',
          Header: <HeaderCell>Name</HeaderCell>,
          width: 465,
          Cell: ({ value }) => <Cell>{value}</Cell>,
        },
        {
          accessor: 'crop',
          Header: <HeaderCell>Crop</HeaderCell>,
          width: 445,
          Cell: ({ value }) => <Cell>{value.name}</Cell>,
        },
        {
          accessor: 'daysLeft',
          Header: <HeaderCell>Days left</HeaderCell>,
          width: 160,
          Cell: ({ value }) => <Cell>{value}</Cell>,
        },
        {
          accessor: 'startAt',
          Header: <HeaderCell>Start date</HeaderCell>,
          width: 153,
          Cell: ({ value }) => (
            <Cell>{moment.unix(value).format('DD.MM.YY')}</Cell>
          ),
        },

        {
          Header: <HeaderCell>End date</HeaderCell>,
          accessor: 'endAt',
          minWidth: 130,
          Cell: ({ value }) => (
            <Cell>{moment.unix(value).format('DD.MM.YY')}</Cell>
          ),
        },
        {
          accessor: 'completion',
          Header: <HeaderCell>Completion</HeaderCell>,
          minWidth: 140,
          Cell: ({ original }) => <Cell>{original.status}</Cell>,
        },
      ]}
    />
  );
};

export const InProgressCyclesTable = InProgressCyclesTableView;
