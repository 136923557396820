import React from 'react'
import PropTypes from 'prop-types'

const EnergyIcon = ({ width, height }) => (
  <svg width={width} height={height}>
    <defs>
      <path
        d="M18.706 10.263c.25 0 .46.214.46.466a.467.467 0 0 1-.095.272c-1.69 2.212-3.476 4.385-5.262 6.558-.883 1.067-1.766 2.134-2.688 3.28a.45.45 0 0 1-.634.058.495.495 0 0 1-.134-.524l2.285-6.617-6.03-.02a.437.437 0 0 1-.441-.446.48.48 0 0 1 .096-.29l5.3-6.579a391.413 391.413 0 0 1 2.63-3.26.45.45 0 0 1 .634-.058c.154.136.192.35.134.524l-2.285 6.617 6.03.02zm-5.484 6.005c.355-.408 1.99-2.696 3.548-4.572h-5.286c-.42.046-.668-.513-.582-.821l1.129-2.856-3.458 4.475h5.004c.392 0 .757.162.757.656l-1.112 3.118z"
        id="EnergyIcon__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="EnergyIcon__b">
        <use xlinkHref="#EnergyIcon__a" />
      </mask>
      <use className="_color" fillRule="nonzero" xlinkHref="#EnergyIcon__a" />
      <g mask="url(#EnergyIcon__b)">
        <path d="M1 0h24v24H1z" />
      </g>
    </g>
  </svg>
)

EnergyIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

EnergyIcon.defaultProps = {
  width: 24,
  height: 24
}

export default EnergyIcon
