import React from "react";
import moment from "moment";
import styled from "styled-components";
import { Trans } from "react-i18next";

import { StatusCell } from "src/components/alerts/AlertsTable/index";

export const columns = [
  {
    Header: <Trans i18nKey="type_and_priority">Type/Priority</Trans>,
    accessor: "priority",
    minWidth: 120,
    Cell: (row) => (
      <TypeWrapper
        className={row.original.priority && row.original.priority.toLowerCase()}
      >
        <Type>
          <Trans i18nKey={row.original.typename.toLowerCase()}>
            {row.original.typename}
          </Trans>
        </Type>
        <Priority>
          <Trans i18nKey={row.original.priority.toLowerCase()}>
            {row.original.priority}
          </Trans>
        </Priority>
      </TypeWrapper>
    ),
  },
  {
    Header: <Trans i18nKey="zone">Zone</Trans>,
    accessor: "zone",
    minWidth: 90,
    Cell: (row) => <CellItem>{row.value}</CellItem>,
  },
  {
    Header: <Trans i18nKey="description">Description</Trans>,
    accessor: "message",
    minWidth: 200,
    Cell: (row) => <CellItem>{row.value}</CellItem>,
  },
  {
    Header: <Trans i18nKey="date">Date</Trans>,
    accessor: "createdAt",
    minWidth: 100,
    Cell: (row) => (
      <CellItem>
        {moment
          .unix(row.value)
          .format(
            process.env.REACT_APP_LOCALE === "en" ? "YYYY/MM/DD" : "DD/MM/YYYY"
          )}
      </CellItem>
    ),
  },
  {
    Header: <Trans i18nKey="status">Status</Trans>,
    accessor: "resolved",
    Cell: (row) => StatusCell(row),
  },
];

const TypeWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  border-radius: 4px;
  padding: 16px;

  &.urgent {
    box-shadow: inset 4px 0 0 ${(p) => p.theme.red};
    color: ${(p) => p.theme.red};
  }

  &.high {
    box-shadow: inset 4px 0 0 ${(p) => p.theme.brightGreen};
    color: ${(p) => p.theme.brightGreen};
  }

  &.low {
    box-shadow: inset 4px 0 0 ${(p) => p.theme.lightGrayAux};
    color: ${(p) => p.theme.lightGrayAux};
  }
`;

const Type = styled.div`
  color: ${(p) => p.theme.darkGray};
`;

const Priority = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: currentColor;
`;

const CellItem = styled.div`
  padding: 16px;
`;
