import React from 'react'
import PropTypes from 'prop-types'

const ChartArrowIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path d="M20.968 7.756l.236.236a.9.9 0 0 1 0 1.273l-7.213 7.212a.9.9 0 0 1-1.272 0l-.236-.235a.9.9 0 0 1 0-1.273l7.212-7.213a.9.9 0 0 1 1.273 0zM7.313 11.114l-5.41 5.41a.9.9 0 0 1-1.273 0l-.235-.236a.9.9 0 0 1 0-1.272l6.27-6.27a.898.898 0 0 1 .254-.179.9.9 0 0 1 1.035.171l6.27 6.27a.9.9 0 0 1 0 1.273l-.236.235a.9.9 0 0 1-1.273 0l-5.402-5.402zM20.798 7.97l-3.012.01a.9.9 0 0 1-.903-.9v-.323a.9.9 0 0 1 .897-.9l3.76-.012a.905.905 0 0 1 .158-.014h.334a.9.9 0 0 1 .9.9v4.442a.9.9 0 0 1-.9.9h-.334a.9.9 0 0 1-.9-.9V7.969z" />
  </svg>
)

ChartArrowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

ChartArrowIcon.defaultProps = {
  width: 24,
  height: 24
}

export default ChartArrowIcon
