import ApolloClient from 'apollo-boost'
import {toast} from "react-toastify";

export const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL + process.env.REACT_APP_GQL_PATH,
  request: async (operation) => {
    operation.setContext({
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
  },
  onError: ({ networkError }) => {
    if (networkError && networkError.statusCode === 401) {
      // localStorage.removeItem('token')
      toast.error(networkError.result.message ? networkError.result.message : networkError.message);
      // window.location.reload()
    }
  }
})
