import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

export const AlertAIAnalyticsChart = ({ data }) => {
  return (
    <LineChart
      width={976}
      height={241}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 0,
        bottom: 5,
      }}
    >
      <CartesianGrid horizontal={false} vertical={false}/>
      <XAxis dataKey="time" stroke="#BBBBBB" />
      <YAxis
        dataKey="t"
        domain={[20, 50]}
        padding={{ top: 10 }}
        stroke="#BBBBBB"
        type='category'
        width={26}
        axisLine={true}
      />
      
      <Tooltip />
      <Line
        type="natural"
        dataKey="t"
        stroke="#979797"
        strokeWidth={1}
        dot={false}
        activeDot={false}
      />
    </LineChart>
  );
};
