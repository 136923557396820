import React from 'react'
import PropTypes from 'prop-types'

const DropdownArrow = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 15 15">
    <path d="M13.717 6.775a.887.887 0 0 0-1.249 0l-3.703 3.713V-.122a.884.884 0 0 0-.881-.882.884.884 0 0 0-.882.882v10.61L3.289 6.775a.887.887 0 0 0-1.249 0 .887.887 0 0 0 0 1.25l5.217 5.215a.88.88 0 0 0.622.26.88.88 0 0 0 .622-.26l5.216-5.216a.887.887 0 0 0 0-1.249z" />
  </svg>
)

DropdownArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

DropdownArrow.defaultProps = {
  width: 15,
  height: 15
}

export default DropdownArrow
