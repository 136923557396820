import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  AIWaterIcon,
  AIWaterBadValueIcon,
  AIWaterSuccessValueIcon,
} from "src/components/Icons";

const CardRoot = styled.li`
  width: 100%;
  padding: 24px 76px 24px 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  :hover {
    box-shadow: 0 0 10px rgba(207, 7, 7, 0.5);
    transition: 0.3s;
  }

  @media (max-width: 1700px) {
    padding: 20px 55px 20px 20px;
  }
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  flex-direction: column;
`;

const Title = styled.div`
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 18px;
  line-height: 24px;
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  margin: 0 0 7px;

  .field {
    color: ${(p) => p.theme.textGrayAux};
    font-style: italic;
  }
`;

const ValueWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  font-size: 24px;
  line-height: 44px;
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  padding: 0 0 0 38px;
  margin: 0 0 5px;

  @media (max-width: 1850px) {
    font-size: 20px;
  }
`;

const Value = styled.div`
  font-size: 42px;
  line-height: 56px;

  @media (max-width: 1850px) {
    font-size: 32px;
  }
`;

const ValueDescr = styled.div`
  font-size: 14px;
  line-height: 18px;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.darkGray};
  text-align: right;
`;

const ResWrapper = styled.div`
  position: absolute;
  right: 56px;
  bottom: 30px;

  @media (max-width: 1850px) {
    right: 35px;
  }
`;

const FieldItemView = ({ field }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id, avr, res } = field;

  return (
    <CardRoot>
      <StyledLink to={`${location.pathname}/${field.id}`}>
        <Title>
          <AIWaterIcon />
          &nbsp;&nbsp;&nbsp;ID {id} <span className="field">{t("field")}</span>
        </Title>
        <ValueWrapper>
          <Value>{avr}</Value>
          &nbsp;{t("t_day")}
        </ValueWrapper>
        <ValueDescr>{t("avg")}</ValueDescr>
      </StyledLink>
      <ResWrapper>
        {res === "bad" ? <AIWaterBadValueIcon /> : <AIWaterSuccessValueIcon />}
      </ResWrapper>
    </CardRoot>
  );
};

export const FieldItem = FieldItemView;
