import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { PageHeader, Root } from "src/ui";
import ArrowDown from "src/components/Icons/ArrowDown";
import { aiRecommendationsStub } from "src/stubs";

const Filter = styled.div`
  width: 100%;
  cursor: pointer;
  display: block;
  text-decoration: none;

  & + * {
    margin-top: 16px;
  }

  & .slide-wrap {
    width: 100%;
    height: 8px;
    background: linear-gradient(
      90deg,
      #cf0707 0%,
      #e6ce7a 50.31%,
      #58c08e 100%
    );
    border-radius: 4px;
    position: relative;
  }

  & p {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #364652;
    margin-bottom: 26px;
  }
`;

const Slide = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ percent }) =>
    percent > 65 ? "#58C08E" : percent < 35 ? "#C64627" : "#E6CE7A"};
  border: 1px solid #c64627;
  box-sizing: border-box;
  position: absolute;
  top: -4px;
  left: ${(props) => props.percent}%;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 1400px) {
    display: block;
  }
`;

const RowItem = styled.div`
  width: 30%;
  margin: 8px 12px;

  @media (max-width: 1400px) {
    width: 100%;
    max-height: 30%;
    overflow-y: auto;
    margin-bottom: 40px;
  }
`;

const Collapse = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 8px;

  & .head {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 24px;

    &--dot {
      width: 8px;
      height: 8px;
      background: ${({ color }) => color || "#CF0707"};
      margin-right: 24px;
      border-radius: 50%;
    }

    &--date {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #6b7280;
    }

    &--text {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #364652;
      max-width: 300px;
    }

    &--icon {
      margin-left: auto;
    }
  }

  & .body {
    padding: 0 24px 24px 56px;

    & p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #364652;
    }

    & button {
      background: #ff6600;
      border: 2px solid #ff6600;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 14px 24px;
      margin: 24px 0 0 auto;
      display: flex;
      cursor: pointer;
    }
  }
`;

export const AIRecommendations = () => {
  const { t } = useTranslation();
  const [equipmentList, setEquipmentList] = useState([]);
  const [agroTechnologyList, setAgroTechnologyList] = useState([]);
  const [resourceEfficiencyList, setResourceEfficiencyList] = useState([]);

  useEffect(() => {
    setEquipmentList(
      aiRecommendationsStub.listEquipment.map((equipmentRecommendation) => ({
        ...equipmentRecommendation,
        isOpen: false,
        isAccepted: false,
      }))
    );
    setAgroTechnologyList(
      aiRecommendationsStub.agroTechnologyList.map(
        (agroTechnologyRecommendation) => ({
          ...agroTechnologyRecommendation,
          isOpen: false,
          isAccepted: false,
        })
      )
    );
    setResourceEfficiencyList(
      aiRecommendationsStub.resourceEfficiencyList.map(
        (resourceEfficiencyRecommendation) => ({
          ...resourceEfficiencyRecommendation,
          isOpen: false,
          isAccepted: false,
        })
      )
    );
  }, []);

  const handleToggleOpen = (label, list, updateList) => () => {
    if (label === equipmentList[0].label) {
      const updatedList = list.map((it) => ({
        ...it,
        isOpen: it.label === label ? !it.isOpen : it.isOpen,
      }));
      updateList(updatedList);
    }
  };

  const handleAccept = (label, list, updateList) => () => {
    const updatedList = list.map((it) => ({
      ...it,
      accepted: it.label === label ? true : it.accepted,
    }));

    updateList(updatedList);
  };

  const getColor = (status) =>
    (status === 1 && "#58C08E") ||
    (status === 2 && "#FF6600") ||
    (status === 3 && "#CF0707");

  return (
    <Root>
      <PageHeader title={t("expert_system_recommendations")} />
      <Row>
        <RowItem>
          <Filter>
            <p>{t("equipment")}</p>
            <div className="slide-wrap">
              <Slide percent={49} />
            </div>
          </Filter>
          {equipmentList
            .filter((it) => !it.accepted)
            .map((it) => (
              <Collapse key={it.label} color={getColor(it.status)}>
                <div
                  className="head"
                  onClick={handleToggleOpen(
                    it.label,
                    equipmentList,
                    setEquipmentList
                  )}
                >
                  <div className="head--dot" />
                  <div>
                    <p className="head--date">{it.date}</p>
                    <p className="head--text">{it.label}</p>
                  </div>
                  <div
                    className="head--icon"
                    style={{ transform: `rotate(${it.isOpen ? 180 : 0}deg)` }}
                  >
                    <ArrowDown fill="#6B7280" />
                  </div>
                </div>

                {it.isOpen && (
                  <div className="body">
                    <p>{it.text}</p>
                    <button
                      onClick={handleAccept(
                        it.label,
                        equipmentList,
                        setEquipmentList
                      )}
                    >
                      {t("accepted").toUpperCase()}
                    </button>
                  </div>
                )}
              </Collapse>
            ))}
        </RowItem>
        <RowItem>
          <Filter>
            <p>{t("agro_tech_and_plant_protection")}</p>
            <div className="slide-wrap">
              <Slide percent={85} />
            </div>
          </Filter>
          {agroTechnologyList
            .filter((it) => !it.accepted)
            .map((it) => (
              <Collapse key={it.label} color={getColor(it.status)}>
                <div
                  className="head"
                  onClick={handleToggleOpen(
                    it.label,
                    agroTechnologyList,
                    setAgroTechnologyList
                  )}
                >
                  <div className="head--dot" />
                  <div>
                    <p className="head--date">{it.date}</p>
                    <p className="head--text">{it.label}</p>
                  </div>
                  <div
                    className="head--icon"
                    style={{ transform: `rotate(${it.isOpen ? 180 : 0}deg)` }}
                  >
                    <ArrowDown fill="#6B7280" />
                  </div>
                </div>

                {it.isOpen && (
                  <div className="body">
                    <p>{it.text}</p>
                    <button
                      onClick={handleAccept(
                        it.label,
                        agroTechnologyList,
                        setAgroTechnologyList
                      )}
                    >
                      {t("accepted").toUpperCase()}
                    </button>
                  </div>
                )}
              </Collapse>
            ))}
        </RowItem>

        <RowItem>
          <Filter>
            <p>{t("resource_efficiency")}</p>
            <div className="slide-wrap">
              <Slide percent={30} />
            </div>
          </Filter>
          {resourceEfficiencyList
            .filter((it) => !it.accepted)
            .map((it) => (
              <Collapse key={it.label} color={getColor(it.status)}>
                <div
                  className="head"
                  onClick={handleToggleOpen(
                    it.label,
                    resourceEfficiencyList,
                    setResourceEfficiencyList
                  )}
                >
                  <div className="head--dot" />
                  <div>
                    <p className="head--date">{it.date}</p>
                    <p className="head--text">{it.label}</p>
                  </div>
                  <div
                    className="head--icon"
                    style={{ transform: `rotate(${it.isOpen ? 180 : 0}deg)` }}
                  >
                    <ArrowDown fill="#6B7280" />
                  </div>
                </div>

                {it.isOpen && (
                  <div className="body">
                    <p>{it.text}</p>
                    <button
                      onClick={handleAccept(
                        it.label,
                        resourceEfficiencyList,
                        setResourceEfficiencyList
                      )}
                    >
                      {t("accepted").toUpperCase()}
                    </button>
                  </div>
                )}
              </Collapse>
            ))}
        </RowItem>
      </Row>
    </Root>
  );
};
