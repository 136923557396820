import React from 'react'

import {alertsTableDataStub} from 'src/stubs'

export const AlertsContext = React.createContext(null)

export const AlertsStore = ({ children }) => {
  const [alerts, setAlerts] = React.useState(alertsTableDataStub);
  const [analytics, setAnalytics] = React.useState({});
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  return (
    <AlertsContext.Provider value={[
      alerts,
      setAlerts,
      analytics,
      setAnalytics,
      isOpenModal,
      setIsOpenModal,
    ]}>
      {children}
    </AlertsContext.Provider>
  );
};
