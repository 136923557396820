import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  ${(p) => p.theme.ProximaNovaLight};
  font-size: 3rem;
`

export const PageUnderConstruction = () => {
  return (
    <Title>This page is still under construction. Please visit later.</Title>
  )
}

