import React from 'react'
import PropTypes from 'prop-types'

const PlayIcon = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Virtual"
        transform="translate(-458.000000, -438.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <path
          d="M477.066972,448.52085 L460.407108,438.348774 C459.193683,437.536901 458,438.19122 458,439.805148 L458,460.195047 C458,461.806967 459.193479,462.465303 460.407108,461.649413 L477.039699,451.627081 C478.582634,450.663455 478.020493,449.109335 477.066972,448.52085 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
)

PlayIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

PlayIcon.defaultProps = {
  width: 20,
  height: 24
}

export default PlayIcon
