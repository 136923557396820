import React, { useState } from "react";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  LogoIcon,
  LogoIconRu,
  DashboardIcon,
  AlertIcon,
  FarmStatusIcon,
  SettingsIcon,
  AnalyticsIcon,
} from "src/components/Icons";
import { NavDropdown } from "src/components/";
import { AsideNavItem } from "src/ui";

const Nav = styled.nav`
  z-index: 20000;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
  background-color: ${(p) => p.theme.darkGray};
  position: relative;
`;

const NavInner = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(p) => p.theme.darkGray};

  display: flex;
  flex-direction: column;
  width: 328px;
`;

const Links = styled.ul`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0;

  * {
    ${(p) => p.theme.ProximaNova}
  }
`;

const LogoNavLink = styled.a`
  display: inline-block;
  height: 122px;
  width: 100%;
  padding: 16px 32px;

  transition: all 0.2s ease-out;
  flex-shrink: 0;
  text-decoration: none;

  :hover,
  :active,
  :focus {
    background-color: ${(p) => p.theme.activeGray};
    outline: none;
  }
`;

const Header = styled.h1`
  margin: 0;
`;

export const AsideNav = ({ className }) => {
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const toggleHandler = (labelItem) => {
    if (activeItem === labelItem) setActiveItem("");
    else setActiveItem(labelItem);
  };

  return (
    <Nav className={className}>
      <NavInner>
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
          {i18n.language === "en" ? (
            <LogoNavLink
              href="https://jetruby.com/farming-management-system"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Header>
                <LogoIcon aria-label="Jetruby: Ai & ML based Farming Management System" />
              </Header>
            </LogoNavLink>
          ) : (
            <LogoNavLink href="/dashboard">
              <Header>
                <LogoIconRu />
              </Header>
            </LogoNavLink>
          )}
          <Links>
            <AsideNavItem
              onClick={() => toggleHandler("Dashboard")}
              to="/dashboard"
            >
              <DashboardIcon />
              {t("dashboard")}
            </AsideNavItem>

            <AsideNavItem onClick={() => toggleHandler("Alerts")} to="/alerts">
              <AlertIcon />
              {t("alerts")}
            </AsideNavItem>

            <NavDropdown
              labelIcon={<AnalyticsIcon />}
              labelText={t("analytics")}
              pathRegexp={/analytics/i}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              toggleHandler={toggleHandler}
              location={location}
              items={[
                {
                  to: "/analytics/yield-forecast",
                  label: t("yield_forecast"),
                },
                {
                  to: "/analytics/ai-water-management",
                  label: t("ai_water_management"),
                },
                // {
                //   to: "/analytics/growing-cycles",
                //   label: t("growing_cycles"),
                // },
                {
                  to: "/analytics/forecast-of-critical-situations",
                  label: t("forecast_of_critical_situations"),
                },
                {
                  to: "/analytics/ai-recommendations",
                  label: t("ai_recommendations"),
                },
              ]}
            />

            {/* <NavDropdown
              labelIcon={<FarmStatusIcon />}
              labelText={t("growth_cycles")}
              pathRegexp={/growth-cycles/i}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              toggleHandler={toggleHandler}
              location={location}
              items={[
                {
                  to: "/growth-cycles/in-progress",
                  label: t("in_progress"),
                },
                {
                  to: "/growth-cycles/planned",
                  label: t("planned"),
                },
                {
                  to: "/growth-cycles/completed",
                  label: t("completed"),
                },
              ]}
            /> */}

            <AsideNavItem
              onClick={() => toggleHandler("Settings")}
              exact="true"
              to="/settings"
            >
              <SettingsIcon />
              {t("settings")}
            </AsideNavItem>
          </Links>
        </Scrollbars>
      </NavInner>
    </Nav>
  );
};
