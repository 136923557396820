import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { NumericArrow } from 'src/components/Icons'

const Input = styled.input`
  display: block;
  outline: none;
  height: 56px;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.textDisable};

  ${(p) => p.direction && `
    padding-left: 40px;
  `}

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  outline: none;
  :focus {
    border: 1px ${(p) => p.theme.orange};
  }

  ::placeholder {
    color: ${(p) => p.theme.textDisable};
  }
`

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 24px;

  ${(p) => p.direction === 'down' && `
    transform: rotate(180deg);
  `}
`

const Root = styled.div`
  display: block;
  position: relative;
  width: 100%;

  :focus-within ${Input} {
    border: 1px solid ${(p) => p.theme.orange};
  }
`

const handleChange = (e, onChange) => {
  const { value } = e.target
  return onChange(+value)
}

export const NumericInput = ({ onChange, value, direction, className, ...props }) => (
  <Root className={className}>
    {direction &&
      <IconWrapper direction={direction}>
        <NumericArrow />
      </IconWrapper>
    }
    <Input
      {...props}
      type="number"
      value={value}
      direction={direction}
      onChange={(e) => handleChange(e, onChange)}
      autoComplete="off"
    />
  </Root>
)

NumericInput.propTypes = {
  value: PropTypes.number
}
