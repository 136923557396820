import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import { WateringChart } from "src/components/aiWaterManagement/WateringChart";
import { SoilChart } from "src/components/aiWaterManagement/SoilChart";
import { SatisticBox } from "src/components/aiWaterManagement/SatisticBox";
import {
  defaultFields,
  moistureTicks,
  temperatureTicks,
} from "src/stubs/AiWaterManagement";
import { H3, ThreeFour, OneFour, PageHeader } from "src/ui";
import { AlarmActiveIcon, MapIcon, WaterDrop } from "src/components/Icons";
import { DataName, DataValue } from "src/ui/Analytics";

const Root = styled.div`
  height: 100%;
`;

const Content = styled.div`
  display: flex;
`;

const List = styled.ul`
  list-style-type: none;
`;

const UpperAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;

const UpperArea = styled(List)`
  display: flex;
  width: calc(100% - 262px);
  padding: 24px 0;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-right: 24px;
  background-color: ${(p) => p.theme.white};
`;

const SensorsButton = styled.span`
  display: flex;
  width: 238px;
  background-color: ${(p) => p.theme.white};
  border-radius: 4px;
  overflow: hidden;
`;

const DataItem = styled.li`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  ${(p) =>
    !p.isParams &&
    `
    width: 33.3%;
    padding-left: 24px;
    margin: 0;
  `}

  @media (max-width: 1650px) {
    flex-wrap: wrap;
  }
`;

const Name = styled(DataName)`
  ${(p) => p.theme.ProximaNovaBold};

  ${(p) =>
    p.isParams &&
    `
    ${p.theme.ProximaNova};
    width: 165px;
    margin-right: 28px;
  `}

  @media (max-width: 1630px) {
    width: 100%;
  }
`;

const ChartBlock = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};
  margin-bottom: 22px;
`;

const ChartTitle = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.darkGray};
`;

const ChartName = styled.p`
  ${(p) => p.theme.ProximaNovaBold};
  margin: 0 0 8px;
  font-size: 24px;
  line-height: 30px;
`;

const Percent = styled.span`
  ${(p) => p.theme.ProximaNova};
  font-size: 18px;
  line-height: 24px;
`;

const ChartWrapper = styled.div`
  padding: 24px 24px 50px 40px;
  display: flex;
  flex-wrap: wrap;
`;

const Legend = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

const LegendItem = styled.li`
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  line-height: 24px;
  color: ${(p) => p.theme.darkGray};
  display: flex;
  align-items: center;
  padding: 16px 5px;
`;
const LegendItemTitle = styled.span`
  max-width: 225px;
  color: ${(p) => p.theme.green};
  ${(p) => p.theme.ProximaNova};
`;

const Icon = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(p) => p.theme[p.color]};
`;

// const Recommendation = styled.div`
//   ${(p) => p.theme.ProximaNova};
//   font-size: 18px;
//   line-height: 22px;
//   width: 100%;
//   padding: 24px;
//   border-radius: 4px;
//   background-color: ${(p) => p.theme.lightBlue};
// `;

// const RecommendationTitle = styled(H3)`
//   font-size: 24px;
//   line-height: 30px;
//   padding: 0;
//   margin-bottom: 16px;
//   color: ${(p) => p.theme.black};
// `;

const Params = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};
  margin-bottom: 24px;
`;

const ParamsTitle = styled(H3)`
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin-bottom: 24px;
`;

const AlarmBlock = styled.div`
  width: 100%;
  padding: 32px 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};

  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const AlarmTitle = styled.p`
  ${(p) => p.theme.ProximaNovaBold};
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 8px;

  svg {
    margin-right: 18px;
  }
`;

const AlarmText = styled.p`
  margin: 0;
  padding-left: 40px;
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.green};
  font-size: 14px;
  line-height: 18px;
`;

const ListItem = ({ name, value, isParams }) => (
  <DataItem isParams={isParams}>
    <Name isParams={isParams}>{name}:</Name>
    <DataValue>{value}</DataValue>
  </DataItem>
);

export const AIWaterManagementStatistic = () => {
  const { t } = useTranslation();
  const { fieldId } = useParams();
  const location = useLocation();
  const currentField = defaultFields.find((field) => field.id === +fieldId);
  const {
    id,
    area,
    crop,
    phase,
    warnings,
    temperature,
    humidity,
    windSpeed,
    atmospherePressure,
    water,
    avrHumidity,
    avrSoilTemperature,
  } = currentField;

  const usedWaterTitle = t("used_water_amount");
  const AITitle = t("ai");

  const wateringChartValues = [
    {
      name: `0 ${t("day")}`,
      [AITitle]: 19,
      [usedWaterTitle]: 19,
    },
    {
      name: `1 ${t("day")}`,
      [AITitle]: 18,
      [usedWaterTitle]: 18,
    },
    {
      name: `2 ${t("day")}`,
      [AITitle]: 15,
      [usedWaterTitle]: 15,
    },
    {
      name: `3 ${t("day")}`,
      [AITitle]: 13,
      [usedWaterTitle]: 13,
    },
    {
      name: `4 ${t("day")}`,
      [AITitle]: 16,
      [usedWaterTitle]: 16,
    },
    {
      name: `5 ${t("day")}`,
      [AITitle]: 17,
      [usedWaterTitle]: 17,
    },
    {
      name: `6 ${t("day")}`,
      [AITitle]: 20,
      [usedWaterTitle]: 20,
    },
    {
      name: `7 ${t("day")}`,
      [AITitle]: 18,
      [usedWaterTitle]: 18,
    },
    {
      name: `8 ${t("day")}`,
      [AITitle]: 16,
      [usedWaterTitle]: 16,
    },
    {
      name: `9 ${t("day")}`,
      [AITitle]: 14,
    },
    {
      name: `10 ${t("day")}`,
      [AITitle]: 12,
    },
    {
      name: `11 ${t("day")}`,
      [AITitle]: 16,
    },
    {
      name: `12 ${t("day")}`,
      [AITitle]: 21,
    },
    {
      name: `13 ${t("day")}`,
      [AITitle]: 18,
    },
    {
      name: `14 ${t("day")}`,
      [AITitle]: 13,
    },
    {
      name: `15 ${t("day")}`,
      [AITitle]: 10,
    },
  ];

  const soilChartLegend = [
    {
      color: "blue",
      text: `-5 ${t("depth")}`,
    },
    {
      color: "green",
      text: `-15 ${t("depth")}`,
    },
    {
      color: "darkRed",
      text: `-40 ${t("depth")}`,
    },
  ];

  const moistureChartValues = [
    {
      name: `0 ${t("day")}`,
      blue: 55,
      gray1: 55,
      green: 32,
      gray2: 32,
      red: 17,
      gray3: 17,
    },
    {
      name: `1 ${t("day")}`,
      blue: 58,
      gray1: 58,
      green: 46,
      gray2: 46,
      red: 20,
      gray3: 20,
    },
    {
      name: `2 ${t("day")}`,
      blue: 57,
      gray1: 57,
      green: 40,
      gray2: 40,
      red: 17,
      gray3: 17,
    },
    {
      name: `3 ${t("day")}`,
      blue: 59,
      gray1: 59,
      green: 41,
      gray2: 41,
      red: 21,
      gray3: 21,
    },
    {
      name: `4 ${t("day")}`,
      blue: 62,
      gray1: 62,
      green: 46,
      gray2: 46,
      red: 23,
      gray3: 23,
    },
    {
      name: `5 ${t("day")}`,
      blue: 53,
      gray1: 53,
      green: 30,
      gray2: 30,
      red: 18,
      gray3: 18,
    },
    {
      name: `6 ${t("day")}`,
      blue: 60,
      gray1: 60,
      green: 46,
      gray2: 46,
      red: 20,
      gray3: 20,
    },
    {
      name: `7 ${t("day")}`,
      blue: 63,
      gray1: 63,
      green: 49,
      gray2: 49,
      red: 22,
      gray3: 22,
    },
    {
      name: `8 ${t("day")}`,
      gray1: 52,
      gray2: 29,
      gray3: 6,
    },
    {
      name: `9 ${t("day")}`,
      gray1: 54,
      gray2: 32,
      gray3: 9,
    },
    {
      name: `10 ${t("day")}`,
      gray1: 53,
      gray2: 29,
      gray3: 7,
    },
    {
      name: `11 ${t("day")}`,
      gray1: 52,
      gray2: 28,
      gray3: 5,
    },
    {
      name: `12 ${t("day")}`,
      gray1: 51,
      gray2: 27,
      gray3: 2,
    },
    {
      name: `13 ${t("day")}`,
      gray1: 52,
      gray2: 28,
      gray3: 6,
    },
    {
      name: `14 ${t("day")}`,
      gray1: 51,
      gray2: 27,
      gray3: 2,
    },
    {
      name: `15 ${t("day")}`,
      gray1: 50,
      gray2: 26,
      gray3: 0,
    },
  ];

  const temperatureChartValues = [
    {
      name: `0 ${t("day")}`,
      blue: 17,
      gray1: 17,
      green: 13,
      gray2: 13,
      red: 6,
      gray3: 6,
    },
    {
      name: `1 ${t("day")}`,
      blue: 18,
      gray1: 18,
      green: 14,
      gray2: 14,
      red: 6,
      gray3: 6,
    },
    {
      name: `2 ${t("day")}`,
      blue: 17,
      gray1: 17,
      green: 14,
      gray2: 14,
      red: 6,
      gray3: 6,
    },
    {
      name: `3 ${t("day")}`,
      blue: 19,
      gray1: 19,
      green: 14.5,
      gray2: 14.5,
      red: 7,
      gray3: 7,
    },
    {
      name: `4 ${t("day")}`,
      blue: 20,
      gray1: 20,
      green: 16,
      gray2: 16,
      red: 7.5,
      gray3: 7.5,
    },
    {
      name: `5 ${t("day")}`,
      blue: 16,
      gray1: 16,
      green: 14,
      gray2: 14,
      red: 7,
      gray3: 7,
    },
    {
      name: `6 ${t("day")}`,
      blue: 19,
      gray1: 19,
      green: 15,
      gray2: 15,
      red: 7.5,
      gray3: 7.5,
    },
    {
      name: `7 ${t("day")}`,
      blue: 21,
      gray1: 21,
      green: 16,
      gray2: 16,
      red: 8,
      gray3: 8,
    },
    {
      name: `8 ${t("day")}`,
      gray1: 18,
      gray2: 13,
      gray3: 7,
    },
    {
      name: `9 ${t("day")}`,
      gray1: 16,
      gray2: 12,
      gray3: 6,
    },
    {
      name: `10 ${t("day")}`,
      gray1: 17,
      gray2: 13,
      gray3: 5.5,
    },
    {
      name: `11 ${t("day")}`,
      gray1: 16,
      gray2: 12.5,
      gray3: 6.5,
    },
    {
      name: `12 ${t("day")}`,
      gray1: 15,
      gray2: 12,
      gray3: 5,
    },
    {
      name: `13 ${t("day")}`,
      gray1: 14.5,
      gray2: 11,
      gray3: 4,
    },
    {
      name: `14 ${t("day")}`,
      gray1: 15,
      gray2: 12,
      gray3: 4.5,
    },
    {
      name: `15 ${t("day")}`,
      gray1: 14,
      gray2: 11,
      gray3: 3,
    },
  ];

  return (
    <Root>
      <PageHeader
        title={t("ai_water_management_title")}
        subTitle={`${t("id_field")}: <strong>${id}</strong>`}
        isBackButton
      />
      <Content>
        <ThreeFour>
          <UpperAreaWrapper>
            <UpperArea>
              <ListItem
                name={t("field_area")}
                value={`${area} ${t("hectares")}`}
              />
              <ListItem name={t("crop")} value={crop} />
              <ListItem name={t("growth_phase")} value={phase} />
            </UpperArea>

            <Link to={`${location.pathname}/sensors`}>
              <SensorsButton>
                <MapIcon />
              </SensorsButton>
            </Link>
          </UpperAreaWrapper>
          <ChartBlock>
            <ChartTitle>
              <ChartName>{t("watering_schedule")}</ChartName>
              <Percent>{t("cubic_liters")}</Percent>
            </ChartTitle>
            <ChartWrapper>
              <WateringChart data={wateringChartValues} />
              <Legend>
                <LegendItem>
                  <LegendItemTitle>
                    {t("used_water_amount")}:&nbsp;
                  </LegendItemTitle>
                  120 000 {t("liters")}
                </LegendItem>
              </Legend>
            </ChartWrapper>
          </ChartBlock>
          <ChartBlock>
            <ChartTitle>
              <ChartName>{t("soil_moisture_by_depth")}</ChartName>
              <Percent>%</Percent>
            </ChartTitle>
            <ChartWrapper>
              <SoilChart data={moistureChartValues} ticks={moistureTicks} />
              <Legend>
                {soilChartLegend.map(({ text, color }) => (
                  <LegendItem key={text}>
                    <Icon color={color} />
                    {text}
                  </LegendItem>
                ))}
              </Legend>
            </ChartWrapper>
          </ChartBlock>
          <ChartBlock>
            <ChartTitle>
              <ChartName>{t("soil_temp_by_depth")}</ChartName>
              <Percent>°C</Percent>
            </ChartTitle>
            <ChartWrapper>
              <SoilChart
                data={temperatureChartValues}
                ticks={temperatureTicks}
              />
              <Legend>
                {soilChartLegend.map((item) => (
                  <LegendItem key={item.text}>
                    <Icon color={item.color} />
                    {item.text}
                  </LegendItem>
                ))}
              </Legend>
            </ChartWrapper>
          </ChartBlock>

          {/* <Recommendation>
            <RecommendationTitle>{recommendation.title}</RecommendationTitle>
            {recommendation.description}
          </Recommendation> */}
        </ThreeFour>
        <OneFour>
          <Params>
            <ParamsTitle>{t("parameters")}</ParamsTitle>
            <List>
              <ListItem isParams name={t("air_temp")} value={temperature} />
              <ListItem isParams name={t("humidity")} value={humidity} />
              <ListItem isParams name={t("wind_speed")} value={windSpeed} />
              <ListItem
                isParams
                name={t("atmosphere_pressure")}
                value={atmospherePressure}
              />
              <ListItem isParams name={t("water_consuption")} value={water} />
              <ListItem isParams name={t("avg_humidity")} value={avrHumidity} />
              <ListItem
                isParams
                name={t("avg_soil_temperature")}
                value={avrSoilTemperature}
              />
            </List>
          </Params>
          <SatisticBox
            width={100}
            padding={0}
            title={t("water_consuption")}
            description={t("reserves_gallons")}
            amount={1700}
            amountName={t("today")}
            totalAmount={2900}
            totalName={t("net_two_days")}
            icon={<WaterDrop />}
          />
          {warnings.map((warning) => (
            <AlarmBlock key={warning.id}>
              <AlarmTitle>
                <AlarmActiveIcon />
                {warning.title}
              </AlarmTitle>
              <AlarmText>{warning.description}</AlarmText>
            </AlarmBlock>
          ))}
        </OneFour>
      </Content>
    </Root>
  );
};
