import React, {useEffect, useState} from "react";
import moment from "moment";

import {FilterPrioritySelect, FilterDateRangeSelect} from "src/components/index";
import {FiltersWrapper} from "src/ui";
import {alertsTableDataStub} from "src/stubs"
import {useAlertsContext} from 'src/store'

export const TitleFilters = () => {
  const {setAlerts} = useAlertsContext()

  const [filterPriorityValue, setFilterPriorityValue] = useState(null)
  const [filterPeriodValue, setFilterPeriodValue] = useState('')

  const onChangePrioritySelect = (value => {
    setFilterPriorityValue(value)
  })

  const onChangePeriodSelect = (value => {
    setFilterPeriodValue({startDate: value.startDate, endDate: value.endDate})
    const endOfDay = new Date(value.endDate)
    endOfDay.setHours(23,59,59)
    
    setFilterPeriodValue({startDate: value.startDate, endDate: endOfDay})
  })

  useEffect(() => {
    const {startDate, endDate} = filterPeriodValue

    if (!filterPriorityValue && !startDate) {
      setAlerts(alertsTableDataStub)
    } else if (filterPriorityValue && !startDate) {
      setAlerts(alertsTableDataStub.filter(row =>
        row.priority === filterPriorityValue
      ))
    } else if (!filterPriorityValue && startDate && endDate) {
      setAlerts(alertsTableDataStub.filter(row =>
        row.createdAt >= moment(startDate).unix() &&
        row.createdAt <= moment(endDate).unix()
      ))
    } else if (filterPriorityValue && startDate && endDate) {
      setAlerts(alertsTableDataStub.filter(row =>
        row.priority === filterPriorityValue &&
        row.createdAt >= moment(startDate).unix() &&
        row.createdAt <= moment(endDate).unix()
      ))}
  }, [filterPriorityValue, filterPeriodValue])

  return (
    <FiltersWrapper>
      <FilterPrioritySelect
        value={filterPriorityValue}
        onChange={onChangePrioritySelect}
      />
      <FilterDateRangeSelect
        value={filterPeriodValue}
        onChange={onChangePeriodSelect}
      />
    </FiltersWrapper>
  )
}

