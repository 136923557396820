import React from 'react'
import styled from 'styled-components'

import { BaseTable } from './BaseTable'

import { DropdownArrow } from 'src/components/Icons'

const ThComponentStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &.-sort-asc,
  &.-sort-desc {
    color: ${(p) => p.theme.orange};
  }
`

const SortIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 15px;

  svg {
    path {
      fill: ${(p) => p.theme.orange};
    }
    display: none;

    ${(p) =>
      p.sortedAsc &&
      `
      display: block;
      transform: rotate(180deg);
  `}

    ${(p) =>
      p.sortedDesc &&
      `
      display: block;
  `}
  }
`

const ThComponent = ({ toggleSort, children, className, ...props }) => {
  const sortedAsc = className.includes('-sort-asc')
  const sortedDesc = className.includes('-sort-desc')

  return (
    <ThComponentStyled onClick={toggleSort} className={className} {...props}>
      {children}
      <SortIconWrapper sortedAsc={sortedAsc} sortedDesc={sortedDesc}>
        <DropdownArrow width={12} height={12} />
      </SortIconWrapper>
    </ThComponentStyled>
  )
}

export const CustomTable = ({ className, ...props }) => (
  <BaseTable className={className} ThComponent={ThComponent} {...props} />
)
