import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ContentBox } from "src/components";
import { NewIcon } from "src/components/Icons";

const windowWidth = window.innerWidth;
const blockWidth = windowWidth < 1400 ? 50 : 100;

export const StatisticCards = () => {
  const { t } = useTranslation();

  return (
    <CardsWrapper>
      <ContentBox
        width={blockWidth}
        padding={12}
        title={t("new")}
        description={t("wait_your_solution")}
        amount={3}
        totalAmount={6}
        icon={<NewIcon />}
        shadowColor="orange"
        isLeftAmountPosition
        isAlert
      />
      <ContentBox
        isAlert
        shadowColor="darkRed"
        title={t("urgent")}
        padding={12}
        amount={2}
        width={blockWidth}
        isLeftAmountPosition
        icon={<CircleIcon color="darkRed" />}
      />
      <ContentBox
        isAlert
        shadowColor="green"
        title={t("high")}
        padding={12}
        amount={1}
        width={blockWidth}
        isLeftAmountPosition
        icon={<CircleIcon color="green" />}
      />
      <ContentBox
        isAlert
        shadowColor="lightGrayAlert"
        title={t("low")}
        padding={12}
        amount={3}
        width={blockWidth}
        isLeftAmountPosition
        icon={<CircleIcon color="lightGrayAlert" />}
      />
    </CardsWrapper>
  );
};

const CardsWrapper = styled.div`
  @media (max-width: 1400px) {
    width: 100%;
    order: 1;
    display: flex;
    flex-wrap: wrap;
  }
`;

const CircleIcon = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 8px solid ${(p) => p.theme.white};
  border-radius: 50%;
  background-color: ${(p) => p.theme[p.color]};
`;
