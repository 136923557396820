import React from 'react'
import styled from 'styled-components'

const Root = styled.button`
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 18px;
  height: 18px;
  cursor: pointer;
`

const Dot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #c2c2c2;
`

export const ThreeDotsButton = (props) => (
  <Root {...props}>
    <Dot />
    <Dot />
    <Dot />
  </Root>
)
