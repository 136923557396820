import React from 'react'
import PropTypes from 'prop-types'

const HardwareIcon = ({ width, height }) => (
  <svg width={width} height={height}>
    <defs>
      <path
        d="M8.88 6.5h6.24a2.383 2.383 0 0 1 2.38 2.38v6.24a2.383 2.383 0 0 1-2.38 2.38H8.88a2.383 2.383 0 0 1-2.38-2.38V8.88A2.383 2.383 0 0 1 8.88 6.5zm7.27 8.611v-6.12c0-.587-.478-1.065-1.065-1.065h-6.12c-.587 0-1.065.478-1.065 1.065v6.12c0 .587.478 1.065 1.065 1.065h6.12c.587 0 1.065-.478 1.065-1.065zm4.838-7.015V11.297h1.309a.703.703 0 1 1 0 1.406h-1.31V17.698h1.31a.703.703 0 1 1 0 1.407H20.89a2.596 2.596 0 0 1-1.785 1.785v1.407a.703.703 0 1 1-1.407 0v-1.31h-4.995v1.31a.703.703 0 1 1-1.406 0v-1.31H6.302v1.31a.703.703 0 1 1-1.407 0V20.89a2.596 2.596 0 0 1-1.785-1.785H1.703a.703.703 0 1 1 0-1.407h1.31v-4.995h-1.31a.703.703 0 1 1 0-1.406h1.31V6.302h-1.31a.703.703 0 1 1 0-1.407h1.408A2.595 2.595 0 0 1 4.895 3.11V1.703a.703.703 0 0 1 1.407 0v1.31h4.995v-1.31a.703.703 0 1 1 1.406 0v1.31H17.7v-1.31a.703.703 0 1 1 1.406 0V3.11a2.596 2.596 0 0 1 1.785 1.785h1.407a.703.703 0 1 1 0 1.407h-1.31v1.794zm-2.409 11.642c.662 0 1.201-.54 1.201-1.201V5.502c0-.662-.539-1.201-1.2-1.201H5.543c-.662 0-1.2.539-1.2 1.201v13.035c0 .662.538 1.2 1.2 1.2H18.58z"
        id="hardware__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="hardware__b">
        <use xlinkHref="#hardware__a" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#hardware__a" />
      <g mask="url(#hardware__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

HardwareIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

HardwareIcon.defaultProps = {
  width: 24,
  height: 24
}

export default HardwareIcon
