import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'

import {
  LightStageIcon,
  WaterStageIcon,
  FanStageIcon,
  StagePopupCloseIcon
} from 'src/components/Icons'

const Root = styled.div`
  position: relative;

  width: 200px;
  background-color: ${(p) => p.theme.white};
  padding: 10px 0;
  border-radius: 2px;

  box-shadow: 0 2px 8px 0 rgba(225, 225, 225, 0.6);
`

const List = styled.ul`
  list-style-type: none;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
`

const StageIconWrapper = styled.li`
  height: 30px;
  width: 20px;
  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :not(:first-child) {
    border-left: 1px solid ${transparentize(0.8, '#d8d8d8')};
  }

  svg path {
    fill: ${(p) =>
      /* eslint-disable */
        p.isWater ? '#8acff0' 
      : p.isLight ? '#ffb236'
      : p.isFan   ? '#9ee4D8'
      : '#c2c2c2'
      /* eslint-enable */
    };
  }
`

const CloseButton = styled.button`
  background: none;
  border: none;

  background-color: ${(p) => p.theme.white};
  position: absolute;
  top: -11px;
  right: -11px;
  cursor: pointer;
  border-radius: 2px;

  box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.5);

  height: 22px;
  width: 22px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${(p) => transparentize(0.6, p.theme.orange)};
  }
`

const disabledButton = css`
  svg path {
    fill: #c7c7c7;
  }
`

export const StageTypeSelectorPopup = ({
  onClose,
  className,
  hasWaterSchedule,
  hasLightSchedule,
  hasFanSchedule,
  onRedirect
}) => {
  return (
    <Root className={className}>
      <CloseButton onClick={onClose}>
        <StagePopupCloseIcon />
      </CloseButton>
      <List>
        <StageIconWrapper
          onClick={(e) => {
            e.stopPropagation()
            onRedirect('lighting')
          }}
          isLight={hasLightSchedule}
        >
          <LightStageIcon />
        </StageIconWrapper>
        <StageIconWrapper
          css={disabledButton}
          onClick={(e) => e.stopPropagation()}
          isWater={hasWaterSchedule}
        >
          <WaterStageIcon />
        </StageIconWrapper>
        <StageIconWrapper
          css={disabledButton}
          onClick={(e) => e.stopPropagation()}
          isFan={hasFanSchedule}
        >
          <FanStageIcon />
        </StageIconWrapper>
      </List>
    </Root>
  )
}

StageTypeSelectorPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  className: PropTypes.string,
  hasWaterSchedule: PropTypes.bool.isRequired,
  hasLightSchedule: PropTypes.bool.isRequired,
  hasFanSchedule: PropTypes.bool.isRequired
}
