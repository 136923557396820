export const unitsMap = {
  temperature: {
    name: 'Temperature',
    units: 'C°'
  },
  humidity: {
    name: 'Humidity',
    units: '%'
  },
  co2: {
    name: 'Carbon dioxide',
    units: 'ppm'
  },
  water: {
    name: 'Total water consumption',
    units: 'Gal'
  },
  electricity: {
    name: 'Electricity consumption',
    units: 'kW'
  }
}

const sensorTypesMap = {
  temperature: 'temperature_sensor_logs',
  co2: 'co2_sensor_logs',
  humidity: 'humidity_sensor_logs',
  water: 'water_sensor_logs',
  electricity: 'electricity_sensor_logs'
}

export const extractSensorData = (sensorType, data) => {
  const dataKey = sensorTypesMap[sensorType]

  const extracted = data.reduce((acc, dayData) => {
    const dataFromDay = dayData[dataKey].map((dataItem) => ({
      y: dataItem.value,
      x: dataItem.created_at
    }))
    return [...acc, ...dataFromDay]
  }, [])

  return extracted
}

/*
output format
{
  'temperature': [
    { cycleId, cycleName,data: [x,y]}
    { cycleId, cycleName,data: [x,y]}
    ...
  ]
}
*/
export const extractDataForCompare = (cycles) => {
  let dataArrays = {}

  for (let key in sensorTypesMap) {
    dataArrays[key] = []
  }

  // iterate over cycles
  cycles.forEach((cycle) => {
    let cycleDataArrays = {}

    let startAt = {}
    for (let key in sensorTypesMap) {
      // set startAt as date of first sensor log date
      startAt[key] = cycle.days[0][sensorTypesMap[key]][0].created_at
    }

    // for each cycle we will store data in object with same shape as sensorTypesMap
    for (let key in sensorTypesMap) {
      cycleDataArrays[key] = []
    }

    // iterate over days of each cycle
    cycle.days.forEach((day) => {
      // for each day we store value inside
      // a corresponding property of cycleDataArrays
      for (let key in sensorTypesMap) {
        const dataKey = sensorTypesMap[key]

        // we iterate over each day extracting values we want
        // for x-axis we take how much time passed from cycle start
        const dataFromDay = day[dataKey]
          .map((dataItem) => ({
            y: dataItem.value,
            x: dataItem.created_at - startAt[key]
          }))
          .reverse()
        // and than spread it inside out object
        cycleDataArrays[key].push(...dataFromDay)
      }
    })

    // now we spread all data we got from this cycle into main object (see output format comment above)
    for (let key in sensorTypesMap) {
      dataArrays[key] = [
        ...dataArrays[key],
        { cycleId: cycle.id, cycleName: cycle.name, data: cycleDataArrays[key] }
      ]
    }
  })

  return dataArrays
}
