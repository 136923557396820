import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { SelectField } from "./SelectField";

import { Button, H3 } from "src/ui";

const Root = styled.form`
  background-color: ${(p) => p.theme.white};
  width: 368px;
  padding: 24px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Header = styled(H3)`
  padding: 0;
  color: ${(p) => p.theme.darkGray};
  ${(p) => p.theme.ProximaNovaBold}
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 24px;
  margin-top: 30px;
`;

const Hint = styled.p`
  ${(p) => p.theme.ProximaNova}
  color: ${(p) => p.theme.green};
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 24px;
`;

const ConfirmButton = styled(Button)`
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  width: 100%;
  padding: 14px 30px;
`;

const FieldWrapper = styled.label`
  margin-top: 42px;
  width: 100%;
`;

const LabelText = styled.p`
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.green};
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
`;

const StretchedSelectField = styled(SelectField)`
  margin-bottom: 24px;
  height: 56px;
`;

const DepartmentSelectField = styled(SelectField)`
  margin-bottom: 24px;
  height: 56px;
`;

const FullnameField = styled.input`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid ${(p) => p.theme.textDisable};

  :focus-within {
    border: 1px solid ${(p) => p.theme.orange};
  }

  ::placeholder {
    color: ${(p) => p.theme.textDisable};
  }
`;

export const NewEmployeeForm = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Header>{t("new_employee")}</Header>
      <Hint>{t("new_employee_form_description")}</Hint>

      <FieldWrapper>
        <FullnameField placeholder={t("full_name")} />
      </FieldWrapper>

      <FieldWrapper>
        <LabelText>{t("position")}</LabelText>
        <StretchedSelectField placeholder={t("position")} />
      </FieldWrapper>

      <FieldWrapper>
        <LabelText>{t("department")}</LabelText>
        <DepartmentSelectField placeholder={t("facility")} />
      </FieldWrapper>
      <ConfirmButton inset>{t("create").toUpperCase()}</ConfirmButton>
    </Root>
  );
};
