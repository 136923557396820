import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

export const GlobalStyles = createGlobalStyle`
  ${styledNormalize};

  html {
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    word-break: break-word;
  }

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    outline: none;
    padding: 0;
    margin: 0;
    transform-origin: center;
  }

  *:focus {
    outline: none !important;
  }

  body {
    background-color: ${(p) => p.theme.grayBg};
    ${(p) => p.theme.ProximaNova};
  }
`;
