import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const AsideNavItem = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 0 16px 32px;
  text-align: left;
  cursor: pointer;
  color: ${(p) => p.theme.grayBg};
  text-decoration: none;
  user-select: none;
  background-color: transparent;
  outline: none;
  transition: all 0.2s ease-out;
  font-size: 18px;
  font-weight: 400;
  flex-shrink: 0;

  svg {
    fill: ${(p) => p.theme.green};
    margin-right: 14px;
    transition: all 0.2s ease-out;
  }

  &.active {
    svg {
      fill: ${(p) => p.theme.orange};
    }
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    text-decoration: none;
    color: ${(p) => p.theme.lightestGreen};
    ${(p) => p.theme.ProximaNovaSemibold}
    background-color: ${(p) => p.theme.activeGray};
  }
`
