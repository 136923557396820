import React from 'react'

const ArrowIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 10 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Prototype" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g
        id="Farm"
        transform="translate(-286.000000, -39.000000)"
        fillRule="nonzero"
      >
        <g id="right-arrow-(7)" transform="translate(286.000000, 39.000000)">
          <path
            d="M9.7284857,8.29763986 L2.15369023,0.287558638 C1.97849445,0.102145511 1.74462332,0 1.49525303,0 C1.24588274,0 1.01201161,0.102145511 0.836815832,0.287558638 L0.278984739,0.877309941 C-0.0839998694,1.2615995 -0.0839998694,1.88617979 0.278984739,2.26988398 L6.63972608,8.99626832 L0.27192709,15.730116 C0.0967313158,15.9155291 0,16.1626979 0,16.4262567 C0,16.6901082 0.0967313158,16.9372769 0.27192709,17.1228364 L0.829758183,17.7124414 C1.00509234,17.8978545 1.23882509,18 1.48819538,18 C1.73756567,18 1.9714368,17.8978545 2.14663258,17.7124414 L9.7284857,9.69504313 C9.90409663,9.50904464 10.0005512,9.2607052 9.99999763,8.99670734 C10.0005512,8.73168511 9.90409663,8.483492 9.7284857,8.29763986 Z"
            id="Shape"
            transform="translate(5.000000, 9.000000) scale(-1, 1) translate(-5.000000, -9.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowIcon
