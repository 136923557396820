import React from 'react'

import { SensorsSettingTable } from './SensorsSettingTable'

export const CO2Alarms = ({ growingCycle }) => (
  <SensorsSettingTable
    growingCycle={growingCycle}
    initialValues={{ minValue: 1000, maxValue: 2000 }}
    sensorType={'co2'}
    formTitle={'CO2 Sensor Alarm'}
    label={'co2'}
    units={'ppm'}
    data={growingCycle.co2AlarmConfigs}
  />
)
