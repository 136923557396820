import React from 'react'
import PropTypes from 'prop-types'

const VirtualIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Prototype"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard"
        transform="translate(-23.000000, -386.000000)"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(23.000000, 386.000000)">
          <path
            d="M12,2 C5.3725991,2 0,7.14874081 0,13.5 C0,19.8512592 5.3725991,25 12,25 C18.6274009,25 24,19.8514061 24,13.5001469 C23.9999234,7.14866737 18.6274009,2 12,2 Z M12,19.2861284 C8.6654577,19.2861284 5.96222415,16.6955296 5.96222415,13.5 C5.96222415,10.304397 8.6654577,7.71379815 12,7.71379815 C15.3345423,7.71379815 18.0377758,10.304397 18.0377758,13.5 C18.0376226,16.6955296 15.3344657,19.2861284 12,19.2861284 Z"
            id="Shape"
          />
          <path
            d="M12,24 C5.38316835,24 0,18.6168633 0,12.0000705 C0,5.38327765 5.3830979,0 12,0 C18.6169021,0 24,5.38313675 24,12 C24,18.6168633 18.6167612,24 12,24 Z M12,2.56624153 C6.79811262,2.56624153 2.56625216,6.79820918 2.56625216,11.999934 C2.56625216,17.2016588 6.79817864,21.4337585 12,21.4337585 C17.2018214,21.4337585 21.4337478,17.2017908 21.4337478,12.000066 C21.4337478,6.79834122 17.2017553,2.56624153 12,2.56624153 Z M12,18.8588317 C8.21802002,18.8588317 5.14105568,15.7820338 5.14105568,12.0000018 C5.14105568,8.21796993 8.21787174,5.1410978 12,5.1410978 C15.7821283,5.1410978 18.8589443,8.21804407 18.8589443,12.0000018 C18.8589443,15.7819596 15.78198,18.8588317 12,18.8588317 Z M12,8.52681996 C10.0849276,8.52681996 8.52681665,10.0848684 8.52681665,11.9999829 C8.52681665,13.9150974 10.0848742,15.4730391 12,15.4730391 C13.9151258,15.4730391 15.4731833,13.9150441 15.4731833,11.9999829 C15.4731833,10.0849217 13.9149657,8.52681996 12,8.52681996 Z"
            id="Shape"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>
)

VirtualIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

VirtualIcon.defaultProps = {
  width: 24,
  height: 25
}

export default VirtualIcon
