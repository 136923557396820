import React, { Fragment } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Yield } from "src/components/Icons";
import { DataName, DataValue } from "src/ui/Analytics";

const CardRoot = styled.li`
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.white};
  cursor: pointer;
  transition: 0.3s;

  :hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    transition: 0.3s;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const ID = styled.span`
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  font-size: 18px;
  line-height: 22px;
  margin-left: 16px;
  margin-right: 5px;
`;

const Field = styled.span`
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.textGrayAux};
  font-size: 18px;
  font-style: italic;
  line-height: 22px;
`;

const DataItem = styled.p`
  margin: 0;
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;
const Name = styled(DataName)`
  width: 120px;
  margin-right: 8px;

  @media (max-width: 1700px) {
    width: 100px;
  }
`;

const Rate = styled.span`
  display: inline-block;
  color: ${(p) => p.theme.yieldGreen};
  margin-left: 5px;

  ${(p) =>
    p.type === "minus" &&
    `
    color: ${p.theme.darkRed};
  `}
`;

const DataElement = ({ name, value }) => (
  <DataItem>
    <Name>{name}:</Name>
    <DataValue>{value}</DataValue>
  </DataItem>
);

const FieldItemView = ({ field }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const yieldValue = (
    <Fragment>
      {field.yield}
      <Rate type={field.rating.type}>({field.rating.value})</Rate>
    </Fragment>
  );

  return (
    <CardRoot>
      <StyledLink to={`${location.pathname}/${field.id}`}>
        <Title>
          <Yield />
          <ID>ID {field.id}</ID>
          <Field>{t("field")}</Field>
        </Title>
        <DataElement name={t("crop")} value={field.crop} />
        <DataElement name={t("growth_phase")} value={field.phase} />
        <DataElement name={t("yield_forecast")} value={yieldValue} />
      </StyledLink>
    </CardRoot>
  );
};

export const FieldItem = FieldItemView;
