import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select from 'react-select'

import {CutPieIcon} from 'src/components/Icons'

const generateList = (length) =>
  Array(length)
    .fill()
    .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }))

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${(p) => p.theme.green};
  }
`

const Control = styled.div`
  background-color: ${(p) => p.theme.white};
  height: 50px;
  width: 212px;
  border: 1px solid #BFC4CD;
  border-radius: 2px;
  padding: 15px;

  display: flex;
  justify-content: space-between;

  :focus-within {
    box-shadow: 0 0 1px 1px ${(p) => p.theme.orange};
  }
`

export const SelectDurationInput = ({
  value,
  onChange,
  listLength,
  units,
  icon,
  className,
  ...props
}) => {
  const options = generateList(listLength)

  return (
    <Select
      icon={icon}
      getOptionLabel={(option) => `${option.label}`}
      isSearchable={false}
      value={{ label: `${value.toString()} ${value > 1 ? units+'s' : units}` }}
      onChange={(selectedOption) => onChange(selectedOption.value)}
      options={options}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Control: ({ innerProps, innerRef, children }) => (
          <Control className={className} ref={innerRef} {...innerProps}>
            <IconWrapper {...innerProps} ref={innerRef}>
              <CutPieIcon width={20} height={20} />
            </IconWrapper>
            {children}
          </Control>
        )
      }}
      {...props}
    />
  )
}

SelectDurationInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  listLength: PropTypes.number,
  units: PropTypes.string
}

SelectDurationInput.defaultProps = {
  listLength: 20,
  units: ''
}
