import React from "react";
import moment from "moment";
import styled from "styled-components";
import { DefaultPlayer as Video } from "react-html5video";
import { useTranslation } from "react-i18next";

const Root = styled.div`
  width: 50vw;
  background-color: ${(p) => p.theme.white};
  padding: 24px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Title = styled.p`
  ${(p) => p.theme.ProximaNovaBold}
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 24px;
  margin-top: 30px;
`;

const Time = styled.span`
  display: inline-block;
  ${(p) => p.theme.ProximaNova}
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.darkGray};
  margin-bottom: 24px;
`;

const VideoWrapper = styled.div`
  width: 100%;
`;

export const VideoPopup = ({ video }) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Title>
        {t("field")} {video.title}
      </Title>
      <Time>{moment().format("LT")}</Time>
      <VideoWrapper>
        <Video
          loop
          controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
        >
          <source src={video.content} type="video/mp4" />
        </Video>
      </VideoWrapper>
    </Root>
  );
};
