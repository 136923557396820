import React from "react";
import { Trans } from "react-i18next";

export const defaultFields = [
  {
    id: 2599877,
    avr: 25,
    res: "bad",
    area: 150,
    crop: <Trans i18nKey="boston_lettuce" />,
    phase: <Trans i18nKey="catagen" />,
    temperature: "24 °C",
    humidity: "46%",
    windSpeed: (
      <span>
        5 <Trans i18nKey="meters_sek" />
      </span>
    ),
    atmospherePressure: (
      <span>
        1013 <Trans i18nKey="hPa" />
      </span>
    ),
    water: (
      <span>
        120 000 <Trans i18nKey="liters" />
      </span>
    ),
    avrHumidity: "38%",
    avrSoilTemperature: "18 °C",
    waterConsumption: {
      today: 1700,
      nextTwoDays: 2900,
    },
    warnings: [
      {
        id: 1,
        title: <Trans i18nKey="low_soil_temperature" />,
        description: <Trans i18nKey="low_soil_temperature_value" />,
      },
      {
        id: 2,
        title: <Trans i18nKey="soil_drying_up" />,
        description: <Trans i18nKey="soil_drying_up_value" />,
      },
    ],
    recommendation: {
      title: <Trans i18nKey="expert_recommendations" />,
      description: <Trans i18nKey="expert_recommendation_water_text" />,
    },
    sensors: [
      {
        id: 54781,
        condition: true,
        moisture: 60,
        temperature: 23,
        level: 35,
        left: 590,
        top: 260,
      },
    ],
  },
  {
    id: 252277,
    avr: 205,
    res: "success",
    area: 150,
    crop: <Trans i18nKey="boston_lettuce" />,
    phase: <Trans i18nKey="catagen" />,
    temperature: "24 °C",
    humidity: "46%",
    windSpeed: (
      <span>
        5 <Trans i18nKey="meters_sek" />
      </span>
    ),
    atmospherePressure: (
      <span>
        1013 <Trans i18nKey="hPa" />
      </span>
    ),
    water: (
      <span>
        120 000 <Trans i18nKey="liters" />
      </span>
    ),
    avrHumidity: "38%",
    avrSoilTemperature: "18 °C",
    waterConsumption: {
      today: 1700,
      nextTwoDays: 2900,
    },
    warnings: [
      {
        id: 1,
        title: <Trans i18nKey="low_soil_temperature" />,
        description: <Trans i18nKey="low_soil_temperature_value" />,
      },
      {
        id: 2,
        title: <Trans i18nKey="soil_drying_up" />,
        description: <Trans i18nKey="soil_drying_up_value" />,
      },
    ],
    recommendation: {
      title: <Trans i18nKey="expert_recommendations" />,
      description: <Trans i18nKey="expert_recommendation_water_text" />,
    },
    sensors: [
      {
        id: 54778,
        condition: true,
        moisture: 58,
        temperature: 24,
        level: 33,
        left: 490,
        top: 510,
      },
    ],
  },
  {
    id: 252278,
    avr: 103,
    res: "success",
    area: 150,
    crop: <Trans i18nKey="boston_lettuce" />,
    phase: <Trans i18nKey="catagen" />,
    temperature: "24 °C",
    humidity: "46%",
    windSpeed: (
      <span>
        5 <Trans i18nKey="meters_sek" />
      </span>
    ),
    atmospherePressure: (
      <span>
        1013 <Trans i18nKey="hPa" />
      </span>
    ),
    water: (
      <span>
        120 000 <Trans i18nKey="liters" />
      </span>
    ),
    avrHumidity: "38%",
    avrSoilTemperature: "18 °C",
    waterConsumption: {
      today: 1700,
      nextTwoDays: 2900,
    },
    warnings: [
      {
        id: 1,
        title: <Trans i18nKey="low_soil_temperature" />,
        description: <Trans i18nKey="low_soil_temperature_value" />,
      },
      {
        id: 2,
        title: <Trans i18nKey="soil_drying_up" />,
        description: <Trans i18nKey="soil_drying_up_value" />,
      },
    ],
    recommendation: {
      title: <Trans i18nKey="expert_recommendations" />,
      description: <Trans i18nKey="expert_recommendation_water_text" />,
    },
    sensors: [
      {
        id: 54787,
        condition: true,
        moisture: 46,
        temperature: 21,
        level: 31,
        left: 390,
        top: 378,
      },
      {
        id: 54788,
        condition: true,
        moisture: 42,
        temperature: 22,
        level: 34,
        left: 340,
        top: 404,
      },
      {
        id: 54789,
        condition: true,
        moisture: 44,
        temperature: 23,
        level: 33,
        left: 302,
        top: 444,
      },
    ],
  },
];

export const moistureTicks = [0, 20, 30, 50, 60, 70, 80];

export const temperatureTicks = [0, 5, 10, 15, 20, 25, 30];
