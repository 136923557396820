import React from "react";
import moment from "moment";
import { Trans } from "react-i18next";

const startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
startDate.setHours(0);
startDate.setMinutes(0);
startDate.setSeconds(0);
startDate.setMilliseconds(0);

let t = 32;

const getNextTime = () =>
  new Date(startDate.setHours(startDate.getHours() + 1)).toLocaleTimeString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }
  );

const chartData = [
  {
    time: startDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
  {
    time: getNextTime(),
    t: ++t,
  },
];

export const ALERT_ANALYTICS_DATA_STUB = [
  {
    id: "description",
    transId: "soil_temperature_exceeds_critical_level",
  },
  {
    id: "chart",
    data: chartData,
  },
  {
    id: "causes",
    transId: "possible_causes",
  },
  {
    id: "consequences",
    transId: "possible_consequences",
  },
  {
    id: "offers",
    transId: "offers",
  },
];

const today = moment().unix();
const yesterday = moment()
  .subtract(1, "days")
  .unix();

export const alertsTableDataStub = [
  {
    createdAt: today,
    id: "206",
    zone: "#5324",
    message: (
      <Trans i18nKey="temperature_above_setpoint">
        Temperature above set point ({">"}10%)
      </Trans>
    ),
    resolved: false,
    typename: "Sensor",
    priority: "Urgent",
    analytics: ALERT_ANALYTICS_DATA_STUB,
  },
  {
    createdAt: today,
    id: "207",
    zone: "#5324",
    message: (
      <Trans i18nKey="substrate_moisture_high">Substrate moisture high</Trans>
    ),
    resolved: false,
    typename: "Crop",
    priority: "Urgent",
  },
  {
    createdAt: yesterday,
    id: "208",
    zone: "#5324",
    message: (
      <Trans i18nKey="crop_will_be_ready_to_harvest">
        Your crop will be ready to harvest in 10 hours
      </Trans>
    ),
    resolved: false,
    typename: "Sensor",
    priority: "High",
  },
  {
    createdAt: yesterday,
    id: "209",
    zone: "#5324",
    message: (
      <Trans i18nKey="malfunction_in_lighting_hardware">
        Malfunction in lighting hardware
      </Trans>
    ),
    resolved: true,
    typename: "Hardware",
    priority: "Low",
  },
  {
    createdAt: yesterday,
    id: "210",
    zone: "#5324",
    message: (
      <Trans i18nKey="malfunction_in_lighting_hardware">
        Malfunction in lighting hardware
      </Trans>
    ),
    resolved: false,
    typename: "Sensor",
    priority: "Low",
  },
  {
    createdAt: yesterday,
    id: "211",
    zone: "#5324",
    message: (
      <Trans i18nKey="malfunction_in_lighting_hardware">
        Malfunction in lighting hardware
      </Trans>
    ),
    resolved: true,
    typename: "Hardware",
    priority: "Low",
  },
];
