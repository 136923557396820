import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentBox } from "src/components/ContentBox";
import { TreeLeaf } from "src/components/Icons";

const windowWidth = window.innerWidth;
const blockWidth = windowWidth < 1400 ? 50 : 25;

export function SoilMoistureSensor() {
  const { t } = useTranslation();
  const [sensor, setSensor] = useState({ signal: 52, defined: 0 });

  useEffect(() => {
    const interval = setInterval(() => {
      setSensor((prevSensor) => ({
        ...prevSensor,
        signal: Math.floor(Math.random() * 4 + 51),
      }));
    }, 3000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <ContentBox
      width={`${blockWidth}%`}
      padding={12}
      title={t("soil_moisture")}
      description="%"
      amount={sensor.signal}
      amountName={t("avg")}
      totalAmount="37-62"
      totalName={t("range")}
      icon={<TreeLeaf />}
      res="+11%"
      resType="bad"
    />
  );
}
