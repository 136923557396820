import styled from "styled-components";
import React from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { PageHeader } from "src/ui";
import { FiltersForm } from "src/components/growthCycles";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 16px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

export const ActionHeader = ({
  title,
  setStubData,
  growingCyclesStub,
  error,
}) => {
  const { t } = useTranslation();

  if (error) {
    toast.error(t("loading_cycles_error"));

    return null;
  }

  return (
    <PageHeader title={title} subTitle="Growth cycles">
      <ActionsWrapper>
        <FiltersForm stubData={growingCyclesStub} setStubData={setStubData} />
      </ActionsWrapper>
    </PageHeader>
  );
};
