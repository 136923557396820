import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Link, useLocation} from 'react-router-dom';

import { CyclesBaseTable, HeaderCell, Cell } from '../index';

import { DropdownRow } from './DropdownRow';

const LinkToShowPage = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const CompletedCyclesTableView = ({ data, className }) => {
  const location = useLocation()

  return (
    <CyclesBaseTable
      className={className}
      data={data}
      getTrGroupProps={(_, data) => ({ data })}
      defaultSorted={[{
        id: 'endAt',
        desc: true
      }]}
      TrGroupComponent={({ ...props }) => <DropdownRow {...props} />}
      columns={[
        {
          accessor: 'name',
          Header: <HeaderCell>Name</HeaderCell>,
          width: 465,
          Cell: ({ value, original }) => (
            <Cell>
              <LinkToShowPage to={`${location.pathname}${original.id}`}>
                {value}
              </LinkToShowPage>
            </Cell>
          ),
        },
        {
          accessor: 'crop',
          Header: <HeaderCell>Crop</HeaderCell>,
          width: 445,
          Cell: ({ value }) => <Cell>{value.name}</Cell>,
        },
        {
          accessor: 'durationInDays',
          Header: (
            <HeaderCell>
              Duration <span>days</span>
            </HeaderCell>
          ),
          width: 160,
          Cell: ({ value }) => <Cell>{value}</Cell>,
        },
        {
          accessor: 'startAt',
          Header: <HeaderCell>Start date</HeaderCell>,
          width: 153,
          Cell: (row) => (
            <Cell>{moment.unix(row.value).format('DD.MM.YY')}</Cell>
          ),
        },
        {
          Header: <HeaderCell>End date</HeaderCell>,
          accessor: 'endAt',
          width: 130,
          Cell: (row) => (
            <Cell>{moment.unix(row.value).format('DD.MM.YY')}</Cell>
          ),
        },
        {
          Header: '',
          accessor: 'arrow',
          width: 140,
        },
      ]}
    />
  );
};

export const CompletedCyclesTable = CompletedCyclesTableView
