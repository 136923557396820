import React from "react";
import { Trans } from "react-i18next";

export const aiRecommendationsStub = {
  listEquipment: [
    {
      date: "2022/08/05",
      label: <Trans i18nKey="first_equipment_recommendation" />,
      text: <Trans i18nKey="recommendation_text" />,
      status: 3,
    },
    {
      date: "2022/08/05",
      label: <Trans i18nKey="second_equipment_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 3,
    },
    {
      date: "2022/08/05",
      label: <Trans i18nKey="third_equipment_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 2,
    },
    {
      date: "2022/08/05",
      label: <Trans i18nKey="fourth_equipment_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 1,
    },
    {
      date: "2022/08/05",
      label: <Trans i18nKey="fifth_equipment_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 1,
    },
  ],
  agroTechnologyList: [
    {
      date: "2022/08/05",
      label: <Trans i18nKey="first_argo_tech_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 3,
    },
    {
      date: "2022/08/05",
      label: <Trans i18nKey="second_argo_tech_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 3,
    },
    // {
    //   date: "2022/08/05",
    //   label: <Trans i18nKey="third_argo_tech_recommendation" />,
    //   text:
    //     "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
    //   status: 2,
    // },
    {
      date: "2022/08/05",
      label: <Trans i18nKey="fourth_argo_tech_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 2,
    },
    // {
    //   date: "2022/08/05",
    //   label: <Trans i18nKey="fifth_argo_tech_recommendation" />,
    //   text:
    //     "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
    //   status: 1,
    // },
    // {
    //   date: "2022/08/05",
    //   label: <Trans i18nKey="sixth_argo_tech_recommendation" />,
    //   text:
    //     "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
    //   status: 1,
    // },
    {
      date: "2022/08/05",
      label: <Trans i18nKey="seventh_argo_tech_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 1,
    },
  ],
  resourceEfficiencyList: [
    {
      date: "2022/08/05",
      label: <Trans i18nKey="first_resource_efficiency_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 3,
    },
    {
      date: "2022/08/05",
      label: <Trans i18nKey="second_resource_efficiency_recommendation" />,
      text:
        "Based on the operating and inspection data, we are strongly recommended that the pump ID_455/2 is replaced with a new pump within the next ten days. The probability of failure after this period is over 80%.\nThe equipment can be handed over for overhaul and then put into reserve with a remaining life of approx. 85-90%. This will save you around $4000 over the next two years. ",
      status: 1,
    },
  ],
};
