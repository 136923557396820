import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { DateRangePicker } from "./DateRangePicker";

import { SelectWrapper } from "src/ui";

export const FilterDateRangeSelect = ({ value, onChange, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <SelectWrapper>
      <DateRangeSelect
        pickerPosition="bottom"
        placeholder={
          <Placeholder>
            <span>{t("period")}</span> ({t("start_end_date")})
          </Placeholder>
        }
        displayFormat="DD.MM"
        onChange={onChange}
        value={value}
        isDisabled={isDisabled}
      />
    </SelectWrapper>
  );
};

const DateRangeSelect = styled(DateRangePicker)`
  background-color: ${(p) => p.theme.white};
  height: 50px;
  min-width: 232px;
  border-radius: 4px;

  svg path {
    fill: ${(p) => p.theme.green};
  }
`;

const Placeholder = styled.p`
  margin: 0;
  font-size: 14px;
  > span {
    color: ${(p) => p.theme.darkGray};
  }

  overflow: hidden;
  text-overflow: ellipsis;
`;
