import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "src/ui";
import { ArrowIcon } from "src/components/Icons";
import { useAlertsContext } from "src/store";
import { ALERT_ANALYTICS_DATA_STUB } from "src/stubs";
import { AlertAiAnalyticsModal } from "src/components/alerts/AlertAIAnalytics";

export const StatusCell = (row) => {
  const {
    getAlert,
    resolveAlert,
    setAnalytics,
    closeModal,
    openModal,
  } = useAlertsContext();
  const { t } = useTranslation();

  const { id, priority, status, resolved } = row.original;

  const alert = getAlert(id);

  const onClick = () => resolveAlert(id);

  const toggleAnalytics = () => {
    setAnalytics((prevAnalytics) => {
      if (prevAnalytics.alertId) {
        closeModal();

        return {};
      }
      openModal();

      return { alertId: id, data: ALERT_ANALYTICS_DATA_STUB };
    });
  };

  return (
    <Cell>
      {!resolved && !alert?.analytics && (
        <ResolveBtn inset onClick={onClick} priority={priority} status={status}>
          {t("resolve")}
        </ResolveBtn>
      )}
      {!resolved && alert?.analytics && (
        <DetailsWrapperButton onClick={toggleAnalytics}>
          <StatusText>{t("details")}</StatusText>
          <ArrowIconWrapper>
            <ArrowIcon />
          </ArrowIconWrapper>
        </DetailsWrapperButton>
      )}
      {resolved && <StatusText>{t("resolved")}</StatusText>}
      {alert && alert.analytics && <AlertAiAnalyticsModal />}
    </Cell>
  );
};

const Cell = styled.div`
  text-align: center;
  width: 100%;
`;

const ResolveBtn = styled(Button)`
  font: ${(p) => p.theme.ProximaNova};
  font-size: 18px;
  line-height: 24px;
  padding: 13px 24px;
  margin: 0 auto;
`;

const StatusText = styled.div`
  text-align: center;
  ${(p) => p.theme.ProximaNova};
  text-transform: uppercase;
  color: ${(p) => p.theme.textGrayAux};
`;

export const DetailsWrapperButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
`;

const ArrowIconWrapper = styled.div`
  margin-left: 10px;
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
`;
