import React from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'

import {
  Alerts,
  AIRecommendations,
  AIWaterManagement,
  AIWaterManagementSensors,
  AIWaterManagementStatistic,
  GrowthCyclesCompleted,
  GrowingCyclesCompare,
  Dashboard,
  ForecastOfCriticalSituations,
  ForecastOfCriticalSituationInfo,
  GrowingCycles,
  GrowthCyclesInProgress,
  LoginPage,
  GrowthCyclesPlanned,
  GrowthCyclesPlannedSettings,
  Settings,
  YieldForecast,
  YieldForecastStatistics,
} from "src/pages"
import {PrivateRoute} from 'src/components'
import {GrowingStage} from 'src/components/growingStage'


export const AppRoutes = () => {
  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     document.activeElement.blur()
  //   })
  //
  //   return unlisten()
  // })

  return (
    <Routes>
      <Route exact path='login' element={<LoginPage />} />
      <Route element={<PrivateRoute />} >
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='alerts' element={<Alerts />} />
        <Route path='analytics'>
          <Route
            path='yield-forecast'
            element={<YieldForecast />}
          />
          <Route
            path='yield-forecast/:yieldId'
            element={<YieldForecastStatistics />}
          />
          <Route
            path='ai-water-management'
            element={<AIWaterManagement />}
          />
          <Route
            path='ai-water-management/:fieldId'
            element={<AIWaterManagementStatistic />}
          />
          <Route
            path='ai-water-management/:fieldId/sensors'
            element={<AIWaterManagementSensors />}
            exact
          />
  
          <Route
            path='growing-cycles'
            element={<GrowingCycles />}
          />
          <Route
            path='growing-cycles/compare'
            element={<GrowingCyclesCompare />}
          />
  
          <Route
            path='forecast-of-critical-situations'
            element={<ForecastOfCriticalSituations />}
          />
          <Route
            path='forecast-of-critical-situations/:id'
            element={<ForecastOfCriticalSituationInfo />}
          />
  
          <Route
            path='ai-recommendations'
            element={<AIRecommendations />}
          />
        </Route>

        <Route path='growth-cycles' >
          <Route path='completed' element={<GrowthCyclesCompleted />} />

          <Route path='in-progress' element={<GrowthCyclesInProgress />} />

          <Route path='planned' element={<GrowthCyclesPlanned />}
          />

          <Route
            path='planned/:cycleId/settings/growing-stage/:growingStageId'
            element={<GrowingStage />}
          />
          <Route
            path='planned/:cycleId/settings'
            element={<GrowthCyclesPlannedSettings />}
          />
        </Route>

        <Route path='settings' element={<Settings />} />
        <Route path="*" element={<Navigate to='dashboard'/>} />
      </Route>
    </Routes>
  )
}
