import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EmployeesList, NewEmployeeForm } from "../components/management";

import { Modal } from "src/components";
import { Button, PageHeader, Root, RowWrapper } from "src/ui";
import { Toggler } from "src/utils/toggler";
import { TreeLeaf, DropdownArrow } from "src/components/Icons";

const ThreeFour = styled.div`
  display: flex;
  width: 75%;
  height: 100%;
  padding: 12px;
  align-items: center;
`;

const OneFour = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 12px;
  align-items: flex-start;
`;

const NewEmployeeBtn = styled(Button)`
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  padding: 14px 24px;
`;

const BtnLink = styled(Link)`
  ${(p) => p.theme.ProximaNovaBold}
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  line-height: 30px;
  width: 100%;
  min-height: 77px;
  padding: 24px;
  background-color: ${(p) => p.theme.white};
  color: ${(p) => p.theme.activeGray};
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;

  @media(max-width: 1400px) {
   padding: 10px;
  }
}

  :hover {
    svg path {
      fill: ${(p) => p.theme.green};
      transition: 0.2s;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
  }

  @media (max-width: 1400px) {
    font-size: 20px;
    line-height: normal;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg path {
    fill: ${(p) => p.theme.textGrayAux};
    transform: rotate(-90deg);
    transition: 0.2s;
  }
`;

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <RowWrapper>
        <PageHeader title={t("management")} isThreeFourWidth>
          <ActionsWrapper>
            <Toggler>
              {({ onToggle, toggled }) => (
                <Fragment>
                  <Modal isOpen={toggled} onClose={() => onToggle(false)}>
                    <NewEmployeeForm />
                  </Modal>
                  <NewEmployeeBtn inset onClick={() => onToggle(true)}>
                    {t("new_employee").toUpperCase()}
                  </NewEmployeeBtn>
                </Fragment>
              )}
            </Toggler>
          </ActionsWrapper>
        </PageHeader>
        {/* <SettingsHeader>{t("settings")}</SettingsHeader> */}
      </RowWrapper>
      <RowWrapper>
        {/* <ThreeFour> */}
        <EmployeesList />
        {/* </ThreeFour> */}
        {/* <OneFour>
          <BtnLink to="/growth-cycles/planned/14/settings">
            <TitleWrapper>
              <TreeLeaf />
              {t("planned_settings")}
            </TitleWrapper>
            <IconWrapper>
              <DropdownArrow width={16} height={16} />
            </IconWrapper>
          </BtnLink>
        </OneFour> */}
      </RowWrapper>
    </Root>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;
const SettingsHeader = styled.div`
  width: 25%;
  font-weight: 700;
  font-size: 24px;
  color: #364652;
  padding: 12px;
`;
