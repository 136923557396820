import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { RowWrapper } from "src/ui";
import { Spinner } from "src/components/Spinner";

const getWeatherValues = new Promise((resolve, reject) => {
  const error = false;

  if (!error) {
    setTimeout(() => {
      resolve({
        temperature: "+31",
        wind: "8",
        humidity: "37",
        pressure: "980",
      });
    }, 1000);
  } else {
    reject(Error("Smth. goes wrong."));
  }
});

export const Weather = ({ temperature, wind, humidity, pressure }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [temperatureValue, setTemperatureValue] = useState(
    temperature || "---"
  );
  const [windValue, setWindValue] = useState(wind || "---");
  const [humidityValue, setHumidityValue] = useState(humidity || "---");
  const [pressureValue, setPressureValue] = useState(pressure || "---");

  useEffect(() => {
    const timer1 = setInterval(async () => {
      setIsLoading(true);
      try {
        const weatherValues = await getWeatherValues;

        setTemperatureValue(weatherValues.temperature);
        setWindValue(weatherValues.wind);
        setHumidityValue(weatherValues.humidity);
        setPressureValue(weatherValues.pressure);
      } catch (e) {
        console.error(e.message);
      } finally {
        setIsLoading(false);
      }
    }, 5000);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <Root>
      {isLoading ? (
        <Spinner />
      ) : (
        <RowWrapper>
          <OneFour>
            <RowWrapper justifyContent={"center"} alignItems={"end"}>
              <Value>{temperatureValue}</Value>
              <Label>°C</Label>
            </RowWrapper>
            <Label>{t("temperature")}</Label>
          </OneFour>
          <OneFour>
            <RowWrapper justifyContent={"center"} alignItems={"end"}>
              <Value>{windValue}</Value>
              <Label>{t("meters_sek")}</Label>
            </RowWrapper>
            <Label>{t("wind")}</Label>
          </OneFour>
          <OneFour>
            <RowWrapper justifyContent={"center"} alignItems={"end"}>
              <Value>{humidityValue}</Value>
              <Label>%</Label>
            </RowWrapper>
            <Label>{t("humidity_dashboard")}</Label>
          </OneFour>
          <OneFour>
            <RowWrapper justifyContent={"center"} alignItems={"end"}>
              <Value>{pressureValue}</Value>
              <Label>{t("hPa")}</Label>
            </RowWrapper>
            <Label>{t("pressure")}</Label>
          </OneFour>
        </RowWrapper>
      )}
    </Root>
  );
};

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 18px;
  align-items: center;
`;

const Value = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(p) => p.theme.darkGray};
`;
const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(p) => p.theme.darkGray};
  //align-self: end;
`;
export const OneFour = styled.span`
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
