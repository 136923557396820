import React from 'react'
import styled from 'styled-components'
import ReactTable from 'react-table'

export const BaseTable = ({ className, ...props }) => (
  <TableView
    className={className}
    sortable={true}
    showPagination={false}
    showPaginationTop={false}
    showPaginationBottom={false}
    showPageSizeOptions={false}
    resizable={false}
    {...props}
  />
)

const TableView = styled(ReactTable)`
  &.ReactTable {
    border: none;

    /* remove scrollbars */
    .rt-tbody,
    .rt-table {
      overflow-x: hidden;
    }

    .rt-tr-group,
    .rt-tr {
      display: flex;
      width: 100%;
      flex: 1 0 auto;
    }

    .-loading {
      display: none;
    }

    .rt-noData {
      display: none;
    }

    .rt-thead.rt-th {
      border: none;
    }

    /* scroll */
    .rt-tbody {
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${(p) => p.theme.green};
        outline: 1px solid ${(p) => p.theme.green};
      }
    }

    /* remove padding from cell wrapper */
    .rt-tbody,
    .rt-thead {
      .rt-tr-group,
      .rt-td,
      .rt-rh {
        padding: 0;
        border: none;

        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    /* remove  shadow from header row */
    .rt-thead.-header {
      box-shadow: none;
    }
  }
`
