import moment from 'moment'

export const getDaysArray = (growingCycle) => {
  const initialUnix = moment.unix(growingCycle.startAt)

  // get all days that have stages
  const stagedDays = growingCycle.stages.reduce((acc, stage) => {
    const hasLightSchedule = Boolean(stage.hardwareConfigs[0])
    const hasFanSchedule = false
    const hasWaterSchedule = false

    const currentStageDays = Array(stage.durationInDays)
      .fill()
      .map(() => ({
        stageId: stage.id,
        hasWaterSchedule,
        hasLightSchedule,
        hasFanSchedule,
        isFirstUnscheduled: false,
        date: initialUnix.add(1, 'days').clone()
      }))

    acc = [...acc, ...currentStageDays]

    return acc
  }, [])

  // get all days without stages
  const remainingDaysNumber = growingCycle.durationInDays - stagedDays.length

  if (remainingDaysNumber < 0) {
    // if we have more staged days then cycle's length, return sliced array  of stagedDays
    // this can occur if growingCycle was edited and its durationInDays was set to a smaller value
    return stagedDays.slice(0, growingCycle.durationInDays)
  }

  const restOfDays = Array(remainingDaysNumber > 0 ? remainingDaysNumber : 0)
    .fill()
    .map((_, idx) => {
      const day = {
        stageId: null,
        hasWaterSchedule: false,
        hasLightSchedule: false,
        hasFanSchedule: false,
        isFirstUnscheduled: false,
        date: initialUnix.add(1, 'days').clone()
      }

      if (idx === 0) return { ...day, isFirstUnscheduled: true }

      return day
    })

  return [...stagedDays, ...restOfDays]
}
