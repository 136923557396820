import gql from 'graphql-tag'

export const CURRENT_CYCLE_QUERY = gql`
  query getCycle($id: ID!) {
    growing_cycle(id: $id) {
      id
      name
      start_at
      duration_in_days
      status
      stages {
        id
        duration_in_days
        name
      }
      crop {
        id
        name
      }
      growing_space {
        id
        devices {
          id
          name
          type
          configs {
            id
            name
            settings {
              id
              data
              duration_in_minutes
              order_number
            }
          }
        }
      }

      current_day_number
      current_day {
        id
        stage {
          id
          name
          start_gap_in_minutes
          hardware_configs {
            id
            name
            settings {
              id
              duration_in_minutes
              order_number
              data
            }
          }
        }

        par_sensor_logs {
          id
          additional_data
        }
        co2_sensor_logs {
          id
          value
        }
        temperature_sensor_logs {
          id
          value
        }
        humidity_sensor_logs {
          id
          value
        }
      }
    }
  }
`
