import React from 'react'

const GrowthDynamicsIcon = ({ width, height }) => (
  <svg
    width={width || '24'}
    height={height | '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.13736 2.51923L2.5 2.33713V2.99999C2.5 6.01896 2.99522 9.30662 6.27639 10.9472C6.52338 11.0707 6.82372 10.9706 6.94721 10.7236C7.07071 10.4766 6.9706 10.1763 6.72361 10.0528C4.21621 8.79908 3.5919 6.3947 3.50996 3.69008C4.86445 4.18212 5.92369 4.92691 6.67006 5.87231C7.52993 6.96148 8 8.34885 8 9.99999V16V21.5C8 21.7761 8.22386 22 8.5 22C8.77614 22 9 21.7761 9 21.5V16C9 14.0564 9.3653 12.4108 10.1718 11.1434C10.8774 10.0346 11.9439 9.17836 13.488 8.66802C13.4205 10.4896 13.0733 11.7018 12.569 12.559C12.011 13.5076 11.2286 14.0767 10.2764 14.5528C10.0294 14.6763 9.92929 14.9766 10.0528 15.2236C10.1763 15.4706 10.4766 15.5707 10.7236 15.4472C11.7714 14.9233 12.739 14.2424 13.431 13.066C14.1175 11.899 14.5 10.3003 14.5 8.00001V7.35962L13.8787 7.51494C11.7697 8.04219 10.2799 9.11099 9.32817 10.6066C9.21015 10.792 9.10087 10.9833 9 11.1801V9.99999C9 8.15113 8.47007 6.5385 7.45494 5.25267C6.44136 3.9688 4.96952 3.0427 3.13736 2.51923ZM19.5 21.4399C19.2239 21.4399 19 21.2161 19 20.9399V13.8653L17.8905 15.2523C17.7179 15.4679 17.4033 15.5029 17.1877 15.3304C16.972 15.1579 16.9371 14.8432 17.1096 14.6276L19.0315 12.2252C19.2717 11.9249 19.7283 11.9249 19.9685 12.2252L21.8905 14.6276C22.063 14.8432 22.028 15.1579 21.8124 15.3304C21.5967 15.5029 21.2821 15.4679 21.1096 15.2523L20 13.8653V20.9399C20 21.2161 19.7761 21.4399 19.5 21.4399Z"
      fill="#85B5BA"
    />
  </svg>
)

export default GrowthDynamicsIcon