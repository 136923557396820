import React, { Fragment } from 'react'
import styled from 'styled-components'

import { LightingScheduleTable, PhytophySettings } from './components'

import { Toggler } from 'src/utils/toggler'
import { Button } from 'src/ui'
import { Modal } from 'src/components'

const ScheduleListWrapper = styled.div`
  max-width: 1100px;
  display: flex;
  flex-direction: column;
`

const TableTitle = styled.div`
  margin: 0 0 24px 0;
  ${(p) => p.theme.ProximaNovaSemibold};
  opacity: 0.61;
  font-size: 16px;
`

const NewScheduleButton = styled(Button)`
  margin-top: 10px;
  align-self: flex-end;
`

export const LightingSchedule = ({ schedule, onCreate, onRemove, onUpdate }) => {
  return (
    <ScheduleListWrapper>
      <TableTitle>Schedule</TableTitle>
      <LightingScheduleTable data={schedule} onRemove={onRemove} onUpdate={onUpdate} />

      <Toggler>
        {({ toggled, onToggle }) => (
          <Fragment>
            <Modal isOpen={toggled} onClose={() => onToggle(false)}>
              <PhytophySettings
                onApply={(values) => {
                  onToggle(false)
                  onCreate(values)
                }}
                onClose={() => onToggle(false)}
              />
            </Modal>
            <NewScheduleButton type="button" onClick={() => onToggle(true)}>
              Add new
            </NewScheduleButton>
          </Fragment>
        )}
      </Toggler>
    </ScheduleListWrapper>
  )
}
