import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

import { CustomDateRange } from "./CustomDateRange";

import { CalendarIcon } from "src/components/Icons";
import { ClickOutside } from "src/utils/click-outside";

const Root = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const InputRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: visible;

  background-color: ${(p) => p.theme.lightGray};
  height: 48px;

  cursor: pointer;
  padding: 10px 15px;

  svg {
    fill: ${(p) => p.theme.black};
  }

  :focus {
    box-shadow: ${(p) => !p.isDisabled && `0 0 1px 1px ${p.theme.orange}`};
  }

  ${(p) =>
    p.focused &&
    `
    box-shadow: 0 0 1px 1px ${p.theme.orange};
  `}
`;

const Text = styled.div`
  white-space: nowrap;
  ${(p) => p.theme.RobotoBold};
  max-width: 175px;

  transition: all 0.5s;
  :focus {
    box-shadow: none;
  }

  ${(p) =>
    p.noValue &&
    `
    color: #ccc;
  `}
`;

const PickerRoot = styled(ClickOutside)`
  .rdr-Day.is-selected {
    background-color: ${(p) => p.theme.orange} !important;
  }

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 200;
  right: 0;

  ${(p) =>
    p.pickerPosition === "top" &&
    `
    bottom: 105%;
  `}

  ${(p) =>
    p.pickerPosition === "bottom" &&
    `
    top:  105%;
  `}
`;

export class DateRangePicker extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.oneOf([""]),
      PropTypes.shape({
        startDate: PropTypes.object.isRequired,
        endDate: PropTypes.object.isRequired,
      }),
    ]),
    pickerPosition: PropTypes.oneOf(["top", "bottom"]),
    className: PropTypes.string,
    displayFormat: PropTypes.string,
    icon: PropTypes.element,
    placeholder: PropTypes.element,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    pickerPosition: "top",
    displayFormat: "MMM, DD",
    icon: <CalendarIcon />,
    value: {
      startAt: moment().toDate(),
      endAt: moment().toDate(),
    },
  };

  state = {
    pickerIsShown: false,
  };

  hidePicker = () => this.setState({ pickerIsShown: false });
  showPicker = () => this.setState({ pickerIsShown: true });

  handleSelect = (value) => {
    const isSameValue =
      Boolean(value) &&
      moment(value.startDate).isSame(this.props.value.startDate) &&
      moment(value.endDate).isSame(this.props.value.endDate);

    if (!isSameValue) {
      this.props.onChange(value);
    }
    return this.hidePicker();
  };

  render() {
    const {
      value,
      pickerPosition,
      onChange,
      className,
      displayFormat,
      icon,
      placeholder,
      isDisabled,
    } = this.props;
    const { pickerIsShown } = this.state;

    return (
      <Root>
        <InputRoot
          focused={pickerIsShown}
          tabIndex="0"
          onClick={!isDisabled && this.showPicker}
          className={className}
          isDisabled={isDisabled}
        >
          <IconWrapper>{icon}</IconWrapper>
          <Text noValue={!Boolean(value)}>
            {value
              ? `${moment(value.startDate).format(displayFormat)} - ${moment(
                  value.endDate
                ).format(displayFormat)}`
              : placeholder}
          </Text>
        </InputRoot>

        {pickerIsShown && (
          <PickerRoot
            pickerPosition={pickerPosition}
            onClickOutside={this.hidePicker}
          >
            <CustomDateRange
              startDate={value.startDate}
              endDate={value.endDate}
              ranges={value}
              onChange={onChange}
            />
          </PickerRoot>
        )}
      </Root>
    );
  }
}
