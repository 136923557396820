import React from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'

import {getDaysArray} from '../helpers'

import {DayCard} from '.'


export const DaysSchedule = ({growingCycle}) => {
  const location = useLocation()
  const navigate = useNavigate()
  
  const days = getDaysArray(growingCycle)
  const {stages} = growingCycle

  const handleRedirect = (event) => {
    const {stage, sensor} = event.currentTarget.dataset

    navigate(
      `${location.pathname}growing-stage/${stage}?scheduleType=${sensor}`
    )
  }

  return (
    <DayCardsGrid>
      {days.map((day, idx) => (
        <DayCard
          key={day.date.valueOf()}
          dayNumber={idx + 1}
          day={day}
          stages={stages}
          onRedirect={handleRedirect}
        />
      ))}
    </DayCardsGrid>
  )
}

const DayCardsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px
`

