import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Link } from 'react-router-dom'
import qs from 'qs'

import { Button } from 'src/ui'
import { DropdownArrow } from 'src/components/Icons'

const IconWrapper = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 70px;

  svg {
    fill: ${(p) => p.theme.green};
    transform: rotate(-90deg);
    transition: transform 0.2s ease-out;
  }

  ${(p) =>
    p.active &&
    `
    svg {
      transform: rotate(0deg);
    }
  `}
`

const RowRoot = styled.div`
  width: calc(100% - 8px);
  position: relative;

  min-height: 83px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

const TopRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

const DataList = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 100%;
`

const DataItem = styled.li`
  width: ${(p) => p.width}px;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  ${(p) => p.theme.ProximaNovaSemibold};

  :not(:first-child) {
    border-left: 1px solid ${(p) => p.theme.green};
  }
`

const DataName = styled.span`
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.green};
  font-size: 18px;
  line-height: 22px;
  margin-right: 5px;
`

const DataValue = styled.span`
  ${(p) => p.theme.ProximaNovaBold};
  color: ${(p) => p.theme.darkGray};
  font-size: 18px;
  line-height: 22px;
`

const DataElement = ({ name, value, width }) => (
  <DataItem width={width}>
    <DataName>{name}:</DataName>
    <DataValue>{value}</DataValue>
  </DataItem>
)

const HiddenRow = styled.div`
  height: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  border-radius: 0 0 4px 4px;
  background-color: ${(p) => p.theme.white};

  transition: all 0.2s ease-out;

  ${(p) =>
    p.isOpen && `
      height: 86px;
      padding: 8px 24px;
      margin-bottom: 8px;
      border-top: 1px solid ${p.theme.green};
  `}
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const ButtonWrapper = styled(Button)`
  ${(p) => p.theme.ProximaNovaBold};
  font-size: 18px;
  line-height: 22px;
  padding: 14px 24px;
`
const DropdownRowView = ({data, children}) => {
  const [isActive, setIsActive] = useState(false)

  const toggleActive = () => setIsActive(!isActive)

  return (
    <RowRoot>
      <TopRow>
        {children}
        <IconWrapper active={isActive} onClick={toggleActive}>
          <DropdownArrow />
        </IconWrapper>
      </TopRow>

      <HiddenRow isOpen={isActive}>
        <DataList>
          <DataElement
            width={257}
            name="Harvest Date"
            value={moment.unix(data?.original.endAt).format('DD.MM.YYYY')}
          />
          <DataElement width={174} name="# of Plants" value="42" />
          <DataElement width={128} name="Yield" value="42" />
          <DataElement width={215} name="Plant height" value="42 cm" />
        </DataList>
        <StyledLink
          to={{
            pathname: '/analytics/growing-cycles/compare',
            search: qs.stringify({ cyclesIds: [data?.original.id] })
          }}
        >
          <ButtonWrapper inset as="div">
            ANALYTICS
          </ButtonWrapper>
        </StyledLink>
      </HiddenRow>
    </RowRoot>
  )
}

export const DropdownRow = DropdownRowView
