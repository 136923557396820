import gql from 'graphql-tag'

export const ALL_ALERTS_QUERY = gql`
  query {
    alerts {
      id
      message
      created_at
      resolved
    }
  }
`
