import * as React from "react";

const NumericArrow = () => (
  <svg
    width={8}
    height={16}
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 3.99L3 16L5 16L5 3.99L8 3.99L4 4.76995e-08L1.43218e-07 3.99L3 3.99Z"
      fill="#85B5BA"
    />
  </svg>
);

export default NumericArrow;