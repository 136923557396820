import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { WarningIcon } from "src/components/Icons";

export const NoAlertsNotification = () => {
  const { t } = useTranslation();

  return (
    <IconMessageWrapper>
      <IconMessage iconColor={(p) => p.theme.yieldGreen}>
        <WarningIcon />
        <div className="message">
          <h2>{t("no_alerts")}</h2>
          <div className="description">{t("no_alerts_description")}</div>
        </div>
      </IconMessage>
    </IconMessageWrapper>
  );
};

const IconMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
`;
const IconMessage = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  width: auto;
  background: ${({ color }) => color || "transparent"};
  & svg {
    fill: ${({ iconColor }) => iconColor || "black"};
    width: 90px;
    height: 90px;
  }
  & .message {
    width: 240px;
    margin-left: 32px;
  }
  & .description {
    margin-top: 10px;
  }
`;
