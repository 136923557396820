import React from "react";
import styled from "styled-components";

import { ComparisonLineChart } from "./ComparisonLineChart";
import { ChartLegend } from "./ChartLegend";

import { unitsMap } from "src/utils/helpers";
import { H3 } from "src/ui";

const Root = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px 24px 24px 24px;
`;

const Header = styled(H3)`
  padding: 0;
  margin: 0px;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: -40px;
`;

const Unit = styled.p`
  margin: 0;
  ${(p) => p.theme.ProximaNova};
  color: ${(p) => p.theme.textGrayAux};
  font-size: 18px;
  line-height: 22px;
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
`;

export const ComparisonChartItem = ({
  dataSets,
  chartType,
  colors,
  idsFromQuery,
}) => {
  const cyclesToShow = dataSets.filter((cycle) =>
    idsFromQuery.includes(cycle.cycleId)
  );
  const units = unitsMap[chartType].units;
  const name = unitsMap[chartType].name;

  return (
    <Root>
      <Header>
        {name}
        <Unit>{units}</Unit>
      </Header>
      <ChartWrapper>
        <ComparisonLineChart
          colors={colors}
          showLabels={false}
          containerWidth={700}
          containerHeight={400}
          dataSets={dataSets}
        />
        {idsFromQuery.length === 1 && (
          <ChartLegend
            chartType={chartType}
            colors={colors}
            cyclesToShow={cyclesToShow}
          />
        )}
      </ChartWrapper>
    </Root>
  );
};

export default ComparisonChartItem;
