import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentBox } from "src/components/ContentBox";
import { GrowthDynamicsIcon } from "src/components/Icons";

const MIN = -4;
const MAX = 2;

const windowWidth = window.innerWidth;
const blockWidth = windowWidth < 1400 ? 50 : 25;

let current = 0;
let isUpDirection = true;

export function SoilNPKSensor() {
  const { t } = useTranslation();
  const [sensor, setSensor] = useState({ signal: -18, defined: -18 });

  useEffect(() => {
    current = sensor.defined;

    const interval = setInterval(() => {
      if (isUpDirection && current < sensor.defined + MAX) {
        current = ++current;
        isUpDirection = true;
      }
      if (isUpDirection && current >= sensor.defined + MAX) {
        current = --current;
        isUpDirection = false;
      }
      if (!isUpDirection && current > sensor.defined - Math.abs(MIN)) {
        current = --current;
        isUpDirection = false;
      }
      if (!isUpDirection && current <= sensor.defined - Math.abs(MIN)) {
        current = ++current;
        isUpDirection = true;
      }

      setSensor((prevSensor) => ({ ...prevSensor, signal: current }));
    }, 20000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <ContentBox
      width={`${blockWidth}%`}
      height={"156px"}
      padding={12}
      title={t("soil_NPK")}
      amount={`${sensor.signal}%`}
      totalAmount={t("k_potassium")}
      icon={<GrowthDynamicsIcon />}
      isLeftAmountPosition
      alertType="error"
    />
  );
}
