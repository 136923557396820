import React from 'react'
import PropTypes from 'prop-types'

const CutPieIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3753 5.76C13.2053 4.59 11.6753 4 10.1353 4V10L5.89525 14.24C8.23525 16.58 12.0353 16.58 14.3853 14.24C16.7253 11.9 16.7253 8.1 14.3753 5.76ZM10.1353 0C4.61525 0 0.135254 4.48 0.135254 10C0.135254 15.52 4.61525 20 10.1353 20C15.6553 20 20.1353 15.52 20.1353 10C20.1353 4.48 15.6553 0 10.1353 0ZM10.1353 18C5.71525 18 2.13525 14.42 2.13525 10C2.13525 5.58 5.71525 2 10.1353 2C14.5553 2 18.1353 5.58 18.1353 10C18.1353 14.42 14.5553 18 10.1353 18Z"
      fill="#85B5BA"
    />
  </svg>
)

CutPieIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

CutPieIcon.defaultProps = {
  width: 24,
  height: 24
}

export default CutPieIcon
